import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import EditListingImage from '../EditListingImage/EditListingImage';

import css from './EditListingPricingPanel.module.css';
import clisting10 from '../../assets/screen-13-ripped-img.png';
import clistingmb10 from '../../assets/screen-13-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';

const { Money } = sdkTypes;
export const shouldToFix = (value = 0) => {
  const current = parseFloat(value);
  if (current % 1 === 0) {
    return value;
  } else {
    return current.toFixed(2);
  }
};
const EditListingPricingPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    history,
    backButton,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price } = currentListing.attributes;
  const {
    categories,
    depositOption,
    depositOptionAmount,
    dueDate,
    openTill,
    priceType,
    additionalPricingInfo,
    displayPricing,
    childThreeAmount,
    childTwoAmount,
    siblingDiscount,
    trialFee,
    trialMsg,
    trialOption,
    earlyDropOff,
    latePickup,
    childOne,
    childTwo,
    perChild,
    perAdult,
    perFamily,
    perStudent,
    perOAP,
    trialClasses,
    open,
    closed,
    closingTime,
  } = currentListing.attributes?.publicData;
  const submitValues = values => {
    const {
      price,
      depositOption,
      depositOptionAmount,
      additionalPricingInfo,
      priceType,
      siblingDiscount,
      dueDate,
      childOne,
      childTwo,
      trialClasses,
      open,
      closed,
    } = values;
    const openTillhour = values?.openTill?.hour;
    const openTillminute = values?.openTill?.minute;
    const closingTimehour = values?.closingTime?.hour;
    const closingTimeminute = values?.closingTime?.minute;

    const updatedPrice =
      typeof price === 'number' || typeof price === 'string'
        ? new Money(Number(price) * 100, config.currency)
        : price;
    let updateValues;
    if (
      categories[0] === 'birthdayParty' ||
      categories[0] === 'schoolTours' ||
      categories[0] === 'inSchoolProgrammes'
    ) {
      updateValues = {
        price: updatedPrice,
        publicData: {
          priceType,
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
          openTill: {
            hour: !!openTillhour ? openTillhour : 0,
            minute: !!openTillminute ? openTillminute : 0,
          },

          additionalPricingInfo: !!additionalPricingInfo ? additionalPricingInfo : null,
        },
      };
    } else if (categories[0] === 'afterSchoolClub') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          priceType,
          siblingDiscount: !!siblingDiscount && updatedPrice.amount > 0,
          childOne: { amount: values.childTwoAmount, currency: config.currency },
          childTwo: { amount: values.childThreeAmount, currency: config.currency },
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
          openTill: {
            hour: !!openTillhour ? openTillhour : 0,
            minute: !!openTillminute ? openTillminute : 0,
          },
          closingTime: {
            hour: !!closingTimehour ? closingTimehour : 0,
            minute: !!closingTimeminute ? closingTimeminute : 0,
          },
          additionalPricingInfo: !!additionalPricingInfo ? additionalPricingInfo : null,
          closed,
          open,
        },
      };
    } else if (categories[0] === 'breakfastClub') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          priceType,
          siblingDiscount: !!siblingDiscount && updatedPrice.amount > 0,
          childOne: { amount: values.childTwoAmount, currency: config.currency },
          childTwo: { amount: values.childThreeAmount, currency: config.currency },
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
          openTill: {
            hour: !!openTillhour ? openTillhour : 0,
            minute: !!openTillminute ? openTillminute : 0,
          },
          closingTime: {
            hour: !!closingTimehour ? closingTimehour : 0,
            minute: !!closingTimeminute ? closingTimeminute : 0,
          },
          additionalPricingInfo: !!additionalPricingInfo ? additionalPricingInfo : null,
          closed,
          open,
        },
      };
    } else if (categories[0] === 'club') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          priceType,
          additionalPricingInfo: !!additionalPricingInfo ? additionalPricingInfo : null,
        },
      };
    } else if (categories[0] === 'placesToVisit') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          additionalPricingInfo,
          priceType,
        },
      };
    } else if (categories[0] === 'classes') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
          siblingDiscount: !!siblingDiscount && updatedPrice.amount > 0,
          childOne: { amount: values.childTwoAmount, currency: config.currency },
          childTwo: { amount: values.childThreeAmount, currency: config.currency },
          trialOption: values.trialOption,
          trialFee: { amount: values.trialFee, currency: config.currency },
          trialMsg: values.trialMsg || null,
          trialClasses,
        },
      };
    } else if (categories[0] === 'camps') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
          siblingDiscount: !!siblingDiscount && updatedPrice.amount > 0,
          earlyDropOff: {
            amount: values.earlyDropOff,
            currency: config.currency,
          },
          latePickup: {
            amount: values.latePickup,
            currency: config.currency,
          },
          childOne: { amount: values.childTwoAmount, currency: config.currency },
          childTwo: { amount: values.childThreeAmount, currency: config.currency },
        },
      };
    } else if (categories[0] === 'workshops') {
      updateValues = {
        price: updatedPrice,
        publicData: {
          siblingDiscount: !!siblingDiscount && updatedPrice.amount > 0,
          childOne: { amount: values.childTwoAmount, currency: config.currency },
          childTwo: { amount: values.childThreeAmount, currency: config.currency },
          depositOption: !!depositOption && updatedPrice.amount > 0,
          depositOptionAmount: !!depositOption
            ? { amount: depositOptionAmount, currency: config.currency }
            : null,
          dueDate: !!dueDate ? dueDate.toString() : null,
        },
      };
    } else if (categories[0] === 'events') {
      updateValues = {
        price: new Money(values.perChild * 100, config.currency),
        publicData: {
          perChild: { currency: config.currency, amount: values.perChild },
          perAdult: { currency: config.currency, amount: values.perAdult },
          perStudent: { currency: config.currency, amount: values.perStudent },
          perFamily: { currency: config.currency, amount: values.perFamily },
          perOAP: { currency: config.currency, amount: values.perOAP },
          additionalPricingInfo: values.additionalPricingInfo,
        },
      };
    }
    return updateValues;
  };
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );
  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const saveAndExit = values => {
    const updateValues = submitValues(values);
    if (values.price) {
      savenExit(updateValues);
    } else {
      delete updateValues['price'];
      savenExit(updateValues);
    }
  };

  const form = priceCurrencyValid ? (
    <EditListingPricingForm
      className={css.form}
      backButton={backButton}
      saveAndExit={saveAndExit}
      tabProgress={tabProgress}
      backbtnLoader={backbtnLoader}
      initialValues={
        categories[0] === 'birthdayParty' ||
        categories[0] === 'schoolTours' ||
        categories[0] === 'inSchoolProgrammes'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
              openTill,
              priceType,
              additionalPricingInfo,
            }
          : categories[0] === 'afterSchoolClub'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              childThreeAmount: shouldToFix(childTwo?.amount),
              childTwoAmount: shouldToFix(childOne?.amount),
              siblingDiscount,
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
              openTill,
              closingTime,
              priceType,
              additionalPricingInfo,
              open,
              closed,
            }
          : categories[0] === 'breakfastClub'
          ? {
              childThreeAmount: shouldToFix(childTwo?.amount),
              childTwoAmount: shouldToFix(childOne?.amount),
              siblingDiscount,
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
              openTill,
              closingTime,
              priceType,
              additionalPricingInfo,
              open,
              closed,
            }
          : categories[0] === 'club'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              priceType,
              additionalPricingInfo,
            }
          : categories[0] === 'placesToVisit'
          ? {
              additionalPricingInfo,
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              priceType,
            }
          : categories[0] === 'classes'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
              childThreeAmount: shouldToFix(childTwo?.amount),
              childTwoAmount: shouldToFix(childOne?.amount),
              siblingDiscount,
              trialFee: shouldToFix(trialFee?.amount),
              trialMsg:
                typeof trialMsg === 'string'
                  ? trialMsg
                  : 'One trial per child. \n Trial numbers are limited.',
              trialOption,
              trialClasses,
            }
          : categories[0] === 'camps'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              siblingDiscount,
              earlyDropOff: !!earlyDropOff ? earlyDropOff.amount : 0,
              latePickup: !!latePickup ? latePickup?.amount : 0,
              childThreeAmount: shouldToFix(childTwo?.amount),
              childTwoAmount: shouldToFix(childOne?.amount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
            }
          : categories[0] === 'workshops'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              siblingDiscount,
              childThreeAmount: shouldToFix(childTwo?.amount),
              childTwoAmount: shouldToFix(childOne?.amount),
              depositOption,
              depositOptionAmount: !!depositOptionAmount?.amount
                ? shouldToFix(depositOptionAmount?.amount)
                : shouldToFix(depositOptionAmount),
              dueDate: !!dueDate ? new Date(dueDate) : null,
            }
          : categories[0] === 'events'
          ? {
              price: typeof price?.amount === 'number' ? price?.amount / 100 : 0,
              perChild: perChild?.amount ? shouldToFix(perChild?.amount) : 0,
              perAdult: perAdult?.amount ? shouldToFix(perAdult?.amount) : 0,
              perFamily: perFamily?.amount ? shouldToFix(perFamily?.amount) : 0,
              perStudent: perStudent?.amount ? shouldToFix(perStudent?.amount) : 0,
              perOAP: perOAP?.amount ? shouldToFix(perOAP?.amount) : 0,
              additionalPricingInfo,
            }
          : null
      }
      // onSubmit={onSubmit}
      onSubmit={values => {
        const updatedValues = submitValues(values);
        onSubmit(updatedValues);
      }}
      publicData={listing.attributes.publicData}
      history={history}
      currency={config.currency}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      currentUser={currentUser}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Now for the fun part – set your price "
          logoImg={listinglogo}
          desktopImg={clisting10}
          mobileImg={clistingmb10}
        />

        <div className={css.rightSection}>{form}</div>
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPricingPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPricingPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPricingPanel;
