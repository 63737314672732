import React, { useEffect, useState } from 'react';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
const { Money } = sdkTypes;
const SalesBox = props => {
  const { id, intl } = props;
  const [sales, setSales] = useState(null);
  useEffect(() => {
    if (
      typeof sales !== 'number' &&
      id &&
      process.env.REACT_APP_ESCAPE_RATE_LIMIT_FOR_LIVE === 'true'
    ) {
      const url = `${apiBaseUrl()}/api/get-all-sales?id=${id}`;
      axios.get(url, { withCredentials: true }).then(res => {
        const totalSales =
          res.data.data?.length > 0
            ? res.data.data.reduce((acc, curr) => {
                const payoutTotal = curr?.attributes?.payoutTotal?.amount;
                return acc + payoutTotal;
              }, 0)
            : 0;
        setSales(totalSales);
      });
    }
  });
  const salesAsMoney = formatMoney(intl, new Money(sales || 0, config.currency));
  return (
    <div>
      <span>Paid:</span>
      <span>{salesAsMoney}</span>
    </div>
  );
};

export default SalesBox;
