import React from 'react';
import { bool, func, object, string } from 'prop-types';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingOpenForForm from '../../forms/EditListingOpenForForm/EditListingOpenForForm';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import css from './EditListingOpenForPanel.module.css';
import clisting2 from '../../assets/screen-03-ripped-img.png';
import clistingmb2 from '../../assets/screen-03-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogo.png';
const EditListingOpenForPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    backButton,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = listing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        <EditListingImage
          className={css.imgSection}
          logoImg={listinglogo}
          desktopImg={clisting2}
          mobileImg={clistingmb2}
          imageText="Which of these best describes your availability?"
        ></EditListingImage>
        <div className={css.rightSection}>
          <EditListingOpenForForm
            savenExit={savenExit}
            backbtnLoader={backbtnLoader}
            isPublished={isPublished}
            backButton={backButton}
            saveActionMsg={submitButtonText}
            initialValues={{ openFor: publicData.openFor, schoolName: publicData.schoolName }}
            history={history}
            className={css.form}
            disabled={disabled}
            ready={ready}
            tabProgress={tabProgress}
            updated={panelUpdated}
            onSubmit={values => {
              const { openFor, schoolName } = values;
              const updateValues = {
                publicData: {
                  openFor,
                  schoolName,
                },
              };
              onSubmit(updateValues);
            }}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </div>
      </div>
    </div>
  );
};
EditListingOpenForPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingOpenForPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingOpenForPanel;
