import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';
import { compose } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { getListingType, createSlug } from '../../util/urlHelpers';

import css from './UserNav.module.css';
import { logout } from '../../ducks/Auth.duck';
import { createResourceLocatorString, pathByRouteName } from '../../util/routes';
import config from '../../config';

const listingTab = (listing, selectedPageName) => {
  return {
    text: <FormattedMessage id="UserNav.newListing" />,
    selected: selectedPageName === 'NewListingPage',
    linkProps: {
      name: 'NewListingPage',
    },
  };
  // if (!listing) {
  //   return {
  //     text: <FormattedMessage id="UserNav.newListing" />,
  //     selected: selectedPageName === 'NewListingPage',
  //     linkProps: {
  //       name: 'NewListingPage',
  //     },
  //   };
  // }
  // const currentListing = ensureOwnListing(listing);
  // const id = currentListing.id.uuid;
  // const { title = '', state } = currentListing.attributes;
  // const slug = createSlug(title);
  // const isDraft = state === LISTING_STATE_DRAFT;

  // return {
  //   text: <FormattedMessage id="UserNav.editListing" />,
  //   selected: selectedPageName === 'EditListingPage',
  //   linkProps: {
  //     name: 'EditListingPage',
  //     params: {
  //       id,
  //       slug,
  //       type: getListingType(isDraft),
  //       tab: 'photos',
  //     },
  //   },
  // };
};

const UserNav = props => {
  const { className, rootClassName, selectedPageName, history, currentUser, location } = props;
  const dispatch = useDispatch();
  const onLogout = () =>
    dispatch(logout()).then(() => {
      if (location.pathname.startsWith('/verify-email')) {
        return;
      }
      const path = pathByRouteName('LandingPage', routeConfiguration());

      if (process.env.REACT_APP_DONT_CLEAR_DATA_AFTER_LOGOUT_FOR_LOCAL === 'true') {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }

      console.log('logged out');
    });
  const classes = classNames(rootClassName || css.root, className);
  const providerProfileDisplay =
    currentUser &&
    currentUser?.attributes?.profile?.publicData?.isProvider &&
    currentUser?.attributes?.profile?.publicData?.isProfilePublished
      ? {
          text: 'My Profile',
          selected: selectedPageName === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfilePage',
            params: { id: currentUser?.id.uuid },
          },
        }
      : {
          text: 'My Profile',
          selected: selectedPageName === 'ProfileSettingsPage',
          disabled: false,
          linkProps: {
            name: 'ProfileSettingsBasePage',
          },
        };
  const tabs =
    currentUser && currentUser?.attributes?.profile?.publicData?.isProvider
      ? [
          // {
          //   ...listingTab(listing, selectedPageName),
          // },
          {
            text: 'Activity Listings',
            selected: selectedPageName === 'ManageListingsPage',
            disabled: false,
            linkProps: {
              name: 'ManageListingsBasePage',
            },
          },
          {
            text: 'My Account',
            selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
            disabled: false,
            linkProps: {
              name: 'ContactDetailsPage',
            },
          },
          {
            text: "T&C's",
            selected: selectedPageName === 'TermsAndConditionsPage',
            disabled: false,
            linkProps: {
              name: 'TermsAndConditionsPage',
            },
          },
          {
            text: 'Reviews',
            selected: selectedPageName === 'ReviewsPage',
            disabled: false,
            linkProps: {
              name: 'ReviewsPage',
            },
          },
          { ...providerProfileDisplay },
          {
            text: 'Logout',
            selected: selectedPageName === 'Logout',
            disabled: false,
            onClick: onLogout,
            linkProps: {
              name: 'AuthenticationPage',
            },
          },
        ]
      : [
          {
            text: 'Bookings',
            selected: selectedPageName === 'InboxPage',
            disabled: false,
            linkProps: {
              name: 'InboxPage',
              params: { tab: 'orders' },
            },
          },
          {
            text: 'Favourites',
            selected: selectedPageName === 'FavouritesPage',
            disabled: false,
            linkProps: {
              name: 'FavouritesPage',
            },
          },
          {
            text: 'Profile',
            selected: selectedPageName === 'ProfileSettingsPage',
            disabled: false,
            linkProps: {
              name: 'ProfileSettingsPage',
            },
          },
          {
            text: 'My Account',
            selected: ACCOUNT_SETTINGS_PAGES.includes(selectedPageName),
            disabled: false,
            linkProps: {
              name: 'ContactDetailsPage',
            },
          },
          {
            text: 'Logout',
            selected: selectedPageName === 'Logout',
            disabled: false,
            onClick: onLogout,
            linkProps: {
              name: 'AuthenticationPage',
            },
          },
        ];
  if (!currentUser?.id?.uuid)
    return (
      <div className={css.inviteProv}>
        <button
          type="button"
          onClick={e => {
            history.push(
              createResourceLocatorString('ListMyBusinessPage', routeConfiguration(), {}, {})
            );
          }}
        >
          Providers - learn how to get listed <span className={css.freeUndl}>FREE</span>!
        </button>
      </div>
    );
  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  selectedPageName: string.isRequired,
};
const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    currentUser,
  };
};
export default compose(connect(mapStateToProps, null), injectIntl, withRouter)(UserNav);
