import React, { useState } from 'react';
import { array, arrayOf, bool, func, object, oneOf, shape, string } from 'prop-types';
import { propTypes } from '../../util/types';
import { intlShape } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
  LISTING_PAGE_PARAM_TYPE_EDIT,
} from '../../util/urlHelpers';
import { ensureListing } from '../../util/data';
import { createResourceLocatorString } from '../../util/routes';
import {
  EditListingAvailabilityPanel,
  EditListingDescriptionPanel,
  EditListingFeaturesPanel,
  EditListingLocationPanel,
  EditListingPhotosPanel,
  EditListingPoliciesPanel,
  EditListingPricingPanel,
  // EditListingAgePanel,
} from '../../components';
import EditListingAgePanel from '../EditListingAgePanel/EditListingAgePanel';
import EditListingActivityTypePanel from '../EditListingActivityTypePanel/EditListingActivityTypePanel';
import EditListingGettingNewEnquiryPanel from '../EditListingGettingNewEnquiryPanel/EditListingGettingNewEnquiryPanel';
import css from './EditListingWizard.module.css';
import { apiBaseUrl } from '../../util/api';
import axios from 'axios';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import EditListingCategoriesPanel from '../EditListingCategoriesPanel/EditListingCategoriesPanel';
import EditListingPublishPanel from '../EditListingPublishPanel/EditListingPublishPanel';
import EditListingSubCategoriesPanelComponent from '../EditListingSubCategoriesPanel/EditListingSubCategoriesPanel';
import EditListingTitlePanelComponent from '../EditListingTitlePanel/EditListingTitlePanel';
import EditListingWhatToBringPanelComponent from '../EditListingWhatToBringPanel/EditListingWhatToBringPanel';
import EditListingBookingTypePanel from '../EditListingBookingTypePanel/EditListingBookingTypePanel';
import EditListingNumberOfKidsPanel from '../EditListingNumberOfKidsPanel/EditListingNumberOfKidsPanel';
import EditListingOpenForPanel from '../EditListingOpenForPanel/EditListingOpenForPanel';
import EditListingActivityModePanel from '../EditListingActivityModePanel/EditListingActivityModePanel';
import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { withViewport } from '../../util/contextHelpers';
export const CATEGORIES = 'categories';
export const ACTIVITYTYPE = 'activityType';
export const SUBCATEGORIES = 'subCategories';
export const TITLE = 'title';
export const LOCATION = 'location';
export const DESCRIPTION = 'description';
export const WHATTOBRING = 'whatToBring';
export const SEATS = 'seats';
export const AVAILABILITY = 'availability';
export const PRICING = 'pricing';
export const BOOKINGTYPE = 'bookingType';
export const NUMBEROFKIDS = 'numberOfKids';
export const FEATURES = 'features';
export const POLICY = 'policy';
export const PHOTOS = 'photos';
export const AGE = 'age';
export const PUBLISH = 'publish';
export const GETTINGNEWENQUIRY = 'gettingNewEnquiry';
export const OPENFOR = 'openFor';
export const ACTIVITYMODE = 'activityMode';

// EditListingWizardTab component supports these tabs
export const SUPPORTED_TABS = [
  CATEGORIES,
  ACTIVITYTYPE,
  NUMBEROFKIDS,
  GETTINGNEWENQUIRY,
  OPENFOR,
  ACTIVITYMODE,
  SUBCATEGORIES,
  TITLE,
  LOCATION,
  DESCRIPTION,
  WHATTOBRING,
  FEATURES,
  POLICY,
  SEATS,
  PRICING,
  AVAILABILITY,
  PHOTOS,
  AGE,
  BOOKINGTYPE,
  PUBLISH,
];
const ageOptions = findOptionsForSelectFilter('age', config.custom.filters);
const pathParamsToNextTab = (params, tab, marketplaceTabs, listing) => {
  const nextTabIndex =
    tab === 'activityMode' &&
    listing.attributes.publicData.activityMode &&
    listing.attributes.publicData.activityMode === 'online'
      ? marketplaceTabs.findIndex(s => s === 'age')
      : tab === 'activityMode' &&
        listing.attributes.publicData.activityMode &&
        listing.attributes.publicData.activityMode === 'offline'
      ? marketplaceTabs.findIndex(s => s === 'location')
      : marketplaceTabs.findIndex(s => s === tab) + 1;
  const nextTab =
    nextTabIndex < marketplaceTabs.length
      ? marketplaceTabs[nextTabIndex]
      : marketplaceTabs[marketplaceTabs.length - 1];
  return { ...params, tab: nextTab };
};

// When user has update draft listing, he should be redirected to next EditListingWizardTab
const redirectAfterDraftUpdate = (listingId, params, tab, marketplaceTabs, history, listing) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_DRAFT,
    id: listingId,
  };
  const routes = routeConfiguration();
  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }
  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs, listing);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.replace(to);
};
const redirectAfterEditUpdate = (listingId, params, tab, marketplaceTabs, history, listing) => {
  const currentPathParams = {
    ...params,
    type: LISTING_PAGE_PARAM_TYPE_EDIT,
    id: listingId,
  };
  const routes = routeConfiguration();

  // Replace current "new" path to "draft" path.
  // Browser's back button should lead to editing current draft instead of creating a new one.
  if (params.type === LISTING_PAGE_PARAM_TYPE_NEW) {
    const draftURI = createResourceLocatorString('EditListingPage', routes, currentPathParams, {});
    history.replace(draftURI);
  }
  if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
    const toDashboardTab =
      listing.attributes?.state === 'closed' ? 'snoozedListings' : 'activeListings';
    return history.push(
      createResourceLocatorString('ManageListingsPage', routeConfiguration(), {
        tab: toDashboardTab,
      })
    );
  }
  // Redirect to next tab
  const nextPathParams = pathParamsToNextTab(currentPathParams, tab, marketplaceTabs, listing);
  const to = createResourceLocatorString('EditListingPage', routes, nextPathParams, {});
  history.replace(to);
};

const EditListingWizardTabComponent = props => {
  const [backbtnLoader, setbackbtnLoader] = useState(false);
  const [savebtnLoader, setsavebtnLoader] = useState(false);
  const {
    tab,
    marketplaceTabs,
    params,
    errors,
    fetchInProgress,
    newListingPublished,
    history,
    images,
    listing,
    handleCreateFlowTabScrolling,
    handlePublishListing,
    onAddAvailabilityException,
    onDeleteAvailabilityException,
    onUpdateListing,
    onCreateListingDraft,
    onImageUpload,
    onUpdateImageOrder,
    onRemoveImage,
    onChange,
    currentUser,
    onManageDisableScrolling,
    updatedTab,
    updateInProgress,
    intl,
    fetchExceptionsInProgress,
    availabilityExceptions,
    fetchTimeslotsInProgress,
    timeslots,
    backButton,
    viewport,
    isStarter,
    snoozeLoading,
  } = props;
  const [listingCountUpdateInProgress, setListingCountUpdateInProgress] = useState(false);
  const [disablePublish, setdisablePublish] = useState(false);

  const { type } = params;
  const isNewURI = type === LISTING_PAGE_PARAM_TYPE_NEW;
  const isDraftURI = type === LISTING_PAGE_PARAM_TYPE_DRAFT;
  const isNewListingFlow = isNewURI || isDraftURI;

  const currentListing = ensureListing(listing);
  const imageIds = images => {
    return images ? images.map(img => img.imageId || img.id) : null;
  };
  const savenExit = updateValues => {
    setbackbtnLoader(true);
    if (tab === CATEGORIES) {
      if (currentListing.attributes.publicData.categories) {
        onUpdateListing(tab, { ...updateValues, id: currentListing.id }).then(() => {
          setbackbtnLoader(false);
          history.push(
            createResourceLocatorString(
              'ManageListingsPage',
              routeConfiguration(),
              { tab: 'activeListings' },
              {}
            )
          );
        });
      } else {
        onCreateListingDraft(updateValues).then(() => {
          setbackbtnLoader(false);
          history.push(
            createResourceLocatorString(
              'ManageListingsPage',
              routeConfiguration(),
              { tab: 'activeListings' },
              {}
            )
          );
        });
      }
    } else if (tab === PHOTOS) {
      const { images: updatedImages, ...otherValues } = updateValues;
      const imageProperty =
        typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
      const updateValuesWithImages = { ...otherValues, ...imageProperty };
      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };
      onUpdateListing(tab, upsertValues).then(() => {
        setbackbtnLoader(false);
        history.push(
          createResourceLocatorString(
            'ManageListingsPage',
            routeConfiguration(),
            { tab: 'activeListings' },
            {}
          )
        );
      });
    } else {
      onUpdateListing(tab, { ...updateValues, id: currentListing.id }).then(() => {
        setbackbtnLoader(false);
        history.push(
          createResourceLocatorString(
            'ManageListingsPage',
            routeConfiguration(),
            { tab: 'activeListings' },
            {}
          )
        );
      });
    }
  };
  const tabProgress = tabName => {
    const tabIndex = marketplaceTabs.indexOf(tabName) + 1;
    const tabProgress =
      props.marketplaceTabs.length > 4 ? (tabIndex * 100) / marketplaceTabs.length : 7;
    return tabProgress;
  };
  const onSnoozedSubmit = () => {
    return handlePublishListing(currentListing.id, true);
  };

  const onCompleteEditListingWizardTab = async (tab, updateValues, passThrownErrors = false) => {
    // Normalize images for API call
    const { images: updatedImages, ...otherValues } = updateValues;
    const imageProperty =
      typeof updatedImages !== 'undefined' ? { images: imageIds(updatedImages) } : {};
    const updateValuesWithImages = { ...otherValues, ...imageProperty };
    const state = currentListing?.attributes.state;
    const shouldUpdateCount =
      state === 'published'
        ? tab === LOCATION || tab === AGE
        : state === 'draft'
        ? tab === marketplaceTabs[marketplaceTabs.length - 1]
        : false;
    if (shouldUpdateCount && !!currentListing.attributes.publicData) {
      setListingCountUpdateInProgress(true);
      const url = `${apiBaseUrl()}/api/count/updateCount/${currentListing?.id?.uuid}`;
      const publicData = currentListing.attributes.publicData;

      const data =
        tab === LOCATION
          ? {
              county:
                publicData.activityMode === 'offline' ? updateValues.publicData.county : 'online',
            }
          : tab === AGE
          ? { age: updateValues.publicData.age }
          : {
              county: publicData.activityMode === 'offline' ? publicData?.county : 'online',
              age: publicData?.age,
              category: publicData.categories[0],
            };

      const resp = await axios.post(url, data);
      setListingCountUpdateInProgress(false);
      if (resp.status !== 200) throw new Error('Failed to update listing');
    }

    setsavebtnLoader(true);
    if (isNewListingFlow) {
      const onUpsertListingDraft = isNewURI
        ? (tab, updateValues) => onCreateListingDraft(updateValues)
        : onUpdateListing;
      const upsertValues = isNewURI
        ? updateValuesWithImages
        : { ...updateValuesWithImages, id: currentListing.id };
      return onUpsertListingDraft(tab, upsertValues)
        .then(r => {
          if (tab !== marketplaceTabs[marketplaceTabs.length - 1]) {
            const listing = r.data.data;

            // Create listing flow: smooth scrolling polyfill to scroll to correct tab
            handleCreateFlowTabScrolling(false);
            setsavebtnLoader(false);
            // After successful saving of draft data, user should be redirected to next tab
            redirectAfterDraftUpdate(
              r.data.data.id.uuid,
              params,
              tab,
              marketplaceTabs,
              history,
              listing
            );
          } else if (tab === marketplaceTabs[marketplaceTabs.length - 1]) {
            handlePublishListing(currentListing.id);
          }
        })
        .catch(e => {
          if (passThrownErrors) {
            throw e;
          }
          // No need for extra actions
          // Error is logged in EditListingPage.duck file.
        });
    } else {
      return onUpdateListing(tab, { ...updateValuesWithImages, id: currentListing.id }).then(r => {
        // Create listing flow: smooth scrolling polyfill to scroll to correct tab
        // handleCreateFlowTabScrolling(false);
        setsavebtnLoader(false);
        const listing = r.data.data;
        // After successful saving of draft data, user should be redirected to next tab
        redirectAfterEditUpdate(
          r.data.data.id.uuid,
          params,
          tab,
          marketplaceTabs,
          history,
          listing
        );
      });
    }
  };

  const panelProps = tab => {
    return {
      className: css.panel,
      errors,
      currentUser,
      listing,
      onChange,
      panelUpdated: updatedTab === tab,
      updateInProgress: (updateInProgress || listingCountUpdateInProgress) && savebtnLoader,
      onManageDisableScrolling,
      // newListingPublished and fetchInProgress are flags for the last wizard tab
      ready: newListingPublished,
      disabled: fetchInProgress,
      params,
      backButton,
      currentTab: tab,
      tabProgress,
      savenExit,
      backbtnLoader,
      viewport,
      isStarter,
    };
  };
  switch (tab) {
    case CATEGORIES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingCategoriesPanel
          history={history}
          {...panelProps(CATEGORIES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          isNewURI={isNewURI}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ACTIVITYTYPE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingActivityTypePanel
          history={history}
          {...panelProps(ACTIVITYTYPE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case GETTINGNEWENQUIRY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingGettingNewEnquiryPanel
          history={history}
          {...panelProps(GETTINGNEWENQUIRY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case SUBCATEGORIES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingSubCategoriesPanelComponent
          history={history}
          {...panelProps(SUBCATEGORIES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case TITLE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingTitlePanelComponent
          history={history}
          {...panelProps(TITLE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case LOCATION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewLocation'
        : 'EditListingWizard.saveEditLocation';
      return (
        <EditListingLocationPanel
          history={history}
          {...panelProps(LOCATION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case DESCRIPTION: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingDescriptionPanel
          history={history}
          {...panelProps(DESCRIPTION)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case WHATTOBRING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewDescription'
        : 'EditListingWizard.saveEditDescription';
      return (
        <EditListingWhatToBringPanelComponent
          history={history}
          {...panelProps(WHATTOBRING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case FEATURES: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewFeatures'
        : 'EditListingWizard.saveEditFeatures';
      return (
        <EditListingFeaturesPanel
          history={history}
          {...panelProps(FEATURES)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AGE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAge'
        : 'EditListingWizard.saveEditAge';
      return (
        <EditListingAgePanel
          history={history}
          {...panelProps(AGE)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case POLICY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPoliciesPanel
          history={history}
          {...panelProps(POLICY)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PUBLISH: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPolicies'
        : 'EditListingWizard.saveEditPolicies';
      return (
        <EditListingPublishPanel
          history={history}
          listingCountUpdateInProgress={listingCountUpdateInProgress}
          {...panelProps(PUBLISH)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSnoozedSubmit={onSnoozedSubmit}
          snoozeLoading={snoozeLoading}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case PRICING: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingPricingPanel
          history={history}
          {...panelProps(PRICING)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }

    case BOOKINGTYPE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPricing'
        : 'EditListingWizard.saveEditPricing';
      return (
        <EditListingBookingTypePanel
          history={history}
          {...panelProps(BOOKINGTYPE)}
          timeslots={timeslots}
          availabilityExceptions={availabilityExceptions}
          fetchTimeslotsInProgress={fetchTimeslotsInProgress}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case AVAILABILITY: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewAvailability'
        : 'EditListingWizard.saveEditAvailability';
      return (
        <EditListingAvailabilityPanel
          history={history}
          {...panelProps(AVAILABILITY)}
          fetchExceptionsInProgress={fetchExceptionsInProgress}
          timeslots={timeslots}
          availabilityExceptions={availabilityExceptions}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          onAddAvailabilityException={onAddAvailabilityException}
          onDeleteAvailabilityException={onDeleteAvailabilityException}
          onSubmit={values => {
            // We want to return the Promise to the form,
            // so that it doesn't close its modal if an error is thrown.
            return onCompleteEditListingWizardTab(tab, values);
          }}
          onNextTab={() =>
            redirectAfterDraftUpdate(listing.id.uuid, params, tab, marketplaceTabs, history)
          }
        />
      );
    }
    case PHOTOS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewPhotos'
        : 'EditListingWizard.saveEditPhotos';

      return (
        <EditListingPhotosPanel
          history={history}
          {...panelProps(PHOTOS)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          images={images}
          onImageUpload={onImageUpload}
          onRemoveImage={onRemoveImage}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
          onUpdateImageOrder={onUpdateImageOrder}
        />
      );
    }
    case NUMBEROFKIDS: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewNumberOfKids'
        : 'EditListingWizard.saveEditNumberOfKids';
      return (
        <EditListingNumberOfKidsPanel
          {...panelProps(NUMBEROFKIDS)}
          history={history}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case OPENFOR: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewNumberOfKids'
        : 'EditListingWizard.saveEditNumberOfKids';
      return (
        <EditListingOpenForPanel
          {...panelProps(OPENFOR)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          history={history}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    case ACTIVITYMODE: {
      const submitButtonTranslationKey = isNewListingFlow
        ? 'EditListingWizard.saveNewNumberOfKids'
        : 'EditListingWizard.saveEditNumberOfKids';
      return (
        <EditListingActivityModePanel
          {...panelProps(OPENFOR)}
          submitButtonText={intl.formatMessage({ id: submitButtonTranslationKey })}
          history={history}
          intl={intl}
          onSubmit={values => {
            onCompleteEditListingWizardTab(tab, values);
          }}
        />
      );
    }
    default:
      return null;
  }
};

EditListingWizardTabComponent.defaultProps = {
  listing: null,
  updatedTab: null,
  availabilityExceptions: [],
};

EditListingWizardTabComponent.propTypes = {
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    tab: oneOf(SUPPORTED_TABS).isRequired,
  }).isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  errors: shape({
    createListingDraftError: object,
    publishListingError: object,
    updateListingError: object,
    showListingsError: object,
    uploadImageError: object,
    fetchExceptionsError: object,
    addExceptionError: object,
    deleteExceptionError: object,
  }).isRequired,
  fetchInProgress: bool.isRequired,
  fetchExceptionsInProgress: bool.isRequired,
  newListingPublished: bool.isRequired,
  history: shape({
    push: func.isRequired,
    replace: func.isRequired,
  }).isRequired,
  images: array.isRequired,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  handleCreateFlowTabScrolling: func.isRequired,
  handlePublishListing: func.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onUpdateListing: func.isRequired,
  onCreateListingDraft: func.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onRemoveImage: func.isRequired,
  onChange: func.isRequired,
  updatedTab: string,
  updateInProgress: bool.isRequired,

  intl: intlShape.isRequired,
};
const EditListingWizardTab = compose(withRouter, withViewport)(EditListingWizardTabComponent);
export default EditListingWizardTab;
