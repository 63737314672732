import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, FieldTextInput, Modal } from '../../components';
import css from './EditListingGettingNewEnquiryForm.module.css';
import { FormattedMessage } from 'react-intl';
import { BiCheck, BiInfoCircle } from 'react-icons/bi';
import {
  GETTINGNEWENQUIRY,
  PRICING,
} from '../../components/EditListingWizard/EditListingWizardTab';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import * as validators from '../../util/validators';
import { BsDot } from 'react-icons/bs';
function EditListingGettingNewEnquiryForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          categories,
          initialValues,
          tabProgress,
          savenExit,
          backbtnLoader,
          publicData,
          isStarter,
          onManageDisableScrolling,
        } = formRenderProps;
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [urlError, setUrlError] = useState(false);
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};

        const tabPercent = tabProgress(GETTINGNEWENQUIRY);
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = disabled || submitInProgress;
        const lastTab = PRICING;
        const onCheck = e => {
          if (!publicData.bookingType) form.change('bookingType', e.target.value);
        };
        const urlRequired = validators.required(
          'We need a url to redirect customers to your website'
        );
        const urlValid = validators.validURL('Url format invalid');
        const offPlatformMaybe = (
          <div className={`${css.bborow} ${css.bborowbtmm}`}>
            <input
              type="radio"
              id="offPlatform"
              value="offPlatform"
              name="bookingType"
              className={css.checkbox}
              onChange={e => onCheck(e)}
              disabled={isStarter}
              defaultChecked={initialValues.bookingType === 'offPlatform'}
            />{' '}
            <label
              htmlFor="offPlatform"
              className={classNames(css.checkboxLabel, {
                [css.selectedCheckboxLabel]: values.bookingType === 'offPlatform',
              })}
            >
              <div className={css.decoyDiv}>
                <div className={css.offPlatform}>
                  <span className={css.tckspn}>
                    {values.bookingType === 'offPlatform' ? <BiCheck /> : null}
                    {isStarter && <span className={css.subInfoArro}></span>}
                    {isStarter && (
                      <span className={css.subInfo}>
                        <span>Membership Feature</span>
                        <span>
                          <b>Upgrade</b> anytime for <b>off-platform booking</b>. By upgrading, you
                          access all features and turbo-boost your activities. Check your affordable
                          options within{' '}
                          <b>
                            <u>My Plan</u>
                          </b>{' '}
                          now.
                        </span>
                      </span>
                    )}
                  </span>
                  <div className={css.ibbox}>
                    <h2
                      className={classNames(css.someclass, {
                        [css.colWhite]: values.bookingType === 'offPlatform',
                      })}
                    >
                      Off-Platform Booking{' '}
                      <span className={css.tooltipContainer}>
                        <button
                          type="button"
                          onClick={e => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                          }}
                          className={css.tooltip}
                        >
                          <BiInfoCircle />
                        </button>
                        {/* <span className={css.tooltipInfo}>
                          <span className={css.headspn}>
                            <span>Off-Platform Booking</span>
                            <span> - Pros</span>
                          </span>
                          <span className={css.prospn}>
                            <BsDot /> <span>Move customers to your website</span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />
                            <span>Use unique features you've developed</span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />
                            <span> Keep your bookings the same as always</span>
                          </span>{' '}
                        </span> */}
                      </span>
                    </h2>
                  </div>
                  <p
                    className={classNames(css.someclass, {
                      [css.colWhite]: values.bookingType === 'offPlatform',
                    })}
                  >
                    <b>THREE step booking</b>. Move your interested customers from our website to
                    your website, to complete bookings.
                  </p>
                </div>
              </div>
            </label>
            <>
              <FieldTextInput
                id="referalLink"
                name="referalLink"
                type="text"
                disabled={values.bookingType !== 'offPlatform'}
                onChange={e => {
                  const value = e.target.value;
                  if (values.bookingType === 'offPlatform') {
                    form.change('referalLink', value);
                  }
                }}
                className={classNames(css.referalLink, {
                  [css.urlerr]: urlError,
                  [css.hiderflnk]: values.bookingType !== 'offPlatform',
                })}
                placeholder="Add your website address here for this activity"
                validate={validators.composeValidators(urlRequired, urlValid)}
              />
              <span
                className={classNames(css.mustinc, {
                  [css.hiderflnk]: values.bookingType !== 'offPlatform',
                })}
              >
                Must include your domain name
              </span>
            </>
          </div>
        );
        const instantBookingMaybe = (
          <div className={css.bborow}>
            <input
              type="radio"
              id="instantBooking"
              value="instantBooking"
              className={css.checkbox}
              name="bookingType"
              onChange={e => onCheck(e)}
              disabled={isStarter}
              defaultChecked={initialValues.bookingType === 'instantBooking'}
            />

            <label
              htmlFor="instantBooking"
              className={classNames(css.checkboxLabel, {
                [css.selectedCheckboxLabel]: values.bookingType === 'instantBooking',
              })}
            >
              {/* <span className={css.checkicon}>
                {values.bookingType === 'instantBooking' ? (
                  <GrCheckboxSelected className={css.checkboxIconTrial} />
                ) : (
                  <GrCheckbox className={css.checkboxIconTrial} />
                )}
              </span> */}
              <div className={css.decoyDiv}>
                <div className={css.offPlatform}>
                  <span className={css.tckspn}>
                    {values.bookingType === 'instantBooking' ? <BiCheck /> : null}
                  </span>
                  <div className={css.ibbox}>
                    <h2
                      className={classNames(css.someclass, {
                        [css.colWhite]: values.bookingType === 'instantBooking',
                      })}
                    >
                      Express Booking{' '}
                      <span className={css.tooltipContainer}>
                        <button
                          type="button"
                          onClick={e => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                          }}
                          className={css.tooltip}
                        >
                          <BiInfoCircle />
                        </button>
                        {/* <span className={`${css.xprstool} ${css.tooltipInfo}`}>
                          <span className={css.headspn}>
                            <span>Express Booking</span>
                            <span> - Pros</span>
                          </span>
                          <span className={css.prospn}>
                            <BsDot />
                            <span>Enjoy higher ranking in our searches</span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot /> <span>Get paid instantly via Stripe</span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />
                            <span>Customers book on your listing page</span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />{' '}
                            <span>
                              Personal dashboard - track revenue, spaces & waiting list on the go.
                            </span>
                          </span>
                          <span className={css.prospn}>
                            <BsDot />
                            <span>
                              Offer customers trials, sibling discounts and deposit options here.
                            </span>
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />
                            <span>Customer communication tools.</span>{' '}
                          </span>{' '}
                          <span className={css.prospn}>
                            <BsDot />
                            <span>You can trail some spaces to see how it works.</span>
                          </span>
                        </span> */}
                      </span>
                    </h2>
                  </div>
                  <p
                    className={classNames(css.someclass, {
                      [css.colWhite]: values.bookingType === 'instantBooking',
                    })}
                  >
                    <b>ONE step booking.</b> Your customers complete on-page bookings instantly.
                  </p>
                </div>
              </div>
            </label>
          </div>
        );
        const instantEnquiryMaybe = (
          <div className={css.bborow}>
            <input
              type="radio"
              id="instantEnquiry"
              value="instantEnquiry"
              className={css.checkbox}
              name="bookingType"
              onChange={e => onCheck(e)}
              defaultChecked={initialValues.bookingType === 'instantEnquiry'}
            />

            <label
              htmlFor="instantEnquiry"
              className={classNames(css.checkboxLabel, {
                [css.selectedCheckboxLabel]: values.bookingType === 'instantEnquiry',
              })}
            >
              {/* <span className={css.checkicon}>
                {values.bookingType === 'instantEnquiry' ? (
                  <GrCheckboxSelected className={css.checkboxIconTrial} />
                ) : (
                  <GrCheckbox className={css.checkboxIconTrial} />
                )}
              </span> */}
              <div className={css.decoyDiv}>
                {' '}
                <div className={css.offPlatform}>
                  <span className={css.tckspn}>
                    {values.bookingType === 'instantEnquiry' ? <BiCheck /> : null}
                    {isStarter && <span className={css.subInfoArro}></span>}
                    {isStarter && (
                      <span className={css.subInfo}>
                        <span>Membership Feature</span>
                        <span>
                          <b>Upgrade</b> anytime for <b>instant-enquiry</b>. By upgrading, you
                          access all features and turbo-boost your activities. Check your affordable
                          options within{' '}
                          <b>
                            <u>My Plan</u>
                          </b>{' '}
                          now.
                        </span>
                      </span>
                    )}
                  </span>
                  <div className={css.ibbox}>
                    <h2
                      className={classNames(css.someclass, {
                        [css.colWhite]: values.bookingType === 'instantEnquiry',
                      })}
                    >
                      Instant Enquiry{' '}
                      <span className={css.tooltipContainer}>
                        <button
                          type="button"
                          onClick={e => {
                            e.stopPropagation();
                            setIsModalOpen(true);
                          }}
                          className={css.tooltip}
                        >
                          <BiInfoCircle />
                        </button>
                        {/* <span className={css.tooltipInfo}>
                          <span className={css.headspn}>
                            <span>Instant Enquiry</span>
                          </span>
                          <span className={css.prospn}>
                            <BsDot />
                            <span>
                              At the moment Activity Time does not have a booking option on our
                              platform for this type of activity. Your customer enquires will go to
                              the email address provided in your profile.
                            </span>
                          </span>
                        </span> */}
                      </span>
                    </h2>
                  </div>
                  <p
                    className={classNames(css.someclass, {
                      [css.colWhite]: values.bookingType === 'instantEnquiry',
                    })}
                  >
                    Encourage customers to get in touch with any questions to help complete a
                    booking.
                  </p>
                </div>
              </div>
            </label>
          </div>
        );
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const { bookingType, referalLink } = values;
              const updateValues = {
                publicData: {
                  bookingType,
                  referalLink,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        return (
          <Form
            className={classes}
            onSubmit={e => {
              e.preventDefault();
              props.onSubmit(values);
            }}
          >
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              {/* {errorMessageCreateListingDraft}
              {errorMessageUpdateListing}
              {errorMessageShowListing} */}

              <div className={css.mainContent}>
                {categories?.includes('afterSchoolClub') ||
                categories?.includes('birthdayParty') ||
                categories?.includes('breakfastClub') ||
                categories?.includes('schoolTours') ||
                categories?.includes('inSchoolProgrammes') ? (
                  <h2>
                    Activity Time customers are 65% more likely to complete a booking when they get
                    a response within 12 hours.
                  </h2>
                ) : categories?.includes('club') ||
                  categories?.includes('placesToVisit') ||
                  categories?.includes('events') ? (
                  <>
                    <div className={css.bookingTypeDiv}>
                      {offPlatformMaybe}
                      {instantEnquiryMaybe}
                    </div>
                  </>
                ) : categories?.includes('classes') ||
                  categories?.includes('camps') ||
                  categories?.includes('workshops') ? (
                  <>
                    <div className={css.bookingTypeDiv}>
                      <h2>
                        Pick <span>your</span> best option:
                      </h2>
                      {instantBookingMaybe}
                      {offPlatformMaybe}
                    </div>
                  </>
                ) : null}
              </div>
            </div>

            <div className={css.formlistbtmsec}>
              <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={
                    submitDisabled ||
                    (categories?.includes('club') && !values.bookingType) ||
                    (values.bookingType === 'offPlatform' && (!values.referalLink || urlError)) ||
                    (categories?.includes('placesToVisit') && !values.bookingType) ||
                    (categories?.includes('classes') && !values.bookingType) ||
                    (categories?.includes('camps') && !values.bookingType)
                  }
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
            <Modal
              id="bookingTYpeTip"
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              // usePortal
              onManageDisableScrolling={onManageDisableScrolling}
            >
              <div className={css.termsWrapper}>
                <div className={css.trmtp}>
                  <span className={`${css.xprstool} ${css.tooltipInfo}`}>
                    <span className={css.headspn}>
                      <span>Express Booking</span>&nbsp; <span>Advantages?</span>
                    </span>
                    <span className={css.prospn}>
                      <BsDot />
                      <span>On-page bookings convert significantly higher</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot />
                      <span>Enjoy priority ranking in our searches</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot /> <span>Get paid instantly via Stripe</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot />
                      <span>Save time: connect marketing, ticketing & admin</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot />{' '}
                      <span>
                        <u>Automated Features include:</u>
                      </span>
                    </span>
                    <span className={css.bulletspan}>
                      <span>Trial spaces</span>
                      <span>Waiting list</span>
                      <span>Sibling discounts</span>
                      <span>Low spaces notification</span>
                      <span>Customer reviews</span>
                      <span>Tracked revenue & customer info</span>
                      <span>Direct customer communication tools</span>
                    </span>
                  </span>
                  <span className={css.tooltipInfo}>
                    <span className={css.headspn}>
                      <span>Off-Platform Booking</span>&nbsp;
                      <span> Advantages?</span>
                    </span>
                    <span className={css.prospn}>
                      <BsDot /> <span>Move traffic to your website</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot />
                      <span>Use unique features you've developed</span>
                    </span>{' '}
                    <span className={css.prospn}>
                      <BsDot />
                      <span>Keep your bookings the same as always</span>
                    </span>{' '}
                    {/* <span className={css.prospn}>
                      <BsDot />
                      <span>Pay only €0.19 when a primed customer connects to your website.</span>
                    </span>{' '} */}
                  </span>
                  {categories?.includes('club') ||
                  categories?.includes('placesToVisit') ||
                  categories?.includes('events') ? (
                    <span className={css.tooltipInfo}>
                      <span className={css.headspn}>
                        <span>Instant Enquiry</span>
                      </span>
                      <span className={css.prospn}>
                        <BsDot />
                        <span>
                          At the moment Activity Time does not have a booking option on our platform
                          for this type of activity. Your customer enquires will go to the email
                          address provided in your profile.
                        </span>
                      </span>
                    </span>
                  ) : null}
                </div>
                <div className={css.trmbtm}>
                  <label>
                    This listing is bookable in a just a few clicks… with Express Booking
                  </label>
                </div>
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  );
}

export default EditListingGettingNewEnquiryForm;
