/**
 *  TopbarMobileMenu prints the menu content for authenticated user or
 * shows login actions for those who are not authenticated.
 */
import React, { useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import { ensureCurrentUser } from '../../util/data';
import { AvatarLarge, InlineTextButton, NamedLink, NotificationBadge } from '../../components';

import css from './TopbarMobileMenu.module.css';
import { useSelector } from 'react-redux';
import SwitchUserModal from '../SwitchUserModal/SwitchUserModal';

const TopbarMobileMenu = props => {
  const {
    isAuthenticated,
    currentPage,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
    notificationCount,
    onLogout,
  } = props;
  const [modalOpen, setModalOpen] = useState(false);

  const user = ensureCurrentUser(currentUser);
  const isProvider = isAuthenticated && !!currentUser?.attributes?.profile?.publicData?.isProvider;
  const isCustomer = isAuthenticated && !currentUser?.attributes?.profile?.publicData?.isProvider;
  // const isAuthenticated = useSelector(state=>state.Auth.isAuthenticated)
  // if (!isAuthenticated) {
  //   const signup = (
  //     <NamedLink name="SignupPage" className={css.signupLink}>
  //       <FormattedMessage id="TopbarMobileMenu.signupLink" />
  //     </NamedLink>
  //   );

  //   const login = (
  //     <NamedLink name="LoginPage" className={css.loginLink}>
  //       <FormattedMessage id="TopbarMobileMenu.loginLink" />
  //     </NamedLink>
  //   );

  //   const signupOrLogin = (
  //     <span className={css.authenticationLinks}>
  //       <FormattedMessage id="TopbarMobileMenu.signupOrLogin" values={{ signup, login }} />
  //     </span>
  //   );
  //   return (
  //     <div className={css.root}>
  //       <div className={css.content}>
  //         <div className={css.authenticationGreeting}>
  //           <FormattedMessage
  //             id="TopbarMobileMenu.unauthorizedGreeting"
  //             values={{ lineBreak: <br />, signupOrLogin }}
  //           />
  //         </div>
  //       </div>
  //       {/* <div className={css.footer}>
  //         <NamedLink className={css.createNewListingLink} name="NewListingPage">
  //           <FormattedMessage id="TopbarMobileMenu.newListingLink" />
  //         </NamedLink>
  //       </div> */}
  //     </div>
  //   );
  // }

  const notificationCountBadge =
    notificationCount > 0 ? (
      <NotificationBadge className={css.notificationBadge} count={notificationCount} />
    ) : null;

  const displayName = user.attributes.profile.firstName;
  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const dummyModalOpener = name => {
    return (
      <span className={css.link} onClick={() => setModalOpen(true)}>
        {name}
      </span>
    );
  };
  return (
    <div className={css.root}>
      {!isAuthenticated && (
        <NamedLink name="LoginPage" className={css.btndv}>
          Sign Up / Log in
        </NamedLink>
      )}
      <div className={css.content}>
        <div className={css.searches}>
          <label className={css.linkslabel}>Parents:</label>
          <ul className={css.list}>
            <li className={css.listItem}>
              {isProvider ? (
                dummyModalOpener('Search Activities')
              ) : (
                <NamedLink name="SearchPage" className={css.link}>
                  Search Activities
                </NamedLink>
              )}
            </li>
            <li className={css.listItem}>
              {isProvider ? (
                dummyModalOpener('Trust and Safety')
              ) : (
                <NamedLink name="TrustAndSafetyPage" className={css.link}>
                  <FormattedMessage id="Footer.trustAndSafety" />
                </NamedLink>
              )}
            </li>
            {/* <li className={css.listItem}>
              {isProvider ? (
                dummyModalOpener('Blog for Parents')
              ) : (
                <NamedLink name="LandingPage" className={css.link}>
                  <FormattedMessage id="Footer.parentsBlogPage" />
                </NamedLink>
              )}
            </li> */}
            <li className={css.listItem}>
              {isProvider ? (
                dummyModalOpener('Newsletter')
              ) : (
                <NamedLink name="LandingPage" className={css.link}>
                  Newsletter
                </NamedLink>
              )}
            </li>
          </ul>
        </div>
        <div className={css.searches}>
          <label className={css.linkslabel}>Activity Providers:</label>
          <ul className={css.list}>
            <li className={css.listItem}>
              {isCustomer ? (
                dummyModalOpener('Add New Activity')
              ) : (
                <NamedLink name="CreateListingPage" className={css.link}>
                  Add New Activity
                </NamedLink>
              )}
            </li>
            <li className={css.listItem}>
              <NamedLink name="ListMyBusinessPage" className={css.link}>
                New Providers
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="ListMyBusinessPage" className={css.link}>
                Pricing
              </NamedLink>
            </li>
            {/* <li className={css.listItem}>
              {!isCustomer ? (
                dummyModalOpener('Blog for Providers')
              ) : (
                <NamedLink name="LoginPage" className={css.link}>
                  <FormattedMessage id="Footer.providerBlogPage" />
                </NamedLink>
              )}
            </li> */}
          </ul>
        </div>
        <div className={css.searches}>
          <label className={css.linkslabel}>Useful Links:</label>
          <ul className={css.list}>
            <li className={css.listItem}>
              <NamedLink name="AboutPage" className={css.link}>
                <FormattedMessage id="Footer.toAboutPage" />
              </NamedLink>
            </li>
            <li className={css.listItem}>
              <NamedLink name="ContactUsPage" className={css.link}>
                <FormattedMessage id="Footer.toContactUs" />
              </NamedLink>
            </li>
          </ul>
        </div>
      </div>
      <SwitchUserModal modalOpen={modalOpen} isProvider={isProvider} setModalOpen={setModalOpen} />
    </div>
  );
};

TopbarMobileMenu.defaultProps = {
  currentUser: null,
  notificationCount: 0,
  currentPage: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarMobileMenu.propTypes = {
  isAuthenticated: bool.isRequired,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  notificationCount: number,
  onLogout: func.isRequired,
};

export default TopbarMobileMenu;
