import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import css from './EditListingTitlePanel.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { classTypes, defaultTitle } from '../../marketplace-custom-config';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingTitleFormComponent from '../../forms/EditListingTitleForm/EditListingTitleForm';
import { types as sdkTypes } from '../../util/sdkLoader';
import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-05-icon.png';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { campsActivityTypeOptions } from '../../assets/activityTypeOptions';

const EditListingTitlePanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    currentTab,
    savenExit,
    currentUser,
    backbtnLoader,
  } = props;
  const businessName = currentUser?.attributes?.profile?.publicData.bname;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { title, publicData } = currentListing.attributes;
  const { isTitleSet } = listing.attributes.publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  const titleAddon = publicData?.categories?.includes('camps')
    ? publicData.activityType?.includes('other')
      ? publicData.activityTypeOthers
      : campsActivityTypeOptions.filter(o => o.value === publicData.activityType?.[0])[0].label
    : publicData?.categories?.includes('schoolTours')
    ? 'School Tour'
    : publicData?.categories?.includes('afterSchoolClub')
    ? 'After-School Club'
    : publicData?.categories?.includes('birthdayParty')
    ? 'Party'
    : publicData?.categories?.includes('breakfastClub')
    ? 'Breakfast Club'
    : publicData?.categories?.includes('classes')
    ? 'Classes'
    : publicData?.categories?.includes('inSchoolProgrammes')
    ? 'In-School Programme'
    : publicData?.categories?.includes('workshops')
    ? 'Workshop'
    : publicData?.categories?.includes('events')
    ? 'Event'
    : publicData?.categories?.includes('club')
    ? 'Club'
    : '';
  const submitFunc = values => {
    const { title, classType } = values;

    const titleWithAddon = `${title} ${classType?.value ? classType.label : titleAddon}`;
    let updateValues;
    if (publicData.categories[0] === 'club') {
      updateValues = {
        title: titleWithAddon.trim(),
        publicData: {
          isTitleSet: title ? true : false,
          clubName: values.clubName,
          email: values.email,
          phone: values.phone,
        },
      };
    } else if (publicData.categories[0] === 'placesToVisit') {
      updateValues = {
        title: title,
        publicData: {
          isTitleSet: title ? true : false,
          businessName: values.businessName,
        },
      };
    } else if (publicData.categories[0] === 'schoolTours') {
      updateValues = {
        title: titleWithAddon.trim(),
        publicData: {
          isTitleSet: title ? true : false,
          businessName: values.businessName,
        },
      };
    } else if (publicData.categories[0] === 'classes') {
      updateValues = {
        title: titleWithAddon.trim(),
        publicData: {
          isTitleSet: title ? true : false,
          classType: classType,
        },
      };
    } else {
      updateValues = {
        title: titleWithAddon.trim(),
        publicData: {
          isTitleSet: title ? true : false,
        },
      };
    }

    return updateValues;
  };
  const saveAndExit = values => {
    const updateValues = submitFunc(values);
    if (values.title && updateValues) {
      savenExit(updateValues);
    } else {
      delete updateValues['title'];
      savenExit(updateValues);
    }
  };
  const titleForDisp =
    title &&
    title.replace(` ${publicData?.classType ? publicData?.classType.label : titleAddon}`, '');

  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}

        <EditListingImage
          className={css.imgSection}
          imageText={
            publicData.categories[0] === 'schoolTours'
              ? 'Let’s give your school tour package a name'
              : 'Let’s give your activity a name'
          }
          currentTab={currentTab}
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
          bgIcon={screenicon}
          publicData={publicData}
        ></EditListingImage>

        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingTitleFormComponent
            saveAndExit={saveAndExit}
            titleAddon={titleAddon}
            isPublished={isPublished}
            backbtnLoader={backbtnLoader}
            savenExit={savenExit}
            backButton={backButton}
            tabProgress={tabProgress}
            listing={listing}
            className={css.form}
            initialValues={{
              title: isTitleSet
                ? publicData.categories[0] === 'placesToVisit'
                  ? title
                  : titleForDisp
                : null,
              tagline: isTitleSet
                ? publicData.categories[0] === 'placesToVisit'
                  ? title
                  : null
                : null,
              clubName: publicData.categories[0] === 'club' ? publicData.clubName : null,
              email: publicData.categories[0] === 'club' ? publicData.email : null,
              phone: publicData.categories[0] === 'club' ? publicData.phone : null,
              businessName: publicData.businessName ? publicData.businessName : businessName,
              classType: publicData.classType ? publicData.classType : null,
            }}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const updateValues = submitFunc(values);
              updateValues && onSubmit(updateValues);
            }}
            history={history}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
          />
        </div>
      </div>
    </div>
  );
};

EditListingTitlePanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingTitlePanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingTitlePanelComponent;
