import React from 'react';
import css from './ManageListingCard.module.css';
import { BsDot, BsThreeDots } from 'react-icons/bs';
import classNames from 'classnames';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
  Modal,
  Button,
} from '../../components';
import moment from 'moment';
import { placesToVisitActivityTypeOptions } from '../../assets/activityTypeOptions';
import { LISTING_STATE_CLOSED, LISTING_STATE_DRAFT } from '../../util/types';
import SalesBox from './SalesBox';
const MENU_CONTENT_OFFSET = -12;

const ManageListingCardTop = props => {
  const {
    currentListing,
    menuComponent = null,
    listingBookings,
    onTitleClick,
    currentUser,
    intl,
  } = props;
  const { bname, county } = currentUser?.attributes.profile.publicData || {};
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const { price, publicData, state, title } = currentListing?.attributes || {};
  const { waitingList = [] } = currentListing?.attributes?.privateData || {};
  const currentStock = currentListing?.currentStock?.attributes?.quantity;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;
  const priceTypeTexts = (
    <span style={{ color: '#616161b0' }}>
      {publicData?.priceType === 'perHour'
        ? 'per hour'
        : publicData?.priceType === 'perChild'
        ? 'per child'
        : publicData?.priceType === 'totalPrice'
        ? 'total price'
        : publicData?.priceType === 'perMorning'
        ? 'per morning'
        : publicData?.priceType === 'perAfternoon'
        ? 'per afternoon'
        : publicData?.priceType === 'perDay'
        ? 'per day'
        : publicData?.priceType === 'perTerm'
        ? 'per term'
        : publicData?.priceType === 'perClass'
        ? 'per class'
        : publicData?.priceType === 'perYear'
        ? 'per year'
        : publicData?.priceType === 'perMonth'
        ? 'per month'
        : publicData?.priceType === 'perAdult'
        ? 'per adult'
        : null}
    </span>
  );
  const priceDisplay = (
    <div className={css.datetimeinfocol}>
      <span>{'Cost: '}</span>
      <span className={css.pricespn}>
        {price ? (price?.amount === 0 ? 'Free' : `€${price?.amount / 100}`) : 'Not Set'}
      </span>
      {` `}
      <span style={{ color: '#616161b0' }}>
        {publicData?.categories?.[0] === 'classes' && publicData?.noOfClasses
          ? `(${publicData?.noOfClasses} ${
              publicData?.classType ? publicData?.classType?.label : 'classes'
            })`
          : publicData?.categories?.[0] === 'camps' && publicData?.noOfDays
          ? `(${publicData?.noOfDays} ${publicData?.noOfDays > 1 ? 'days' : 'day'})`
          : publicData?.categories?.[0] === 'workshops'
          ? ''
          : null}
      </span>
      {price?.amount === 0 ? '' : priceTypeTexts}
    </div>
  );
  const eventTimes = publicData?.day && (
    <div className={css.datetimeinfocol}>
      {publicData?.day?.map((d, i) => (
        <div key={i}>
          <span>Time (day {i + 1}): </span>{' '}
          {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
          {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
          {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
          {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
        </div>
      ))}
    </div>
  );
  const openTillMaybe = (
    <div className={css.datetimeinfocol}>
      <span>
        {publicData?.categories?.[0] === 'inSchoolProgrammes'
          ? 'Time: '
          : publicData?.categories?.[0] === 'birthdayParty' ||
            publicData?.categories?.[0] === 'schoolTours'
          ? 'Length: '
          : publicData?.categories?.[0] === 'breakfastClub' ||
            publicData?.categories?.[0] === 'afterSchoolClub'
          ? 'Opens: '
          : 'Closes: '}
      </span>
      {publicData?.openTill ? (
        <>
          {`${
            publicData?.openTill?.hour < 10
              ? `0${publicData?.openTill?.hour}`
              : publicData?.openTill?.hour
          }:${
            publicData?.openTill?.minute < 10
              ? `0${publicData?.openTill?.minute}`
              : publicData?.openTill?.minute
          }`}{' '}
          {publicData?.categories?.[0] === 'birthdayParty' ||
          publicData?.categories?.[0] === 'schoolTours' ||
          publicData?.categories?.[0] === 'inSchoolProgrammes'
            ? 'hours'
            : null}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const noOfKidsMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Min - Max: </span>
      {publicData?.noOfKids
        ? `${publicData?.noOfKids?.minNo} - ${publicData?.noOfKids?.maxNo}`
        : 'Not Set'}
    </div>
  );
  const closingTimeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Closes: </span>
      {publicData?.closingTime ? (
        <>
          {' '}
          {`${
            publicData?.closingTime?.hour < 10
              ? `0${publicData?.closingTime?.hour || 0}`
              : publicData?.closingTime?.hour || '00'
          }:${
            publicData?.closingTime?.minute < 10
              ? `0${publicData?.closingTime?.minute || 0}`
              : publicData?.closingTime?.minute || '00'
          }`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const ageMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Age: </span>{' '}
      {publicData?.age && publicData?.age?.length > 0
        ? (publicData?.categories?.[0] === 'events' ||
            publicData?.categories?.[0] === 'placesToVisit') &&
          publicData?.allAges
          ? 'All Ages'
          : `${publicData?.age[0]} - ${publicData?.age[publicData?.age?.length - 1]}`
        : 'Not Set'}
    </div>
  );
  const activityTypesObj = publicData?.activityType?.map(a =>
    placesToVisitActivityTypeOptions.find(o => o.value === a)
  );
  const activityTypeMaybe = publicData?.activityType && (
    <div className={`${css.atrow} ${css.datetimeinfocol}`}>
      <span>Categories: </span>{' '}
      {activityTypesObj &&
        publicData?.categories?.[0] === 'placesToVisit' &&
        activityTypesObj.reduce(
          (pre, curr, idx) =>
            `${pre}${
              idx === 0
                ? ` ${curr.label}`
                : idx === activityTypesObj?.length - 1
                ? ` & ${curr.label}`
                : `, ${curr.label}`
            }`,
          ''
        )}
    </div>
  );
  const timeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Time: </span>{' '}
      {publicData?.startTime ? (
        <>
          {publicData?.startTime?.hour >= 10
            ? publicData?.startTime?.hour
            : `0${publicData?.startTime?.hour}`}
          .
          {publicData?.startTime?.minute >= 10
            ? publicData?.startTime?.minute
            : `0${publicData?.startTime?.minute}`}{' '}
          –{' '}
          {publicData?.endTime?.hour >= 10
            ? publicData?.endTime?.hour
            : `0${publicData?.endTime?.hour}`}
          .
          {publicData?.endTime?.minute >= 10
            ? publicData?.endTime?.minute
            : `0${publicData?.endTime?.minute}`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const dateMaybe = (
    <div className={css.datetimeinfocol}>
      <span>{'Dates'}:</span>
      {publicData?.startDate ? (
        <>
          {publicData?.categories?.[0] === 'events' || publicData?.categories?.[0] === 'workshops'
            ? moment(publicData?.startDate).format('MMM Do, YYYY')
            : `${moment(publicData?.startDate).format('MMM Do')} – ${moment(
                publicData?.endDate
              ).format('MMM Do, YYYY')}`}
          {publicData?.categories?.[0] === 'workshops' && '(1 day)'}
          {publicData?.categories?.[0] === 'events' &&
            publicData?.day &&
            `(${publicData?.day?.length} ${publicData?.day?.length > 1 ? 'days' : 'day'})`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const cityMaybe = (
    <div className={css.datetimeinfocol}>
      <span>{'Location: '}</span>
      {publicData?.city || publicData?.county ? (
        <span className={css.pricespn}>
          {publicData?.city ? publicData?.city.toUpperCase() : publicData?.county?.toUpperCase()}
        </span>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const div1 = (
    <div className={css.dv1}>
      {menuComponent ? (
        <div className={css.menubarWrapper}>
          <div className={css.menubar}>{menuComponent}</div>
        </div>
      ) : (
        <div></div>
      )}
      <div className={css.imgdiv}>
        <div
          className={classNames(css.trdv, {
            [css.drfttr]: isClosed,
            [css.redtr]: isDraft,
          })}
        ></div>
        <img src={firstImage?.attributes.variants['landscape-crop'].url} />
      </div>
      <div className={css.listingdetdiv}>
        <div>
          <p tabIndex={0} onClick={onTitleClick}>
            {/* {title?.length > 25 ? title?.substring(0, 30) + '...' : title} */}
            {title}
          </p>
          <div>
            <span>{bname}</span> -{' '}
            <span>
              {publicData?.activityMode === 'online'
                ? 'ONLINE'
                : publicData?.city
                ? publicData?.city
                : publicData?.county?.toUpperCase()}
            </span>
          </div>
        </div>
        {publicData?.categories?.[0] === 'camps' ? (
          <>
            {dateMaybe}
            {timeMaybe}
            {ageMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'afterSchoolClub' ? (
          <>
            {ageMaybe}
            {openTillMaybe}
            {closingTimeMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'birthdayParty' ? (
          <>
            {ageMaybe}
            {openTillMaybe}
            {noOfKidsMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'breakfastClub' ? (
          <>
            {ageMaybe}
            {openTillMaybe}
            {closingTimeMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'classes' ? (
          <>
            {dateMaybe}
            {timeMaybe}
            {ageMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'events' ? (
          <>
            {ageMaybe}
            {dateMaybe}
            {eventTimes}
            {cityMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'workshops' ? (
          <>
            {dateMaybe}
            {timeMaybe}
            {ageMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'placesToVisit' ? (
          <>
            {ageMaybe}
            {activityTypeMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'club' ? (
          <>
            {ageMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'inSchoolProgrammes' ? (
          <>
            {ageMaybe}
            {openTillMaybe}
            {noOfKidsMaybe}
            {priceDisplay}
          </>
        ) : publicData?.categories?.[0] === 'schoolTours' ? (
          <>
            {ageMaybe}
            {openTillMaybe}
            {noOfKidsMaybe}
            {priceDisplay}
          </>
        ) : null}
      </div>
    </div>
  );

  const div2 = (
    <div className={css.dv2}>
      <div>
        <span>Spaces:</span>
        <span>{currentStock ? currentStock : 0}</span>
      </div>
      <div>
        <span>Bookings:</span>
        <span>{listingBookings}</span>
      </div>
      <div>
        <span>Waiting:</span>
        <span>{waitingList?.length ? waitingList?.length : 0}</span>
      </div>

      <SalesBox key={currentListing?.id?.uuid} id={currentListing?.id?.uuid} intl={intl} />
    </div>
  );
  return (
    <>
      {div1}
      {div2}
    </>
  );
};

export default ManageListingCardTop;
