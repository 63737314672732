import React, { useState, useEffect, useRef } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import Select, { components } from 'react-select';
import { Field, Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import {
  Form,
  LocationAutocompleteInputField,
  Button,
  FieldTextInput,
  SearchMap,
  Modal,
  FieldCheckbox,
  FieldCheckboxGroup,
  IconSpinner,
  LocationAutocompleteInput,
} from '../../components';
import axios from 'axios';
import mapPinIcon from '../../icons/map_pin.png';
import mapPinTrans from '../../icons/map_pin_trans.png';
import css from './EditListingLocationForm.module.css';
import SearchMapWithMapbox from '../../components/SearchMap/SearchMapWithMapbox';
import { types as sdkTypes } from '../../util/sdkLoader';
import { locationBounds } from '../../components/LocationAutocompleteInput/GeocoderMapbox';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import CustomSearchMap from '../../components/SearchMap/CustomSeachMap';
import { parse } from '../../util/urlHelpers';
import { withRouter } from 'react-router-dom';
import LocationSearchForm from '../LocationSearchForm/LocationSearchForm';
import {
  ACTIVITYMODE,
  LOCATION,
  TITLE,
} from '../../components/EditListingWizard/EditListingWizardTab';
import { MdLocationPin, MdMapsHomeWork } from 'react-icons/md';
import OtpInput from 'react-otp-input';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';

const identity = v => v;
const LOCATION_BOUNDS = 1000;
const { LatLng } = sdkTypes;
const LOCATION_SEARCH_INTERVAL = 1000; //1 sec;
const irelandCords = { lat: 53.1423672, lng: -7.692053599999999 };
export const EditListingLocationFormComponent = props => {
  const { initialValues, onManageDisableScrolling, listing, location, intl, saveAndExit } = props;
  const countyOptions = config.custom.filters.find(f => f.id === 'county').config.options;
  const countyOptionsWithAllCounties = [
    { key: 'chooseAll', value: 'chooseAll', label: 'All Counties' },
    ...countyOptions,
  ];
  const [showMap, setShowMap] = useState(!!initialValues?.address);
  const [searchInputRef, setSearchInputRef] = useState(null);
  const [initialMapUpdate, setInitialMapUpdate] = useState(false);
  const [checkedCounties, setCheckedCounties] = useState(
    !!props.initialValues.allCounties ? props.initialValues.allCounties : []
  );
  const [allCheckedCounties, setAllCheckedCounties] = useState(
    props.initialValues.allCounties?.includes('chooseAll') ? props.initialValues.allCounties : []
  );
  const [countySelect, setCountySelect] = useState(
    initialValues?.county
      ? countyOptions.find(f => initialValues.county.toLowerCase().includes(f.value))
      : null
  );
  const [bounds, setBounds] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [bpType, setBPType] = useState(props.initialValues.bpType);
  const [bpStep, setBPStep] = useState(0);
  const [interactive, setInteractive] = useState(
    initialValues.address || initialValues.lat || initialValues.lng ? true : false
  );
  const mapRef = useRef(null);
  const mapContainer = useRef(null);
  const [searchAddress, setSearchAddress] = useState({
    search: initialValues?.address ? initialValues?.address : '',
    selectedPlace: null,
    predictions: [],
  });
  const newListing = useRef({ ...listing });

  const country = 'Ireland';
  const formRef = useRef(null);

  function initialize() {
    var myOptions = {
      zoom: 8,
      mapTypeControl: false,
      draggable: false,
      scaleControl: false,
      scrollwheel: false,
      navigationControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };
    const element = document.getElementById('googlemaps');
    // mapContainer.current = element;

    if (element) {
      var map = new google.maps.Map(element, myOptions);
      mapRef.current = map;
      var latLng = new google.maps.LatLng(irelandCords.lat, irelandCords.lng);
      map.setCenter(latLng);
    }
  }

  useEffect(() => {
    if (mapRef.current === null) {
      if (typeof window !== 'undefined') {
        initialize();
      }
    }
  });

  useEffect(() => {
    try {
      setInitialMapUpdate(true);
      let org = null;
      if (initialValues?.lat && initialValues?.lng) {
        org = new LatLng(initialValues?.lat, initialValues?.lng);
        setOrigin(org);
        setBounds(locationBounds(org, 1000));
      } else {
        org = new LatLng(irelandCords.lat, irelandCords.lng);
        setOrigin(org);
        setBounds(locationBounds(org, 100000));
      }
      setTimeout(() => setInitialMapUpdate(false), 10);
    } catch (e) {}
  }, []);

  const getAddressFromLatLng = async (lng, lat) => {
    try {
      const apiResp = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&sensor=true&key=AIzaSyD5tc_BskQZ2WY9EOHDgLYOpffPPpV3dYM`
      );

      const placeDetails = apiResp.data.results[0];
      const currLat = placeDetails?.geometry?.location?.lat;
      const currLng = placeDetails?.geometry?.location?.lng;
      const currOrigin = new LatLng(currLat, currLng);
      const county =
        placeDetails.address_components.find(f => f.types.includes('administrative_area_level_1'))
          ?.long_name || '';
      const city =
        placeDetails.address_components.find(f => f.types.includes('locality'))?.long_name || '';
      const address2 =
        placeDetails.address_components.find(f => f.types.includes('neighborhood'))?.long_name ||
        '';
      const eircode =
        placeDetails.address_components.find(f => f.types.includes('postal_code'))?.long_name || '';
      setInitialMapUpdate(true);
      setCountySelect(countyOptions.find(f => county?.toLowerCase()?.includes(f.value)));
      newListing.current.attributes.geolocation = new LatLng(origin.lat, origin.lng);
      formRef.current.change('address', placeDetails.formatted_address);
      formRef.current.change('county', county.split(' ')[1].toLowerCase());
      formRef.current.change('city', city);
      formRef.current.change('address2', address2);
      formRef.current.change('eircode', eircode);
      formRef.current.change('lat', currLat);
      formRef.current.change('lng', currLng);
      formRef.current.change('country', country);
      setSearchAddress({ ...searchAddress, search: placeDetails.formatted_address });
      setBounds(locationBounds(currOrigin, 1000));
      setOrigin(currOrigin);
      setTimeout(() => {
        setInitialMapUpdate(false);
      }, 300);
      setShowMap(true);
    } catch (error) {
      console.log(error);
    }
  };

  const checkboxChange = e => {
    var value = e.target.value;
    if (e.target.checked) {
      if (value === 'chooseAll') {
        setAllCheckedCounties(countyOptions.map(o => o.value));
      } else {
        setCheckedCounties([...checkedCounties, value]);
      }
    } else {
      var index = checkedCounties?.indexOf(value);
      if (value === 'chooseAll') {
        // setCheckedCounties([...allCheckedCounties]);
        setAllCheckedCounties([]);
      } else {
        setCheckedCounties(checkedCounties?.filter(county => county !== value));
      }
      // if (index > -1) {
      // }
    }
  };
  const onSearchChange = value => {
    const selectedPlace = value?.selectedPlace;
    if (selectedPlace) {
      const placeDetails = selectedPlace.placeDetails;
      const currOrigin = selectedPlace.origin;
      const currLat = placeDetails?.geometry?.location?.lat();
      const currLng = placeDetails?.geometry?.location?.lng();
      const county =
        placeDetails?.address_components?.find(f => f.types.includes('administrative_area_level_1'))
          ?.long_name || '';
      const city =
        placeDetails?.address_components?.find(f => f.types.includes('locality'))?.long_name || '';
      const address2 =
        placeDetails?.address_components?.find(f => f.types.includes('neighborhood'))?.long_name ||
        '';
      const eircode =
        placeDetails?.address_components?.find(f => f.types.includes('postal_code'))?.long_name ||
        '';
      setInitialMapUpdate(true);
      setInteractive(true);
      newListing.current.attributes.geolocation = new LatLng(currOrigin.lat, currOrigin.lng);
      setCountySelect(countyOptions.find(f => county?.toLowerCase()?.includes(f.value)));
      formRef.current.change('address', selectedPlace?.address);
      formRef.current.change('county', county?.split(' ')[1]?.toLowerCase());
      formRef.current.change('city', city);
      formRef.current.change('address2', address2);
      formRef.current.change('eircode', eircode);
      formRef.current.change('lat', currLat);
      formRef.current.change('lng', currLng);
      formRef.current.change('country', country);
      setSearchAddress({ ...value, search: selectedPlace?.address });
      setBounds(locationBounds(currOrigin, 1000));
      setOrigin(currOrigin);
      setTimeout(() => {
        setInitialMapUpdate(false);
      }, 300);
      setShowMap(true);
    }
  };
  const onEnterAddressManually = e => {
    e.stopPropagation();
    e.preventDefault();
    setShowMap(true);
  };
  const customControl = props => (
    <components.Control {...props} className={css.customControl}>
      {props.children}
    </components.Control>
  );
  const customMenu = props => (
    <components.Menu {...props} className={css.customMenu}>
      {props.children}
    </components.Menu>
  );
  const customOption = props => (
    <components.Option {...props} className={css.customOption}>
      {props.children}
    </components.Option>
  );

  return (
    <>
      <FinalForm
        {...props}
        render={formRenderProps => {
          const {
            className,
            disabled,
            ready,
            handleSubmit,
            intl,
            invalid,
            pristine,
            updated,
            updateInProgress,
            fetchErrors,
            values,
            form,
            tabProgress,
            backButton,
            initialValues,
            backbtnLoader,
            isMobile,
            categories,
            activityType,
          } = formRenderProps;
          const tabPercent = tabProgress(LOCATION);
          formRef.current = form;
          const optionalText = intl.formatMessage({
            id: 'EditListingLocationForm.optionalText',
          });

          const { updateListingError, showListingsError } = fetchErrors || {};
          const errorMessage = updateListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.updateFailed" />
            </p>
          ) : null;

          const errorMessageShowListing = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingLocationForm.showListingFailed" />
            </p>
          ) : null;
          const lastTab =
            categories[0] === 'camps' ||
            categories[0] === 'classes' ||
            categories[0] === 'workshops' ||
            categories[0] === 'events'
              ? ACTIVITYMODE
              : TITLE;
          const classes = classNames(css.root, className);
          const submitReady = (updated && pristine) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled = invalid || disabled || submitInProgress;
          const saveExit = (
            <div className={css.svexbtnsec}>
              <Button
                type="button"
                inProgress={backbtnLoader}
                className={css.exitButton}
                disabled={false}
                onClick={() => {
                  saveAndExit(values);
                }}
              >
                Save & Exit
              </Button>
            </div>
          );
          const searchField = (
            <Field
              name="location"
              className={classNames(css.locationText, {
                [css.pinningTextPresentAgainstFiled]: values?.address,
              })}
              format={identity}
              render={({ input, meta }) => {
                const { onChange, ...restInput } = input;

                // Merge the standard onChange function with custom behaviur. A better solution would
                // be to use the FormSpy component from Final Form and pass this.onChange to the
                // onChange prop but that breaks due to insufficient subscription handling.
                // See: https://github.com/final-form/react-final-form/issues/159
                const searchOnChange = val => {
                  setSearchAddress({ ...val, search: val.search });
                  onChange(val);
                  onSearchChange(val);
                };

                const searchInput = {
                  ...restInput,
                  value: searchAddress,
                  onChange: searchOnChange,
                };
                return (
                  <LocationAutocompleteInput
                    // className={css.locationAddress}
                    className={classNames(css.locationText, {
                      [css.pinningTextPresentAgainstFiled]: values?.address,
                    })}
                    iconClassName={css.locationAutocompleteInputIcon}
                    predictionsClassName={css.predictionsRoot}
                    validClassName={css.validLocation}
                    predictionsAttributionClassName={
                      isMobile ? css.mobilePredictionsAttribution : null
                    }
                    placeholder="Search"
                    closeOnBlur={!isMobile}
                    inputRef={node => {
                      setSearchInputRef(node);
                    }}
                    onEnterAddressManually={onEnterAddressManually}
                    input={searchInput}
                    meta={meta}
                  />
                );
              }}
            />
          );
          const customMap = (
            <div
              className={classNames(css.anyClass, {
                [css.toughClass]: !!values.address && showMap,
              })}
            >
              {searchField}
              {values.address ? <h2>Is the pin in the right spot?</h2> : null}
              <CustomSearchMap
                key="EditlistingPage.map"
                reusableContainerClassName={css.map}
                activeListingId={null}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={true}
                location={location}
                listings={[newListing.current]}
                onMapMoveEnd={() => {}}
                onCloseAsModal={() => {
                  onManageDisableScrolling('EditlistingPage.map', false);
                }}
                messages={intl.messages}
                setPickupAddress={getAddressFromLatLng}
                initialMapUpdate={initialMapUpdate}
                showCustomMap={true}
                boundsDistance={LOCATION_BOUNDS}
                interactive={interactive}
                isAddressAbsent={values.address ? false : true}
                removeClassName={{ className: 'SearchPage_map' }}
              />
            </div>
          );

          const manualAddressForm = (
            <div className={css.locationinputmodal}>
              <div className={css.mfb}>
                <FieldTextInput
                  id="address"
                  name="address"
                  type="text"
                  label={'Address'}
                  placeholder="Address"
                />
              </div>
              <div className={css.mfb}>
                <FieldTextInput
                  id="address2"
                  name="address2"
                  type="text"
                  label={'Address line 2'}
                  placeholder="Address"
                />
              </div>
              <div className={css.mfb}>
                <FieldTextInput
                  id="city"
                  name="city"
                  type="text"
                  label={'City'}
                  placeholder="City"
                />
              </div>
              <div className={css.inlineinputeri}>
                <div className={`${css.select} ${css.mfb}`}>
                  <Select
                    id="county"
                    options={countyOptions}
                    isClearable={false}
                    isSearchable={true}
                    placeholder="County"
                    components={{
                      Control: customControl,
                      Menu: customMenu,
                      Option: customOption,
                      IndicatorSeparator: () => null,
                    }}
                    value={countySelect}
                    onChange={e => {
                      const lat = e.latlng.lat;
                      const lng = e.latlng.lng;
                      const org = new LatLng(lat, lng);
                      setInitialMapUpdate(true);
                      setCountySelect(e);
                      form.change('county', e.value);
                      setInteractive(true);
                      formRef.current.change('lat', lat);
                      formRef.current.change('lng', lng);
                      setBounds(locationBounds(org, 1000));
                      setTimeout(() => {
                        setInitialMapUpdate(false);
                      }, 300);
                    }}
                  />
                </div>
                <div className={css.mfb}>
                  <FieldTextInput
                    id="eircode"
                    name="eircode"
                    placeholder="Eircode"
                    value={values.eircode}
                    type="text"
                    label={'Eircode'}
                  />
                </div>
              </div>
              <div className={css.mfb}>
                <FieldTextInput
                  id="country"
                  name="country"
                  type="text"
                  label={'Country'}
                  placeholder="Country"
                />
              </div>
            </div>
          );

          return (
            <Form
              className={classes}
              onSubmit={e => {
                e.preventDefault();
                values.bpType = bpType;
                if (categories[0] === 'birthdayParty' && bpType === 'venueParty' && bpStep === 0) {
                  return setBPStep(1);
                }
                values.county =
                  categories[0] === 'inSchoolProgrammes' ||
                  (categories[0] === 'birthdayParty' && bpType === 'mobileParty')
                    ? allCheckedCounties?.length === 0
                      ? checkedCounties
                      : allCheckedCounties
                    : values.county;
                handleSubmit();
              }}
            >
              {saveExit}
              {categories[0] === 'inSchoolProgrammes' ? (
                <div className={`${css.formseclist} ${css.mainoutrowmod}`}>
                  <div id="googlemaps" className={css.bggmaps}></div>

                  <div className={`${css.mainoutrow} ${css.chkbtngrp} ${css.zindx}`}>
                    <div className={` ${css.bckgrn} ${css.pdg} ${css.isplbl}`}>
                      <label>
                        In-School Programme
                        <span>Provider travels to school venue</span>
                      </label>
                    </div>
                    <div className={` ${css.bckgrn} ${css.pdg}`}>
                      {' '}
                      <label>What counties to do service?</label>
                      <div className={css.countyOption}>
                        {countyOptionsWithAllCounties.map(c => (
                          <div className={css.frmchkwctds} key={c.value}>
                            <input
                              type="checkbox"
                              checked={
                                allCheckedCounties?.includes(c.value) ||
                                checkedCounties?.includes(c.value) ||
                                (c.value === 'chooseAll' &&
                                  (allCheckedCounties?.length > 0 ||
                                    checkedCounties?.length === countyOptions?.length))
                              }
                              key={c.key}
                              id={c.value}
                              value={c.value}
                              onChange={e => checkboxChange(e)}
                              disabled={
                                allCheckedCounties?.includes('chooseAll') && c.value !== 'chooseAll'
                              }
                            />

                            <label
                              htmlFor={c.value}
                              className={classNames(css.somelabel, {
                                [css.selchkd]:
                                  checkedCounties?.includes(c.value) ||
                                  allCheckedCounties?.includes(c.value) ||
                                  (c.value === 'chooseAll' &&
                                    (allCheckedCounties?.length > 0 ||
                                      checkedCounties?.length === countyOptions?.length)),
                              })}
                            >
                              {checkedCounties?.includes(c.value) ||
                              allCheckedCounties?.includes(c.value) ||
                              (c.value === 'chooseAll' &&
                                (allCheckedCounties?.length > 0 ||
                                  checkedCounties?.length === countyOptions?.length)) ? (
                                <GrCheckboxSelected />
                              ) : (
                                <GrCheckbox />
                              )}{' '}
                              {c.label}
                            </label>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              ) : categories[0] === 'birthdayParty' && bpStep !== 1 ? (
                <div className={`${css.formseclist} ${css.mainoutrowmod}`}>
                  <div id="googlemaps" className={css.bggmaps}></div>
                  <div className={`${css.mainoutrow} ${css.chkbtngrp} ${css.mycls} ${css.zindx}`}>
                    {categories[0] === 'birthdayParty' &&
                      activityType?.includes('mobileParties') === false && (
                        <div className={css.bpcbx}>
                          <input
                            type="radio"
                            id="venueParty"
                            name="BPTYPE"
                            onChange={() => {
                              setBPType('venueParty');
                            }}
                          />
                          <label htmlFor="venueParty">
                            {bpType === 'venueParty' ? <GrCheckboxSelected /> : <GrCheckbox />}
                            <span>
                              Venue-Suppled Party
                              <span>Client travels to provider venue</span>
                            </span>
                          </label>
                        </div>
                      )}
                    <div className={css.bpcbx}>
                      <input
                        type="radio"
                        id="mobileParty"
                        name="BPTYPE"
                        onChange={() => {
                          setBPType('mobileParty');
                        }}
                      />
                      <label htmlFor="mobileParty">
                        {bpType === 'mobileParty' ? <GrCheckboxSelected /> : <GrCheckbox />}
                        <span>
                          Mobile Party
                          <span>You travel to client venue/home</span>
                        </span>
                      </label>
                    </div>
                    {bpType === 'mobileParty' ? (
                      <div className={`${css.mainoutrow} ${css.chkbtngrp} ${css.bckgrn}`}>
                        <label>What counties to do service?</label>
                        <div className={css.countyOption}>
                          {countyOptionsWithAllCounties.map(c => (
                            <div className={css.frmchkwctds} key={c.value}>
                              <input
                                type="checkbox"
                                checked={
                                  allCheckedCounties?.includes(c.value) ||
                                  checkedCounties?.includes(c.value) ||
                                  (c.value === 'chooseAll' &&
                                    (allCheckedCounties?.length > 0 ||
                                      checkedCounties?.length === countyOptions?.length))
                                }
                                key={c.key}
                                id={c.value}
                                value={c.value}
                                onChange={e => checkboxChange(e)}
                                disabled={
                                  allCheckedCounties?.includes('chooseAll') &&
                                  c.value !== 'chooseAll'
                                }
                              />
                              <label htmlFor={c.value}>
                                {checkedCounties?.includes(c.value) ||
                                allCheckedCounties?.includes(c.value) ||
                                (c.value === 'chooseAll' &&
                                  (allCheckedCounties?.length > 0 ||
                                    checkedCounties?.length === countyOptions?.length)) ? (
                                  <GrCheckboxSelected />
                                ) : (
                                  <GrCheckbox />
                                )}{' '}
                                {c.label}
                              </label>
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : (
                <div className={css.formseclist}>
                  {showMap ? manualAddressForm : null}
                  {customMap}
                </div>
              )}

              <div className={css.formlistbtmsec}>
                <ProgressBar bgcolor="red" progress={tabPercent} height={30} />

                <div className={css.buttonDiv}>
                  <Button
                    type="button"
                    className={css.backButton}
                    onClick={() => backButton(lastTab)}
                  >
                    Back
                  </Button>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={
                      categories[0] === 'birthdayParty' && bpStep === 0 && bpType === 'venueParty'
                        ? !(bpType === 'venueParty')
                        : (categories[0] === 'birthdayParty' && bpType === 'mobileParty') ||
                          categories[0] === 'inSchoolProgrammes'
                        ? allCheckedCounties?.length === 0 && checkedCounties?.length === 0
                        : !values.address || !values.county
                    }
                    ready={submitReady}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </Form>
          );
        }}
      />
    </>
  );
};

EditListingLocationFormComponent.defaultProps = {
  selectedPlace: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingLocationFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

export default compose(withRouter, injectIntl)(EditListingLocationFormComponent);
