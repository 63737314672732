import React from 'react';

const ProgressBar = ({ bgcolor, progress, height }) => {
  const Parentdiv = {
    height: 10,
    width: '100%',
    backgroundColor: '#fff',
    borderTop: '1px solid #921b98',
    borderBottom: '1px solid #c4385a',
    borderRight: '1px solid #921b98',
    margin: '0 0 20px',
  };

  const Childdiv = {
    height: '100%',
    width: `${progress}%`,
    background: 'linear-gradient(90deg, rgba(201, 59, 84, 1) 27%, rgba(146, 27, 152, 1) 72%)',
    textAlign: 'right',
    position: 'relative',
  };

  const progresstext = {
    padding: 10,
    color: 'black',
    fontWeight: 900,
  };

  return (
    <div style={Parentdiv}>
      <div style={Childdiv} className="pgrsstep">
        <span style={progresstext}></span>
      </div>
    </div>
  );
};

export default ProgressBar;
