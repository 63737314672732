import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingDescriptionForm } from '../../forms';
import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import css from './EditListingDescriptionPanel.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import EditListingImage from '../EditListingImage/EditListingImage';

import clisting10 from '../../assets/screen-10-ripped-img.png';
import clistingmb10 from '../../assets/screen-10-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';

const EditListingDescriptionPanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { description, title, publicData } = currentListing.attributes;
  const [step, setStep] = useState(1);

  const prevStep = () => {
    setStep(step => step - 1);
  };
  const nextStep = () => {
    setStep(step => step + 1);
  };

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingDescriptionPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingDescriptionPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingDescriptionPanel.createListingTitle" />
  );
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Now, let’s describe your activity."
          logoImg={listinglogo}
          desktopImg={clisting10}
          mobileImg={clistingmb10}
        ></EditListingImage>
        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingDescriptionForm
            isPublished={isPublished}
            className={css.form}
            backbtnLoader={backbtnLoader}
            savenExit={savenExit}
            backButton={backButton}
            initialValues={{
              description,
            }}
            step={step}
            prevStep={prevStep}
            nextStep={nextStep}
            saveActionMsg={submitButtonText}
            tabProgress={tabProgress}
            onSubmit={values => {
              const { description } = values;
              const updateValues = {
                description,
              };
              onSubmit(updateValues);
            }}
            history={history}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
            publicData={publicData}
          />
        </div>
      </div>
      {/* </div> */}
      {/* </div> */}

      {/* </Modal> */}
    </div>
  );
};

EditListingDescriptionPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingDescriptionPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingDescriptionPanelComponent;
