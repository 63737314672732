import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import EditListingAgeForm from '../../forms/EditListingAgeForm/EditListingAgeForm';
import { ListingLink } from '..';
import EditListingImage from '../EditListingImage/EditListingImage';

import css from './EditListingAgePanel.module.css';

import clisting5 from '../../assets/screen-08-ripped-img.png';
import clistingmb5 from '../../assets/screen-08-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';

const AGE = 'age';

const EditListingAgePanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingAgePanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingAgePanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingAgePanel.createListingTitle" />
  );
  const submitFunc = (values, isSNE) => {
    let updatedValues;
    if (
      publicData.categories?.includes('afterSchoolClub') ||
      publicData.categories?.includes('birthdayParty') ||
      publicData.categories?.includes('breakfastClub') ||
      publicData.categories?.includes('classes') ||
      publicData.categories?.includes('schoolTours') ||
      publicData.categories?.includes('workshops') ||
      publicData.categories?.includes('camps') ||
      publicData.categories?.includes('inSchoolProgrammes') ||
      publicData.categories?.includes('club') ||
      publicData.categories?.includes('camps')
    ) {
      updatedValues = {
        publicData: {
          age: [...Array(parseInt(values.maxAge) + 1).keys()]
            .filter(e => e >= values.minAge && e <= values.maxAge)
            .map(String),
        },
      };
      isSNE ? savenExit(updatedValues) : onSubmit(updatedValues);
    } else if (
      publicData.categories?.includes('placesToVisit') ||
      publicData.categories?.includes('events')
    ) {
      updatedValues = {
        publicData: {
          allAges: values.allAges,
          age: [...Array(parseInt(values.maxAge) + 1).keys()]
            .filter(e => e >= values.minAge && e <= values.maxAge)
            .map(String),
        },
      };
      isSNE ? savenExit(updatedValues) : onSubmit(updatedValues);
    }
  };
  const saveAndExit = values => {
    submitFunc(values, true);
  };
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="What’s the best age range for your activity?"
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
        />
        <div className={css.rightSection}>
          <EditListingAgeForm
            className={css.form}
            tabProgress={tabProgress}
            backbtnLoader={backbtnLoader}
            name={AGE}
            backButton={backButton}
            initialValues={
              publicData.categories?.includes('afterSchoolClub') ||
              publicData.categories?.includes('birthdayParty') ||
              publicData.categories?.includes('breakfastClub') ||
              publicData.categories?.includes('classes') ||
              publicData.categories?.includes('schoolTours') ||
              publicData.categories?.includes('workshops') ||
              publicData.categories?.includes('camps') ||
              publicData.categories?.includes('inSchoolProgrammes') ||
              publicData.categories?.includes('club') ||
              publicData.categories?.includes('camps')
                ? {
                    minAge: publicData.age ? publicData?.age[0] : null,
                    maxAge: publicData.age ? publicData?.age[publicData?.age?.length - 1] : null,
                  }
                : publicData.categories?.includes('placesToVisit') ||
                  publicData.categories?.includes('events')
                ? {
                    minAge: publicData.age ? publicData?.age[0] : null,
                    maxAge: publicData.age ? publicData?.age[publicData?.age?.length - 1] : null,
                    allAges: publicData.allAges,
                  }
                : null
            }
            onSubmit={values => {
              submitFunc(values, false);
            }}
            saveAndExit={saveAndExit}
            history={history}
            publicData={publicData}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </div>
      </div>
    </div>
  );
};

EditListingAgePanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingAgePanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAgePanel;
