import React from 'react';
import { bool, func, object, string } from 'prop-types';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingGettingNewEnquiryForm from '../../forms/EditListingGettingNewEnquiryForm/EditListingGettingNewEnquiryForm';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import css from './EditListingGettingNewEnquiryPanel.module.css';

import clisting10 from '../../assets/screen-14-ripped-img.png';
import clistingmb10 from '../../assets/screen-14-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogo.png';
const EditListingGettingNewEnquiryPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    backButton,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    tabProgress,
    savenExit,
    backbtnLoader,
    isStarter,
    onManageDisableScrolling,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        <EditListingImage
          className={css.imgSection}
          imageText="Getting new enquires and bookings!"
          logoImg={listinglogo}
          desktopImg={clisting10}
          mobileImg={clistingmb10}
        ></EditListingImage>
        <div className={css.rightSection}>
          <EditListingGettingNewEnquiryForm
            isPublished={isPublished}
            onManageDisableScrolling={onManageDisableScrolling}
            backbtnLoader={backbtnLoader}
            savenExit={savenExit}
            saveActionMsg={submitButtonText}
            history={history}
            tabProgress={tabProgress}
            className={css.form}
            disabled={disabled}
            ready={ready}
            isStarter={isStarter}
            updated={panelUpdated}
            onSubmit={values => {
              const { bookingType, referalLink } = values;
              const linkWithProtocol =
                referalLink && !/^https?:\/\//i.test(referalLink)
                  ? `https://${referalLink}`
                  : referalLink;
              const updateValues = {
                publicData: {
                  bookingType: isStarter ? 'instantBooking' : bookingType,
                  referalLink: linkWithProtocol,
                },
              };
              // if (publicData.categories[0] === 'club') {
              //   bookingType?.includes('instantEnquiry')
              //     ? (updateValues = {
              //         publicData: {
              //           bookingType,
              //         },
              //       })
              //     : (updateValues = {
              //         publicData: { bookingType: [...bookingType, 'instantEnquiry'] },
              //       });
              // } else {
              //   updateValues = {
              //     ...publicData,
              //   };
              // }
              onSubmit(updateValues);
            }}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            backButton={backButton}
            publicData={publicData}
            categories={publicData.categories}
            initialValues={{
              bookingType: publicData.bookingType
                ? publicData.bookingType
                : publicData?.categories[0] === 'classes' ||
                  publicData?.categories[0] === 'camps' ||
                  publicData?.categories[0] === 'workshops'
                ? 'instantBooking'
                : 'instantEnquiry',
              referalLink: publicData.referalLink,
            }}
          />
        </div>
      </div>
    </div>
  );
};
EditListingGettingNewEnquiryPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingGettingNewEnquiryPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingGettingNewEnquiryPanel;
