import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import clisting4 from '../../assets/screen-04-ripped-img.png';
import clistingmb4 from '../../assets/screen-04-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';

import css from './EditListingSubCategoriesPanel.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { defaultTitle } from '../../marketplace-custom-config';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingSubCategoriesForm from '../../forms/EditListingSubCategoriesForm/EditListingSubCategoriesForm';

const subCategoryOptionsForInSchlProg = [
  { key: 'business', value: 'business', label: 'Business' },
  { key: 'creative', value: 'creative', label: 'Creative' },
  { key: 'cultural', value: 'cultural', label: 'Cultural' },
  { key: 'dance', value: 'dance', label: 'Dance' },
  { key: 'educational', value: 'educational', label: 'Educational' },
  { key: 'languages', value: 'languages', label: 'Languages' },
  { key: 'maths', value: 'maths', label: 'Maths' },
  { key: 'music', value: 'music', label: 'Music' },
  { key: 'performingArts', value: 'performingArts', label: 'Performing Arts' },
  { key: 'sports', value: 'sports', label: 'Sports & P.E.' },
  {
    key: 'scienceTechnology',
    value: 'scienceTechnology',
    label: 'Science, Technology, Engineering, Maths',
  },
  { key: 'wellbeing', value: 'wellbeing', label: 'Wellbeing' },
  { key: 'others', value: 'others', label: 'Others' },
];
const EditListingSubCategoriesPanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const subCategories = (publicData && publicData.subCategories) ?? [];
  const otherSubCategory = (publicData && publicData.otherSubCategory) ?? '';
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        <EditListingImage
          className={css.imgSection}
          imageText="Which categories best match your activity?"
          logoImg={listinglogo}
          desktopImg={clisting4}
          mobileImg={clistingmb4}
        ></EditListingImage>
        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingSubCategoriesForm
            isPublished={isPublished}
            backbtnLoader={backbtnLoader}
            className={css.form}
            tabProgress={tabProgress}
            initialValues={{
              subCategories,
              otherSubCategory,
            }}
            savenExit={savenExit}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { subCategories, otherSubCategory } = values;
              const updateValues = {
                publicData: {
                  subCategories,
                  otherSubCategory,
                },
              };

              onSubmit(updateValues);
            }}
            history={history}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            categories={publicData.categories}
            backButton={backButton}
            certificateOptions={certificateOptions}
            subCategoryOptionsForInSchlProg={subCategoryOptionsForInSchlProg}
          />
        </div>
      </div>
    </div>
  );
};

EditListingSubCategoriesPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingSubCategoriesPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingSubCategoriesPanelComponent;
