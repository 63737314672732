import React from 'react';
import css from './EditListingImage.module.css';

// import clisting2 from '../../assets/clisting-2.png';
// import clistingmb2 from '../../assets/screen-02-full-img.png';
// import listinglogo from '../../assets/listinglogowhite.png';
import {
  ACTIVITYMODE,
  AVAILABILITY,
  LOCATION,
  PHOTOS,
  POLICY,
  PUBLISH,
  TITLE,
  WHATTOBRING,
} from '../EditListingWizard/EditListingWizardTab';
import NamedLink from '../../components/NamedLink/NamedLink';
import classNames from 'classnames';
function EditListingImage(props) {
  const imageText = props.imageText;
  const {
    logoImg,
    desktopImg,
    mobileImg,
    currentTab,
    bgIcon,
    fromPublishSuccess,
    publicData,
  } = props;
  return (
    <>
      {currentTab === TITLE ||
      currentTab === ACTIVITYMODE ||
      currentTab === LOCATION ||
      currentTab === PHOTOS ||
      currentTab === WHATTOBRING ||
      currentTab === AVAILABILITY ||
      currentTab === POLICY ||
      currentTab === PUBLISH ? (
        <div
          className={classNames(`${css.leftSection} ${css.bgColorscreen}`, {
            [css.locdv]: currentTab === LOCATION,
          })}
        >
          <NamedLink name="LandingPage">
            <img src={logoImg} className={css.lstlogo} />
          </NamedLink>

          <img src={desktopImg} alt="" className={css.imgSection} />
          <img src={mobileImg} alt="" className={css.imgSectionmb} />

          <div className={css.midcontain}>
            <p>{imageText}</p>
            {currentTab === PUBLISH ? (
              <span className={css.pubtxt}>
                {props.fromProviderPreview ? 'Your Profile ' : 'This listing '}will be visible to
                customers immediately after you publish. You can add more info or make changes
                anytime.
              </span>
            ) : currentTab === PHOTOS ? (
              <span className={`${css.localign} ${css.pubtxt}`}>
                Close up, focus-shots of kids having fun are ideal. Don’t have any? You are welcome
                to search through our small library of images and use them.
              </span>
            ) : currentTab === TITLE ? (
              publicData?.categories[0] === 'schoolTours' ? (
                <span className={`${css.localign} ${css.pubtxt}`}>
                  It's best to list school packages <u>individually</u>, as teachers will search by
                  age range, prices, number sizes, length of time and categories.
                </span>
              ) : null
            ) : props.providerTab ? (
              <span className={css.pubtxt}>
                <span>{props?.providerPhotosTexts?.one}</span>
                <span>{props?.providerPhotosTexts?.two}</span>
              </span>
            ) : null}
            <img src={bgIcon} alt="" className={css.screenicon} />
          </div>
        </div>
      ) : (
        <div
          className={classNames(css.leftSection, { [css.fromPublishSuccess]: fromPublishSuccess })}
        >
          <NamedLink name="LandingPage">
            <img src={logoImg} className={css.lstlogo} />
          </NamedLink>

          <img src={desktopImg} alt="" className={css.imgSection} />
          <img src={mobileImg} alt="" className={css.imgSectionmb} />

          <p>{imageText}</p>
          {props.fromProvCates ? (
            <p className={css.fromProvCates}>
              We understand businesses change, come back and easily update anytime!
            </p>
          ) : null}
        </div>
      )}
    </>
  );
}

export default EditListingImage;
