import moment from 'moment';
export const formatListingData = listing => {
  const { price, publicData, title } = listing.attributes;
  const {
    categories,
    startDate,
    endDate,
    day,
    priceType,
    activityType,
    activityTypesObj,
    startTime,
    endTime,
    allAges,
    age,
    openTill,
    closingTime,
    noOfKids,
    noOfClasses,
    classType,
    noOfDays,
    activityMode,
    city,
    county,
  } = publicData;
  const bname = listing?.author?.attributes?.profile?.publicData?.bname;
  const activityLocationMaybe = {
    location:
      activityMode === 'online' ? 'ONLINE' : city ? city?.toUpperCase() : county?.toUpperCase(),
  };

  const dateMaybe = {
    dates:
      categories[0] === 'events' || categories[0] === 'workshops'
        ? moment(startDate).format('MMM Do, YYYY')
        : `${`${moment(startDate).format('MMM Do')} – ${moment(endDate).format('MMM Do, YYYY')}`} ${
            categories === 'workshops'
              ? '1 day'
              : categories[0] === 'events' && day
              ? `(${day?.length} ${day?.length > 1 ? 'days' : 'day'})`
              : ''
          }`,
  };
  const timeMaybe = {
    Time: `${startTime?.hour >= 10 ? startTime?.hour : `0${startTime?.hour}`}.${
      startTime?.minute >= 10 ? startTime?.minute : `0${startTime?.minute}`
    } – ${endTime?.hour >= 10 ? endTime?.hour : `0${endTime?.hour}`}.${
      endTime?.minute >= 10 ? endTime?.minute : `0${endTime?.minute}`
    }`,
  };
  const priceTypeTexts =
    priceType === 'perHour'
      ? 'per hour'
      : priceType === 'perChild'
      ? 'per child'
      : priceType === 'totalPrice'
      ? 'total price'
      : priceType === 'perMorning'
      ? 'per morning'
      : priceType === 'perAfternoon'
      ? 'per afternoon'
      : priceType === 'perDay'
      ? 'per day'
      : priceType === 'perTerm'
      ? 'per term'
      : priceType === 'perClass'
      ? 'per class'
      : priceType === 'perYear'
      ? 'per year'
      : priceType === 'perMonth'
      ? 'per month'
      : priceType === 'perAdult'
      ? 'per adult'
      : null;

  const activityTypeMaybe = activityType &&
    activityTypesObj?.length > 0 &&
    categories[0] === 'placesToVisit' && {
      Categories: activityTypesObj.reduce(
        (pre, curr, idx) =>
          `${pre}${
            idx === 0
              ? ` ${curr.label}`
              : idx === activityTypesObj?.length - 1
              ? ` & ${curr.label}`
              : `, ${curr.label}`
          }`,
        ''
      ),
    };
  const ageMaybe = {
    Age:
      (categories[0] === 'events' || categories[0] === 'placesToVisit') && allAges
        ? 'All Ages'
        : `${age[0]} - ${age[age?.length - 1]}`,
  };
  const closingTimeMaybe = {
    Closes: `${closingTime?.hour < 10 ? `0${closingTime?.hour || 0}` : closingTime?.hour || '00'}:${
      closingTime?.minute < 10 ? `0${closingTime?.minute || 0}` : closingTime?.minute || '00'
    }`,
  };
  const openTillMaybe = {
    openTill: `${openTill?.hour < 10 ? `0${openTill?.hour}` : openTill?.hour}:${
      openTill?.minute < 10 ? `0${openTill?.minute}` : openTill?.minute
    } ${
      categories[0] === 'birthdayParty' ||
      categories[0] === 'schoolTours' ||
      categories[0] === 'inSchoolProgrammes'
        ? 'hours'
        : ''
    }`,
    label:
      categories[0] === 'inSchoolProgrammes'
        ? 'Time: '
        : categories[0] === 'birthdayParty' || categories[0] === 'schoolTours'
        ? 'Length: '
        : categories[0] === 'breakfastClub' || categories[0] === 'afterSchoolClub'
        ? 'Opens: '
        : 'Closes: ',
  };
  const noOfKidsMaybe = {
    minMax: `${noOfKids?.minNo} - ${noOfKids?.maxNo}`,
  };
  const eventTimes = {
    times: day?.map((d, i) => ({
      label: `Time (day ${i + 1})`,
      time: `${d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
          ${d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
          ${d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
          ${d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}`,
    })),
  };
  const priceDisplay = {
    Cost: price?.amount === 0 ? 'Free' : `€${price?.amount / 100}`,
    extra:
      categories[0] === 'classes'
        ? `(${noOfClasses} ${classType ? classType?.label : 'classes'})`
        : categories[0] === 'camps'
        ? `(${noOfDays} ${noOfDays > 1 ? 'days' : 'day'})`
        : categories[0] === 'workshops'
        ? '(1 day)'
        : '',
  };

  const cityMaybe =
    publicData?.city || publicData?.county
      ? {
          location: city ? city.toUpperCase() : county?.toUpperCase(),
        }
      : null;
  const categoryDetailsMap = {
    camps: [dateMaybe, timeMaybe, ageMaybe, priceDisplay],
    afterSchoolClub: [ageMaybe, openTillMaybe, closingTimeMaybe, priceDisplay],
    birthdayParty: [ageMaybe, openTillMaybe, noOfKidsMaybe, priceDisplay],
    breakfastClub: [ageMaybe, openTillMaybe, closingTimeMaybe, priceDisplay],
    classes: [dateMaybe, timeMaybe, ageMaybe, priceDisplay],
    events: [ageMaybe, dateMaybe, eventTimes, cityMaybe, priceDisplay],
    workshops: [dateMaybe, timeMaybe, ageMaybe, priceDisplay],
    placesToVisit: [ageMaybe, activityTypeMaybe, priceDisplay],
    club: [ageMaybe, priceDisplay],
    inSchoolProgrammes: [ageMaybe, openTillMaybe, noOfKidsMaybe, priceDisplay],
    schoolTours: [ageMaybe, openTillMaybe, noOfKidsMaybe, priceDisplay],
  };
  const result = categoryDetailsMap[categories[0]] || [];

  const cardDataString = result
    .map(r => Object.values(r)[0])
    .reduce((str, curr) => {
      return str + ` * ${curr}`;
    }, '');
  const listingString = `${title} * ${activityLocationMaybe.location}${cardDataString}`;
  const startsTime = `${startTime?.hour >= 10 ? startTime?.hour : `0${startTime?.hour}`}.${
    startTime?.minute >= 10 ? startTime?.minute : `0${startTime?.minute}`
  }`;
  const startsDate = moment(startDate).format('MMM Do, YYYY');
  const locationMaybe = activityMode === 'online' ? 'online' : city ? `in ${city}` : `in ${county}`;
  const mailString = `${title} ${locationMaybe} starting ${startsDate} at ${startsTime}, run by ${bname}`;
  return { cardData: result, cardDataString, listingString, mailString };
};
