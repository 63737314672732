import React, { useState } from 'react';
import { bool, func, number, object, string } from 'prop-types';
import worker from './CustomDownloaderWorker';
import classNames from 'classnames';
import css from './CustomCsvDownloader.module.css';
import moment from 'moment';

const Spinner = ({ className }) => (
  <svg
    className={className}
    viewBox="0 0 30 30"
    preserveAspectRatio="xMidYMid"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15" cy="15" r="12" fill="none" strokeLinecap="round">
      <animateTransform
        attributeName="transform"
        type="rotate"
        calcMode="linear"
        values="0 15 15;180 15 15;720 15 15"
        keyTimes="0;0.5;1"
        dur="0.9s"
        begin="0s"
        repeatCount="indefinite"
      />
      <animate
        attributeName="stroke-dasharray"
        calcMode="linear"
        values="9 56;46 14;9 56"
        keyTimes="0;0.5;1"
        dur="0.9s"
        begin="0s"
        repeatCount="indefinite"
      />
    </circle>
  </svg>
);

const replacer = (key, value) => {
  if (value === undefined || value === null) return '';
  else if (value instanceof Date) return value.toISOString();
  return value;
};

//fetchData is Async function which must return object of format {data:Array,meta:{totalPages:Number}}.
//prepareData is Async function which must accpet individual data object( returned from fetchData function)
// and must return object whoose key will be field name of csv file and value will be their corresponding values.

function CustomCsvDownloader(props) {
  const { txData, fileName = '', buttonClassName, buttonName, depositOptionAmount } = props;
  const [isDownloading, setIsDownloading] = useState(false);
  const classes = classNames(css.btn, buttonClassName);
  const btnName = buttonName || 'Download CSV';
  const prepareData = item => {
    const { childDetails, transaction } = item;
    const lineItems = transaction.attributes.lineItems;
    const customerDetails = transaction.attributes.protectedData.customerDetails;
    const isDeposit = transaction.attributes.protectedData.payingOptions === 'depositPayment';
    const childPayment = lineItems.find(
      l => l.code.indexOf(childDetails.firstName) !== -1 && l.code.indexOf(childDetails.lastName)
    );
    const ChildsName = childDetails.firstName + childDetails.lastName;
    const Paid = isDeposit
      ? `euro ${depositOptionAmount.amount}`
      : `euro ${childPayment.lineTotal.amount / 100}` +
        `${
          isDeposit && !childDetails?.isTrial ? '(deposit)' : childDetails?.isTrial ? '(trial)' : ''
        }`;
    const ParentNameAndTelNo = `${customerDetails?.customerName} ${customerDetails?.customerPhnNo ||
      ''}`;
    const emergencyContacts = transaction.attributes.protectedData.emgContact;
    const EmergencyContact1 = emergencyContacts[0]
      ? `${emergencyContacts[0].firstName} ${emergencyContacts[0].lastName} ${emergencyContacts[0].phnNo}`
      : '';
    const EmergencyContact2 = emergencyContacts[1]
      ? `${emergencyContacts[1].firstName} ${emergencyContacts[1].lastName} ${emergencyContacts[1].phnNo}`
      : '';
    const Due =
      isDeposit && !childDetails?.isTrial
        ? `euro ${childPayment.lineTotal.amount / 100 - depositOptionAmount.amount}`
        : 'euro 0';

    const Booked = moment(transaction.attributes.createdAt).format('DD[-]MM[-]YYYY');
    return {
      ChildsName,
      DateOfBirth: childDetails.dob,
      Paid,
      Booked,
      Due,
      Medical: childDetails.childMedicalNotes,
      ParentNameAndTelNo,
      ParentEmail: customerDetails?.customerEmail,
      EmergencyContact1,
      EmergencyContact2,
    };
  };
  const processData = async (dataObject, shouldReturnKeysStr = false) => {
    return new Promise((resolve, reject) => {
      if (window.Worker) {
        const code = worker.toString();
        const blob = new Blob([`(${code})()`]);
        const procesWorker = new Worker(URL.createObjectURL(blob));
        procesWorker.postMessage(JSON.stringify(dataObject, replacer));
        procesWorker.addEventListener('message', e => {
          procesWorker.terminate();
          resolve(e.data);
        });
      } else {
        const [keys, values] = [Object.keys(dataObject), Object.values(dataObject)];
        let sanitizedData = [];
        for (let d of values) {
          let updatedData = d === null || d === undefined ? '' : d.toString();
          updatedData = updatedData.replace(/(\r\n|\n|\r)/gm, '').replace(/"/gm, '""');
          updatedData = '"' + updatedData + '"';
          sanitizedData.push(updatedData);
        }
        resolve({
          keys: shouldReturnKeysStr ? keys.join(',') : keys,
          values: sanitizedData.join(','),
        });
      }
    });
  };

  const downloadCsv = async () => {
    if (isDownloading) return;
    let csvContent = 'data:text/csv;charset=utf-8,',
      firstTime = true;
    if (typeof window !== 'undefined') {
      setIsDownloading(true);
      const dataArr = Array.isArray(txData) ? txData : [txData];
      const dataArrLength = dataArr.length;
      txData;
      for (let index = 0; index < dataArrLength; index++) {
        const d = dataArr[index];
        const preparedData = prepareData(d);
        const { keys, values } = await processData(preparedData, firstTime);
        if (firstTime) {
          firstTime = false;
          csvContent += keys + '\n';
        }
        csvContent += values + '\n';
      }
      const encodedContent = encodeURI(csvContent);
      const link = document.createElement('a');
      link.setAttribute('href', encodedContent);
      link.setAttribute('download', fileName || 'my_data.csv');
      link.setAttribute('style', 'display:none');
      document.body.appendChild(link);
      setIsDownloading(false);
      link.click();
      document.body.removeChild(link);
    } else {
      throw new Error('This function should called from browser');
    }
  };

  const handleClick = e => {
    e.preventDefault();
    e.stopPropagation();
    downloadCsv();
  };

  return (
    <button type="button" onClick={handleClick} className={classes}>
      {isDownloading ? `Downloading...` : btnName}
    </button>
  );
}
CustomCsvDownloader.defaulProps = {
  buttonName: null,
  buttonClassName: null,
  isPageStartWithZero: false,
};

CustomCsvDownloader.propTypes = {
  prepareData: func.isRequired,
  fetchData: func.isRequired,
  fileName: string,
  buttonClassName: string,
  buttonName: string,
  fetchDataUptoPages: number,
  isPageStartWithZero: bool,
};

export default CustomCsvDownloader;
