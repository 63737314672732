import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';

import css from './SectionFooterTop.module.css';
import { TopbarSearchForm } from '../../forms';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
const validateEmail = email => {
  // Regular expression for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};
const SectionHero = props => {
  const { rootClassName, className } = props;
  const [subState, setSubState] = useState({ loading: false, error: null, success: false });
  const [emailState, setEmailState] = useState({ touched: false, valid: false, error: false });
  const [email, setEmail] = useState('');
  const classes = classNames(rootClassName || css.root, className);
  const onSearchSubmit = values => {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = values.location;
    const { history } = this.props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };

  return (
    <div className={classes}>
      <div className={css.footerTopContent}>
        <p className={css.footerTopMainTitle}>
          At <span className={css.ATcolored}>Activity Time</span> we understand kids explore new
          skills, build friendships and gain confidence from attending the right activities.
        </p>
        {/* {typeof window !== 'undefined' ? (
          window.location.pathname === '/' ||
          window.location.pathname === '/s' ||
          window.location.pathname.startsWith('/u') ? ( */}
        <div className={css.subsfooter}>
          <h2>Subscribe to our Newsletter</h2>
          <div className={css.subsinputrow}>
            <input
              type="text"
              placeholder="Enter your email"
              value={email}
              onFocus={() => setEmailState({ ...emailState, touched: true, error: false })}
              onChange={e => {
                const value = e.target.value;
                const isValid = validateEmail(value);
                setEmail(value);
                setEmailState({ ...emailState, valid: isValid, error: false });
              }}
              onBlur={() => {
                const isValid = validateEmail(email);
                setEmailState({ ...emailState, valid: isValid, error: true });
              }}
            />

            <button
              className={classNames(css.sbbtn, { [css.sentsbbtn]: subState.success })}
              type="submit"
              disabled={!emailState.valid && emailState.error}
              onClick={() => {
                setSubState({ ...subState, loading: true });
                emailState.valid &&
                  axios
                    .post(`${apiBaseUrl()}/api/subscribeToNewsletter`, { email })
                    .then(res => {
                      setSubState({ ...subState, success: true, loading: false, error: null });
                      setEmail('');
                      // typeof window !== 'undefined' ? window.scrollTo(0, 0) : null;
                    })
                    .catch(err => {
                      setSubState({ ...subState, success: false, loading: false, error: err });
                      setEmail('');
                      // typeof window !== 'undefined' ? window.scrollTo(0, 0) : null;
                    });
              }}
            >
              {subState.loading ? 'Subscribing' : subState.success ? 'Sent' : 'Subscribe'}
            </button>
          </div>
          {emailState.error && !emailState.valid && <span>Please enter a valid email address</span>}
        </div>
        {/* ) : null
        ) : null} */}
      </div>
    </div>
  );
};

SectionHero.defaultProps = { rootClassName: null, className: null };

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
};

export default SectionHero;
