import React, { useState } from 'react';
import { string, bool, func } from 'prop-types';
import Modal from '../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { injectIntl } from '../../util/reactIntl';
import FooterLogo from '../../assets/footerlogo.png';
import { withRouter } from 'react-router-dom';
import { logout } from '../../ducks/Auth.duck';
import IconSpinner from '../IconSpinner/IconSpinner';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import { compose } from 'redux';
import css from './SwitchUserModal.module.css';
const SwitchUserModal = props => {
  const { modalOpen, history, isProvider, setModalOpen, intl } = props;
  const [logoutInProgress, setLogoutInProgress] = useState();
  const dispatch = useDispatch();
  const REDUX_STATE = useSelector(state => state);
  const { isAuthenticated } = REDUX_STATE?.Auth || {};
  const { currentUser } = REDUX_STATE?.user || {};
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  const handleLogout = toLogin => {
    if (isAuthenticated && currentUser?.id?.uuid) {
      setLogoutInProgress(true);
      dispatch(logout()).then(() => {
        const path = toLogin
          ? pathByRouteName('LoginPage', routeConfiguration())
          : isProvider
          ? pathByRouteName('SignupProviderPage', routeConfiguration())
          : pathByRouteName('SignupPage', routeConfiguration());

        // In production we ensure that data is really lost,
        // but in development mode we use stored values for debugging
        if (config.dev) {
          history.push(path);
        } else if (typeof window !== 'undefined') {
          window.location = path;
        }
        setLogoutInProgress(false);
        console.log('logged out'); // eslint-disable-line
      });
    } else {
      return setModalOpen(false);
    }
  };
  return (
    <Modal
      id="switchUserModal"
      isOpen={modalOpen}
      onClose={() => setModalOpen(false)}
      onManageDisableScrolling={onManageDisableScrolling}
      className={css.logoutModal}
      intl={intl}
      usePortal
    >
      <div>
        <p className={css.headp}>Switching Accounts?</p>
        <p className={css.secp}>
          Great! To use the {isProvider ? 'parent' : 'activity provider'} features here's what you
          need to do:
        </p>
        <div className={css.stepdv}>
          <span>Step 1:</span>
          <p>Logout of the {isProvider ? 'activity provider' : 'parent'} account.</p>
        </div>
        <div className={css.stepdv}>
          <span>Step 2:</span>
          <p>Sign-up / log-in as a {isProvider ? 'parent' : 'activity provider'}.</p>
        </div>
        <span className={css.notespn}>
          *Please note you need a separate email address for each account.
        </span>
        <button type="button" className={css.logoutbtn} onClick={handleLogout}>
          {logoutInProgress ? <IconSpinner /> : 'LOGOUT'}
        </button>
      </div>
    </Modal>
  );
};
SwitchUserModal.propTypes = {
  rootClassName: string,
  className: string,
  modalOpen: bool.isRequired,
  isProvider: bool.isRequired,
  setModalOpen: func.isRequired,
};
export default compose(withRouter, injectIntl)(SwitchUserModal);
