import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import EditListingImage from '../EditListingImage/EditListingImage';

import css from './EditListingPublishPanel.module.css';
import EditListingPublishForm from '../../forms/EditListingPublishForm/EditListingPublishForm';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-16-icon.png';

const { Money } = sdkTypes;

const EditListingPublishPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    history,
    backButton,
    tabProgress,
    currentTab,
    savenExit,
    backbtnLoader,
    listingCountUpdateInProgress,
    isStarter,
    onSnoozedSubmit,
    viewport,
    snoozeLoading,
    params,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPricingPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingPricingPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingPricingPanel.createListingTitle" />
  );

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const enableSubmit =
    publicData?.categories?.filter(f => f === 'camps' || f === 'classes' || f === 'workshops')
      ?.length > 0 && publicData?.bookingType === 'instantBooking';
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Check out your listing! "
          currentTab={currentTab}
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
          bgIcon={screenicon}
        />

        <div className={css.rightSection}>
          <EditListingPublishForm
            className={css.form}
            listing={listing}
            savenExit={savenExit}
            backbtnLoader={backbtnLoader}
            tabProgress={tabProgress}
            listingCountUpdateInProgress={listingCountUpdateInProgress}
            onSubmit={values => {
              onSubmit({});
            }}
            onSnoozedSubmit={onSnoozedSubmit}
            history={history}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            currentUser={currentUser}
            backButton={backButton}
            isStarter={isStarter}
            enableSubmit={enableSubmit}
            viewport={viewport}
            snoozeLoading={snoozeLoading}
            params={params}
          />
        </div>
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPublishPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPublishPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPublishPanel;
