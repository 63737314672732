import React, { Component, Suspense, lazy } from 'react';
import { bool, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { Field, Form as FinalForm } from 'react-final-form';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { ensureCurrentUser } from '../../util/data';
import { propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { isUploadImageOverLimitError } from '../../util/errors';
import { FieldArray } from 'react-final-form-arrays';
import {
  Form,
  Avatar,
  Button,
  ImageFromFile,
  IconSpinner,
  FieldTextInput,
  NamedLink,
  InlineTextButton,
  Modal,
  PrimaryButton,
} from '../../components';
import Select, { components } from 'react-select';
// import { CKEditor } from 'ckeditor4-react';
import css from './ProfileSettingsForm.module.css';
import SubscriptionProducts from '../../components/SubscriptionProducts/SubscriptionProducts';
import arrayMutators from 'final-form-arrays';
import TextField from '@material-ui/core/TextField';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import CustomDateInput from '../../components/DateInput/CustomDateInput';
import moment from 'moment';
const ACCEPT_IMAGES = 'image/*';
const UPLOAD_CHANGE_DELAY = 2000; // Show spinner so that browser has time to load img srcset
class ProfileSettingsFormComponent extends Component {
  constructor(props) {
    super(props);

    this.uploadDelayTimeoutId = null;
    this.state = {
      uploadDelay: false,
      startDate: '',
      child: [],
      enableEdit: false,
      emailError: false,
      county: props.initialValues.county,
    };
    this.submittedValues = {};
  }

  componentDidUpdate(prevProps) {
    // Upload delay is additional time window where Avatar is added to the DOM,
    // but not yet visible (time to load image URL from srcset)
    if (prevProps.uploadInProgress && !this.props.uploadInProgress) {
      this.setState({ uploadDelay: true });
      this.uploadDelayTimeoutId = window.setTimeout(() => {
        this.setState({ uploadDelay: false });
      }, UPLOAD_CHANGE_DELAY);
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.uploadDelayTimeoutId);
  }

  render() {
    const countyOption = findOptionsForSelectFilter('county', this.props.filterConfig);
    return (
      <FinalForm
        {...this.props}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            className,
            currentUser,
            handleSubmit,
            intl,
            invalid,
            onImageUpload,
            pristine,
            pendingEmail,
            profileImage,
            rootClassName,
            updateInProgress,
            updateProfileError,
            uploadImageError,
            uploadInProgress,
            form,
            values,
            initialValues,
            removeppError,
            removeppLoader,
            uploadedImage,
            deleteProfilePic,
            profileUpdated,
            errors,
          } = fieldRenderProps;
          const setEnableEdit = () => {
            this.setState(prevState => ({ ...prevState, enableEdit: !prevState.enableEdit }));
          };
          const setEmailError = () => {
            this.setState(prevState => ({ ...prevState, emailError: !prevState.emailError }));
          };
          const customControl = () => ({ children, ...rest }) => {
            return (
              <components.Control
                className={classNames(css.customControl, {
                  [css.ctrldisabled]: rest.isDisabled,
                  [css.ctrlfocussed]: rest.isFocussed,
                })}
                {...rest}
              >
                {children}
              </components.Control>
            );
          };
          const customValueContainer = () => ({ children, ...rest }) => (
            <components.ValueContainer className={css.customvalcont} {...rest}>
              {children}
            </components.ValueContainer>
          );
          const customMenu = () => ({ children, ...rest }) => (
            <components.Menu className={css.selmenu} {...rest}>
              {children}
            </components.Menu>
          );
          const customOption = () => ({ children, ...rest }) => (
            <components.Option className={css.selOption} {...rest}>
              {children}
            </components.Option>
          );

          const user = ensureCurrentUser(currentUser);

          const uploadingOverlay =
            uploadInProgress || this.state.uploadDelay ? (
              <div className={css.uploadingImageOverlay}>
                <IconSpinner />
              </div>
            ) : null;

          const hasUploadError = !!uploadImageError && !uploadInProgress;
          const errorClasses = classNames({ [css.avatarUploadError]: hasUploadError });
          const transientUserProfileImage = profileImage.uploadedImage || user.profileImage;
          const transientUser = { ...user, profileImage: transientUserProfileImage };
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);
          const phnNoRequired = validators.required('Phone number is required');

          // Ensure that file exists if imageFromFile is used
          const fileExists = !!profileImage.file;
          const fileUploadInProgress = uploadInProgress && fileExists;
          const delayAfterUpload = profileImage.imageId && this.state.uploadDelay;
          const imageFromFile =
            fileExists && (fileUploadInProgress || delayAfterUpload) ? (
              <ImageFromFile
                id={profileImage.id}
                className={errorClasses}
                rootClassName={css.uploadingImage}
                aspectRatioClassName={css.squareAspectRatio}
                file={profileImage.file}
              >
                {uploadingOverlay}
              </ImageFromFile>
            ) : null;

          // Avatar is rendered in hidden during the upload delay
          // Upload delay smoothes image change process:
          // responsive img has time to load srcset stuff before it is shown to user.
          const avatarClasses = classNames(errorClasses, css.avatar, {
            [css.avatarInvisible]: this.state.uploadDelay,
          });
          const avatarComponent =
            !fileUploadInProgress && profileImage.imageId ? (
              <Avatar
                className={avatarClasses}
                renderSizes="(max-width: 767px) 96px, 240px"
                user={transientUser}
                disableProfileLink
              />
            ) : null;

          const chooseAvatarLabel =
            profileImage.imageId || fileUploadInProgress ? (
              <div className={css.avatarContainer}>
                {imageFromFile}
                {avatarComponent}
                <div className={css.changeAvatar}>
                  <FormattedMessage id="ProfileSettingsForm.changeAvatar" />
                </div>
              </div>
            ) : (
              <div className={css.avatarPlaceholder}>
                <div className={css.avatarPlaceholderText}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePicture" />
                </div>
                <div className={css.avatarPlaceholderTextMobile}>
                  <FormattedMessage id="ProfileSettingsForm.addYourProfilePictureMobile" />
                </div>
              </div>
            );

          const submitError = updateProfileError ? (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.updateProfileFailed" />
            </div>
          ) : null;

          const classes = classNames(rootClassName || css.root, className);
          const submitInProgress = updateInProgress;
          const submittedOnce = Object.keys(this.submittedValues).length > 0;
          const submitted = profileUpdated;
          const pristineSinceLastSubmit = isEqual(values, initialValues);
          const submitDisabled =
            invalid || pristineSinceLastSubmit || uploadInProgress || submitInProgress;

          const profilePictureMaybe = (
            <div className={css.sectionContainer}>
              {/* <h3 className={css.sectionTitle}>
                <FormattedMessage id="ProfileSettingsForm.yourProfilePicture" />
              </h3> */}
              <span className={css.profpic}>Add Profile Picture</span>
              <Field
                accept={ACCEPT_IMAGES}
                id="profileImage"
                name="profileImage"
                label={chooseAvatarLabel}
                type="file"
                form={null}
                uploadImageError={uploadImageError}
                disabled={uploadInProgress}
              >
                {fieldProps => {
                  const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`profileImage`, file);
                    form.blur(`profileImage`);
                    if (file != null) {
                      const tempId = `${file.name}_${Date.now()}`;
                      onImageUpload({ id: tempId, file });
                    }
                  };

                  let error = null;

                  if (isUploadImageOverLimitError(uploadImageError)) {
                    error = (
                      <div className={css.error}>
                        <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
                      </div>
                    );
                  } else if (uploadImageError) {
                    error = (
                      <div className={css.error}>
                        <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
                      </div>
                    );
                  }

                  return (
                    <div className={css.uploadAvatarWrapper}>
                      <label className={css.label} htmlFor={id}>
                        {label}
                      </label>
                      <input
                        accept={accept}
                        id={id}
                        name={name}
                        className={css.uploadAvatarInput}
                        disabled={disabled}
                        onChange={onChange}
                        type={type}
                      />
                      {error}
                    </div>
                  );
                }}
              </Field>
              <div
                className={classNames(css.removepp, {
                  [css.nopp]: currentUser.profileImage ? false : true,
                })}
                onClick={deleteProfilePic}
              >
                {removeppLoader
                  ? 'Removing...'
                  : removeppError
                  ? 'Failed to remove'
                  : 'Remove Profile Picture'}
              </div>
            </div>
          );
          const infoDivMaybe = (
            <div className={css.decoy}>
              <div className={css.formDiv}>
                <div className={css.ooo}>
                  <div className={css.mfbq}>
                    <FieldTextInput
                      name="firstName"
                      id="firstName"
                      className={css.nameinp}
                      placeholder="First Name"
                      type="text"
                      disabled={!this.state.enableEdit}
                    />
                  </div>
                  <div className={css.mfbq}>
                    <FieldTextInput
                      name="lastName"
                      id="lastName"
                      className={css.nameinp}
                      placeholder="Last Name"
                      type="text"
                      disabled={!this.state.enableEdit}
                    />
                  </div>
                </div>
                <div className={css.mfb}>
                  <FieldTextInput
                    name="phnNo"
                    id="phnNo"
                    className={css.nameinp}
                    placeholder="Phone No."
                    type="text"
                    disabled={!this.state.enableEdit}
                    onChange={e => {
                      const value = e.target.value;
                      const regex = /^[0-9\b]+$/;
                      if ((value.match(regex) || value === '') && value.length <= 11)
                        form.change('phnNo', value);
                    }}
                  />
                </div>
                <div className={css.mfbsel}>
                  {' '}
                  <Select
                    isClearable={false}
                    isSearchable={true}
                    isDisabled={!this.state.enableEdit}
                    options={countyOption}
                    value={this.state.county}
                    onChange={e =>
                      this.setState(state => ({ ...state, county: e }), form.change('county', e))
                    }
                    // menuIsOpen={true}
                    placeholder={'Select County'}
                    components={{
                      IndicatorSeparator: undefined,
                      DropdownIndicator: undefined,
                      Control: customControl(),
                      ValueContainer: customValueContainer(),
                      Menu: customMenu(),
                      Option: customOption(),
                    }}
                  />
                </div>
                <button
                  type="button"
                  className={css.editBtn}
                  onClick={() => setEnableEdit(!this.state.enableEdit)}
                >
                  {this.state.enableEdit ? 'Editing' : 'Edit'}
                </button>
              </div>
            </div>
          );
          const childArray = (
            <div className={css.fmlydiv}>
              <span>Family Members</span>
              <FieldArray name="child">
                {({ fields }) => {
                  const currIdx = fields.length;
                  return (
                    <div>
                      {fields.map((f, idx) => (
                        <div key={idx}>
                          <div>
                            <label>
                              <span className={css.cspn}>Child {idx + 1}</span>
                              <span className={css.xpsn} onClick={() => fields.remove(idx)}>
                                x
                              </span>
                            </label>
                            <div className={css.flnmdiv}>
                              <FieldTextInput
                                id={`${f}.firstName`}
                                name={`${f}.firstName`}
                                className={css.policyName}
                                type="text"
                                // value={values.child[idx]?.firstName}
                                placeholder={'Enter first name'}
                                onChange={e => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                    form.change(`${f}.firstName`, value);
                                  }
                                }}
                                validate={
                                  idx === 0 && currIdx === 1 ? () => undefined : firstNameRequired
                                }
                              />
                              <FieldTextInput
                                id={`${f}.lastName`}
                                name={`${f}.lastName`}
                                className={css.policyName}
                                type="text"
                                placeholder={'Enter last name'}
                                onChange={e => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                    form.change(`${f}.lastName`, value);
                                  }
                                }}
                                validate={
                                  idx === 0 && currIdx === 1 ? () => undefined : lastNameRequired
                                }
                              />
                            </div>
                            <div className={css.bnsdate}>
                              <CustomDateInput
                                id={`${f}.dob`}
                                name={`${f}.dob`}
                                className={`${css.datepicker}`}
                                initialDate={values?.child ? values.child[idx]?.dob : null}
                                intl={intl}
                                placeholderText={'MM/DD/YYYY'}
                                // onChange={date => {
                                //   const formattedDate = moment(date.date).format('YYYY-MM-DD');
                                //   form.change(`${f}.dob`, formattedDate);
                                // }}
                                value={values?.child ? values.child[idx]?.dob : null}
                                block={false}
                                readOnly={false}
                                showErrorMessage={true}
                                validate={
                                  idx === 0 && currIdx === 1
                                    ? () => undefined
                                    : validators.customDateRequired('Date is required')
                                }
                              />
                            </div>

                            <div className={css.mdnts}>
                              <span>Medical, Dietary or Additional Needs</span>
                              <FieldTextInput
                                id={`${f}.childMedicalNotes`}
                                name={`${f}.childMedicalNotes`}
                                type="textarea"
                                onChange={e => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z ]+$/.test(value) || value === '')
                                    form.change(`${f}.childMedicalNotes`, value);
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      ))}
                      <div>
                        <label>Child {currIdx + 1}</label>
                        <button
                          type="button"
                          onClick={() =>
                            fields.push({
                              id: '',
                              firstName: '',
                              lastName: '',
                              dob: '',
                              childMedicalNotes: '',
                            })
                          }
                        >
                          Add New Child
                        </button>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          );
          const emgContactsArray = (
            <div className={css.fmlydiv}>
              <span>Emergency Contacts</span>
              <FieldArray name="emgContacts">
                {({ fields }) => {
                  const currIdx = fields.length;
                  return (
                    <div>
                      {fields.map((f, idx) => (
                        <div key={idx}>
                          <div>
                            <label>
                              <span className={css.cspn}>Contact {idx + 1}</span>
                              <span className={css.xpsn} onClick={() => fields.remove(idx)}>
                                x
                              </span>
                            </label>
                            <div className={css.flnmdiv}>
                              <FieldTextInput
                                id={`${f}.firstName`}
                                name={`${f}.firstName`}
                                className={css.policyName}
                                type="text"
                                // value={values.child[idx]?.firstName}
                                placeholder={'Enter first name'}
                                onChange={e => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                    form.change(`${f}.firstName`, value);
                                  }
                                }}
                                validate={
                                  idx === 0 && currIdx === 1 ? () => undefined : firstNameRequired
                                }
                              />
                              <FieldTextInput
                                id={`${f}.lastName`}
                                name={`${f}.lastName`}
                                className={css.policyName}
                                type="text"
                                placeholder={'Enter last name'}
                                onChange={e => {
                                  const value = e.target.value;
                                  if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                    form.change(`${f}.lastName`, value);
                                  }
                                }}
                                validate={
                                  idx === 0 && currIdx === 1 ? () => undefined : lastNameRequired
                                }
                              />
                            </div>
                            <FieldTextInput
                              id={`${f}.phnNo`}
                              name={`${f}.phnNo`}
                              className={css.cmgcntphn}
                              type="text"
                              placeholder={'Contact Tel No.'}
                              onChange={e => {
                                const value = e.target.value;
                                const regex = /^[0-9\b]+$/;
                                if ((value.match(regex) || value === '') && value.length <= 11)
                                  form.change(`${f}.phnNo`, value);
                              }}
                              validate={
                                idx === 0 && currIdx === 1
                                  ? () => undefined
                                  : validators.composeValidators(
                                      phnNoRequired,
                                      validators.minLength('Minimum 9 digits', 9),
                                      validators.maxLength('Miximum 11 digits', 11)
                                    )
                              }
                            />
                          </div>
                        </div>
                      ))}
                      <div>
                        <label>Contact {currIdx + 1}</label>
                        <button
                          type="button"
                          onClick={() =>
                            fields.push({
                              firstName: '',
                              lastName: '',
                              phnNo: '',
                            })
                          }
                        >
                          Add New Contact
                        </button>
                      </div>
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          );
          return (
            <>
              <Form
                className={classes}
                onSubmit={e => {
                  e.preventDefault();
                  this.submittedValues = values;
                  this.props.onSubmit(values);
                }}
              >
                <div className={css.mainDiv}>
                  <div className={css.scdv}>
                    <span className={css.profpic}>My Info</span>
                    {infoDivMaybe}
                    {childArray}
                  </div>
                  <div className={css.scdv}>
                    {profilePictureMaybe}
                    {emgContactsArray}
                  </div>
                </div>
                {submitError}
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress && !removeppLoader}
                  disabled={submitDisabled}
                  ready={submitted}
                >
                  <FormattedMessage id="ProfileSettingsForm.saveChanges" />
                </Button>
              </Form>
            </>
          );
        }}
      />
    );
  }
}

ProfileSettingsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  uploadImageError: null,
  updateProfileError: null,
  updateProfileReady: false,
  filterConfig: config.custom.filters,
};

ProfileSettingsFormComponent.propTypes = {
  rootClassName: string,
  className: string,

  uploadImageError: propTypes.error,
  uploadInProgress: bool.isRequired,
  updateInProgress: bool.isRequired,
  updateProfileError: propTypes.error,
  updateProfileReady: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const ProfileSettingsForm = compose(injectIntl)(ProfileSettingsFormComponent);

ProfileSettingsForm.displayName = 'ProfileSettingsForm';

export default ProfileSettingsForm;
