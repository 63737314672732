/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const classTypes = [
  { label: 'Classes', singular: 'Class', key: 'classes', value: 'classes' },
  { label: 'Lessons', singular: 'Lesson', key: 'lessons', value: 'lessons' },
  { label: 'Sessions', singular: 'Session', key: 'sessions', value: 'sessions' },
];
export const starterOrBookableCategories = ['camps', 'classes', 'workshops'];
export const filters = [
  {
    id: 'dates-length',
    label: 'Dates',
    type: 'BookingDateRangeLengthFilter',
    group: 'primary',
    // Note: BookingDateRangeFilter is fixed filter,
    // you can't change "queryParamNames: ['dates'],"
    queryParamNames: ['dates', 'minDuration'],
    config: {
      // A global time zone to use in availability searches. As listings
      // can be in various time zones, we must decide what time zone we
      // use in search when looking for available listings within a
      // certain time interval.
      //
      // If you have all/most listings in a certain time zone, change this
      // config value to that.
      //
      // See: https://en.wikipedia.org/wiki/List_of_tz_database_time_zones
      searchTimeZone: 'Etc/UTC',

      // Options for the minimum duration of the booking
      options: [
        { key: '0', label: 'Any length' },
        { key: '60', label: '1 hour', shortLabel: '1h' },
        { key: '120', label: '2 hours', shortLabel: '2h' },
      ],
    },
  },
  {
    id: 'price',
    label: 'Price',
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 1000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: 'Keyword',
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },
  // {
  //   id: 'yogaStyles',
  //   label: 'Yoga styles',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_yogaStyles'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'ashtanga', label: 'Ashtanga' },
  //       { key: 'hatha', label: 'Hatha' },
  //       { key: 'kundalini', label: 'Kundalini' },
  //       { key: 'restorative', label: 'Restorative' },
  //       { key: 'vinyasa', label: 'Vinyasa' },
  //       { key: 'yin', label: 'Yin' },
  //     ],
  //   },
  // },
  {
    id: 'features',
    label: 'Features',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_features'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'foodIncluded', label: 'Food Included' },
        { key: 'specialNeedsFriendly', label: 'Special need friendly' },
        { key: 'wheelchairFriendly', label: 'Wheelchair Friendly' },
        { key: 'siblingsDiscount', label: 'Siblings Discount' },
      ],
    },
  },
  {
    id: 'categories',
    label: 'Activities',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_categories'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { value: 'camps', label: 'Camps', key: 'camps' },
        { value: 'classes', label: 'Classes', key: 'classes' },
        { value: 'workshops', label: 'Workshops', key: 'workshops' },
        { value: 'afterSchoolClub', label: 'After-School Club', key: 'afterSchoolClub' },
        { value: 'birthdayParty', label: 'Birthdays', key: 'birthdayParty' },
        { value: 'breakfastClub', label: 'Breakfast Clubs', key: 'breakfastClub' },
        { value: 'club', label: 'Clubs', key: 'club' },
        { value: 'events', label: 'Events', key: 'events' },

        {
          value: 'inSchoolProgrammes',
          label: 'In-School Programmes',
          key: 'inSchoolProgrammes',
        },
        { value: 'placesToVisit', label: 'Places to Visit', key: 'placesToVisit' },
        { value: 'schoolTours', label: 'School Tours', key: 'schoolTours' },
      ],
    },
  },
  {
    id: 'age',
    label: 'Ages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_age'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { value: '1', label: '1', key: '1', sqlKey: 'one' },
        { value: '2', label: '2', key: '2', sqlKey: 'two' },
        { value: '3', label: '3', key: '3', sqlKey: 'three' },
        { value: '4', label: '4', key: '4', sqlKey: 'four' },
        { value: '5', label: '5', key: '5', sqlKey: 'five' },
        { value: '6', label: '6', key: '6', sqlKey: 'six' },
        { value: '7', label: '7', key: '7', sqlKey: 'seven' },
        { value: '8', label: '8', key: '8', sqlKey: 'eight' },
        { value: '9', label: '9', key: '9', sqlKey: 'nine' },
        { value: '10', label: '10', key: '10', sqlKey: 'ten' },
        { value: '11', label: '11', key: '11', sqlKey: 'eleven' },
        { value: '12', label: '12', key: '12', sqlKey: 'twelve' },
        { value: '13', label: '13', key: '13', sqlKey: 'thirteen' },
        { value: '14', label: '14', key: '14', sqlKey: 'forteen' },
        { value: '15', label: '15', key: '15', sqlKey: 'fifteen' },
        { value: '16', label: '16', key: '16', sqlKey: 'sixteen' },
        { value: '17', label: '17', key: '17', sqlKey: 'seventeen' },
        { value: '18', label: '18', key: '18', sqlKey: 'eighteen' },
      ],
    },
  },
  {
    id: 'activityType',
    label: 'Activity Type',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_activityType'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { value: 'summerCamp', label: 'Summer Camp', key: 'summerCamp' },
        { value: 'halloweenCamp', label: 'Halloween Camp', key: 'halloweenCamp' },
        { value: 'easterCamp', label: 'Easter Camp', key: 'easterCamp' },
        {
          value: 'februaryMidTermCamp',
          label: 'February Mid-Term Camp',
          key: 'februaryMidTermCamp',
        },
        { value: 'other', label: 'Other', key: 'other' },
      ],
    },
  },
  {
    id: 'subCategories',
    label: 'Categories',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_subCategories'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // { value: 'chooseAll', label: 'Choose All', key: 'chooseAll' },
        { value: 'business', label: 'Business', key: 'business' },
        { value: 'creative', label: 'Creative', key: 'creative' },
        { value: 'cultural', label: 'Cultural', key: 'cultural' },
        { value: 'dance', label: 'Dance', key: 'dance' },
        { value: 'educational', label: 'Educational', key: 'educational' },
        { value: 'family', label: 'Family - Friendly', key: 'family' },
        { value: 'festival', label: 'Festival', key: 'festival' },
        { value: 'food', label: 'Food', key: 'food' },
        {
          value: 'inclusionAndSpecialNeeds',
          label: 'Inclusion',
          key: 'inclusionAndSpecialNeeds',
        },
        { value: 'languages', label: 'Languages', key: 'languages' },
        { value: 'literature', label: 'Literature', key: 'literature' },
        { value: 'math', label: 'Math', key: 'math' },
        { value: 'multiActivity', label: 'Multi-Activity', key: 'multiActivity' },
        { value: 'music', label: 'Music', key: 'music' },
        { value: 'nature', label: 'Nature & Outdoor', key: 'nature' },
        { value: 'outdoor', label: 'Outdoor', key: 'outdoor' },
        { value: 'performingArts', label: 'Performing Arts', key: 'performingArts' },
        { value: 'residential', label: 'Residential', key: 'residential' },
        { value: 'sports', label: 'Sports', key: 'sports' },
        { value: 'STEM', label: 'STEM', key: 'STEM' },
        { value: 'wellbeing', label: 'Wellbeing', key: 'wellbeing' },
        { value: 'writing', label: 'Writing', key: 'writing' },
        { value: 'other', label: 'Other', key: 'other' },
      ],
    },
  },

  {
    id: 'allowedAge',
    label: 'Ages',
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_allowedAge'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { value: 'fixedAge', label: 'Fixed Age', key: 'fixedAge' },
        { value: 'noRestriction', label: 'No age bar', key: 'noRestriction' },
        { value: 'ageGroup', label: 'Age Range', key: 'ageGroup' },
      ],
    },
  },

  {
    id: 'county',
    label: 'County',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_county'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      // searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        {
          value: 'carlow',
          key: 'carlow',
          label: 'Co. Carlow',
          latlng: { lat: 52.8306, lng: -6.9317 },
        },
        {
          value: 'caven',
          key: 'caven',
          label: 'Co. Cavan',
          latlng: { lat: 53.9765, lng: -7.2997 },
        },
        {
          value: 'clare',
          key: 'clare',
          label: 'Co. Clare',
          latlng: { lat: 52.9045, lng: -8.9811 },
        },
        { value: 'cork', key: 'cork', label: 'Co. Cork', latlng: { lat: 51.9001, lng: -8.4731 } },
        {
          value: 'donegal',
          key: 'donegal',
          label: 'Co. Donegal',
          latlng: { lat: 54.6549, lng: -8.1041 },
        },
        {
          value: 'dublin',
          key: 'dublin',
          label: 'Co. Dublin',
          latlng: { lat: 53.3498, lng: -6.2603 },
        },
        {
          value: 'galway',
          key: 'galway',
          label: 'Co. Galway',
          latlng: { lat: 53.2729, lng: -9.0418 },
        },
        {
          value: 'kerry',
          key: 'kerry',
          label: 'Co. Kerry',
          latlng: { lat: 52.1545, lng: -9.5669 },
        },
        {
          value: 'kildare',
          key: 'kildare',
          label: 'Co. Kildare',
          latlng: { lat: 53.212, lng: -6.8195 },
        },
        {
          value: 'kilkenny',
          key: 'kilkenny',
          label: 'Co. Kilkenny',
          latlng: { lat: 52.6477, lng: -7.2561 },
        },
        {
          value: 'laois',
          key: 'laois',
          label: 'Co. Laois',
          latlng: { lat: 52.9943, lng: -7.3323 },
        },
        {
          value: 'leitrim',
          key: 'leitrim',
          label: 'Co. Leitrim',
          latlng: { lat: 54.1247, lng: -8.002 },
        },
        {
          value: 'limerick',
          key: 'limerick',
          label: 'Co. Limerick',
          latlng: { lat: 52.6653, lng: -8.6238 },
        },
        {
          value: 'longford',
          key: 'longford',
          label: 'Co. Longford',
          latlng: { lat: 53.727, lng: -7.7998 },
        },
        {
          value: 'louth',
          key: 'louth',
          label: 'Co. Louth',
          latlng: { lat: 53.9252, lng: -6.4889 },
        },
        { value: 'mayo', key: 'mayo', label: 'Co. Mayo', latlng: { lat: 54.0153, lng: -9.4289 } },
        {
          value: 'meath',
          key: 'meath',
          label: 'Co. Meath',
          latlng: { lat: 53.6055, lng: -6.6564 },
        },
        {
          value: 'monaghan',
          key: 'monaghan',
          label: 'Co. Monaghan',
          latlng: { lat: 54.2479, lng: -6.9708 },
        },
        {
          value: 'offaly',
          key: 'offaly',
          label: 'Co. Offaly',
          latlng: { lat: 53.2357, lng: -7.7122 },
        },
        {
          value: 'rosscommon',
          key: 'rosscommon',
          label: 'Co. Roscommon',
          latlng: { lat: 53.7593, lng: -8.2682 },
        },
        {
          value: 'sligo',
          key: 'sligo',
          label: 'Co. Sligo',
          latlng: { lat: 54.2667, lng: -8.4833 },
        },
        {
          value: 'tipperary',
          key: 'tipperary',
          label: 'Co. Tipperary',
          latlng: { lat: 52.4738, lng: -8.1619 },
        },
        {
          value: 'waterford',
          key: 'waterford',
          label: 'Co. Waterford',
          latlng: { lat: 52.2583, lng: -7.119 },
        },
        {
          value: 'westmeath',
          key: 'westmeath',
          label: 'Co. Westmeath',
          latlng: { lat: 53.5345, lng: -7.4653 },
        },
        {
          value: 'wexford',
          key: 'wexford',
          label: 'Co. Wexford',
          latlng: { lat: 52.3369, lng: -6.4633 },
        },
        {
          value: 'wicklow',
          key: 'wicklow',
          label: 'Co. Wicklow',
          latlng: { lat: 52.986231, lng: -6.367254 },
        },
      ],
    },
  },

  {
    id: 'city',
    label: 'County(populated)',
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_city'],
    config: {
      // Optional modes: 'has_all', 'has_any'
      // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
      searchMode: 'has_all',

      // "key" is the option you see in Flex Console.
      // "label" is set here for this web app's UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { value: 'carlow', key: 'Carlow', label: 'carlow' },
        { value: 'caven', key: 'Caven', label: 'caven' },
        { value: 'clare', key: 'Clare', label: 'clare' },
        { value: 'cork', key: 'Cork', label: 'cork' },
        { value: 'Donegal', key: 'Donegal', label: 'Donegal' },
        { value: 'wicklow', key: 'Wicklow', label: 'wicklow' },
        { value: 'wexford', key: 'Wexford', label: 'wexford' },
        { value: 'westmeath', key: 'Westmeath', label: 'westmeath' },
        { value: 'waterford', key: 'Waterford', label: 'waterford' },
        { value: 'tipperary', key: 'Tipperary', label: 'tipperary' },
        { value: 'sligo', key: 'Sligo', label: 'sligo' },
        { value: 'rosscommon', key: 'Rosscommon', label: 'rosscommon' },
        { value: 'offaly', key: 'Offaly', label: 'offaly' },
        { value: 'monaghan', key: 'Monaghan', label: 'monaghan' },
        { value: 'meath', key: 'Meath', label: 'meath' },
        { value: 'mayo', key: 'Mayo', label: 'mayo' },
        { value: 'louth', key: 'Louth', label: 'louth' },
        { value: 'longford', key: 'Longford', label: 'longford' },
        { value: 'limerick', key: 'Limerick', label: 'limerick' },
        { value: 'leitrim', key: 'Leitrim', label: 'leitrim' },
        { value: 'laois', key: 'Laois', label: 'laois' },
        { value: 'kilkenny', key: 'Kilkenny', label: 'kilkenny' },
        { value: 'kildare', key: 'Kildare', label: 'kildare' },
        { value: 'kerry', key: 'Kerry', label: 'kerry' },
        { value: 'galway', key: 'Galway', label: 'galway' },
        { value: 'dublin', key: 'Dublin', label: 'dublin' },
        { value: 'dublin1', key: 'Dublin1', label: 'dublin1' },
        { value: 'dublin2', key: 'Dublin2', label: 'dublin2' },
        { value: 'dublin3', key: 'Dublin3', label: 'dublin3' },
        { value: 'dublin4', key: 'Dublin4', label: 'dublin4' },
        { value: 'dublin5', key: 'Dublin5', label: 'dublin5' },
        { value: 'dublin6', key: 'Dublin6', label: 'dublin6' },
        { value: 'dublin7', key: 'Dublin7', label: 'dublin7' },
        { value: 'dublin8', key: 'Dublin8', label: 'dublin8' },
        { value: 'dublin9', key: 'Dublin9', label: 'dublin9' },
        { value: 'dublin10', key: 'Dublin10', label: 'dublin10' },
        { value: 'dublin11', key: 'Dublin11', label: 'dublin11' },
        { value: 'dublin12', key: 'Dublin12', label: 'dublin12' },
        { value: 'dublin13', key: 'Dublin13', label: 'dublin13' },
        { value: 'dublin14', key: 'Dublin14', label: 'dublin14' },
        { value: 'dublin15', key: 'Dublin15', label: 'dublin15' },
        { value: 'dublin16', key: 'Dublin16', label: 'dublin16' },
        { value: 'dublin17', key: 'Dublin17', label: 'dublin17' },
        { value: 'dublin18', key: 'Dublin18', label: 'dublin18' },
        { value: 'dublin20', key: 'Dublin20', label: 'dublin20' },
        { value: 'dublin22', key: 'Dublin22', label: 'dublin22' },
        { value: 'dublin24', key: 'Dublin24', label: 'dublin24' },
        { value: 'northCountyDublin', key: 'Northcountydublin', label: 'northCountyDublin' },
        { value: 'southCountyDublin', key: 'Southcountydublin', label: 'southCountyDublin' },
      ],
    },
  },

  // {
  //   id: 'age',
  //   label: 'Age',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_age'],
  //   config: {
  //     // Optional modes: 'has_all', 'has_any'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'noRestrictions', label: 'No Restrictions' },
  //       { key: 'fixedAge', label: 'Fixed Age' },
  //       { key: 'ageGroup', label: 'Age Group' },
  //     ],
  //   },
  // },
  // {
  //   id: 'certificate',
  //   label: 'Certificate',
  //   type: 'SelectSingleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_certificate'],
  //   config: {
  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'none', label: 'None', hideFromFilters: true, hideFromListingInfo: true },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Keyword filter is sorting the results already by relevance.
  // If keyword filter is active, we need to disable sorting.
  conflictingFilters: ['keyword'],

  options: [
    { key: 'createdAt', label: 'Newest' },
    { key: '-createdAt', label: 'Oldest' },
    { key: '-price', label: 'Lowest price' },
    { key: 'price', label: 'Highest price' },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
  ],
};
export const defaultTitle = 'Please set your activity name!';
