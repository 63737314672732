import React from 'react';
import { AiFillStar } from 'react-icons/ai';
import css from './StarRating.module.css'; // Import the CSS Module
import classNames from 'classnames';

const Star = ({ fill }) => {
  return (
    <div className={css.starContainer}>
      <AiFillStar className={`${css.star} ${css.full} ${css.underlay}`} />
      <div className={css.starOverlay} style={{ width: `${fill}%` }}>
        <AiFillStar className={`${css.star} ${css.partial}`} />
      </div>
    </div>
  );
};

const StarRating = ({ rating, className }) => {
  const fullStars = Math.floor(rating); // Number of full stars
  const partialStar = Math.round((rating % 1) * 100); // Percentage of the partial star
  const emptyStars = 5 - fullStars - (partialStar > 0 ? 1 : 0);
  return (
    <div className={classNames(css.starRating, className)}>
      {[...Array(fullStars)].map((_, index) => (
        <AiFillStar key={index} className={`${css.star} ${css.full}`} />
      ))}
      {partialStar > 0 && <Star fill={partialStar} />}
      {[...Array(emptyStars)].map((_, index) => (
        <AiFillStar
          key={index + fullStars + (partialStar > 0 ? 1 : 0)}
          className={`${css.star} ${css.empty}`}
        />
      ))}
    </div>
  );
};

export default StarRating;
