import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  NamedLink,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingTitleForm.module.css';
import Select, { components } from 'react-select';
import PhoneInput from 'react-phone-input-2';
import './PhoneInput2.css';
import {
  ACTIVITYTYPE,
  OPENFOR,
  SUBCATEGORIES,
  TITLE,
} from '../../components/EditListingWizard/EditListingWizardTab';
import { campsActivityTypeOptions } from '../../assets/activityTypeOptions';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { isEqual } from 'lodash';
import { classTypes } from '../../marketplace-custom-config';

// import { AiOutlineDoubleLeft } from 'react-icons/ai';

const TITLE_MAX_LENGTH = 40;

const EditListingTitleFormComponent = props => {
  const { filterConfig, initialValues } = props;
  const [validEmail, setValidEmail] = useState(true);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showPhoneError, setShowPhoneError] = useState(false);
  const [validPhone, setValidPhone] = useState(true);
  const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
  const emailFormatValid = email => {
    return EMAIL_RE.test(email) ? true : false;
  };
  const customControl = props => (
    <components.Control {...props} className={css.customControl}>
      {props.children}
    </components.Control>
  );
  const customValueContainer = props => (
    <components.ValueContainer {...props} className={css.customValueContainer}>
      {props.children} <span className={css.astx}>*</span>
    </components.ValueContainer>
  );
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          certificateOptions,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          isPublished,
          tabProgress,
          initialValues,
          listing,
          titleAddon,
          saveAndExit,
          backbtnLoader,
        } = formRenderProps;
        const [classType, setClassType] = useState(initialValues?.classType || null);
        const tabPercent = tabProgress(TITLE);
        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titlePlaceholder',
        });
        const titleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.titleRequired',
        });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;
        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const { publicData } = listing.attributes;

        const submitDisabled =
          invalid ||
          disabled ||
          submitInProgress ||
          !values.title ||
          (publicData?.categories?.includes('club') && (!validEmail || !validPhone)) ||
          (publicData?.categories?.includes('classes') && !values.classType);
        const titleMaybe = (
          <FieldTextInput
            id="title"
            name="title"
            className={classNames(css.staptext, {
              [css.pltovsttitle]:
                publicData?.categories?.includes('placesToVisit') ||
                publicData?.categories?.includes('schoolTours'),
            })}
            type="text"
            // label={titleMessage}
            // placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
          />
        );
        const businessNameMaybe = (
          <FieldTextInput
            id="businessName"
            name="businessName"
            className={classNames(css.staptext, {
              [css.pltovsttitle]:
                publicData?.categories?.includes('placesToVisit') ||
                publicData?.categories?.includes('schoolTours'),
            })}
            type="text"
            // label={titleMessage}
            // placeholder={titlePlaceholderMessage}
            maxLength={TITLE_MAX_LENGTH}
          />
        );
        const lastTab =
          publicData?.categories?.includes('camps') ||
          publicData?.categories?.includes('classes') ||
          publicData?.categories?.includes('workshops') ||
          publicData?.categories?.includes('events') ||
          publicData?.categories?.includes('club') ||
          publicData?.categories?.includes('inSchoolProgrammes')
            ? SUBCATEGORIES
            : publicData?.categories?.includes('schoolTours') ||
              publicData?.categories?.includes('placesToVisit') ||
              publicData?.categories?.includes('birthdayParty')
            ? ACTIVITYTYPE
            : publicData?.categories?.includes('afterSchoolClub') ||
              publicData?.categories?.includes('breakfastClub')
            ? OPENFOR
            : null;

        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              saveAndExit(values);
            }}
          >
            Save & Exit
          </Button>
        );
        const classTypeMaybe = (
          <Select
            name="classType"
            id={'classType'}
            options={classTypes}
            value={classType}
            isClearable={false}
            defaultMenuIsOpen={true}
            onChange={e => {
              e === null ? setClassType('') : setClassType(e);
              e === null ? null : form.change('classType', e);
            }}
            components={{
              Control: customControl,
              ValueContainer: customValueContainer,
              IndicatorSeparator: () => null,
            }}
            isSearchable={false}
            className={css.classTypeSelect}
          />
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              {publicData?.categories?.includes('club') ? (
                <div className={`${css.titleContainer} ${css.clubTitle}`}>
                  <label>Your club name:</label>
                  <div className={`${css.stampwinput} ${css.forsctr}`}>{businessNameMaybe}</div>
                  <span className={css.wordLimit}>
                    {!!values.businessName ? values.businessName.length : 0}/{TITLE_MAX_LENGTH}
                  </span>
                </div>
              ) : null}
              {publicData?.categories?.includes('club') ? (
                <p className={css.addtl}>
                  <b>Add a descriptive title -</b> this will be displayed along with your business
                  name
                </p>
              ) : null}
              <div className={css.titleContainer}>
                {publicData?.categories?.includes('camps') ||
                publicData?.categories?.includes('afterSchoolClub') ||
                publicData?.categories?.includes('breakfastClub') ||
                publicData?.categories?.includes('birthdayParty') ||
                publicData?.categories?.includes('classes') ||
                publicData?.categories?.includes('inSchoolProgrammes') ||
                publicData?.categories?.includes('workshops') ||
                publicData?.categories?.includes('events') ? (
                  <>
                    <label>Create your title</label>
                    <div className={css.stampwinput}>
                      {titleMaybe}{' '}
                      <span>
                        {publicData?.categories?.includes('classes') ? classTypeMaybe : titleAddon}
                      </span>
                    </div>
                    <span className={css.wordLimit}>
                      {!!values.title ? values.title.length : 0}/{TITLE_MAX_LENGTH}
                    </span>
                  </>
                ) : publicData?.categories?.includes('club') ? (
                  <>
                    <div className={css.clubTitle}>
                      <label>Create your title</label>
                      <div className={css.stampwinput}>
                        {titleMaybe}
                        <span>{titleAddon}</span>
                      </div>
                    </div>
                    <span className={css.wordLimit}>
                      {!!values.title ? values.title.length : 0}/{TITLE_MAX_LENGTH}
                    </span>
                  </>
                ) : publicData?.categories?.includes('placesToVisit') ? (
                  <div className={css.clubTitle}>
                    <label>Name of your business</label>
                    <div className={css.stampwinput}>{businessNameMaybe}</div>
                    <span className={css.wordLimit}>
                      {!!values.businessName ? values.businessName.length : 0}/{TITLE_MAX_LENGTH}
                    </span>
                    {
                      <p className={css.addtl}>
                        <b>Add a descriptive title -</b> this will be displayed along with your
                        business name
                      </p>
                    }
                    <div className={`${css.ptvtitle} ${css.emailWrapper}`}>
                      {/* <label>Add a descriptive tagline</label> */}
                      {titleMaybe}
                      <span className={css.wordLimit}>
                        {!!values.title ? values.title.length : 0}/{TITLE_MAX_LENGTH}
                      </span>
                    </div>
                  </div>
                ) : publicData?.categories?.includes('schoolTours') ? (
                  <>
                    {' '}
                    <div className={css.clubTitle}>
                      <label>Name of your business</label>
                      <div className={`${css.stampwinput} ${css.forsctr}`}>{businessNameMaybe}</div>
                      <span className={css.wordLimit}>
                        {!!values.businessName ? values.businessName.length : 0}/{TITLE_MAX_LENGTH}
                      </span>
                    </div>
                    <label>Create your title</label>
                    <div className={css.stampwinput}>
                      {titleMaybe}
                      <span>School Tours</span>
                    </div>
                  </>
                ) : null}
              </div>
              {publicData?.categories?.includes('afterSchoolClub') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Bright Sparks</span> After-School Club
                  </p>
                  <p>
                    <span>Little MIT</span> After-School Club
                  </p>
                  <p>
                    <span>Young Ones</span> After-School Club
                  </p>
                </div>
              ) : publicData?.categories?.includes('birthdayParty') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Dinosaur-Themed Art</span> Party
                  </p>
                  <p>
                    <span>Tee-Pee Fairy Sleepover</span> Party
                  </p>
                  <p>
                    <span>Splatball</span> Party
                  </p>
                </div>
              ) : publicData?.categories?.includes('breakfastClub') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Bright Sparks</span> Breakfast Club
                  </p>
                  <p>
                    <span>Little MIT</span> Breakfast Club
                  </p>
                  <p>
                    <span>Young Ones</span> Breakfast Club
                  </p>
                </div>
              ) : publicData?.categories?.includes('classes') ? (
                <div className={`${css.suggestion} ${css.classsuggestion}`}>
                  <label>Ideas:</label>
                  <p>
                    {' '}
                    <span>Mini Ballet</span> Classes
                  </p>
                  <p>
                    <span>Horse-riding</span> Lessons
                  </p>
                  <p>
                    <span>Rugby Coaching</span> Sessions
                  </p>
                </div>
              ) : publicData?.categories?.includes('schoolTours') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>2-Hour Zipline Adventure Zone</span> School Tour
                  </p>
                  <p>
                    <span>Overnight Bushcraft 4-Activity</span> School Tour{' '}
                  </p>
                  <p>
                    <span>Soft Play Adventure & Maritime Museum</span> School Tour{' '}
                  </p>
                </div>
              ) : publicData?.categories?.includes('inSchoolProgrammes') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Simple Cooking Workshop</span> In-School Programme
                  </p>
                  <p>
                    <span>Learning Maths through Athletics</span> In-School Programme
                  </p>
                  <p>
                    <span>Spanish Language Week</span> In-School Programme
                  </p>
                </div>
              ) : publicData?.categories?.includes('events') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Bray Air Show</span> Event{' '}
                  </p>
                  <p>
                    <span>Dublin Street Entertainment Festival</span> Event
                  </p>
                  <p>
                    <span>All Ireland Traditional Music Festival</span> Event
                  </p>
                </div>
              ) : publicData?.categories?.includes('workshops') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Fairy Cake Decoration</span> Workshop
                  </p>
                  <p>
                    <span>Goal Keeping</span> Workshop
                  </p>
                  <p>
                    <span>Cartwheel Gymnastics</span> Workshop
                  </p>
                </div>
              ) : publicData?.categories?.includes('club') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Under 8’s Division 2 Soccer Team</span>{' '}
                  </p>
                  <p>
                    <span> Junior Sailing Level 2</span>{' '}
                  </p>
                  <p>
                    <span>Under 12’s Ireland Chess Teams</span>{' '}
                  </p>
                </div>
              ) : publicData?.categories?.includes('placesToVisit') ? (
                <div className={css.suggestion}>
                  <label>Tagline ideas: </label>
                  <p>
                    <span>Indoor Soft Adventure Play & Cafe</span>{' '}
                  </p>
                  <p>
                    <span>Pop-Up Pottery Painting & Glazing Shop</span>{' '}
                  </p>
                  <p>
                    <span>Historic Home On 800 Acres For All To Enjoy </span>{' '}
                  </p>
                </div>
              ) : publicData?.categories?.includes('camps') ? (
                <div className={css.suggestion}>
                  <label>Ideas:</label>
                  <p>
                    <span>Film-Making </span>
                    {publicData.activityType?.includes('other')
                      ? publicData.activityTypeOthers
                      : campsActivityTypeOptions.filter(
                          o => o.value === publicData?.activityType?.[0]
                        )[0].label}
                  </p>
                  <p>
                    <span> Teen Soccer </span>
                    {publicData.activityType?.includes('other')
                      ? publicData.activityTypeOthers
                      : campsActivityTypeOptions.filter(
                          o => o.value === publicData?.activityType?.[0]
                        )[0].label}
                  </p>
                  <p>
                    <span>Sea Kayaking </span>
                    {publicData.activityType?.includes('other')
                      ? publicData.activityTypeOthers
                      : campsActivityTypeOptions.filter(
                          o => o.value === publicData?.activityType?.[0]
                        )[0].label}
                  </p>
                </div>
              ) : null}
              {publicData?.categories?.includes('club') ? (
                <>
                  <div className={css.emailWrapper}>
                    <label>Email</label>
                    <input
                      id="email"
                      type="text"
                      name="email"
                      placeholder="Provide best email address for this listing"
                      onChange={e => {
                        form.change('email', e.target.value);
                        if (emailFormatValid(e.target.value)) {
                          setValidEmail(true);
                        }
                      }}
                      defaultValue={initialValues.email}
                      onBlur={e => {
                        const isValid = emailFormatValid(values.email);
                        setValidEmail(isValid);
                        setShowEmailError(!isValid);
                      }}
                    />
                    {!validEmail && showEmailError ? (
                      <span className={css.emailError}>Please enter a valid email</span>
                    ) : null}
                  </div>
                  <div className={`${css.emailWrapper} emailWrapper`}>
                    <label>Telephone</label>
                    <PhoneInput
                      country={'ie'}
                      value={values.phone}
                      onChange={val => {
                        form.change('phone', `+${val}`);
                        if (values.phone && values.phone.length > 10) {
                          setValidPhone(true);
                        } else {
                          setValidPhone(false);
                        }
                      }}
                      onBlur={() => {
                        // values.phoneNumber && isPossiblePhoneNumber(values.phoneNumber)
                        if (values.phone && values.phone.length > 12) {
                          setValidPhone(true);
                        } else {
                          setValidPhone(false);
                          setShowPhoneError(true);
                        }
                      }}
                      placeholder={'Enter phone number'}
                    />
                  </div>
                  {!validPhone && showPhoneError ? (
                    <span className={css.emailError}>Please enter a valid phone number</span>
                  ) : null}
                </>
              ) : null}
            </div>

            <div className={css.formlistbtmsec}>
              <ProgressBar progress={tabPercent} height={30} className={css.stepprgs} />

              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>

                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={submitDisabled}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingTitleFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingTitleFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingTitleFormComponent);
