import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingCategoriesForm } from '../../forms';
import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import css from './EditListingActivityTypePanel.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { defaultTitle } from '../../marketplace-custom-config';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingActivityTypeForm from '../../forms/EditListingActivityTypeForm/EditListingActivityTypeForm';

import clisting3 from '../../assets/screen-03-ripped-img.png';
import clistingmb3 from '../../assets/screen-03-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogo.png';

const EditListingActivityTypePanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const activityType = (publicData && publicData.activityType) ?? [];
  // const othersType = (publicData && publicData.othersType) ?? '';
  const activityTypeOthers =
    activityType[0] === 'other' ? publicData && publicData.activityTypeOthers?.slice(0, -5) : '';
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        {/* <div className={css.imgSection}>What kind of activity will you list? </div> */}
        <EditListingImage
          className={css.imgSection}
          imageText="Which of these best describes your activity?"
          logoImg={listinglogo}
          desktopImg={clisting3}
          mobileImg={clistingmb3}
        ></EditListingImage>
        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingActivityTypeForm
            isPublished={isPublished}
            backbtnLoader={backbtnLoader}
            backButton={backButton}
            savenExit={savenExit}
            className={css.form}
            initialValues={{
              activityType,
              othersType: activityTypeOthers,
            }}
            listing={listing}
            tabProgress={tabProgress}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { activityType, othersType } = values;
              const updateValues = activityType.includes('other')
                ? {
                    publicData: {
                      activityType,
                      activityTypeOthers: othersType + ' Camp',
                    },
                  }
                : {
                    publicData: {
                      activityType,
                    },
                  };

              onSubmit(updateValues);
            }}
            history={history}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
          />
        </div>
      </div>
    </div>
  );
};

EditListingActivityTypePanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingActivityTypePanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingActivityTypePanelComponent;
