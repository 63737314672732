import React, { useEffect, useRef, useState } from 'react';
import { RiShareForwardLine } from 'react-icons/ri';
import {
  FacebookShareButton,
  WhatsappShareButton,
  TwitterShareButton,
  EmailShareButton,
  LinkedinShareButton,
  FacebookMessengerShareButton,
} from 'react-share';
import {
  FaFacebook,
  FaWhatsapp,
  FaTwitter,
  FaSnapchat,
  FaLinkedin,
  FaFacebookMessenger,
  FaMailBulk,
} from 'react-icons/fa';
import { MdContentCopy } from 'react-icons/md';

import css from './ShareButton.module.css';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { createSlug } from '../../util/urlHelpers';
import { formatListingData } from '../../util/formattedListingData';
import Modal from '../Modal/Modal';
import { useDispatch } from 'react-redux';
import { manageDisableScrolling } from '../../ducks/UI.duck';
import classNames from 'classnames';
const getUrlByListing = listing => {
  const title = listing?.attributes?.title;

  const origin = typeof window !== 'undefined' && window.location.origin;
  const listingPath = createResourceLocatorString('ListingPage', routeConfiguration(), {
    slug: createSlug(title),
    id: listing?.id?.uuid,
  });
  const link = origin + listingPath;
  return link;
};
const MacShareComponent = props => {
  const { listing } = props;
  const [isCopySuccess, setIsCopySuccess] = useState(false);
  const inputRef = useRef();
  const urlShr = listing?.id?.uuid && getUrlByListing(listing);
  return (
    <div className={css.shareIconDiv}>
      <div className={css.buttonWrapper}>
        <WhatsappShareButton url={urlShr}>
          <FaWhatsapp />
        </WhatsappShareButton>
        <div className={css.iconName}>Whatsapp</div>
      </div>
      <div className={css.buttonWrapper}>
        <FacebookMessengerShareButton url={urlShr}>
          <FaFacebookMessenger />
        </FacebookMessengerShareButton>
        {/* </div> */}
        <div className={css.iconName}>Messenger</div>
      </div>
      <div className={css.buttonWrapper}>
        <FacebookShareButton url={urlShr}>
          <FaFacebook />
        </FacebookShareButton>
        <div className={css.iconName}>Facebook</div>
      </div>
      <div className={css.buttonWrapper}>
        <TwitterShareButton url={urlShr}>
          <FaTwitter />
        </TwitterShareButton>
        <div className={css.iconName}>X</div>
      </div>
      <div className={css.buttonWrapper}>
        <LinkedinShareButton url={urlShr}>
          <FaLinkedin />
        </LinkedinShareButton>
        <div className={css.iconName}>LinkedIn</div>
      </div>
      <div className={css.buttonWrapper}>
        <EmailShareButton url={urlShr}>
          <FaMailBulk />
        </EmailShareButton>
        <div className={css.iconName}>Email</div>
      </div>
      <div className={css.buttonWrapper}>
        <div className={css.linkCopyShare}>
          <input type="text" ref={inputRef} value={urlShr} readOnly className={css.linkCopyBox} />
          <button
            className={css.cpybtn}
            type="button"
            onClick={e => {
              inputRef.current.select();
              document.execCommand('copy');
              setIsCopySuccess(true);
              const closeDiv = setTimeout(() => {
                setIsCopySuccess(false);
              }, 2000);
              return () => clearTimeout(closeDiv);
            }}
          >
            <MdContentCopy />
          </button>
        </div>
        <div className={css.iconName}>{isCopySuccess ? 'Link Copied!' : 'Copy Link'}</div>
      </div>
    </div>
  );
};

const ShareButton = props => {
  const { listing, className, buttonText, id = 'share-button' } = props;
  const dispatch = useDispatch();
  const onManageDisableScrolling = (a, b) => dispatch(manageDisableScrolling(a, b));
  const [showAppleOptions, setShowAppleOptions] = useState(false);
  const [isMac, setIsMac] = useState(false);
  useEffect(() => {
    const checkMac = () => {
      return (
        navigator.platform.indexOf('Mac') > -1 ||
        navigator.userAgentData?.platform.indexOf('Mac') > -1
      );
    };
    setIsMac(checkMac());
  }, []);
  const buttonClass = classNames(css.shareButton, className);
  const handleShare = async () => {
    if (!listing?.id?.uuid) return;
    const title = listing?.attributes?.title;
    const link = getUrlByListing(listing);
    const { listingString } = formatListingData(listing);
    if (typeof window.navigator.share !== 'undefined') {
      try {
        await navigator.share({
          title: title,
          text: listingString,
          url: link,
        });
        console.log('Content shared successfully');
      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
    }
  };

  return isMac ? (
    <>
      <button
        type="button"
        className={buttonClass}
        id={id}
        onClick={e => {
          const rect = e.target.getBoundingClientRect();
          const allElems = Array.from(document.getElementsByClassName(css.shareButton)).map(
            e => e.id
          );
          const currIdx = allElems.indexOf(id);
          const element = document.getElementsByClassName(css.additionalSecondDivClassname)[
            currIdx || 0
          ];
          if (element) {
            element.style.top = `${rect.top - 20}px`;
            element.style.left = `${rect.left + rect.width / 2}px`;
          }
          setShowAppleOptions(true);
        }}
        onBlur={e => setShowAppleOptions(false)}
      >
        {buttonText}
      </button>
      <Modal
        id={`${id}-modal`}
        showLogo={false}
        isOpen={showAppleOptions}
        onManageDisableScrolling={onManageDisableScrolling}
        additionalSecondDivClassname={css.additionalSecondDivClassname}
        onClose={() => {
          setShowAppleOptions(false);
        }}
      >
        <MacShareComponent listing={listing} />
      </Modal>
    </>
  ) : (
    <button type="button" id={id} className={buttonClass} onClick={handleShare}>
      {buttonText}
    </button>
  );
};

export default ShareButton;
