import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import { Form, Button } from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingActivityTypeForm.module.css';
import {
  placesToVisitActivityTypeOptions,
  schoolToursActivityTypeOptions,
  birthdaysActivityTypeOptions,
  campsActivityTypeOptions,
} from '../../assets/activityTypeOptions';
import { ACTIVITYTYPE, CATEGORIES } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
// import { AiOutlineDoubleLeft } from 'react-icons/ai';

const TITLE_MAX_LENGTH = 60;

const EditListingActivityTypeFormComponent = props => {
  const { filterConfig, initialValues, tabProgress } = props;
  const { categories } = props.listing.attributes.publicData;
  const activityTypeOptions =
    categories?.[0] === 'camps'
      ? campsActivityTypeOptions
      : categories?.[0] === 'schoolTours'
      ? schoolToursActivityTypeOptions
      : categories?.[0] === 'placesToVisit'
      ? placesToVisitActivityTypeOptions
      : categories?.[0] === 'birthdayParty'
      ? birthdaysActivityTypeOptions
      : null;

  const [activityType, setActivityType] = useState(initialValues.activityType);
  const [activityTypeEmpty, setActivityTypeEmpty] = useState(
    !initialValues.activityType || initialValues.activityType.length === 0
  );
  const [activityTypeError, setActivityTypeError] = useState(false);
  const tabPercent = tabProgress(ACTIVITYTYPE);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          backbtnLoader,
          initialValues,
          savenExit,
        } = formRenderProps;
        if (activityType.length !== 0 && activityType?.length !== values.activityType?.length) {
          form.change('activityType', activityType);
        }
        const lastTab = CATEGORIES;
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const { activityType, othersType } = values;
              const updateValues = activityType.includes('other')
                ? {
                    publicData: {
                      activityType,
                      activityTypeOthers: othersType + ' Camp',
                    },
                  }
                : {
                    publicData: {
                      activityType,
                    },
                  };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>
            <div className={css.formseclist}>
              {activityTypeOptions?.map(c => (
                <div
                  key={`${c.key}_${values.activityType?.includes(c.value)}`}
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.activityType?.includes(c.value),
                  })}
                >
                  <label
                    htmlFor={c.key}
                    className={css.checkboxLabel}
                    key={`${c.key}_${values.activityType?.includes(c.value)}_label`}
                  >
                    <span className={css.labelDiv}>
                      <span className={css.keylbl}>{c.label}</span>
                    </span>
                  </label>
                  <input
                    key={`${c.key}_${values.activityType?.includes(c.value)}_input`}
                    className={css.checkbox}
                    type={
                      categories.includes('schoolTours') ||
                      categories.includes('placesToVisit') ||
                      categories.includes('birthdayParty')
                        ? 'checkbox'
                        : 'radio'
                    }
                    id={c.key}
                    name={'activityType'}
                    checked={activityType.includes(c.key)}
                    onChange={e => {
                      var isSelected = e.currentTarget.checked;
                      if (isSelected) {
                        if (
                          categories.includes('schoolTours') ||
                          categories.includes('placesToVisit')
                        ) {
                          if (activityType.length < 3) {
                            setActivityType([...activityType, c.key]);
                            form.change('activityType', [...activityType, c.key]);
                          }
                          if (activityType.length === 3) {
                            const newActivities = [
                              ...activityType.filter(
                                a => activityType?.indexOf(a) !== activityType?.length - 1
                              ),
                              c.key,
                            ];
                            setActivityType(newActivities);
                            form.change('activityType', newActivities);
                          }
                        }
                        if (categories.includes('birthdayParty')) {
                          if (activityType.length < 2) {
                            setActivityType([...activityType, c.key]);
                            form.change('activityType', [...activityType, c.key]);
                          }
                          if (activityType.length === 2) {
                            const newActivities = [
                              ...activityType.filter(
                                a => activityType?.indexOf(a) !== activityType?.length - 1
                              ),
                              c.key,
                            ];
                            setActivityType(newActivities);
                            form.change('activityType', newActivities);
                          }
                        }
                        if (categories.includes('camps')) {
                          setActivityType([c.key]);
                          form.change('activityType', [c.key]);
                        }
                      } else {
                        setActivityTypeError(true);
                        setActivityType(activityType?.filter(item => c.key !== item));
                        form.change(
                          'activityType',
                          activityType?.filter(item => c.key !== item)
                        );
                      }
                    }}
                  />
                </div>
              ))}
              {activityType.includes('other') ? (
                <div className={css.othertxt}>
                  {/* <label>Please specify activity type</label> */}
                  <span>Enter Name:</span>{' '}
                  <input
                    type="text"
                    value={values.othersType}
                    placeholder="Eg. Christmas"
                    onChange={e => {
                      const value = e.target.value;
                      form.change('othersType', value);
                    }}
                    onFocus={e => {
                      e.target.placeholder = '';
                    }}
                    onBlur={e => {
                      const value = e.target.value;
                      if (!value) {
                        e.target.placeholder = 'Eg. Christmas';
                      }
                    }}
                  />
                  <span>Camp</span>
                </div>
              ) : null}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar
                bgcolor="red"
                progress={tabPercent}
                height={30}
                className={css.stepprgs}
              />
              <span className={css.progressSpan}>
                {categories.includes('schoolTours') || categories.includes('placesToVisit')
                  ? activityType.length > 0
                    ? `${activityType?.length}/3`
                    : '0/3'
                  : categories.includes('birthdayParty')
                  ? activityType.length > 0
                    ? `${activityType?.length}/2`
                    : '0/2'
                  : activityType.length > 0
                  ? `${activityType?.length}/1`
                  : '0/1'}
              </span>

              <div className={css.buttonDiv}>
                <Button
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                  type="button"
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={
                    values.activityType?.length === 0 ||
                    (values.activityType?.includes('other') ? !values.othersType : false)
                  }
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingActivityTypeFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingActivityTypeFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingActivityTypeFormComponent);
