import React from 'react';
import { bool, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { NamedLink, OwnListingLink } from '../../components';
import AFTarrow from '../../assets/aftarrow.png';

import css from './SectionHowItWorks.module.css';

const SectionHowItWorks = props => {
  const {
    rootClassName,
    className,
    currentUserListing,
    currentUserListingFetched,
    currentUser,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  return (
    <div className={classes}>
      <div className={css.howItWorks}>
        <div className={css.title}>
          <FormattedMessage id="SectionHowItWorks.titleLineOne" />
          {/* <br />
          <FormattedMessage id="SectionHowItWorks.titleLineTwo" /> */}
        </div>

        <div className={css.steps}>
          <div className={css.step12}>
            <div className={css.step1}>
              <div className={css.stepCount}>Step 1</div>
              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part1Title" />
              </h2>
              <p>
                <FormattedMessage id="SectionHowItWorks.part1Text1" />
              </p>
            </div>
            <div className={`${css.step1} ${css.step2}`}>
              <div className={css.stepCount}>Step 2</div>

              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part2Title" />
              </h2>
              <p>
                <FormattedMessage id="SectionHowItWorks.part2Text1" />
              </p>
            </div>
          </div>

          <div className={css.step12}>
            <div className={`${css.step1}  ${css.step3}`}>
              <div className={css.stepCount}>Step 3</div>

              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part3Title" />
              </h2>
              <p>
                <FormattedMessage id="SectionHowItWorks.part3Text1" />
              </p>
            </div>
            <div className={`${css.step1} ${css.step2} ${css.step4} `}>
              <div className={css.stepCount}>Step 4</div>

              <h2 className={css.stepTitle}>
                <FormattedMessage id="SectionHowItWorks.part4Title" />
              </h2>
              <p>
                <FormattedMessage id="SectionHowItWorks.part4Text1" />
              </p>
            </div>
          </div>
        </div>
        {/* {currentUser && currentUser.attributes.profile.publicData.isProvider ? (
          <div className={css.createListingLink}>
            <OwnListingLink listing={currentUserListing} listingFetched={currentUserListingFetched}>
              <FormattedMessage id="SectionHowItWorks.createListingLink" />
            </OwnListingLink>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};

SectionHowItWorks.defaultProps = {
  rootClassName: null,
  className: null,
  currentUserListing: null,
  currentUserListingFetched: false,
};

SectionHowItWorks.propTypes = {
  rootClassName: string,
  className: string,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
};

export default SectionHowItWorks;
