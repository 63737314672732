import React, { useState, useEffect } from 'react';
import { bool, func, object, number, string } from 'prop-types';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import routeConfiguration, { ACCOUNT_SETTINGS_PAGES } from '../../routeConfiguration';
import { propTypes } from '../../util/types';
import {
  Avatar,
  InlineTextButton,
  Logo,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  ListingLink,
  OwnListingLink,
} from '../../components';
import { TopbarSearchForm } from '../../forms';
import topRead1 from '../../assets/topRead1.jpg';

import css from './TopbarDesktop.module.css';
import Modal from '../Modal/Modal';
import { createResourceLocatorString } from '../../util/routes';

const TopbarDesktop = props => {
  const {
    className,
    currentUser,
    currentPage,
    rootClassName,
    currentUserHasListings,
    currentUserListing,
    currentUserListingFetched,
    notificationCount,
    intl,
    history,
    isAuthenticated,
    onLogout,
    onSearchSubmit,
    initialSearchFormValues,
    onManageDisableScrolling,
    location,
  } = props;
  const [mounted, setMounted] = useState(false);
  const [createListingModal, setCreateListingModal] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  const authenticatedOnClientSide = mounted && isAuthenticated;
  const isAuthenticatedOrJustHydrated = isAuthenticated || !mounted;
  const classes = classNames(rootClassName || css.root, className);
  const isProvider = currentUser && currentUser?.attributes?.profile?.publicData?.isProvider;
  const isProfilePublished =
    currentUser && currentUser?.attributes?.profile?.publicData?.isProfilePublished;
  const search = (
    <TopbarSearchForm
      className={css.searchLink}
      desktopInputRoot={css.topbarSearchWithLeftPadding}
      onSubmit={onSearchSubmit}
      initialValues={initialSearchFormValues}
    />
  );

  const notificationDot = notificationCount > 0 ? <div className={css.notificationDot} /> : null;

  const inboxLink = authenticatedOnClientSide ? (
    <NamedLink
      className={css.inboxLink}
      name="InboxPage"
      params={{ tab: currentUserHasListings ? 'sales' : 'orders' }}
    >
      <span className={css.inbox}>
        <FormattedMessage id="TopbarDesktop.inbox" />
        {notificationDot}
      </span>
    </NamedLink>
  ) : null;

  const currentPageClass = page => {
    const isAccountSettingsPage =
      page === 'AccountSettingsPage' && ACCOUNT_SETTINGS_PAGES.includes(currentPage);
    return currentPage === page || isAccountSettingsPage ? css.currentPage : null;
  };
  const profileMenu = authenticatedOnClientSide ? (
    <Menu>
      <MenuLabel className={css.profileMenuLabel} isOpenClassName={css.profileMenuIsOpen}>
        <Avatar className={css.avatar} user={currentUser} disableProfileLink />
      </MenuLabel>
      {isProvider ? (
        <MenuContent className={css.profileMenuContent}>
          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              params={{ id: currentUser?.id.uuid }}
              name={
                isProvider
                  ? isProfilePublished
                    ? 'ProfilePage'
                    : 'ProfileSettingsBasePage'
                  : 'ProfileSettingsPage'
              }
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="ManageListingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ManageListingsPage')
              )}
              name="ManageListingsBasePage"
              // params={{ tab: 'activeListings' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.manageListingPageLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      ) : (
        <MenuContent className={css.profileMenuContent}>
          {/* <MenuItem key="EditListingPage">
          <OwnListingLink
            listing={currentUserListing}
            listingFetched={currentUserListingFetched}
            className={css.yourListingsLink}
          >
            <div>
              <span className={css.menuItemBorder} />
              {currentUserListing ? (
                <FormattedMessage id="TopbarDesktop.editYourListingLink" />
              ) : (
                <FormattedMessage id="TopbarDesktop.addYourListingLink" />
              )}
            </div>
          </OwnListingLink>
        </MenuItem> */}

          <MenuItem key="ProfileSettingsPage">
            <NamedLink
              className={classNames(
                css.profileSettingsLink,
                currentPageClass('ProfileSettingsPage')
              )}
              name="ProfileSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink, currentPageClass('AccountSettingsPage'))}
              name="AccountSettingsPage"
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogout}>
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.logout" />
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      )}
    </Menu>
  ) : null;

  const signupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.signup" />
      </span>
    </NamedLink>
  );

  const providerSignupLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="SignupProviderPage" className={css.signupLink}>
      <span className={css.signup}>
        <FormattedMessage id="TopbarDesktop.providerSignup" />
      </span>
    </NamedLink>
  );

  const loginLink = isAuthenticatedOrJustHydrated ? null : (
    <NamedLink name="LoginPage" to={{ from: location.pathname }} className={css.loginLink}>
      <span className={css.login}>
        <FormattedMessage id="TopbarDesktop.login" />
      </span>
    </NamedLink>
  );

  const listingLink =
    authenticatedOnClientSide && currentUserListingFetched && currentUserListing ? (
      <ListingLink
        className={css.createListingLink}
        listing={currentUserListing}
        children={
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.viewListing" />
          </span>
        }
      />
    ) : null;
  const createListingLink =
    isAuthenticatedOrJustHydrated && !currentUserListingFetched ? null : (
      // <button className={css.createListingButton}>
      <NamedLink className={css.createListingLink} name="NewListingPage">
        {/* <NamedLink className={css.createListingButton} name="ListMyBusinessPage"> */}
        <span className={css.letsGoButton}>
          <FormattedMessage id="TopbarDesktop.createListing" />
        </span>
      </NamedLink>
      //{' '}
      // </button>
    );
  const nonAuthLinks = !isAuthenticatedOrJustHydrated ? (
    <div className={css.topbarSecondContainer}>
      <NamedLink name="AboutPage" className={css.createListingLink}>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.toAboutPage" />
        </span>
      </NamedLink>
      <NamedLink name="FavouritesPage" className={css.createListingLink}>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.favorites" />
        </span>
      </NamedLink>
      <NamedLink name="ListMyBusinessPage" className={css.createListingLink}>
        <span className={css.createListing}>
          <FormattedMessage id="TopbarDesktop.newProviders" />
        </span>
      </NamedLink>
      <NamedLink name="CreateListingPage" className={css.createListingButton}>
        <FormattedMessage id="TopbarDesktop.createListingPage" />
      </NamedLink>
      {loginLink}
      {profileMenu}
    </div>
  ) : null;
  const authParentsLinks =
    isAuthenticatedOrJustHydrated && !isProvider ? (
      <div className={css.topbarSecondContainer}>
        {/* <NamedLink name="InboxPage" params={{ tab: 'orders' }} className={css.createListingLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.bookings" />
          </span>
        </NamedLink>
        <NamedLink name="FavouritesPage" className={css.createListingLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.favorites" />
          </span>
        </NamedLink>
        <NamedLink name="InboxBasePage" className={css.createListingLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.inbox" />
          </span>
        </NamedLink> */}
        <NamedLink name="SearchPage" className={css.createListingButton}>
          {/* <span className={css.createListing}> */}
          <FormattedMessage id="TopbarSearchButton.browseButton" />
          {/* </span> */}
        </NamedLink>
        {/* <NamedLink name="AboutPage" className={css.createListingLink}>
          <span className={css.createListing}>
            <FormattedMessage id="TopbarDesktop.newLetter" />
          </span>
        </NamedLink> */}
        {loginLink}
        {profileMenu}
      </div>
    ) : null;
  const authProvidersLinks =
    isAuthenticatedOrJustHydrated && isProvider ? (
      <div className={css.topbarSecondContainer}>
        <NamedLink name="CreateListingPage" className={css.createListingButton}>
          <FormattedMessage id="TopbarDesktop.createListingPage" />
        </NamedLink>
        {loginLink}
        {profileMenu}
      </div>
    ) : null;
  return (
    <nav className={classes}>
      <NamedLink className={css.logoLink} name="LandingPage">
        <Logo
          format="desktop"
          className={css.logo}
          alt={intl.formatMessage({ id: 'TopbarDesktop.logo' })}
        />
      </NamedLink>
      {/* {search} */}
      {nonAuthLinks}
      {authParentsLinks}
      {authProvidersLinks}
    </nav>
  );
};

TopbarDesktop.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  currentPage: null,
  notificationCount: 0,
  initialSearchFormValues: {},
  currentUserListing: null,
  currentUserListingFetched: false,
};

TopbarDesktop.propTypes = {
  rootClassName: string,
  className: string,
  currentUserHasListings: bool.isRequired,
  currentUserListing: propTypes.ownListing,
  currentUserListingFetched: bool,
  currentUser: propTypes.currentUser,
  currentPage: string,
  isAuthenticated: bool.isRequired,
  onLogout: func.isRequired,
  notificationCount: number,
  onSearchSubmit: func.isRequired,
  initialSearchFormValues: object,
  intl: intlShape.isRequired,
};

export default TopbarDesktop;
