import { Button, ResponsiveImage } from '../../components';
import React, { Component, useState } from 'react';
import css from './EditListingPublishForm.module.css';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { POLICY, PUBLISH } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import pro from '../../assets/pro-feat.png';

import MBscreenimg from '../../assets/background-1440.jpg';

import { AiOutlineCheckCircle, AiOutlineHeart } from 'react-icons/ai';
import {
  campsActivityTypeOptions,
  placesToVisitActivityTypeOptions,
} from '../../assets/activityTypeOptions';
import moment from 'moment';
import config from '../../config';
import { shouldToFix } from '../../components/EditListingPricingPanel/EditListingPricingPanel';
import { LISTING_PAGE_PARAM_TYPE_EDIT } from '../../util/urlHelpers';

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });

function EditListingPublishForm(props) {
  const {
    onSubmit,
    listing,
    tabProgress,
    backButton,
    updateInProgress,
    ready,
    savenExit,
    backbtnLoader,
    currentUser,
    listingCountUpdateInProgress,
    enableSubmit,
    onSnoozedSubmit,
    viewport,
    snoozeLoading,
    params,
  } = props;
  const { width } = viewport;
  const submitInProgress = updateInProgress;
  const submitReady = ready;
  const countyOptions = config.custom.filters.find(f => f.id === 'county').config.options;
  const { publicData, title, description, price, state } = listing.attributes;
  const { firstName, lastName, publicData: currentUserPublicData } =
    currentUser?.attributes.profile || {};
  const isStarter =
    currentUser?.attributes?.profile?.privateData?.subscription?.activePlan === 'starter';
  const providerCounty = currentUserPublicData?.county;
  const {
    categories,
    county,
    noOfDays,
    age,
    startTime,
    endTime,
    openTill,
    priceType,
    noOfKids,
    bpType,
    noOfClasses,
    startDate,
    endDate,
    day,
    allAges,
    city,
    closingTime,
    trialOption,
    depositOption,
    siblingDiscount,
    wheelChair,
    specialNeeds,
    whatToBring,
    additionalInfo,
    additionalPricingInfo,
    activityType,
    activityMode,
    classType,
  } = publicData;
  const activityTypesObj = activityType?.map(a =>
    placesToVisitActivityTypeOptions.find(o => o.value === a)
  );
  const tabPercent = tabProgress(PUBLISH);
  const panelMediumWidth = 50;
  const panelLargeWidth = 62.5;
  const cardRenderSizes = [
    '(max-width: 767px) 100vw',
    `(max-width: 1023px) ${panelMediumWidth}vw`,
    `(max-width: 1920px) ${panelLargeWidth / 2}vw`,
    `${panelLargeWidth / 3}vw`,
  ].join(', ');
  const firstImage = listing.images && listing.images.length > 0 ? listing.images[0] : null;
  const imgUrl = firstImage.attributes.variants['landscape-crop'].url;
  const priceTypeTexts =
    priceType === 'perHour'
      ? 'per hour'
      : priceType === 'perChild'
      ? 'per child'
      : priceType === 'totalPrice'
      ? 'total price'
      : priceType === 'perMorning'
      ? 'per morning'
      : priceType === 'perAfternoon'
      ? 'per afternoon'
      : priceType === 'perDay'
      ? 'per day'
      : priceType === 'perTerm'
      ? 'per term'
      : priceType === 'perClass'
      ? 'per class'
      : priceType === 'perYear'
      ? 'per year'
      : priceType === 'perMonth'
      ? 'per month'
      : priceType === 'perAdult'
      ? 'per adult'
      : null;
  const lastTab = POLICY;
  const saveExit = (
    <Button
      className={css.exitButton}
      type="button"
      inProgress={backbtnLoader}
      onClick={() => {
        savenExit({});
      }}
    >
      Save & Exit
    </Button>
  );
  const eventTimes = (
    <>
      {day?.map((d, i) => (
        <p key={i}>
          <span>Time (day {i + 1}): </span>{' '}
          {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
          {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
          {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
          {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
        </p>
      ))}
    </>
  );
  const titleMaybe = (
    <p>
      <h2>{title} </h2>
    </p>
  );
  const noOfKidsMaybe = (
    <p>
      <span>Min - Max: </span>
      {`${noOfKids?.minNo} - ${noOfKids?.maxNo}`}
    </p>
  );
  const providerInfoMaybe = (
    <p className={css.redtxt}>
      {currentUserPublicData?.bname} –{' '}
      <b>
        {activityMode === 'online'
          ? 'ONLINE'
          : city
          ? city.toUpperCase()
          : typeof county === 'string'
          ? county.toUpperCase()
          : Array.isArray(county)
          ? county[0].toUpperCase()
          : null}
      </b>
    </p>
  );
  const dateMaybe = (
    <p>
      <span>{'Date'}:</span>{' '}
      {categories[0] === 'events' || categories[0] === 'workshops'
        ? moment(startDate).format('MMM Do, YYYY')
        : `${moment(startDate).format('MMM Do')} – ${moment(endDate).format('MMM Do, YYYY')}`}
    </p>
  );
  const timeMaybe = (
    <p>
      <span>Time: </span> {startTime?.hour >= 10 ? startTime?.hour : `0${startTime?.hour}`}.
      {startTime?.minute >= 10 ? startTime?.minute : `0${startTime?.minute}`} –{' '}
      {endTime?.hour >= 10 ? endTime?.hour : `0${endTime?.hour}`}.
      {endTime?.minute >= 10 ? endTime?.minute : `0${endTime?.minute}`}
    </p>
  );
  const ageMaybe = (
    <p>
      <span>Age: </span>{' '}
      {(categories[0] === 'events' || categories[0] === 'placesToVisit') && allAges
        ? 'All Ages'
        : `${age[0]} - ${age[age?.length - 1]}`}
    </p>
  );
  const priceMaybe = (
    <span className={css.pricetxt}>
      <span>€{price.amount / 100}</span> ({noOfDays} days)
    </span>
  );
  const openTillMaybe = (
    <p>
      <span>
        {categories[0] === 'inSchoolProgrammes'
          ? 'Time: '
          : categories[0] === 'birthdayParty' || categories[0] === 'schoolTours'
          ? 'Length: '
          : categories[0] === 'breakfastClub' || categories[0] === 'afterSchoolClub'
          ? 'Opens: '
          : 'Closes: '}
      </span>
      {`${openTill?.hour < 10 ? `0${openTill?.hour || 0}` : openTill?.hour || '00'}:${
        openTill?.minute < 10 ? `0${openTill?.minute || 0}` : openTill?.minute || '00'
      }`}{' '}
      hours
    </p>
  );
  const closingTimeMaybe = (
    <p>
      <span>Closes: </span>
      {`${closingTime?.hour < 10 ? `0${closingTime?.hour || 0}` : closingTime?.hour || '00'}:${
        closingTime?.minute < 10 ? `0${closingTime?.minute || 0}` : closingTime?.minute || '00'
      }`}
    </p>
  );
  const locationMaybe = (
    <p>
      <span>Location: </span>
      {categories[0] === 'birthdayParty' && bpType === 'venueParty'
        ? 'Your Venue'
        : (categories[0] === 'birthdayParty' && bpType === 'mobileParty') ||
          categories[0] === 'inSchoolProgrammes'
        ? publicData?.allCounties?.length === countyOptions?.length
          ? 'All Counties'
          : publicData?.allCounties?.length < 3
          ? publicData?.allCounties?.reduce(
              (str, c, i) => str + `${i === 0 ? '' : ','} ${c.toUpperCase()}`,
              ''
            )
          : 'Various Counties'
        : city
        ? city?.toUpperCase()
        : county
        ? county?.toUpperCase()
        : null}
    </p>
  );

  const priceDisplay = (
    <p>
      <span>{'Cost: '}</span>
      {price?.amount === 0 ? 'Free' : `€${shouldToFix(price?.amount / 100)}`}
      {` `}
      {categories[0] === 'classes'
        ? `(${noOfClasses} ${classType ? classType?.label : 'classes'})`
        : categories[0] === 'camps'
        ? `(${noOfDays} ${noOfDays > 1 ? 'days' : 'day'})`
        : categories[0] === 'workshops'
        ? '(1 day)'
        : null}
      {price?.amount === 0 ? '' : priceTypeTexts}
    </p>
  );
  const optionals = (
    <div>
      <ul className={css.toul}>
        {trialOption ? (
          <li>
            Trial Option <AiOutlineCheckCircle />
          </li>
        ) : null}

        {depositOption ? (
          <li>
            Deposit Option <AiOutlineCheckCircle />
          </li>
        ) : null}
        {siblingDiscount ? (
          <li>
            Sibling Discount <AiOutlineCheckCircle />
          </li>
        ) : null}
        {wheelChair ? (
          <li>
            Wheelchair-Friendly <AiOutlineCheckCircle />
          </li>
        ) : null}
        {specialNeeds ? (
          <li>
            Additional Needs-Friendly <AiOutlineCheckCircle />
          </li>
        ) : null}
      </ul>
    </div>
  );
  const whatToBringMaybe = (
    <div className={css.infosdv}>
      <label>
        {publicData.categories?.includes('club') || publicData.categories?.includes('placesToVisit')
          ? 'What to know'
          : publicData.categories?.includes('camps') ||
            publicData.categories?.includes('classes') ||
            publicData.categories?.includes('workshops')
          ? 'What to bring'
          : 'What’s Included'}
      </label>
      <p>{whatToBring}</p>
    </div>
  );
  const descMaybe = (
    <div className={css.infosdv}>
      <label>Description</label>
      <p>{description}</p>
    </div>
  );
  const additionalInfoMaybe = additionalInfo ? (
    <div className={css.infosdv}>
      <label>Additional Info</label>
      <p>{additionalInfo}</p>
    </div>
  ) : null;
  const additionalPricingInfoMaybe = additionalPricingInfo ? (
    <div className={css.infosdv}>
      <label>Pricing or Availability Info</label>
      <p>{additionalPricingInfo}</p>
    </div>
  ) : null;
  const activityTypeMaybe = activityType ? (
    <p>
      <span>Categories: </span>
      {activityTypesObj &&
        activityTypesObj.reduce(
          (pre, curr, idx) =>
            `${pre}${
              idx === 0
                ? ` ${curr?.label}`
                : idx === activityTypesObj?.length - 1
                ? ` & ${curr?.label}`
                : `, ${curr?.label}`
            }`,
          ''
        )}
      .
    </p>
  ) : null;
  return (
    <>
      <div className={css.svexbtnsec}>{saveExit}</div>
      <div className={css.formseclist}>
        <div className={css.mobilescreen}>
          <div className={css.msinner}>
            <div className={css.imgmsinner}>
              <img src={imgUrl} />
              <span>
                <AiOutlineHeart />
              </span>
            </div>
            <div className={css.mobbody}>
              {categories[0] === 'camps' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {dateMaybe}
                  {timeMaybe}
                  {ageMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'afterSchoolClub' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {openTillMaybe}
                  {closingTimeMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'birthdayParty' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {openTillMaybe}
                  {noOfKidsMaybe}
                  {locationMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'breakfastClub' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {openTillMaybe}
                  {closingTimeMaybe}
                  {priceDisplay}
                  {locationMaybe}
                </>
              ) : categories[0] === 'classes' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {dateMaybe}
                  {timeMaybe}
                  {ageMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'events' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {dateMaybe}
                  {eventTimes}
                  {locationMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'workshops' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {dateMaybe}
                  {timeMaybe}
                  {ageMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'placesToVisit' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {activityTypeMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'club' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'inSchoolProgrammes' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {openTillMaybe}
                  {noOfKidsMaybe}
                  {locationMaybe}
                  {priceDisplay}
                </>
              ) : categories[0] === 'schoolTours' ? (
                <>
                  {titleMaybe}
                  {providerInfoMaybe}
                  {ageMaybe}
                  {openTillMaybe}
                  {noOfKidsMaybe}
                  {locationMaybe}
                  {priceDisplay}
                </>
              ) : null}
              {optionals}
              {descMaybe}
              {whatToBringMaybe}
              {additionalInfoMaybe}
              {additionalPricingInfoMaybe}
            </div>
          </div>
        </div>
      </div>
      <div className={css.formlistbtmsec}>
        <ProgressBar className={css.pubprgs} progress={tabPercent} />
        <div className={css.buttonDiv}>
          <Button type="button" className={css.backButton} onClick={() => backButton(lastTab)}>
            Back
          </Button>
          {params.type === LISTING_PAGE_PARAM_TYPE_EDIT || state !== 'draft' ? (
            <div className={css.bndv}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress || listingCountUpdateInProgress}
                disabled={(submitInProgress || listingCountUpdateInProgress) && !enableSubmit}
                ready={submitReady}
                onClick={() => {
                  onSubmit();
                }}
              >
                Save changes
              </Button>
            </div>
          ) : (
            <div className={css.bndv}>
              {isStarter ? (
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={false}
                  disabled={true}
                  onClick={() => {}}
                >
                  Snooze, Publish Later <img src={pro} />
                </Button>
              ) : (
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={snoozeLoading}
                  disabled={(submitInProgress || listingCountUpdateInProgress) && !enableSubmit}
                  onClick={() => onSnoozedSubmit()}
                >
                  Snooze, Publish Later
                </Button>
              )}
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress || listingCountUpdateInProgress}
                disabled={(submitInProgress || listingCountUpdateInProgress) && !enableSubmit}
                ready={submitReady}
                onClick={() => {
                  onSubmit();
                }}
              >
                {width < 575 ? 'Publish listing' : 'Publish your listing'}
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default EditListingPublishForm;
