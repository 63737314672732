import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  EditListingImage,
  NamedLink,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingCategoriesForm.module.css';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { CATEGORIES } from '../../components/EditListingWizard/EditListingWizardTab';
import { isEqual } from 'lodash';
import { starterOrBookableCategories } from '../../marketplace-custom-config';
import { ReactComponent as ExpressBooking } from '../../assets/exprbk-logo.svg';

const TITLE_MAX_LENGTH = 60;

const EditListingCategoriesFormComponent = props => {
  const { filterConfig, initialValues } = props;
  const [categories, setCategories] = useState(initialValues.categories);
  const categoryOptions = findOptionsForSelectFilter('categories', filterConfig);
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          history,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          initialValues,
          tabProgress,
          savenExit,
          title,
          defaultTitle,
          isTitleSet,
          backbtnLoader,
          isStarter,
        } = formRenderProps;

        if (categories.length !== 0 && categories[0] !== values.categories[0]) {
          form.change('categories', categories);
        }
        const tabPercent = tabProgress(CATEGORIES);
        const saveExitButtonDisabled =
          isEqual(initialValues, values) && (!categories || values.categories?.length === 0);
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const radioLabel = key =>
          key.value === 'afterSchoolClub' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Post-school childcare</span>
            </span>
          ) : key.value === 'birthdayParty' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Party package</span>
            </span>
          ) : key.value === 'breakfastClub' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Childcare before school that provides a morning meal</span>
            </span>
          ) : key.value === 'camps' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Activities organised over 1-5 consecutive days</span>
            </span>
          ) : key.value === 'classes' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>Classes, Lessons & Sessions</span>
              <span>Weekly sessions, sold in bundles or terms</span>
            </span>
          ) : key.value === 'club' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Club-organised activity open to all kids</span>
            </span>
          ) : key.value === 'events' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Family-friendly festivals and events</span>
            </span>
          ) : key.value === 'inSchoolProgrammes' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>School day activities </span>
            </span>
          ) : key.value === 'placesToVisit' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Family-friendly destinations </span>
            </span>
          ) : key.value === 'schoolTours' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Class days out, organised by schools </span>
            </span>
          ) : key.value === 'workshops' ? (
            <span className={css.labelDiv}>
              <span className={css.keylbl}>{key.label}</span>
              <span>Single sessions, completed within a day </span>
            </span>
          ) : null;
        const saveExit = (
          <Button
            className={css.exitButton}
            inProgress={backbtnLoader}
            type="button"
            onClick={() => {
              const updateValues = {
                title: !!title ? title : defaultTitle,
                publicData: {
                  isTitleSet: !!isTitleSet,
                  categories,
                },
              };
              categories && categories.length !== 0
                ? savenExit(updateValues)
                : history.push(
                    createResourceLocatorString('LandingPage', routeConfiguration(), {})
                  );
            }}
          >
            Save & Exit
          </Button>
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>
            <div className={css.formseclist}>
              {categoryOptions?.map(c => {
                const isCategoryLocked = isStarter && !starterOrBookableCategories.includes(c.key);
                const isCategoryDisaled =
                  isCategoryLocked || initialValues?.categories?.length !== 0;
                return (
                  <span
                    key={`${c.key}_${values.categories[0] === c.value}_span`}
                    className={classNames(css.categoriesCheckbox, {
                      [css.selectedCategory]: categories[0] === c.value,
                      [css.disabledCategory]: isCategoryDisaled,
                      [css.lockedCategory]: isCategoryLocked,
                      [css.isStarterDesign]: isStarter,
                    })}
                  >
                    <label
                      htmlFor={c.key}
                      className={css.checkboxLabel}
                      key={`${c.key}_${values.categories[0] === c.value}_label`}
                    >
                      {radioLabel(c)}{' '}
                      {c.key === 'classes' || c.key === 'camps' || c.key === 'workshops' ? (
                        <div className={css.exprbklg}>
                          <ExpressBooking />
                          <span>Express Booking</span>
                        </div>
                      ) : null}
                    </label>
                    <input
                      key={`${c.key}_${values.categories[0] === c.value}_input`}
                      className={css.checkbox}
                      disabled={
                        isCategoryDisaled
                        //  ||
                        // initialValues.categories?.length !== 0
                      }
                      type="radio"
                      id={c.key}
                      name={'categories'}
                      checked={categories.includes(c.key)}
                      // defaultChecked={categories.includes(c.key)}
                      onChange={e => {
                        // var updatedList = [...categoriesChecked];
                        // if (e.target.checked) {
                        //   updatedList = [...categoriesChecked, e.target.value];
                        // } else {
                        //   updatedList = updatedList.filter(l => l !== e.target.value);
                        // }
                        setCategories([c.value]);
                        form.change('categories', [c.value]);
                      }}
                    />
                  </span>
                );
              })}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
              <span className={css.progressSpan}>{categories?.length > 0 ? '1/1' : '0/1'}</span>

              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  name="CreateListingPage"
                  disabled={true}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!categories || values.categories?.length === 0}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingCategoriesFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingCategoriesFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingCategoriesFormComponent);
