import React, { lazy, Suspense, useEffect, useRef, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { Button, Form } from '../../components';
import css from './EditListingPricingForm.module.css';
import Select from 'react-select';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { BiInfoCircle } from 'react-icons/bi';
import Tooltip from 'rc-tooltip';
import {
  AVAILABILITY,
  PRICING,
  WHATTOBRING,
} from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { SingleDatePicker } from 'react-dates';
import { MdOutlineNavigateBefore, MdOutlineNavigateNext } from 'react-icons/md';
import { IoCheckmarkCircle } from 'react-icons/io5';
import moment from 'moment';
const TRIAL_MSG_LIMIT = 50;
const ADDITIONAL_INFO_MAX_LIMIT = 500;
const priceOptions = [
  { key: 'perChild', value: 'perChild', label: 'Per Child' },
  { key: 'perAdult', value: 'perAdult', label: 'Per Adult' },
  { key: 'perFamily', value: 'perFamily', label: 'Per Family' },
];
const TimingData = props => {
  const { firstHeading, placeholder, value, form, name, secondHeading, label } = props;
  return (
    <div className={css.opnclsmaindiv}>
      <label>
        <span>{firstHeading}</span> {secondHeading}
      </label>
      <div className={css.opnclsdiv}>
        <span className={css.opncls}>{label}</span>
        <input
          type="text"
          placeholder={placeholder}
          value={value}
          id={name}
          name={name}
          onChange={e => {
            form.change(name, e.target.value);
          }}
        />
      </div>
    </div>
  );
};
const PriceInputMaybe = boxProps => {
  const { name, id, className, initialValue = 0, type = 'text', form, max = 9999.99 } = boxProps;
  const [value, setValue] = useState('');
  const isFocussed = useRef(null);
  useEffect(() => {
    if (typeof initialValue === 'number' || Number(initialValue)) {
      const formattedNumber = Number(initialValue);
      if (!isFocussed.current)
        setValue(`€${formattedNumber % 1 === 0 ? formattedNumber : formattedNumber.toFixed(2)}`);
    }
  }, [initialValue]);

  const handleChange = e => {
    const target = e.target;
    let inputValue = e.target.value.replace(/[^0-9.]/g, '');
    if (inputValue === '') {
      setValue('');
      return;
    }
    if (inputValue.startsWith('.') && !(parseFloat(inputValue) > 0)) {
      inputValue = '0' + inputValue;
    }
    const parts = inputValue.split('.');
    if (parts[1] && parts[1].length > 2) {
      inputValue = `${parts[0]}.${parts[1].substring(0, 2)}`;
    }
    if (parts.length > 2) return;
    const numericValue = parseFloat(inputValue);
    if (isNaN(numericValue) || numericValue >= max) return;
    const should = inputValue.startsWith('0') && numericValue > 0;
    setValue(
      `${
        numericValue === 0 && inputValue === `${numericValue}`
          ? 0
          : should
          ? `${numericValue}`
          : inputValue
      }`
    );
    form.change(name, numericValue);
  };

  const handleBlur = () => {
    isFocussed.current = false;
    const numericValue = parseFloat(value.replace(/[^0-9.]/g, '')) || 0;
    setValue(numericValue % 1 === 0 ? `€${numericValue}` : `€${numericValue.toFixed(2)}`);
  };
  const handleFocus = () => {
    isFocussed.current = true;
    const numericValue = parseFloat(value.replace('€', '')) || 0;
    setValue(numericValue.toString());
  };
  const getNumericValue = val => {
    const formattedVal = val.replace(/[^0-9.]/g, '');
    return parseFloat(formattedVal);
  };
  const incrementFunction = () => {
    const numericValue = getNumericValue(value);
    form.change(name, numericValue + 1);
  };
  const decrementFunction = () => {
    const numericValue = getNumericValue(value);
    form.change(name, Math.max(numericValue - 1, 0));
  };

  return (
    <div className={css.flexprice}>
      <button
        type="button"
        onClick={decrementFunction}
        disabled={getNumericValue(value) === 0}
        className={css.ageButton}
      >
        -
      </button>
      <span className={css.mainPrice}>
        <input
          type={type}
          className={className}
          value={value}
          onChange={handleChange}
          name={name}
          id={id}
          onBlur={handleBlur}
          onFocus={handleFocus}
        />
      </span>
      <button type="button" onClick={incrementFunction} className={css.ageButton}>
        +
      </button>
    </div>
  );
};
const RadioOption = ({ id, name, value, label, selectedValue, onChange }) => (
  <div className={`${css.siblingDiscountCheck} ${css.unsetwdh}`}>
    <input
      type="radio"
      id={id}
      name={name}
      value={value}
      className={css.checkbox}
      onChange={onChange}
      defaultChecked={selectedValue === value}
    />
    <label htmlFor={id} className={css.checkboxLabel}>
      {selectedValue === value ? (
        <span className={css.checkicon}>
          <GrCheckboxSelected />
        </span>
      ) : (
        <span className={css.checkicon}>
          <GrCheckbox />
        </span>
      )}
      <span>{label}</span>
    </label>
  </div>
);
const priceTypeOptions = {
  afterSchoolClub: [
    { id: 'perHour', value: 'perHour', label: 'Per Hour' },
    { id: 'perAfternoon', value: 'perAfternoon', label: 'Per Afternoon' },
  ],
  breakfastClub: [
    { id: 'perHour', value: 'perHour', label: 'Per Hour' },
    { id: 'perMorning', value: 'perMorning', label: 'Per Morning' },
  ],
  birthdayParty: [
    { id: 'perChild', value: 'perChild', label: 'Per Child' },
    { id: 'totalPrice', value: 'totalPrice', label: 'Total Price' },
  ],
  schoolTours: [
    { id: 'perChild', value: 'perChild', label: 'Per Child' },
    { id: 'totalPrice', value: 'totalPrice', label: 'Total Price' },
  ],
  inSchoolProgrammes: [
    { id: 'perChild', value: 'perChild', label: 'Per Child' },
    { id: 'perClass', value: 'perClass', label: 'Per Class' },
    { id: 'perHour', value: 'perHour', label: 'Per Hour' },
    { id: 'perDay', value: 'perDay', label: 'Per Day' },
    { id: 'perTerm', value: 'perTerm', label: 'Per Term' },
  ],
  club: [
    { id: 'perMonth', value: 'perMonth', label: 'Per Month' },
    { id: 'perYear', value: 'perYear', label: 'Per Year' },
  ],
};
const eventsPriceBoxes = [
  { label: 'Per Child', value: 'perChild' },
  { label: 'Per Adult', value: 'perAdult' },
  { label: 'Per Family (2 + 2)', value: 'perFamily' },
  { label: 'Per Student', value: 'perStudent' },
  { label: 'Per OAP', value: 'perOAP' },
];
export const EditListingPricingFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        values,
        initialValues,
        invalid,
        pristine,
        updated,
        updateInProgress,
        fetchErrors,
        form,
        saveAndExit,
        publicData,
        tabProgress,
        backButton,
        backbtnLoader,
        valid,
        touched,
        error,
      } = formRenderProps;
      const [iUnderStand, setIUnderstand] = useState(false);
      const [textareaFocussed, setTextareaFocussed] = useState(false);
      const [dueDate, setDueDate] = useState(
        !!initialValues.dueDate ? moment(initialValues.dueDate) : null
      );

      const tabPercent = tabProgress(PRICING);
      const [trialClasses, setTrialClasses] = useState(
        !!initialValues?.trialClasses ? initialValues?.trialClasses : 0
      );
      const [openTill, setOpenTill] = useState({
        hour:
          publicData.categories?.includes('afterSchoolClub') ||
          publicData.categories?.includes('birthdayParty') ||
          publicData.categories?.includes('breakfastClub') ||
          publicData.categories?.includes('schoolTours') ||
          publicData.categories?.includes('inSchoolProgrammes')
            ? initialValues?.openTill?.hour
              ? initialValues?.openTill?.hour
              : 0
            : 0,
        minute:
          publicData.categories?.includes('afterSchoolClub') ||
          publicData.categories?.includes('birthdayParty') ||
          publicData.categories?.includes('breakfastClub') ||
          publicData.categories?.includes('schoolTours') ||
          publicData.categories?.includes('inSchoolProgrammes')
            ? initialValues?.openTill?.minute
              ? initialValues?.openTill?.minute
              : 0
            : 0,
        focusOnHour: false,
        focusOnMinute: false,
      });
      const [siblingDiscount, setSiblingDiscount] = useState(initialValues?.siblingDiscount);
      const [depositOption, setDepositOption] = useState(initialValues?.depositOption);
      const [closingTime, setClosingTime] = useState({
        hour:
          publicData.categories?.includes('afterSchoolClub') ||
          publicData.categories?.includes('breakfastClub')
            ? initialValues?.closingTime?.hour
              ? initialValues?.closingTime?.hour
              : 0
            : 0,
        minute:
          publicData.categories?.includes('afterSchoolClub') ||
          publicData.categories?.includes('breakfastClub')
            ? initialValues?.closingTime?.minute
              ? initialValues?.closingTime?.minute
              : 0
            : 0,
        focusOnHour: false,
        focusOnMinute: false,
      });
      const unitType = config.bookingUnitType;
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const lastTab =
        publicData.categories[0] === 'classes' ||
        publicData.categories[0] === 'workshops' ||
        publicData.categories[0] === 'camps' ||
        publicData.categories[0] === 'events' ||
        publicData.categories[0] === 'placesToVisit'
          ? AVAILABILITY
          : WHATTOBRING;
      const { updateListingError, showListingsError } = fetchErrors || {};
      const toFixedOnBlur = (value, callback) => {
        const current = value.substring(1);
        const isDecimal = current.includes('.');
        const putValue = parseFloat(current);
        if (isDecimal) {
          return callback(putValue.toFixed(2));
        } else {
          return callback(putValue);
        }
      };

      const additionalInfoPlaceholder = publicData.categories?.includes('afterSchoolClub') ? (
        // ? 'OPEN: Mon – Fri  1.30pm – 18.00pm CLOSES: During school breaks. Sibling Discount available Full time hours discount available Nearby schools we provide pick-ups from include:'
        'Eg. Our sibling discount applies when another child from the family attends full-time.'
      ) : publicData.categories?.includes('birthdayParty') ? (
        <>
          <span>Help customers by telling them if you...</span>
          <span className={css.tbspn}>
            <span> - Are closed on certain days</span>
            <span> - Have set time slots</span>
            <span> - Are closed seasonally</span>
            <span> - Children costs less once they reach a certain number</span>
            <span> - Larger numbers can be accommodated</span>
          </span>
        </>
      ) : publicData.categories?.includes('schoolTours') ? (
        <>
          <span>Help teachers by telling them if you...</span>
          <span className={css.tbspn}>
            <span> - Are closed on certain days</span>
            <span> - Have set time slots</span>
            <span> - Are closed seasonally</span>
            <span> - Children costs less once they reach a certain number</span>
            <span> - Larger numbers can be accommodated</span>
            <span> - Have a waivers requirement</span>
            <span> - Have a down-loadable PDF</span>
          </span>
        </>
      ) : publicData.categories?.includes('inSchoolProgrammes') ? (
        'Available Mon – Fri within school hours. Our packages are flexible and can be booked for 1 hour, 1 day, 1 week or 1 term.'
      ) : publicData.categories?.includes('club') ? (
        'Help parents by telling them things like... - Membership opens Jan – Feb  - Family memberships available - Sibling Discount offered'
      ) : publicData.categories?.includes('events') ? (
        'Help customers by telling them extra details related to price or availability. E.g. Under 12’s Free'
      ) : publicData.categories?.includes('breakfastClub') ? (
        <>
          <span>OPEN: Mon–Fri 6.30 am–9.30am</span>
          <span> CLOSED: During school breaks</span>
          <span>Sibling Discount available</span>
          <span> Full time hours discount available </span>
          <span>Nearby schools we drop kids to included</span>
        </>
      ) : publicData.categories?.includes('placesToVisit') ? (
        <>
          <span className={css.pltvphstpn}>Example of a good layout:</span>
          <span className={css.pltvphspn}>
            <span> Child under 3:</span> <span>FREE</span>
          </span>
          <span className={css.pltvphspn}>
            {' '}
            <span>Child under 12:</span> <span>€10</span>
          </span>
          <span className={css.pltvphspn}>
            {' '}
            <span> Adult</span>
            <span>€18</span>
          </span>
          <span className={css.pltvphspn}>
            <span>Family(2x2):</span> <span>€35</span>
          </span>
          <span className={css.pltvphspn}>
            <span>Student:</span> <span>€15</span>
          </span>
        </>
      ) : (
        ''
      );
      const additionalInfoMaybe = (
        <div className={css.prroelbl}>
          <label>
            Any additional information on <span>pricing</span> or <span>availability</span>?{' '}
          </label>
          <div className={css.additionalInfoContainer}>
            {!textareaFocussed && !values.additionalPricingInfo ? (
              <div className={css.phdv} onClick={() => setTextareaFocussed(true)}>
                {additionalInfoPlaceholder}
              </div>
            ) : (
              <textarea
                id="additionalPricingInfo"
                name="additionalPricingInfo"
                lang="en-IE"
                spellcheck="true"
                className={css.description}
                autoFocus={true}
                value={initialValues?.additionalPricingInfo}
                onChange={e => {
                  form.change('additionalPricingInfo', e.target.value);
                }}
                onFocus={e => {
                  const value = e.target.value;
                  e.target.selectionStart = value.length;
                  setTextareaFocussed(true);
                }}
                maxLength={ADDITIONAL_INFO_MAX_LIMIT}
                onBlur={() => setTextareaFocussed(false)}
              ></textarea>
            )}
          </div>
          <span className={css.cntspn}>
            {values.additionalPricingInfo
              ? `${values.additionalPricingInfo.length}/${ADDITIONAL_INFO_MAX_LIMIT}`
              : `0/${ADDITIONAL_INFO_MAX_LIMIT}`}
          </span>
        </div>
      );
      const minDate = moment().add(2, 'months');
      const dueDateMaybe = (
        <div className={`${css.calendarWrapper} calendarWrapper`}>
          <SingleDatePicker
            id="dueDate"
            focused={true}
            date={dueDate}
            onDateChange={date => {
              setDueDate(date);
              form.change('dueDate', date);
            }}
            minDate={() => moment()}
            onFocusChange={() => {}}
            numberOfMonths={1}
            hideKeyboardShortcutsPanel
            navPrev={<MdOutlineNavigateBefore />}
            navNext={<MdOutlineNavigateNext />}
            initialVisibleMonth={() => minDate}
          />
        </div>
      );
      const depositOptionMaybe = (
        <div className={css.mainoutrow}>
          <div className={css.siblingDiscountCheck}>
            <input
              className={css.siblingDiscount}
              type="checkbox"
              id="depositOption"
              name="depositOption"
              defaultChecked={values.depositOption}
              onChange={e => {
                if (values?.price > 0) {
                  setDepositOption(e.target.checked);
                  form.change('depositOption', e.target.checked);
                }
              }}
              checked={depositOption}
              disabled={values?.price <= 0}
            />
            <label className={css.siblingDiscountLabel} htmlFor="depositOption">
              <span className={css.checkicon}>
                {!!values.depositOption ? <GrCheckboxSelected /> : <GrCheckbox />}
              </span>
              <span className={css.labelSpan}>Do you want to offer a</span> deposit option?
            </label>
            <span className={css.tooltipContainer}>
              <Tooltip
                placement="left"
                // trigger={['click']}
                overlayClassName={css.ttt}
                overlayInnerStyle={{
                  background: '#002435',
                  backgroundColor: '#002435',
                  border: '0',
                  color: '#fff',
                }}
                // arrowContent={<div className={css.tooltipArrow}></div>}
                overlay={
                  <p>
                    <p>
                      “When a deposit amount is added here, the remaining balance cannot be
                      collected by Activity Time, currently.
                    </p>{' '}
                    <p>
                      This offers you full flexibility to collect the{' '}
                      <b>balance amount by CASH or CARD</b> directly from your customer.
                    </p>
                  </p>
                }
              >
                <span className={css.tooltip}>
                  <BiInfoCircle />
                </span>
              </Tooltip>

              {/* <p className={css.tooltipInfo}>
                  <p>
                    “When a deposit amount is added here, the remaining balance cannot be collected
                    by Activity Time, currently.
                  </p>
                  <p>
                    This offers you full flexibility to collect the{' '}
                    <b>balance amount by CASH or CARD</b> directly from your customer.
                  </p>
                </p> */}
            </span>
          </div>
          {values.depositOption ? (
            <>
              <label>
                Deposit <span>Amount</span>:
              </label>
              <PriceInputMaybe
                name="depositOptionAmount"
                id="depositOptionAmount"
                form={form}
                className={css.mainPriceInput}
                onInputChange={val => form.change('depositOptionAmount', val)}
                initialValue={values?.depositOptionAmount}
                max={values?.price}
              />

              {/* {publicData.categories?.includes('breakfastClub') ||
              publicData.categories?.includes('birthdayParty') ||
              publicData.categories?.includes('schoolTours') ||
              publicData.categories?.includes('inSchoolProgrammes') ? null : (
                <>
                  <label>
                  <span>Remaining</span> Balance <span>Amount</span>:
                  </label>
                  <div className={css.flexprice}>
                    <span className={css.mainPrice}>
                    <input
                        type="text"
                        className={css.mainPriceInput}
                        value={
                          !!price && !!depositOptionAmount
                            ? `€${price - depositOptionAmount}`
                            : '€0'
                        }
                        id="remainingAmountAfterDeposit"
                        disabled
                      />
                    </span>
                    </div>
                </>
                )} */}

              {/* <label>
                Due Date <span>for Balance Amount</span>:
                </label> */}
              {/* {dueDateMaybe} */}
            </>
          ) : null}
        </div>
      );

      const openTillMaybe = (
        <div className={css.openTillDiv}>
          <label>
            {publicData.categories?.includes('afterSchoolClub') ? (
              <>
                <span className={css.labelSpan}>What time do you </span> open{' '}
              </>
            ) : publicData.categories?.includes('breakfastClub') ? (
              <>
                <span className={css.labelSpan}>What time do you </span> open{' '}
                <span className={css.labelSpan}>on school mornings</span>{' '}
              </>
            ) : publicData.categories?.includes('schoolTours') ? (
              <>
                <span className={css.labelSpan}>What length of </span>time{' '}
                <span className={css.labelSpan}>is this school tour package?</span>
              </>
            ) : publicData.categories?.includes('inSchoolProgrammes') ? (
              <>
                <span className={css.labelSpan}>What length of </span> time{' '}
                <span className={css.labelSpan}>is this in-school programme package ?</span>
              </>
            ) : publicData.categories?.includes('birthdayParty') ? (
              <>
                <span className={css.labelSpan}>What length of </span> time{' '}
                <span className={css.labelSpan}>is this party package ?</span>
              </>
            ) : null}
          </label>

          <div className={css.flexprice}>
            <button
              className={css.ageButton}
              type="button"
              onClick={() => {
                if (openTill.focusOnHour && openTill.hour > 0) {
                  setOpenTill(state => ({ ...state, hour: state.hour - 1 }));
                  form.change('hour', Number(openTill.hour) - 1);
                }
                if (openTill.focusOnMinute && openTill.minute > 0) {
                  setOpenTill(state => ({ ...state, minute: state.minute - 1 }));
                  form.change('minute', Number(openTill.minute) - 1);
                }
              }}
            >
              -
            </button>
            <span className={css.mainPrice}>
              <input
                id="hour"
                type="text"
                className={css.mainPriceInput}
                autoFocus
                value={
                  openTill.hour ? (openTill?.hour < 10 ? `0${openTill.hour}` : openTill.hour) : '00'
                }
                // defaultValue={
                //   openTill.hour ? (openTill?.hour < 10 ? `0${openTill.hour}` : openTill.hour) : '00'
                // }
                onChange={e => {
                  if (/^[0-9]*$/.test(e.target.value) && Number(e.target.value) < 25) {
                    setOpenTill({ ...openTill, hour: Number(e.target.value) });
                    form.change('hour', Number(e.target.value));
                  }
                  if (e.target.value === '') {
                    form.change('hour', 0);
                    setOpenTill({ ...openTill, hour: 0 });
                  }
                }}
                onBlur={e => {
                  // setOpenTill({ ...openTill, focusOnHour: false });
                  e.target.value === '' ? setOpenTill({ ...openTill, hour: 0 }) : null;
                }}
                onFocus={e => {
                  setOpenTill({ ...openTill, focusOnHour: true, focusOnMinute: false });
                }}
              />
              :
              <input
                type="text"
                id="minute"
                autoFocus
                value={
                  openTill.minute
                    ? openTill.minute < 10
                      ? `0${openTill.minute}`
                      : openTill.minute
                    : '00'
                }
                className={css.mainPriceInput}
                onChange={e => {
                  if (/^[0-9]*$/.test(e.target.value) && Number(e.target.value) < 60) {
                    form.change('minute', Number(e.target.value));
                    setOpenTill({ ...openTill, minute: Number(e.target.value) });
                  }
                  if (e.target.value === '') {
                    form.change('minute', 0);
                    setOpenTill({ ...openTill, minute: 0 });
                  }
                }}
                onBlur={e => {
                  // setOpenTill({ ...openTill, focusOnMinute: false });
                  e.target.value === '' ? setOpenTill({ ...openTill, minute: 0 }) : null;
                }}
                onFocus={e => {
                  setOpenTill({ ...openTill, focusOnMinute: true, focusOnHour: false });
                }}
              />
            </span>
            <button
              className={css.ageButton}
              type="button"
              // disabled={() => {
              //   if (openTill.focusOnHour && openTill.hour === 24) {
              //     return true;
              //   } else if (openTill.focusOnMinute && openTill.minute === 60) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              onClick={() => {
                if (openTill.focusOnHour && openTill.hour < 24) {
                  setOpenTill(state => ({ ...state, hour: state.hour + 1 }));
                  form.change('hour', Number(openTill.hour) + 1);
                }
                if (openTill.focusOnMinute && openTill.minute < 60) {
                  setOpenTill(state => ({ ...state, minute: state.minute + 1 }));
                  form.change('minute', Number(openTill.minute) + 1);
                }
              }}
            >
              +
            </button>
          </div>
        </div>
      );
      const closingTimeMaybe = (
        <div className={css.openTillDiv}>
          <label>
            {publicData.categories?.includes('afterSchoolClub') ||
            publicData.categories?.includes('birthdayParty') ? (
              <>
                <span className={css.labelSpan}>What time do you </span> close{' '}
              </>
            ) : publicData.categories?.includes('breakfastClub') ? (
              <>
                <span className={css.labelSpan}>What time do you </span> close{' '}
                <span className={css.labelSpan}>on school mornings?</span>
              </>
            ) : publicData.categories?.includes('schoolTours') ? (
              'What length of time is this school tour package?'
            ) : publicData.categories?.includes('inSchoolProgrammes') ? (
              'What length of time is this in-school programme package?'
            ) : publicData.categories?.includes('birthdayParty') ? (
              'What length of time is this party package ?'
            ) : null}
          </label>

          <div className={css.flexprice}>
            <button
              className={css.ageButton}
              type="button"
              onClick={() => {
                if (closingTime.focusOnHour && closingTime.hour > 0) {
                  setClosingTime(state => ({ ...state, hour: state.hour - 1 }));
                  form.change('closingTime.hour', Number(closingTime.hour) - 1);
                }
                if (closingTime.focusOnMinute && closingTime.minute > 0) {
                  setClosingTime(state => ({ ...state, minute: state.minute - 1 }));
                  form.change('closingTime.minute', Number(opclosingTimeenTill.minute) - 1);
                }
              }}
            >
              -
            </button>
            <span className={css.mainPrice}>
              <input
                id="hour"
                type="text"
                className={css.mainPriceInput}
                autoFocus
                value={
                  closingTime.hour
                    ? closingTime?.hour < 10
                      ? `0${closingTime.hour}`
                      : closingTime.hour
                    : '00'
                }
                // defaultValue={
                //   openTill.hour ? (openTill?.hour < 10 ? `0${openTill.hour}` : openTill.hour) : '00'
                // }
                onChange={e => {
                  if (/^[0-9]*$/.test(e.target.value) && Number(e.target.value) < 25) {
                    setClosingTime({ ...closingTime, hour: Number(e.target.value) });
                    form.change('closingTime.hour', Number(e.target.value));
                  }
                  if (e.target.value === '') {
                    form.change('closingTime.hour', 0);
                    setClosingTime({ ...closingTime, hour: 0 });
                  }
                }}
                onBlur={e => {
                  // setOpenTill({ ...openTill, focusOnHour: false });
                  e.target.value === '' ? setClosingTime({ ...closingTime, hour: 0 }) : null;
                }}
                onFocus={e => {
                  setClosingTime({ ...closingTime, focusOnHour: true, focusOnMinute: false });
                }}
              />
              :
              <input
                type="text"
                id="minute"
                autoFocus
                value={
                  closingTime.minute
                    ? closingTime.minute < 10
                      ? `0${closingTime.minute}`
                      : closingTime.minute
                    : '00'
                }
                className={css.mainPriceInput}
                onChange={e => {
                  if (/^[0-9]*$/.test(e.target.value) && Number(e.target.value) < 60) {
                    form.change('closingTime.minute', Number(e.target.value));
                    setClosingTime({ ...closingTime, minute: Number(e.target.value) });
                  }
                  if (e.target.value === '') {
                    form.change('closingTime.minute', 0);
                    setClosingTime({ ...closingTime, minute: 0 });
                  }
                }}
                onBlur={e => {
                  // setOpenTill({ ...openTill, focusOnMinute: false });
                  e.target.value === '' ? setClosingTime({ ...closingTime, minute: 0 }) : null;
                }}
                onFocus={e => {
                  setClosingTime({ ...closingTime, focusOnMinute: true, focusOnHour: false });
                }}
              />
            </span>
            <button
              className={css.ageButton}
              type="button"
              // disabled={() => {
              //   if (openTill.focusOnHour && openTill.hour === 24) {
              //     return true;
              //   } else if (openTill.focusOnMinute && openTill.minute === 60) {
              //     return true;
              //   } else {
              //     return false;
              //   }
              // }}
              onClick={() => {
                if (closingTime.focusOnHour && closingTime.hour < 24) {
                  setClosingTime(state => ({ ...state, hour: state.hour + 1 }));
                  form.change('closingTime.hour', Number(closingTime.hour) + 1);
                }
                if (closingTime.focusOnMinute && closingTime.minute < 60) {
                  setClosingTime(state => ({ ...state, minute: state.minute + 1 }));
                  form.change('closingTime.minute', Number(closingTime.minute) + 1);
                }
              }}
            >
              +
            </button>
          </div>
        </div>
      );
      const earlyDropOffMaybe = (
        <div className={css.mainoutrow}>
          <label>
            Early Drop-Off<span> - daily fee</span>
          </label>
          <PriceInputMaybe
            name="earlyDropOff"
            id="earlyDropOff"
            form={form}
            className={css.mainPriceInput}
            onInputChange={val => form.change('earlyDropOff', val)}
            initialValue={values?.earlyDropOff}
          />
        </div>
      );
      const latePickUpMaybe = (
        <div className={css.mainoutrow}>
          <label>
            Late Pick-Up<span> - daily fee</span>
          </label>
          <PriceInputMaybe
            name="latePickup"
            id="latePickup"
            form={form}
            className={css.mainPriceInput}
            onInputChange={val => form.change('latePickup', val)}
            initialValue={values?.latePickup}
          />
        </div>
      );
      const childTwoMaybe = (
        <PriceInputMaybe
          name="childTwoAmount"
          id="childTwoAmount"
          className={css.mainPriceInput}
          onInputChange={val => form.change('childTwoAmount', val)}
          initialValue={values?.childTwoAmount}
          max={values?.price}
          form={form}
        />
      );
      const childThreeMaybe = (
        <PriceInputMaybe
          name="childThreeAmount"
          id="childThreeAmount"
          max={values?.childTwoAmount}
          form={form}
          className={css.mainPriceInput}
          onInputChange={val => form.change('childThreeAmount', val)}
          initialValue={values?.childThreeAmount}
        />
      );
      const siblingDiscountMaybe = (
        <div className={css.mainoutrow}>
          <div className={css.siblingDiscountCheck}>
            <input
              className={css.siblingDiscount}
              type="checkbox"
              id="siblingDiscount"
              name="siblingDiscount"
              defaultChecked={values.siblingDiscount}
              onChange={e => {
                if (values?.price > 0) {
                  setSiblingDiscount(e.target.checked);
                  form.change('siblingDiscount', e.target.checked);
                }
              }}
              checked={siblingDiscount}
              disabled={values?.price <= 0}
            />
            <label className={css.siblingDiscountLabel} htmlFor="siblingDiscount">
              <span className={css.checkicon}>
                {!!siblingDiscount ? <GrCheckboxSelected /> : <GrCheckbox />}
              </span>
              <span className={css.labelSpan}>Do you want to offer a</span> sibling discount?
            </label>
          </div>
          {values.siblingDiscount ? (
            <div className={css.mainoutrow}>
              <label>Child No. 2</label>
              {childTwoMaybe}
              <div className={css.mainoutrow}>
                <label>Child No. 3</label>

                {childThreeMaybe}
              </div>
            </div>
          ) : null}
        </div>
      );

      const priceMaybe = (
        <PriceInputMaybe
          form={form}
          name="price"
          id="price"
          className={css.mainPriceInput}
          onInputChange={val => form.change('price', val)}
          initialValue={values?.price || 0}
        />
      );

      // Define the options for each category

      // Determine the applicable options based on the category
      const applicableOptions = Object.keys(priceTypeOptions).find(category =>
        publicData.categories?.includes(category)
      );

      // Render the options
      const priceTypeMaybe = applicableOptions ? (
        <div
          className={`${css.priceType} ${
            applicableOptions === 'inSchoolProgrammes' ? css.fivecats : ''
          }`}
        >
          {priceTypeOptions[applicableOptions].map(({ id, value, label }) => (
            <RadioOption
              key={id}
              id={id}
              name="priceType"
              value={value}
              label={label}
              selectedValue={values.priceType}
              onChange={e => form.change('priceType', e.target.value)}
            />
          ))}
        </div>
      ) : null;

      const trialOptionMaybe = (
        <div className={css.mainoutrow}>
          <div className={css.siblingDiscountCheck}>
            <input
              className={css.siblingDiscount}
              type="checkbox"
              id="trialOption"
              name="trialOption"
              defaultChecked={values.trialOption}
              onChange={e => {
                form.change('trialOption', e.target.checked);
              }}
            />

            <label className={`${css.withafter} ${css.siblingDiscountLabel}`} htmlFor="trialOption">
              <span className={css.checkicon}>
                {!!values.trialOption ? <GrCheckboxSelected /> : <GrCheckbox />}
              </span>
              <span className={css.labelSpan}>Do you want to offer</span> trial option?{' '}
            </label>
          </div>
          {values.trialOption ? (
            <>
              <label>Trial price per child</label>
              <PriceInputMaybe
                name="trialFee"
                form={form}
                id="trialFee"
                className={css.mainPriceInput}
                onInputChange={val => form.change('trialFee', val)}
                initialValue={values?.trialFee}
                max={values?.price}
              />
              <label>
                No. of {publicData?.classType ? publicData?.classType?.label : 'classes'} in trial
              </label>
              <div className={css.flexprice}>
                <button
                  type="button"
                  onClick={() => {
                    if (trialClasses >= 1) setTrialClasses(state => state - 1);
                    form.change('trialClasses', Math.max(0, trialClasses - 1));
                  }}
                  disabled={trialClasses === 0}
                  className={css.ageButton}
                >
                  -
                </button>
                <span className={css.mainPrice}>
                  {
                    <input
                      type="text"
                      className={css.mainPriceInput}
                      value={trialClasses}
                      id="trialClasses"
                      onChange={e => {
                        const value = e.target.value;
                        if (/^[0-9]/.test(value)) {
                          setTrialClasses(Number(value));
                          form.change('trialClasses', Number(value));
                        } else if (value === '') {
                          setTrialClasses(0);
                          form.change('trialClasses', 0);
                        }
                      }}
                      onBlur={e => {
                        if (e.target.value === '') {
                          setTrialClasses(0);
                        }
                      }}
                    />
                  }
                </span>
                <button
                  type="button"
                  onClick={() => {
                    setTrialClasses(state => state + 1);
                    form.change('trialClasses', trialClasses + 1);
                  }}
                  className={css.ageButton}
                  // disabled={maxAge === 18}
                >
                  +
                </button>
              </div>
              <label>Additional info</label>
              <div className={css.trialMsg}>
                <textarea
                  type="text"
                  id="trialMsg"
                  lang="en-IE"
                  spellcheck="true"
                  value={values.trialMsg}
                  onChange={e => {
                    const value = e.target.value;
                    if (value.length <= 50) {
                      form.change('trialMsg', value);
                    }
                    if (value === '') {
                      document.getElementById('trialMsg').innerText = '';
                    }
                  }}
                  placeholder="We offer a trail class, so your child can see if they like it."
                  // defaultValue={initialValues.trialMsg}
                ></textarea>
                <p>
                  {!!values.trialMsg
                    ? `${values.trialMsg.length}/${TRIAL_MSG_LIMIT}`
                    : `0/${TRIAL_MSG_LIMIT}`}
                </p>
              </div>
            </>
          ) : null}
        </div>
      );
      const saveExit = (
        <Button
          className={css.exitButton}
          type="button"
          inProgress={backbtnLoader}
          onClick={() => {
            saveAndExit(values);
          }}
        >
          Save & Exit
        </Button>
      );
      const whenOpenMaybe = (
        <TimingData
          firstHeading="Please tell us the days you’re"
          placeholder="Monday - Friday: 13:30 - 18:00"
          value={values?.open}
          form={form}
          name="open"
          secondHeading="open"
          label="OPEN:"
        />
      );
      const whenClosedMaybe = (
        <TimingData
          firstHeading="Please tell us the days you’re"
          placeholder="School Holidays"
          value={values?.closed}
          form={form}
          name="closed"
          secondHeading="closed"
          label="CLOSED:"
        />
      );
      const isSiblingDiscountInvalid =
        siblingDiscount &&
        (!values.childTwoAmount ||
          values.childTwoAmount <= 0 ||
          values.childTwoAmount >= values?.price ||
          !values.childThreeAmount ||
          values.childThreeAmount >= values.childTwoAmount ||
          values.childThreeAmount <= 0);

      const isDepositOptionInvalid = depositOption && !values.depositOptionAmount;
      const isEarlyDropOffInvalid =
        publicData?.extendedChildCare?.earlyDropOff && !values.earlyDropOff;
      const isLatePickupInvalid = publicData?.extendedChildCare?.latePickup && !values.latePickup;
      const isInvalidForClasses =
        publicData.categories?.includes('classes') &&
        (!values.price || (values.trialOption && !values.trialFee && !values.trialClasses));

      const isInvalidForCamps = publicData.categories?.includes('camps') && !values.price;

      const isInvalidForWorkshops =
        publicData.categories?.includes('workshops') && values?.price === 0 && !iUnderStand;

      const submitDisabled =
        invalid ||
        disabled ||
        submitInProgress ||
        isSiblingDiscountInvalid ||
        isDepositOptionInvalid ||
        isInvalidForClasses ||
        isInvalidForCamps ||
        isInvalidForWorkshops ||
        isEarlyDropOffInvalid ||
        isLatePickupInvalid;
      return (
        <Form
          onSubmit={e => {
            e.preventDefault();
            values.openTill = openTill;
            props.onSubmit(values);
          }}
          className={classes}
        >
          <div className={css.svexbtnsec}>{saveExit}</div>

          <div className={classNames(css.formseclist, { [css.secContent]: values?.price === 0 })}>
            {publicData.categories?.includes('birthdayParty') ||
            publicData.categories?.includes('schoolTours') ||
            publicData.categories?.includes('inSchoolProgrammes') ? (
              <div className={css.mainoutrow}>
                <label>Price:</label>

                {priceTypeMaybe}
                {priceMaybe}
                {openTillMaybe}
                {depositOptionMaybe}
                {additionalInfoMaybe}
              </div>
            ) : publicData.categories?.includes('breakfastClub') ? (
              <div className={css.mainoutrow}>
                <label>Price:</label>

                {priceTypeMaybe}
                {priceMaybe}
                {openTillMaybe}
                {closingTimeMaybe}
                {siblingDiscountMaybe}
                {depositOptionMaybe}
                {whenOpenMaybe}
                {whenClosedMaybe}
                {additionalInfoMaybe}
              </div>
            ) : publicData.categories?.includes('afterSchoolClub') ? (
              <div className={css.mainoutrow}>
                <label>Price:</label>

                {priceTypeMaybe}
                {priceMaybe}
                {openTillMaybe}
                {closingTimeMaybe}
                {siblingDiscountMaybe}
                {depositOptionMaybe}
                {whenOpenMaybe}
                {whenClosedMaybe}
                {additionalInfoMaybe}
              </div>
            ) : publicData.categories?.includes('club') ? (
              <div className={css.mainoutrow}>
                <label>Membership Price From:</label>
                {priceTypeMaybe}
                {priceMaybe}
                {additionalInfoMaybe}
              </div>
            ) : publicData.categories?.includes('camps') ? (
              <div className={css.mainoutrow}>
                <label>Price per child</label>
                {priceMaybe}
                {publicData.extendedChildCare?.earlyDropOff ? earlyDropOffMaybe : null}
                {publicData.extendedChildCare?.latePickup ? latePickUpMaybe : null}
                {siblingDiscountMaybe}
                {depositOptionMaybe}
              </div>
            ) : publicData.categories?.includes('placesToVisit') ? (
              <>
                <label className={css.pvpricelabel}>
                  Try to display <span>pricing</span> concisely
                </label>
                {additionalInfoMaybe}

                <label className={`${css.pvpricelabel} ${css.onplbl}`}>
                  *Pick <span>one price</span> for page display
                </label>
                <div className={css.plprcdiv}>
                  <Select
                    className={css.plselect}
                    id={'priceType'}
                    options={priceOptions}
                    isClearable={true}
                    value={priceOptions.find(c => c.value === values.priceType)}
                    onChange={e => {
                      if (e !== null) {
                        form.change('priceType', e.value);
                      } else {
                        form.change('priceType', '');
                      }
                    }}
                  />
                  {priceMaybe}
                </div>
              </>
            ) : publicData.categories?.includes('classes') ? (
              <div className={css.mainoutrow}>
                <label>Price per child</label>
                {priceMaybe}
                {trialOptionMaybe}
                {siblingDiscountMaybe}
                {depositOptionMaybe}
              </div>
            ) : publicData.categories?.includes('workshops') ? (
              <div className={classNames(css.mainoutrow, { [css.optdisbl]: values?.price <= 0 })}>
                <label>Price per child</label>
                {priceMaybe}
                {siblingDiscountMaybe}
                {depositOptionMaybe}
              </div>
            ) : publicData.categories?.includes('events') ? (
              <div>
                <h2 className={css.tktpr}>Ticket Prices:</h2>
                {eventsPriceBoxes.map(o => (
                  <div className={css.prroelbl}>
                    <label htmlFor={o.value}>{o.label}</label>

                    <PriceInputMaybe
                      name={o.value}
                      id={o.value}
                      form={form}
                      className={css.mainPriceInput}
                      onInputChange={val => form.change(o.value, val)}
                      initialValue={values?.[o.value]}
                    />
                  </div>
                ))}
                {additionalInfoMaybe}
              </div>
            ) : null}
            {values?.price === 0 ||
            (values.trialOption && values?.trialFee === 0) ||
            (siblingDiscount && (values.childTwoAmount === 0 || values.childThreeAmount === 0)) ? (
              <div className={css.zeroPrcInfo}>
                <p>FREE tickets are FREE for your customers.</p>
                <p>
                  All Express Bookings include a minimal ticketing fee to cover platform running
                  costs of €0.99 + vat + Stripe per space. Alternatively, you can add a small fee
                  here, shifting the ticketing costs to the end customer. On average 30% of free
                  trials or spaces convert into paying customers.
                </p>

                <div className={css.iUnderStandDiv}>
                  I understand:{' '}
                  <button
                    onClick={() => setIUnderstand(true)}
                    className={css.iUnderStand}
                    type="button"
                  >
                    <span>{iUnderStand ? <IoCheckmarkCircle /> : null} </span>
                  </button>
                </div>
              </div>
            ) : null}
          </div>
          <div className={css.formlistbtmsec}>
            <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />

            <div className={css.buttonDiv}>
              <Button type="button" className={css.backButton} onClick={() => backButton(lastTab)}>
                Back
              </Button>

              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={
                  publicData.categories?.includes('afterSchoolClub')
                    ? submitDisabled || !values.price || !values.open || !values.closed
                    : publicData.categories?.includes('placesToVisit')
                    ? !values.additionalPricingInfo || !values.priceType
                    : publicData.categories?.includes('events') ||
                      publicData.categories?.includes('camps') ||
                      publicData.categories?.includes('classes') ||
                      publicData.categories?.includes('workshops')
                    ? submitDisabled
                    : submitDisabled || !values.price || !values.priceType
                }
                ready={submitReady}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingPricingFormComponent.defaultProps = { fetchErrors: null };

EditListingPricingFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPricingFormComponent);
