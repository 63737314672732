//Places to visit
export const placesToVisitActivityTypeOptions = [
  {
    key: 'adventureAndActivity',
    value: 'adventureAndActivity',
    label: 'Adventure & Activities',
  },
  {
    key: 'cultural',
    value: 'cultural',
    label: 'Cultural',
  },
  {
    key: 'gardensAndPark',
    value: 'gardensAndPark',
    label: 'Gardens and Park',
  },
  {
    key: 'historicVenues',
    value: 'historicVenues',
    label: 'Historic Venues',
  },
  {
    key: 'museumsAndGalleries',
    value: 'museumsAndGalleries',
    label: 'Museums & Galleries',
  },
  {
    key: 'zoosWildlifeAndFarms',
    value: 'zoosWildlifeAndFarms',
    label: 'Zoos, Wildlife & Farms',
  },
  {
    key: 'other',
    value: 'other',
    label: 'Other',
  },
];

//school tours option
export const schoolToursActivityTypeOptions = [
  {
    key: 'adventureAndActivity',
    value: 'adventureAndActivity',
    label: 'Adventure & Activities',
  },
  {
    key: 'gardensAndPark',
    value: 'gardensAndPark',
    label: 'Gardens and Park',
  },
  {
    key: 'historicVenues',
    value: 'historicVenues',
    label: 'Historic Venues',
  },
  {
    key: 'museumsAndGalleries',
    value: 'museumsAndGalleries',
    label: 'Museums & Galleries',
  },
  {
    key: 'natureAndOutdoor',
    value: 'natureAndOutdoor',
    label: 'Nature & Outdoor',
  },
  {
    key: 'overnight',
    value: 'overnight',
    label: 'Overnight',
  },
  {
    key: 'zoosWildlifeAndFarms',
    value: 'zoosWildlifeAndFarms',
    label: 'Zoos Wildlife & Farms',
  },
];

//birthdays option
export const birthdaysActivityTypeOptions = [
  {
    key: 'adventureAndActivityparties',
    value: 'adventureAndActivityparties',
    label: 'Adventure & Activities Parties',
  },
  {
    key: 'natureAndOutdoorparties',
    value: 'natureAndOutdoorparties',
    label: 'Nature & Outdoor Parties',
  },
  {
    key: 'mobileParties',
    value: 'mobileParties',
    label: 'Mobile Parties',
  },
  {
    key: 'wildlifeAndAnimalparties',
    value: 'wildlifeAndAnimalparties',
    label: 'Wildlife & Animal Parties',
  },
];
// camp option
export const campsActivityTypeOptions = [
  { value: 'summerCamp', label: 'Summer Camp', key: 'summerCamp' },
  { value: 'halloweenCamp', label: 'Halloween Camp', key: 'halloweenCamp' },
  { value: 'easterCamp', label: 'Easter Camp', key: 'easterCamp' },
  {
    value: 'februaryMidTermCamp',
    label: 'February Mid-Term Camp',
    key: 'februaryMidTermCamp',
  },
  { value: 'other', label: 'Other', key: 'other' },
];
