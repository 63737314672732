import React from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, FieldTextInput } from '../../components';
import css from './EditListingOpenForForm.module.css';
import { CATEGORIES, OPENFOR } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { isEqual } from 'lodash';
function EditListingOpenForForm(props) {
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          initialValues,
          tabProgress,
          savenExit,
          backbtnLoader,
        } = formRenderProps;
        const tabPercent = tabProgress(OPENFOR);
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const saveExitButtonDisabled = isEqual(initialValues, values) && !values.openFor;
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const updateValues = {
                publicData: {
                  openFor: values.openFor,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        const lastTab = CATEGORIES;
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              <div className={css.othcatnew}>
                <div
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.openFor === 'openToAll',
                  })}
                >
                  <label htmlFor="openToAll" className={css.checkboxLabel}>
                    <div className={css.labelDiv}>
                      <span className={css.keylbl}>Open to all kids</span>
                    </div>
                  </label>
                  <input
                    type="radio"
                    name="openFor"
                    id="openToAll"
                    value={'openToAll'}
                    className={css.radioButton}
                    onChange={e => {
                      form.change('openFor', e.target.value);
                    }}
                    defaultChecked={initialValues.openFor === 'openToAll'}
                  />
                </div>
                <div
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.openFor === 'openForSpecific',
                  })}
                >
                  <label htmlFor="openForS" className={css.checkboxLabel}>
                    <div className={css.labelDiv}>
                      <span className={css.keylbl}>Open to kids from specific school(s)</span>
                    </div>
                  </label>
                  <input
                    type="radio"
                    name="openFor"
                    id="openForS"
                    value={'openForSpecific'}
                    className={css.radioButton}
                    defaultChecked={initialValues.openFor === 'openForSpecific'}
                    onChange={e => {
                      form.change('openFor', e.target.value);
                    }}
                  />
                </div>
                {values.openFor?.includes('openForSpecific') ? (
                  <div className={css.othertxt}>
                    <input
                      type="text"
                      autoFocus={!!values.schoolName}
                      value={values.schoolName}
                      placeholder="Please enter school name"
                      onChange={e => {
                        const value = e.target.value;
                        form.change('schoolName', value);
                      }}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!values.openFor}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default EditListingOpenForForm;
