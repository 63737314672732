import React from 'react';
import { bool, func, object, string } from 'prop-types';
import EditListingImage from '../EditListingImage/EditListingImage';
import EditListingActivityModeForm from '../../forms/EditListingActivityModeForm/EditListingActivityModeForm';
import classNames from 'classnames';
import { ensureOwnListing } from '../../util/data';
import { LISTING_STATE_DRAFT } from '../../util/types';
import css from './EditListingActivityModePanel.module.css';
import clisting2 from '../../assets/screen-05-ripped-img.png';
import clistingmb2 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/in-person-icon.png';
import { ACTIVITYMODE } from '../EditListingWizard/EditListingWizardTab';
const EditListingActivityModePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    backButton,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    tabProgress,
    savenExit,
    backbtnLoader,
    currentUser,
    intl,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = listing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        <EditListingImage
          className={css.imgSection}
          logoImg={listinglogo}
          desktopImg={clisting2}
          mobileImg={clistingmb2}
          currentTab={ACTIVITYMODE}
          bgIcon={screenicon}
          imageText={`Is your activity in-person or online?`}
        ></EditListingImage>
        <div className={css.rightSection}>
          <EditListingActivityModeForm
            savenExit={savenExit}
            backbtnLoader={backbtnLoader}
            isPublished={isPublished}
            backButton={backButton}
            saveActionMsg={submitButtonText}
            initialValues={{ activityMode: publicData.activityMode }}
            history={history}
            className={css.form}
            disabled={disabled}
            ready={ready}
            tabProgress={tabProgress}
            updated={panelUpdated}
            intl={intl}
            onSubmit={values => {
              const { activityMode } = values;
              const updateValues = {
                publicData: {
                  activityMode,
                  county:
                    activityMode === 'online'
                      ? currentUser?.attributes?.profile?.publicData?.county?.toLowerCase()
                      : publicData?.county
                      ? publicData.county
                      : null,
                },
              };
              onSubmit(updateValues);
            }}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </div>
      </div>
    </div>
  );
};
EditListingActivityModePanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingActivityModePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingActivityModePanel;
