import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import routeConfiguration from '../../routeConfiguration';
import pro from '../../assets/pro-feat.png';
import {
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LISTING_STATE_PENDING_APPROVAL,
  LISTING_STATE_CLOSED,
  LISTING_STATE_DRAFT,
  propTypes,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureOwnListing } from '../../util/data';
import {
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  createSlug,
  parse,
} from '../../util/urlHelpers';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import config from '../../config';
import {
  InlineTextButton,
  Menu,
  MenuLabel,
  MenuContent,
  MenuItem,
  NamedLink,
  IconSpinner,
  ResponsiveImage,
  Modal,
  Button,
} from '../../components';
import { placesToVisitActivityTypeOptions } from '../../assets/activityTypeOptions';

import MenuIcon from './MenuIcon';
import Overlay from './Overlay';
import css from './ManageListingCard.module.css';
import moment from 'moment';
import { BsDot, BsThreeDots } from 'react-icons/bs';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import CustomCsvDownloader from '../CustomCsvDownloader/CustomCsvDownloader';
import SalesBox from './SalesBox';
import ManageListingCardTop from './ManageListingCardTop';
import { formatListingData } from '../../util/formattedListingData';
import ShareButton from '../ShareButton/ShareButton';

// Menu content needs the same padding
const MENU_CONTENT_OFFSET = -12;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ManageListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const createListingURL = (routes, listing) => {
  const id = listing.id.uuid;
  const slug = createSlug(listing.attributes.title);
  const isPendingApproval = listing.attributes.state === LISTING_STATE_PENDING_APPROVAL;
  const isDraft = listing.attributes.state === LISTING_STATE_DRAFT;
  const variant = isDraft
    ? LISTING_PAGE_DRAFT_VARIANT
    : isPendingApproval
    ? LISTING_PAGE_PENDING_APPROVAL_VARIANT
    : null;

  const linkProps =
    isPendingApproval || isDraft
      ? {
          name: 'ListingPageVariant',
          params: {
            id,
            slug,
            variant,
          },
        }
      : {
          name: 'ListingPage',
          params: { id, slug },
        };

  return createResourceLocatorString(linkProps.name, routes, linkProps.params, {});
};

// Cards are not fixed sizes - So, long words in title make flexboxed items to grow too big.
// 1. We split title to an array of words and spaces.
//    "foo bar".split(/([^\s]+)/gi) => ["", "foo", " ", "bar", ""]
// 2. Then we break long words by adding a '<span>' with word-break: 'break-all';
const formatTitle = (title, maxLength) => {
  const nonWhiteSpaceSequence = /([^\s]+)/gi;
  return title.split(nonWhiteSpaceSequence).map((word, index) => {
    return word.length > maxLength ? (
      <span key={index} style={{ wordBreak: 'break-all' }}>
        {word}
      </span>
    ) : (
      word
    );
  });
};

export const ManageListingCardComponent = props => {
  const {
    className,
    rootClassName,
    hasClosingError,
    hasOpeningError,
    history,
    intl,
    isMenuOpen,
    actionsInProgressListingId,
    listing,
    onCloseListing,
    onOpenListing,
    onToggleMenu,
    onManageDisableScrolling,
    duplicateListing,
    currentUser,
    params,
    location,
    onLoadData,
    isStarter,
    transactions,
    onGetAndSetTransactions,
  } = props;
  const { search } = location;
  const [listingBookings, setListingBookings] = useState(0);
  const [confirmDuplicate, setConfirmDuplicate] = useState(false);
  const [loadingDuplicate, setLoadingDuplicate] = useState(false);
  const [isTransactionsLoading, setIsTransactionsLoading] = useState(false);
  useEffect(() => {
    const url = `${apiBaseUrl()}/api/listing-bookings`;
    const data = { listingId: currentListing?.id.uuid };
    axios.post(url, data).then(resp => {
      setListingBookings(resp.data.bookings);
    });
  }, [listing]);
  const { waitingList = [] } = listing.attributes.privateData || [];
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const id = currentListing.id.uuid;
  const { title = '', price, state, publicData } = currentListing.attributes;
  const { startDate, endDate, startTime, endTime, age, isSnoozed } = publicData || {};
  const currentStock = currentListing?.currentStock?.attributes?.quantity;
  const slug = createSlug(title);
  const isPendingApproval = state === LISTING_STATE_PENDING_APPROVAL;
  const isClosed = state === LISTING_STATE_CLOSED;
  const isDraft = state === LISTING_STATE_DRAFT;

  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;
  const { bname, county } = currentUser?.attributes.profile.publicData || {};
  const menuItemClasses = classNames(css.menuItem);
  const menuButtonProps = {
    rootClassName: css.menuItemButton,
    // disabled: isStarter,
  };
  const hasError = hasOpeningError || hasClosingError;
  const thisListingInProgress =
    actionsInProgressListingId && actionsInProgressListingId.uuid === id;

  const onOverListingLink = () => {
    // Enforce preloading of ListingPage (loadable component)
    const { component: Page } = findRouteByRouteName('ListingPage', routeConfiguration());
    // Loadable Component has a "preload" function.
    if (Page.preload) {
      Page.preload();
    }
  };

  const titleClasses = classNames(css.title, {
    [css.titlePending]: isPendingApproval,
    [css.titleDraft]: isDraft,
  });

  const editListingLinkType = isDraft
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;

  const unitType = config.bookingUnitType;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const unitTranslationKey = isNightly
    ? 'ManageListingCard.perNight'
    : isDaily
    ? 'ManageListingCard.perDay'
    : 'ManageListingCard.perUnit';
  const onClickTransaction = async () => {
    setIsTransactionsLoading(true);
    await onGetAndSetTransactions(currentListing, listingBookings);
    setIsTransactionsLoading(false);
  };
  const cityMaybe = (
    <div className={css.datetimeinfocol}>
      <span>{'Location: '}</span>
      {publicData?.city || publicData?.county ? (
        <span className={css.pricespn}>
          {publicData?.city ? publicData?.city.toUpperCase() : publicData?.county?.toUpperCase()}
        </span>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const dateMaybe = (
    <div className={css.datetimeinfocol}>
      <span>{'Date'}:</span>
      {publicData?.startDate ? (
        <>
          {publicData?.categories?.[0] === 'events' || publicData?.categories?.[0] === 'workshops'
            ? moment(publicData?.startDate).format('MMM Do, YYYY')
            : `${moment(publicData?.startDate).format('MMM Do')} – ${moment(
                publicData?.endDate
              ).format('MMM Do, YYYY')}`}
          {publicData?.categories?.[0] === 'workshops' && '(1 day)'}
          {publicData?.categories?.[0] === 'events' &&
            publicData?.day &&
            `(${publicData?.day?.length} ${publicData?.day?.length > 1 ? 'days' : 'day'})`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const onTitleClick = () => {
    isDraft
      ? history.push(
          createResourceLocatorString(
            'EditListingPage',
            routeConfiguration(),
            { id, slug, type: editListingLinkType, tab: 'categories' },
            {}
          )
        )
      : history.push(createListingURL(routeConfiguration(), listing));
  };
  const timeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Time: </span>{' '}
      {publicData?.startTime ? (
        <>
          {publicData?.startTime?.hour >= 10
            ? publicData?.startTime?.hour
            : `0${publicData?.startTime?.hour}`}
          .
          {publicData?.startTime?.minute >= 10
            ? publicData?.startTime?.minute
            : `0${publicData?.startTime?.minute}`}{' '}
          –{' '}
          {publicData?.endTime?.hour >= 10
            ? publicData?.endTime?.hour
            : `0${publicData?.endTime?.hour}`}
          .
          {publicData?.endTime?.minute >= 10
            ? publicData?.endTime?.minute
            : `0${publicData?.endTime?.minute}`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const activityTypesObj = publicData?.activityType?.map(a =>
    placesToVisitActivityTypeOptions.find(o => o.value === a)
  );
  const activityTypeMaybe = publicData?.activityType && (
    <div className={`${css.atrow} ${css.datetimeinfocol}`}>
      <span>Categories: </span>{' '}
      {activityTypesObj &&
        publicData?.categories?.[0] === 'placesToVisit' &&
        activityTypesObj.reduce(
          (pre, curr, idx) =>
            `${pre}${
              idx === 0
                ? ` ${curr.label}`
                : idx === activityTypesObj?.length - 1
                ? ` & ${curr.label}`
                : `, ${curr.label}`
            }`,
          ''
        )}
    </div>
  );
  const ageMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Age: </span>{' '}
      {publicData?.age && publicData?.age?.length > 0
        ? (publicData?.categories?.[0] === 'events' ||
            publicData?.categories?.[0] === 'placesToVisit') &&
          publicData?.allAges
          ? 'All Ages'
          : `${publicData?.age[0]} - ${publicData?.age[publicData?.age?.length - 1]}`
        : 'Not Set'}
    </div>
  );
  const closingTimeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Closes: </span>
      {publicData?.closingTime ? (
        <>
          {' '}
          {`${
            publicData?.closingTime?.hour < 10
              ? `0${publicData?.closingTime?.hour || 0}`
              : publicData?.closingTime?.hour || '00'
          }:${
            publicData?.closingTime?.minute < 10
              ? `0${publicData?.closingTime?.minute || 0}`
              : publicData?.closingTime?.minute || '00'
          }`}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const openTillMaybe = (
    <div className={css.datetimeinfocol}>
      <span>
        {publicData?.categories?.[0] === 'inSchoolProgrammes'
          ? 'Time: '
          : publicData?.categories?.[0] === 'birthdayParty' ||
            publicData?.categories?.[0] === 'schoolTours'
          ? 'Length: '
          : publicData?.categories?.[0] === 'breakfastClub' ||
            publicData?.categories?.[0] === 'afterSchoolClub'
          ? 'Opens: '
          : 'Closes: '}
      </span>
      {publicData?.openTill ? (
        <>
          {`${
            publicData?.openTill?.hour < 10
              ? `0${publicData?.openTill?.hour}`
              : publicData?.openTill?.hour
          }:${
            publicData?.openTill?.minute < 10
              ? `0${publicData?.openTill?.minute}`
              : publicData?.openTill?.minute
          }`}{' '}
          {publicData?.categories?.[0] === 'birthdayParty' ||
          publicData?.categories?.[0] === 'schoolTours' ||
          publicData?.categories?.[0] === 'inSchoolProgrammes'
            ? 'hours'
            : null}
        </>
      ) : (
        'Not Set'
      )}
    </div>
  );
  const noOfKidsMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Min - Max: </span>
      {publicData?.noOfKids
        ? `${publicData?.noOfKids?.minNo} - ${publicData?.noOfKids?.maxNo}`
        : 'Not Set'}
    </div>
  );
  const eventTimes = publicData?.day && (
    <div className={css.datetimeinfocol}>
      {publicData?.day?.map((d, i) => (
        <div key={i}>
          <span>Time (day {i + 1}): </span>{' '}
          {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
          {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
          {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
          {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
        </div>
      ))}
    </div>
  );
  const priceTypeTexts = (
    <span style={{ color: '#616161b0' }}>
      {publicData?.priceType === 'perHour'
        ? 'per hour'
        : publicData?.priceType === 'perChild'
        ? 'per child'
        : publicData?.priceType === 'totalPrice'
        ? 'total price'
        : publicData?.priceType === 'perMorning'
        ? 'per morning'
        : publicData?.priceType === 'perAfternoon'
        ? 'per afternoon'
        : publicData?.priceType === 'perDay'
        ? 'per day'
        : publicData?.priceType === 'perTerm'
        ? 'per term'
        : publicData?.priceType === 'perClass'
        ? 'per class'
        : publicData?.priceType === 'perYear'
        ? 'per year'
        : publicData?.priceType === 'perMonth'
        ? 'per month'
        : publicData?.priceType === 'perAdult'
        ? 'per adult'
        : null}
    </span>
  );

  const priceDisplay = (
    <div className={css.datetimeinfocol}>
      <span>{'Cost: '}</span>
      <span className={css.pricespn}>
        {price ? (price?.amount === 0 ? 'Free' : `€${price?.amount / 100}`) : 'Not Set'}
      </span>
      {` `}
      <span style={{ color: '#616161b0' }}>
        {publicData?.categories?.[0] === 'classes' && publicData?.noOfClasses
          ? `(${publicData?.noOfClasses} classes)`
          : publicData?.categories?.[0] === 'camps' && publicData?.noOfDays
          ? `(${publicData?.noOfDays} ${publicData?.noOfDays > 1 ? 'days' : 'day'})`
          : publicData?.categories?.[0] === 'workshops'
          ? '(1 day)'
          : null}
      </span>
      {price?.amount === 0 ? '' : priceTypeTexts}
    </div>
  );

  const lockedMenuItemMaybe = (key, label) => {
    return (
      <MenuItem key={'Unvailable' + key} className={classNames(css.menuItem, css.lockedMenuItem)}>
        <InlineTextButton
          rootClassName={classNames(menuButtonProps.rootClassName, css.lockedMenuButton)}
          disabled
        >
          {label} <img src={pro} />
        </InlineTextButton>
      </MenuItem>
    );
  };
  const snoozeListing = () => {
    onCloseListing(currentListing.id).then(res => onLoadData(params, search));
  };
  const unSnoozeListing = () => {
    onOpenListing(currentListing.id).then(res => onLoadData(params, search));
  };
  const onDuplicate = event => {
    event.preventDefault();
    event.stopPropagation();
    setLoadingDuplicate(true);
    if (!actionsInProgressListingId) {
      onToggleMenu(null);
      duplicateListing(currentListing)
        .then(res => {
          const id = res?.data?.data?.id?.uuid;
          const slug = res?.data?.data?.attributes?.title;
          setLoadingDuplicate(false);
          setConfirmDuplicate(false);
          history.push(
            createResourceLocatorString(
              'EditListingPage',
              routeConfiguration(),
              { id, slug, type: 'draft', tab: 'categories' },
              {}
            )
          );
        })
        .catch(err => {
          console.log(err);
          setLoadingDuplicate(false);
          setConfirmDuplicate(false);
        });
      // onCloseListing(currentListing.id);
    }
  };
  const editMenuItem = (
    <MenuItem key="edit-listing" className={css.menuItem}>
      <InlineTextButton
        {...menuButtonProps}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            history.push(
              createResourceLocatorString(
                'EditListingPage',
                routeConfiguration(),
                { id, slug, type: editListingLinkType, tab: 'categories' },
                {}
              )
            );
          }
        }}
      >
        Edit
      </InlineTextButton>
    </MenuItem>
  );
  const snoozeMenuItem = isStarter ? (
    lockedMenuItemMaybe('snooze-listing', 'Snooze')
  ) : (
    <MenuItem key="snooze-listing" className={css.menuItem}>
      <InlineTextButton
        {...menuButtonProps}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            snoozeListing();
          }
        }}
      >
        Snooze
      </InlineTextButton>
    </MenuItem>
  );
  const unSnoozeMenuItem = false ? (
    lockedMenuItemMaybe('un-snooze-listing', 'Un-Snooze')
  ) : (
    <MenuItem key="un-snooze-listing" className={css.menuItem}>
      <InlineTextButton
        {...menuButtonProps}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            unSnoozeListing();
          }
        }}
      >
        Un-Snooze
      </InlineTextButton>
    </MenuItem>
  );
  const duplicateMenuItem = isStarter ? (
    lockedMenuItemMaybe('duplicate-listing', 'Duplicate')
  ) : (
    <MenuItem key="duplicate-listing" className={css.menuItem}>
      <InlineTextButton {...menuButtonProps} onClick={() => setConfirmDuplicate(true)}>
        Duplicate
      </InlineTextButton>
    </MenuItem>
  );
  const deleteMenuItem = (
    <MenuItem key="close-listing" className={css.menuItem}>
      <InlineTextButton
        {...menuButtonProps}
        onClick={event => {
          event.preventDefault();
          event.stopPropagation();
          if (!actionsInProgressListingId) {
            onToggleMenu(null);
            onCloseListing(currentListing.id)
              .then(res =>
                axios.post(`${apiBaseUrl()}/api/set-deleted`, {
                  listingId: currentListing.id,
                })
              )
              .then(res => onLoadData(params, search));
          }
        }}
      >
        Delete
      </InlineTextButton>
    </MenuItem>
  );

  const shareMenuItem = (
    <MenuItem key="share-listing" className={css.menuItem}>
      <ShareButton
        listing={currentListing}
        buttonText="Share"
        className={css.menuItemButton}
        id={currentListing?.id?.uuid}
      />
    </MenuItem>
  );
  const publishedMenuContent = (
    <MenuContent rootClassName={css.menuContent}>
      {editMenuItem}
      {shareMenuItem}
      {snoozeMenuItem}
      {duplicateMenuItem}
      {deleteMenuItem}
    </MenuContent>
  );
  const snoozedMenuContent = (
    <MenuContent rootClassName={css.menuContent}>
      {editMenuItem}
      {unSnoozeMenuItem}
      {duplicateMenuItem}
      {deleteMenuItem}
    </MenuContent>
  );
  const pastMenuContent = (
    <MenuContent rootClassName={css.menuContent}>
      {editMenuItem}
      {duplicateMenuItem}
      {deleteMenuItem}
    </MenuContent>
  );
  const draftMenuContent = (
    <MenuContent rootClassName={css.menuContent}>
      {editMenuItem}
      {deleteMenuItem}
    </MenuContent>
  );
  const menuContentMaybe =
    publicData?.searchableTill < moment().valueOf() ? (
      pastMenuContent
    ) : state === 'published' ? (
      publishedMenuContent
    ) : state === 'draft' ? (
      draftMenuContent
    ) : state === 'closed' ? (
      snoozedMenuContent
    ) : (
      <MenuContent rootClassName={css.menuContent}></MenuContent>
    );

  const menuComponent = (
    <Menu
      className={classNames(css.menu, { [css.cardIsOpen]: true })}
      contentPlacementOffset={MENU_CONTENT_OFFSET}
      contentPosition="left"
      useArrow={false}
      onToggleActive={isOpen => {
        const listingOpen = isOpen ? currentListing : null;
        onToggleMenu(listingOpen);
      }}
      isOpen={isMenuOpen}
    >
      <MenuLabel className={css.menuLabel} isOpenClassName={css.listingMenuIsOpen}>
        <BsDot /> <BsDot /> <BsDot />
      </MenuLabel>
      {menuContentMaybe}
    </Menu>
  );

  return (
    <div className={classes}>
      <ManageListingCardTop
        currentListing={currentListing}
        menuComponent={menuComponent}
        listingBookings={listingBookings}
        onTitleClick={onTitleClick}
        currentUser={currentUser}
        intl={intl}
      />
      {!isDraft && (
        <div className={css.dv3}>
          <button type="button" onClick={onClickTransaction}>
            {isTransactionsLoading ? 'Fetching Bookings' : 'Full Customer Details'}
          </button>
        </div>
      )}

      <Modal
        isOpen={confirmDuplicate}
        // isOpen={true}
        onClose={() => setConfirmDuplicate(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        id="ManageListingsPage.duplicationModal"
        usePortal
      >
        <div className={css.cnfDuplicamodal}>
          <p>Duplicating is a great time saver</p>
          <p>
            Change <span>at least 1</span> of the following to successfully publish a new activity
            listing:
          </p>
          <p>
            {' '}
            <span>Date </span> - <span> Time </span> - <span> Age </span> - <span> Location</span>{' '}
          </p>
          <Button
            inProgress={loadingDuplicate}
            disabled={actionsInProgressListingId}
            onClick={onDuplicate}
          >
            I get it. Let's duplicate
          </Button>
        </div>
      </Modal>
    </div>
  );
};

ManageListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  actionsInProgressListingId: null,
  renderSizes: null,
  availabilityEnabled: config.enableAvailability,
};

const { bool, func, shape, string } = PropTypes;

ManageListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  hasClosingError: bool.isRequired,
  hasOpeningError: bool.isRequired,
  intl: intlShape.isRequired,
  listing: propTypes.ownListing.isRequired,
  isMenuOpen: bool.isRequired,
  actionsInProgressListingId: shape({ uuid: string.isRequired }),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  onToggleMenu: func.isRequired,
  availabilityEnabled: bool,

  // Responsive image sizes hint
  renderSizes: string,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

export default compose(withRouter, injectIntl)(ManageListingCardComponent);
