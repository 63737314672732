/**
 * Creates a sortable image grid with children added to the end of the created grid.
 *
 * Example:
 * // images = [{ id: 'tempId', imageId: 'realIdFromAPI', file: File }];
 * <AddImages images={images}>
 *   <input type="file" accept="images/*" onChange={handleChange} />
 * </AddImages>
 */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ImageFromFile, ResponsiveImage, IconSpinner } from '../../components';

import css from './AddImages.module.css';
import RemoveImageButton from './RemoveImageButton';
const MAX_IMAGES = 5;
const ThumbnailWrapper = props => {
  const { className, image, savedImageAltText, onRemoveImage } = props;
  const handleRemoveClick = e => {
    e.stopPropagation();
    // e.preventDefault();
    onRemoveImage(image.id);
  };

  if (image.file) {
    // Add remove button only when the image has been uploaded and can be removed
    const removeButton = image.imageId ? <RemoveImageButton onClick={handleRemoveClick} /> : null;

    // While image is uploading we show overlay on top of thumbnail
    const uploadingOverlay = !image.imageId ? (
      <div className={css.thumbnailLoading}>
        <IconSpinner />
      </div>
    ) : null;

    return (
      <ImageFromFile
        id={image.id}
        className={className}
        rootClassName={css.thumbnail}
        file={image.file}
      >
        {removeButton}
        {uploadingOverlay}
      </ImageFromFile>
    );
  } else {
    const classes = classNames(css.thumbnail, className);
    return (
      <div className={classes}>
        <div className={css.threeToTwoWrapper}>
          <div className={css.aspectWrapper}>
            <ResponsiveImage
              rootClassName={css.rootForImage}
              image={image}
              alt={savedImageAltText}
              variants={['landscape-crop', 'landscape-crop2x']}
            />
          </div>
          <RemoveImageButton onClick={handleRemoveClick} />
        </div>
      </div>
    );
  }
};

ThumbnailWrapper.defaultProps = { className: null };

const { array, func, node, string, object } = PropTypes;

ThumbnailWrapper.propTypes = {
  className: string,
  image: object.isRequired,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

const AddImages = props => {
  const {
    children,
    className,
    thumbnailClassName,
    images,
    savedImageAltText,
    onRemoveImage,
    renderWithin,
    width,
  } = props;
  const classes = classNames(css.root, className);
  const leftImg =
    (width >= 576 && width < 768) || width >= 992 ? Math.abs(MAX_IMAGES - images.length) : 1;
  const childrens = [...Array(leftImg).keys()];

  return (
    <div className={classes}>
      {/* <div className={css.one}>
        <ThumbnailWrapper
          image={images[0]}
          index={0}
          key={images[0].id.uuid || images[0].id}
          className={thumbnailClassName}
          savedImageAltText={savedImageAltText}
          onRemoveImage={onRemoveImage}
        />
      </div>
      <div className={css.two}>
        <ThumbnailWrapper
          image={images[1]}
          index={1}
          key={images[1].id.uuid || images[1].id}
          className={thumbnailClassName}
          savedImageAltText={savedImageAltText}
          onRemoveImage={onRemoveImage}
        />
        <ThumbnailWrapper
          image={images[2]}
          index={2}
          key={images[2].id.uuid || images[2].id}
          className={thumbnailClassName}
          savedImageAltText={savedImageAltText}
          onRemoveImage={onRemoveImage}
        />
      </div> */}
      {images.map((image, index) => {
        return (
          <ThumbnailWrapper
            image={image}
            index={index}
            key={image.id.uuid || image.id}
            className={index === 0 ? css.thumbnail : css.small}
            savedImageAltText={savedImageAltText}
            onRemoveImage={onRemoveImage}
          />
        );
      })}
      {images.length < 5 ? (
        images.length !== 0 ? (
          <React.Fragment>
            {childrens.map(e => (
              <div className={css.addImg} key={e}>
                {children}
              </div>
            ))}
          </React.Fragment>
        ) : (
          <div className={css.singleUpload}>{children}</div>
        )
      ) : null}
    </div>
  );
};

AddImages.defaultProps = { className: null, thumbnailClassName: null, images: [] };

AddImages.propTypes = {
  images: array,
  children: node.isRequired,
  className: string,
  thumbnailClassName: string,
  savedImageAltText: string.isRequired,
  onRemoveImage: func.isRequired,
};

export default AddImages;
