import React, { Component } from 'react';
import { array, arrayOf, bool, func, number, object, string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { BiInfoCircle } from 'react-icons/bi';
import classNames from 'classnames';
import {
  TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY,
  txIsAccepted,
  txIsCanceled,
  txIsDeclined,
  txIsEnquired,
  txIsPaymentExpired,
  txIsPaymentPending,
  txIsRequested,
  txHasBeenDelivered,
  isReviewable,
} from '../../util/transaction';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import { humanizeLineItemCode } from '../../util/data';
import {
  ensureListing,
  ensureTransaction,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { isMobileSafari } from '../../util/userAgent';
import { formatMoney } from '../../util/currency';
import {
  AvatarLarge,
  BookingPanel,
  NamedLink,
  ReviewModal,
  UserDisplayName,
} from '../../components';
import { ReviewForm, SendMessageForm } from '../../forms';
import config from '../../config';

// These are internal components that make this file more readable.
import AddressLinkMaybe from './AddressLinkMaybe';
import BreakdownMaybe from './BreakdownMaybe';
import DetailCardHeadingsMaybe from './DetailCardHeadingsMaybe';
import DetailCardImage from './DetailCardImage';
import FeedSection from './FeedSection';
import SaleActionButtonsMaybe from './SaleActionButtonsMaybe';
import PanelHeading, {
  HEADING_ENQUIRED,
  HEADING_PAYMENT_PENDING,
  HEADING_PAYMENT_EXPIRED,
  HEADING_REQUESTED,
  HEADING_ACCEPTED,
  HEADING_DECLINED,
  HEADING_CANCELED,
  HEADING_DELIVERED,
} from './PanelHeading';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './TransactionPanel.module.css';
import moment from 'moment';
import { AiFillStar, AiOutlineStar } from 'react-icons/ai';
import { createResourceLocatorString } from '../../util/routes';
import { createSlug } from '../../util/urlHelpers';
import routeConfiguration from '../../routeConfiguration';
import { getChildNameFromLineitemCode } from '../../forms/BookingTimeForm/EstimatedBreakdownMaybe';
import { formatListingData } from '../../util/formattedListingData';
import ShareButton from '../ShareButton/ShareButton';
const { Money } = sdkTypes;
// Helper function to get display names for different roles
const displayNames = (currentUser, currentProvider, currentCustomer, intl) => {
  const authorDisplayName = <UserDisplayName user={currentProvider} intl={intl} />;
  const customerDisplayName = <UserDisplayName user={currentCustomer} intl={intl} />;

  let otherUserDisplayName = '';
  let otherUserDisplayNameString = '';
  const currentUserIsCustomer =
    currentUser.id && currentCustomer.id && currentUser.id.uuid === currentCustomer.id.uuid;
  const currentUserIsProvider =
    currentUser.id && currentProvider.id && currentUser.id.uuid === currentProvider.id.uuid;

  if (currentUserIsCustomer) {
    otherUserDisplayName = authorDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentProvider, '');
  } else if (currentUserIsProvider) {
    otherUserDisplayName = customerDisplayName;
    otherUserDisplayNameString = userDisplayNameAsString(currentCustomer, '');
  }

  return {
    authorDisplayName,
    customerDisplayName,
    otherUserDisplayName,
    otherUserDisplayNameString,
  };
};

export class TransactionPanelComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMessageFormFocused: false,
      isReviewModalOpen: false,
      reviewSubmitted: false,
    };
    this.isMobSaf = false;
    this.sendMessageFormName = 'TransactionPanel.SendMessageForm';

    this.onOpenReviewModal = this.onOpenReviewModal.bind(this);
    this.onSubmitReview = this.onSubmitReview.bind(this);
    this.onSendMessageFormFocus = this.onSendMessageFormFocus.bind(this);
    this.onSendMessageFormBlur = this.onSendMessageFormBlur.bind(this);
    this.onMessageSubmit = this.onMessageSubmit.bind(this);
    this.scrollToMessage = this.scrollToMessage.bind(this);
  }

  componentDidMount() {
    this.isMobSaf = isMobileSafari();
  }

  onOpenReviewModal() {
    this.setState({ isReviewModalOpen: true });
  }

  onSubmitReview(values) {
    const { onSendReview, transaction, transactionRole } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const { reviewRating, reviewContent } = values;
    const rating = Number.parseInt(reviewRating, 10);
    onSendReview(transactionRole, currentTransaction, rating, reviewContent)
      .then(r => this.setState({ isReviewModalOpen: false, reviewSubmitted: true }))
      .catch(e => {
        // Do nothing.
      });
  }

  onSendMessageFormFocus() {
    this.setState({ sendMessageFormFocused: true });
    if (this.isMobSaf) {
      // Scroll to bottom
      window.scroll({ top: document.body.scrollHeight, left: 0, behavior: 'smooth' });
    }
  }

  onSendMessageFormBlur() {
    this.setState({ sendMessageFormFocused: false });
  }

  onMessageSubmit(values, form) {
    const message = values.message ? values.message.trim() : null;
    const { transaction, onSendMessage } = this.props;
    const ensuredTransaction = ensureTransaction(transaction);

    if (!message) {
      return;
    }
    onSendMessage(ensuredTransaction.id, message)
      .then(messageId => {
        form.reset();
        this.scrollToMessage(messageId);
      })
      .catch(e => {
        // Ignore, Redux handles the error
      });
  }

  scrollToMessage(messageId) {
    const selector = `#msg-${messageId.uuid}`;
    const el = document.querySelector(selector);
    if (el) {
      el.scrollIntoView({
        block: 'start',
        behavior: 'smooth',
      });
    }
  }

  render() {
    const {
      rootClassName,
      className,
      currentUser,
      transaction,
      totalMessagePages,
      oldestMessagePageFetched,
      messages,
      initialMessageFailed,
      savePaymentMethodFailed,
      fetchMessagesInProgress,
      fetchMessagesError,
      sendMessageInProgress,
      sendMessageError,
      sendReviewInProgress,
      sendReviewError,
      onFetchTimeSlots,
      onManageDisableScrolling,
      onShowMoreMessages,
      transactionRole,
      intl,
      onAcceptSale,
      onDeclineSale,
      acceptInProgress,
      declineInProgress,
      acceptSaleError,
      declineSaleError,
      onSubmitBookingRequest,
      monthlyTimeSlots,
      nextTransitions,
      onFetchTransactionLineItems,
      lineItems,
      fetchLineItemsInProgress,
      fetchLineItemsError,
      history,
    } = this.props;
    const currentTransaction = ensureTransaction(transaction);
    const isDeposit =
      currentTransaction.attributes.protectedData?.payingOptions === 'depositPayment';
    const currentListing = ensureListing(currentTransaction.listing);
    const depositOptionAmount = currentListing.attributes?.publicData?.depositOptionAmount;
    const depositPrice = isDeposit
      ? new Money(depositOptionAmount?.amount * 100, depositOptionAmount?.currency)
      : null;
    const formattedDeposit = depositPrice ? formatMoney(intl, depositPrice) : null;
    const currentProvider = ensureUser(currentTransaction.provider);
    const currentCustomer = ensureUser(currentTransaction.customer);
    const isCustomer = transactionRole === 'customer';
    const isProvider = transactionRole === 'provider';

    const listingLoaded = !!currentListing.id;
    const listingDeleted = listingLoaded && currentListing.attributes.deleted;
    const iscustomerLoaded = !!currentCustomer.id;
    const isCustomerBanned = iscustomerLoaded && currentCustomer.attributes.banned;
    const isCustomerDeleted = iscustomerLoaded && currentCustomer.attributes.deleted;
    const isProviderLoaded = !!currentProvider.id;
    const isProviderBanned = isProviderLoaded && currentProvider.attributes.banned;
    const isProviderDeleted = isProviderLoaded && currentProvider.attributes.deleted;

    const stateDataFn = tx => {
      if (txIsEnquired(tx)) {
        const transitions = Array.isArray(nextTransitions)
          ? nextTransitions.map(transition => {
              return transition.attributes.name;
            })
          : [];
        const hasCorrectNextTransition =
          transitions.length > 0 && transitions.includes(TRANSITION_REQUEST_PAYMENT_AFTER_ENQUIRY);
        return {
          headingState: HEADING_ENQUIRED,
          showBookingPanel: isCustomer && !isProviderBanned && hasCorrectNextTransition,
        };
      } else if (txIsPaymentPending(tx)) {
        return {
          headingState: HEADING_PAYMENT_PENDING,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsPaymentExpired(tx)) {
        return {
          headingState: HEADING_PAYMENT_EXPIRED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsRequested(tx)) {
        return {
          headingState: HEADING_REQUESTED,
          showDetailCardHeadings: isCustomer,
          showSaleButtons: isProvider && !isCustomerBanned,
        };
      } else if (txIsAccepted(tx)) {
        return {
          headingState: HEADING_ACCEPTED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else if (txIsDeclined(tx)) {
        return {
          headingState: HEADING_DECLINED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txIsCanceled(tx)) {
        return {
          headingState: HEADING_CANCELED,
          showDetailCardHeadings: isCustomer,
        };
      } else if (txHasBeenDelivered(tx)) {
        return {
          headingState: HEADING_DELIVERED,
          showDetailCardHeadings: isCustomer,
          showAddress: isCustomer,
        };
      } else {
        return { headingState: 'unknown' };
      }
    };
    const stateData = stateDataFn(currentTransaction);
    const deletedListingTitle = intl.formatMessage({
      id: 'TransactionPanel.deletedListingTitle',
    });
    const {
      authorDisplayName,
      customerDisplayName,
      otherUserDisplayName,
      otherUserDisplayNameString,
    } = displayNames(currentUser, currentProvider, currentCustomer, intl);

    const { publicData, geolocation } = currentListing.attributes;
    const location = publicData && publicData.location ? publicData.location : {};
    const listingTitle = currentListing.attributes.deleted
      ? deletedListingTitle
      : currentListing.attributes.title;

    const unitType = config.bookingUnitType;
    const isNightly = unitType === LINE_ITEM_NIGHT;
    const isDaily = unitType === LINE_ITEM_DAY;

    const unitTranslationKey = isNightly
      ? 'TransactionPanel.perNight'
      : isDaily
      ? 'TransactionPanel.perDay'
      : 'TransactionPanel.perUnit';

    const price = currentListing.attributes.price;
    const bookingSubTitle = price
      ? `${formatMoney(intl, price)} ${intl.formatMessage({ id: unitTranslationKey })}`
      : '';

    const firstImage =
      currentListing.images && currentListing.images.length > 0
        ? currentListing.images[0].attributes.variants['landscape-crop2x'].url
        : null;
    const saleButtons = (
      <SaleActionButtonsMaybe
        showButtons={stateData.showSaleButtons}
        acceptInProgress={acceptInProgress}
        declineInProgress={declineInProgress}
        acceptSaleError={acceptSaleError}
        declineSaleError={declineSaleError}
        onAcceptSale={() => onAcceptSale(currentTransaction.id)}
        onDeclineSale={() => onDeclineSale(currentTransaction.id)}
      />
    );

    const showSendMessageForm =
      !isCustomerBanned && !isCustomerDeleted && !isProviderBanned && !isProviderDeleted;

    const sendMessagePlaceholder = intl.formatMessage(
      { id: 'TransactionPanel.sendMessagePlaceholder' },
      { name: otherUserDisplayNameString }
    );

    const sendingMessageNotAllowed = intl.formatMessage({
      id: 'TransactionPanel.sendingMessageNotAllowed',
    });

    const paymentMethodsPageLink = (
      <NamedLink name="PaymentMethodsPage">
        <FormattedMessage id="TransactionPanel.paymentMethodsPageLink" />
      </NamedLink>
    );

    const classes = classNames(rootClassName || css.root, className);
    const slug = createSlug(currentListing.attributes.title);

    const handleShare = async (title, urlShr) => {
      const { listingString } = formatListingData(currentListing);

      if (typeof window.navigator.share !== 'undefined') {
        try {
          await navigator.share({
            title: title,
            text: listingString,
            url: urlShr,
          });
          console.log('Content shared successfully');
        } catch (error) {
          console.error('Error sharing:', error);
          if (viewport.width > 768) this.setState({ openShareModal: true });
        }
      } else {
        this.setState({ openShareModal: true });
      }
    };
    const isClickableLink = publicData?.searchableTill > new Date().getTime();
    return (
      <div className={classes}>
        <span className={css.bhspn}>Booking History</span>
        <div className={css.parent}>
          <p className={css.typ}>Thank you for booking</p>
          <div className={css.container}>
            <div className={css.dvone}>
              <div>
                <img src={firstImage} />
              </div>
              <div>
                <p
                  className={classNames(css.listTitle, { [css.isPlink]: isClickableLink })}
                  onClick={() =>
                    isClickableLink &&
                    history.push(
                      createResourceLocatorString(
                        'ListingPage',
                        routeConfiguration(),
                        {
                          id: currentListing?.id?.uuid,
                          slug,
                        },
                        {}
                      )
                    )
                  }
                >
                  {listingTitle}
                </p>
                <p>
                  {currentProvider?.attributes?.profile.publicData?.bname}-
                  {publicData?.activityMode === 'online'
                    ? 'ONLINE'
                    : publicData?.city
                    ? publicData?.city?.toUpperCase()
                    : publicData?.county?.toUpperCase()}
                  {/* {currentProvider?.attributes?.profile.publicData?.county?.toUpperCase()} */}
                </p>
                <div className={css.linfodv}>
                  <span>Date: </span>
                  <span>
                    {currentListing?.attributes?.publicData?.categories[0] === 'workshops'
                      ? `${moment(currentListing?.attributes?.publicData.startDate).format(
                          'dddd, MMM Do, YYYY'
                        )}`
                      : `${moment(currentListing?.attributes?.publicData.startDate).format(
                          'MMM Do'
                        )} - ${moment(currentListing?.attributes?.publicData.endDate).format(
                          'MMM Do, YYYY'
                        )}`}
                  </span>
                </div>
                <div className={css.linfodv}>
                  <span>Time: </span>
                  <span>
                    {currentListing?.attributes?.publicData.startTime?.hour >= 10
                      ? currentListing?.attributes?.publicData.startTime?.hour
                      : `0${currentListing?.attributes?.publicData.startTime?.hour}`}
                    .
                    {currentListing?.attributes?.publicData.startTime?.minute >= 10
                      ? currentListing?.attributes?.publicData.startTime?.minute
                      : `0${currentListing?.attributes?.publicData.startTime?.minute}`}{' '}
                    –{' '}
                    {currentListing?.attributes?.publicData.endTime?.hour >= 10
                      ? currentListing?.attributes?.publicData.endTime?.hour
                      : `0${currentListing?.attributes?.publicData.endTime?.hour}`}
                    .
                    {currentListing?.attributes?.publicData.endTime?.minute >= 10
                      ? currentListing?.attributes?.publicData.endTime?.minute
                      : `0${currentListing?.attributes?.publicData.endTime?.minute}`}
                  </span>
                </div>
                <div className={css.linfodv}>
                  <span>Age: </span>
                  <span>{`${currentListing?.attributes?.publicData.age[0]} - ${
                    currentListing?.attributes?.publicData.age[
                      currentListing?.attributes?.publicData.age?.length - 1
                    ]
                  }`}</span>
                </div>
                <div className={css.linfodv}>
                  <span>Cost: </span>
                  <span>
                    €{currentListing?.attributes?.price.amount / 100}{' '}
                    {currentListing?.attributes?.publicData?.categories[0] === 'workshops'
                      ? `(1 day)`
                      : currentListing?.attributes?.publicData?.categories[0] === 'camps'
                      ? `${currentListing?.attributes?.publicData?.noOfDays} ${
                          currentListing?.attributes?.publicData?.noOfDays > 1 ? 'days' : 'day'
                        }`
                      : currentListing?.attributes?.publicData?.categories[0] === 'classes'
                      ? `(${currentListing?.attributes?.publicData?.noOfClasses} ${
                          publicData?.classType ? publicData?.classType?.label : 'classes'
                        })`
                      : null}
                  </span>
                </div>
              </div>
            </div>
            {isReviewable(currentTransaction, currentUser?.id?.uuid) &&
            currentTransaction?.reviews?.length === 0 ? (
              <div className={css.rvdv}>
                <div>
                  <h2>
                    Hi {customerDisplayName}, your booking is complete, would you like to review the
                    activity and the activity provider?{' '}
                    <span>
                      Reviews help future activity buyers and the provider. Suggestions include:
                      staff, venue, value for money, etc.
                    </span>
                  </h2>
                  <ReviewForm
                    onSubmit={this.onSubmitReview}
                    reviewSent={this.state.reviewSubmitted}
                    sendReviewInProgress={sendReviewInProgress}
                    sendReviewError={sendReviewError}
                  />
                </div>
              </div>
            ) : currentTransaction?.reviews?.length > 0 ? (
              <div>
                <div className={css.rvdv}>
                  <h2>Reviews</h2>
                  {currentTransaction?.reviews?.map(r => {
                    // if (r.attributes.type === 'ofProvider')
                    return (
                      <div className={css.echrv}>
                        <div>
                          <img
                            alt=""
                            src={
                              currentCustomer?.profileImage?.attributes?.variants['square-small2x']
                                .url
                            }
                          />
                          <div className={css.strdv}>
                            {customerDisplayName}
                            <span>
                              {[...Array(5).keys()].map((s, i) =>
                                i <= r.attributes.rating - 1 ? <AiFillStar /> : <AiOutlineStar />
                              )}
                            </span>
                          </div>
                        </div>
                        <p>{r.attributes.content}</p>
                      </div>
                    );
                    return (
                      <div className={`${css.authrev} ${css.echrv}`}>
                        <div>
                          <img
                            alt=""
                            src={
                              currentProvider?.profileImage?.attributes?.variants['square-small2x']
                                .url
                            }
                          />
                          <div className={css.strdv}>
                            {authorDisplayName}
                            <span>
                              {[...Array(5).keys()].map((s, i) =>
                                i <= r.attributes.rating - 1 ? <AiFillStar /> : <AiOutlineStar />
                              )}
                            </span>
                          </div>
                        </div>
                        <p>{r.attributes.content}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}

            <div className={css.dvto}>
              <div>
                <span>ORDER NUMBER</span>
                <span>{currentTransaction.id.uuid}</span>
              </div>
              <div>
                <span>Order Status:</span>
                <span>
                  {currentTransaction.attributes.lineItems.filter(
                    l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                  ).length === 0
                    ? 'Paid in full'
                    : 'Paid Deposit'}
                </span>
              </div>
              <div>
                <span>Date Placed:</span>
                <span>
                  {moment(currentTransaction?.attributes?.createdAt).format('MMMM Do, YYYY')}
                </span>
              </div>
            </div>
            <div className={css.dvth}>
              {currentTransaction.attributes.lineItems
                .filter(item => item.code.includes('/child'))
                .map(item => {
                  const code = item.code;
                  const isTrial = item.code.includes('trial');
                  const childName = isTrial
                    ? code.replace('line-item/trial/child/', '').replace('-', ' ')
                    : code.replace('line-item/child/', '').replace('-', ' ');
                  const label = isTrial ? (
                    <>
                      {childName} <span className={css.greyspan}>(trial)</span>
                    </>
                  ) : (
                    <>
                      {childName}{' '}
                      {isDeposit ? <span className={css.greyspan}>(deposit)</span> : null}
                    </>
                  );
                  const formattedTotal = formatMoney(intl, item.lineTotal);
                  return (
                    <div key={code}>
                      <span>{label}</span>
                      <span>{isDeposit && !isTrial ? formattedDeposit : formattedTotal}</span>
                    </div>
                  );
                })}
              {currentTransaction.attributes.lineItems
                .filter(
                  item =>
                    item.code.includes('line-item/early-drop-off-') ||
                    item.code.includes('line-item/late-pick-up-')
                )
                .map(item => {
                  const code = item.code;
                  const type = code.includes('line-item/late-pick-up-')
                    ? 'late-pick-up'
                    : 'early-drop-off';
                  const childName = getChildNameFromLineitemCode(code, type);
                  const label = code.includes('line-item/late-pick-up-') ? (
                    <>
                      Late Pick Up <span className={css.greyspan}>({childName})</span>
                    </>
                  ) : (
                    <>
                      Early Drop Off <span className={css.greyspan}>({childName})</span>
                    </>
                  );
                  // quantity && quantity > 1
                  // ? `${humanizeLineItemCode(item.code)} x ${quantity}`
                  // :
                  const formattedTotal = formatMoney(intl, item.lineTotal);
                  return (
                    <div key={code}>
                      <span>{label}</span>
                      <span>{formattedTotal}</span>
                    </div>
                  );
                })}
              <div>
                <span>
                  {currentTransaction?.attributes?.lineItems.find(l =>
                    l.code.includes('service-fee')
                  ) && 'Ticketing Fee'}
                  <span className={css.tooltipContainer}>
                    <span className={css.tooltip}>
                      <BiInfoCircle />
                    </span>
                    <span className={css.tooltipInfo}>
                      This helps us run our platform plus includes credit card booking fees.
                    </span>
                  </span>
                </span>
                <span>
                  {currentTransaction?.attributes?.lineItems.find(l =>
                    l.code.includes('service-fee')
                  ) &&
                    formatMoney(
                      intl,
                      currentTransaction?.attributes?.lineItems.find(l =>
                        l.code.includes('service-fee')
                      )?.lineTotal
                    )}
                </span>
              </div>
              <div className={css.tamp}>
                {' '}
                <span>Total Price Due:</span>
                <span>
                  {currentTransaction &&
                  currentTransaction?.attributes?.lineItems?.find(
                    l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                  )?.lineTotal &&
                  currentTransaction?.attributes?.lineItems?.find(
                    l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                  )?.lineTotal.amount
                    ? formatMoney(
                        intl,
                        new Money(
                          currentTransaction?.attributes?.payinTotal?.amount +
                            currentTransaction?.attributes?.lineItems?.find(
                              l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                            )?.lineTotal.amount *
                              -1,
                          config.currency
                        )
                      )
                    : formatMoney(intl, currentTransaction?.attributes?.payinTotal)}
                </span>
              </div>
              <div>
                {' '}
                <span>Amount Paid:</span>
                <span>{formatMoney(intl, currentTransaction?.attributes?.payinTotal)}</span>
              </div>
            </div>
            <div className={css.dvfr}>
              <div>
                <span>Balance due</span>
                <span>
                  {currentTransaction &&
                  currentTransaction?.attributes?.lineItems?.find(
                    l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                  )?.lineTotal &&
                  currentTransaction?.attributes?.lineItems?.find(
                    l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                  )?.lineTotal.amount
                    ? formatMoney(
                        intl,
                        new Money(
                          currentTransaction?.attributes?.lineItems?.find(
                            l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                          )?.lineTotal.amount * -1,
                          config.currency
                        )
                      )
                    : '€0.00'}
                </span>
              </div>
            </div>
            <div className={css.dvfv}>
              <p>PAID BY</p>
              <p>{currentCustomer?.attributes?.profile.displayName}</p>
            </div>
          </div>
          <div className={css.btndv}>
            <button type="button" onClick={() => history.goBack()}>
              Back
            </button>
            <div>
              <ShareButton listing={currentListing} buttonText="Share" />

              <button
                type="button"
                onClick={() => {
                  !currentUser?.attributes?.profile?.publicData?.isProvider
                    ? history.push(
                        createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                      )
                    : null;
                }}
              >
                New Search
              </button>
            </div>
          </div>
        </div>

        {/* <ReviewModal
          id="ReviewOrderModal"
          isOpen={this.state.isReviewModalOpen}
          onCloseModal={() => this.setState({ isReviewModalOpen: false })}
          onManageDisableScrolling={onManageDisableScrolling}
          onSubmitReview={this.onSubmitReview}
          revieweeName={otherUserDisplayName}
          reviewSent={this.state.reviewSubmitted}
          sendReviewInProgress={sendReviewInProgress}
          sendReviewError={sendReviewError}
        /> */}
      </div>
    );
  }
}

TransactionPanelComponent.defaultProps = {
  rootClassName: null,
  className: null,
  currentUser: null,
  acceptSaleError: null,
  declineSaleError: null,
  fetchMessagesError: null,
  initialMessageFailed: false,
  savePaymentMethodFailed: false,
  sendMessageError: null,
  sendReviewError: null,
  monthlyTimeSlots: null,
  nextTransitions: null,
  lineItems: null,
  fetchLineItemsError: null,
};

TransactionPanelComponent.propTypes = {
  rootClassName: string,
  className: string,

  currentUser: propTypes.currentUser,
  transaction: propTypes.transaction.isRequired,
  totalMessagePages: number.isRequired,
  oldestMessagePageFetched: number.isRequired,
  messages: arrayOf(propTypes.message).isRequired,
  initialMessageFailed: bool,
  savePaymentMethodFailed: bool,
  fetchMessagesInProgress: bool.isRequired,
  fetchMessagesError: propTypes.error,
  sendMessageInProgress: bool.isRequired,
  sendMessageError: propTypes.error,
  sendReviewInProgress: bool.isRequired,
  sendReviewError: propTypes.error,
  onFetchTimeSlots: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onShowMoreMessages: func.isRequired,
  onSendMessage: func.isRequired,
  onSendReview: func.isRequired,
  onSubmitBookingRequest: func.isRequired,
  monthlyTimeSlots: object,
  nextTransitions: array,

  // Sale related props
  onAcceptSale: func.isRequired,
  onDeclineSale: func.isRequired,
  acceptInProgress: bool.isRequired,
  declineInProgress: bool.isRequired,
  acceptSaleError: propTypes.error,
  declineSaleError: propTypes.error,

  // line items
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape,
};

const TransactionPanel = injectIntl(TransactionPanelComponent);

export default TransactionPanel;
