import React, { useEffect, useState } from 'react';
import IconSpinner from '../IconSpinner/IconSpinner';
import Modal from '../Modal/Modal';
import axios from 'axios';
import css from './ImageGalleryModal.module.css';
import { apiBaseUrl } from '../../util/api';
import InfiniteScroll from 'react-infinite-scroll-component';

const ImageGalleryModal = props => {
  const {
    modalId,
    onImageClick,
    isOpen,
    onModalClose,
    onManageDisableScrolling,
    galleryImgLoading,
    error,
  } = props;
  const [uploadImageUrls, setUploadImageUrls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const getFiles = startAfter => {
    setLoading(true);
    const url = `${apiBaseUrl()}/fileshare/hit-s3`;
    axios
      .post(url, { startAfter })
      .then(res => {
        const imgArr = res.data.data?.length > 0 ? res.data.data : [];
        setUploadImageUrls([...uploadImageUrls, ...imgArr]);
        setLoading(false);
        setHasMore(res.data.hasMore);
      })
      .catch(err => {
        console.log(err);
        setUploadImageUrls([]);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (isOpen && uploadImageUrls?.length < 1 && !loading) {
      getFiles('');
    }
  });
  return (
    <Modal
      id={modalId || 'GalleryModal'}
      // contentClassName={css.modalContent}
      isOpen={isOpen}
      onClose={onModalClose}
      onManageDisableScrolling={onManageDisableScrolling}
      showLogo={false}
    >
      <div className={css.modaldv}>
        <p className={css.gallp}>
          <span>WELCOME TO OUR LIBRARY.</span> Add <span>3 - 5 images</span> for this listing. The
          first image you pick will be your feature image. Come back and edit anytime.
        </p>
        {error ? (
          <span className={css.errspn}>{error.message || 'Failed to fetch image'}</span>
        ) : null}
        <div className={css.imgContainer}>
          {loading && !uploadImageUrls?.length ? (
            <IconSpinner />
          ) : (
            <InfiniteScroll
              dataLength={uploadImageUrls.length}
              next={() => {
                const lastItem =
                  uploadImageUrls?.length > 0 ? uploadImageUrls[uploadImageUrls.length - 1] : '';
                getFiles(lastItem);
              }}
              hasMore={hasMore}
              loader={
                <span className={css.infiLoader}>
                  <IconSpinner />
                </span>
              }
              height={400}
              endMessage={
                <p className={`${css.endOfResMsg} ${css.infiLoader}`}>
                  <b>Yeah! You have seen it all</b>
                </p>
              }
              className={css.infiniteScroll}
            >
              {uploadImageUrls?.map((i, idx) => (
                // <div>
                <div className={css.imggl} key={i}>
                  {galleryImgLoading === i ? (
                    <span>
                      <IconSpinner />
                    </span>
                  ) : null}
                  <img alt="" key={idx} src={i} onClick={() => onImageClick(i)} />
                </div>

                // </div>
              ))}
            </InfiniteScroll>
            //    <InfiniteScroll

            //  >
          )}
        </div>
        {/* <div className={css.pagination}>
          <button
            onClick={() => {
              const lastItem =
                uploadImageUrls?.length > 0 ? uploadImageUrls[uploadImageUrls.length - 1] : '';
              getFiles(lastItem);
            }}
            type="button"
          >
            Load More
          </button>
        </div> */}
      </div>
    </Modal>
  );
};

export default ImageGalleryModal;
