import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { isScrollingDisabled, manageDisableScrolling } from '../../ducks/UI.duck';
import {
  ManageListingCard,
  Page,
  PaginationLinks,
  UserNav,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  IconSpinner,
  Modal,
} from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TopbarContainer } from '../../containers';
import { withRouter } from 'react-router-dom';
import { closeListing, openListing, getOwnListingsById, loadData } from './ManageListingsPage.duck';
import css from './ManageListingsPage.module.css';
import { createInstance } from '../../util/sdkLoader';
import config from '../../config';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import classNames from 'classnames';
import ManageListingCardTop from '../../components/ManageListingCard/ManageListingCardTop';
import moment from 'moment';
import DownloadListComponent from '../../components/DownloadListComponent/DownloadListComponent';
const { UUID } = sdkTypes;
const toDataURL = url =>
  fetch(url)
    .then(response => response.blob())
    .then(
      blob =>
        new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        })
    );

const dataURLtoFile = (dataurl, filename) => {
  var arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = window !== undefined || (window !== null && window.atob(arr[1])),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};
export const urlToFile = url => {
  return fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'image/jpeg', // Set the correct content type
      // 'x-amz-acl': 'public-read', // Set the appropriate ACL
    },
  })
    .then(response => {
      return response.arrayBuffer();
    })
    .then(res => {
      const filename = 'temp.jpg';
      const file = new File([res], filename, { type: 'image/jpg' });
      return file;
    });
};
export class ManageListingsPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      listingMenuOpen: null,
      currentTab: props.params.tab,
      sdkInstance: null,
      isAllTransactionOpen: false,
      isTransactionsLoading: false,
      transactions: [],
      activeListing: {},
      listingBookings: 0,
      renderWaitingTable: false,
    };
    this.onToggleMenu = this.onToggleMenu.bind(this);
    this.duplicateListing = this.duplicateListing.bind(this);
    this.onGetAndSetTransactions = this.onGetAndSetTransactions.bind(this);
    this.setActiveListing = this.setActiveListing.bind(this);
    this.sdkRef = React.createRef();
  }

  onToggleMenu(listing) {
    this.setState({ listingMenuOpen: listing });
  }
  componentDidMount() {
    this.sdkRef.current = true;
  }
  setActiveListing(listing) {
    this.setState({ activeListing: listing });
  }
  componentDidUpdate() {
    if (this.state.sdkInstance === null && this.sdkRef.current) {
      this.sdkRef.current = false;
      this.setState({ sdkInstance: createInstance({ clientId: config.sdk.clientId }) });
    }
  }
  async duplicateListing(listing) {
    const listingWithImages = await this.state.sdkInstance.listings.show({
      id: listing.id.uuid,
      include: ['images'],
    });
    const images = listingWithImages.data.data.relationships.images.data
      .filter(i => i.type === 'image')
      .map(i => i.id.uuid);

    const imagesUrl = listingWithImages.data.included
      .filter(i => images.includes(i.id.uuid))
      .map(i => i.attributes.variants.default.url);

    const imageFiles = await Promise.all(
      imagesUrl.map(async url => {
        const file = await urlToFile(url);
        return file;
      })
    );
    const newImgIds = await Promise.all(
      imageFiles.map(async f => {
        const data = await this.state.sdkInstance.images.upload({
          image: f,
        });
        return data.data.data.id.uuid;
      })
    );

    // const uploadFiles = await imageFiles;
    const newListingDatas = {
      // title: '(copy) ' + listing.attributes.title,
      title: listing.attributes.title,
      description: listing.attributes.description,
      geolocation: listing.attributes.geolocation,
      price: listing.attributes.price,
      availabilityPlan: listing.attributes.availabilityPlan,
      privateData: listing.attributes.privateData,
      publicData: { isDuplicate: true, ...listing.attributes.publicData },
      images: newImgIds,
    };
    return this.state.sdkInstance.ownListings.createDraft(newListingDatas, {
      expand: true,
      include: ['images'],
    });
    // return this.state.sdkInstance.ownListings.addImage(
    //   {
    //     id: '63e838e2-1cd7-4524-9eb6-615078cf04ae',
    //     imageId: '63e60fba-cb7b-44ca-b730-1050d8f9fe14',
    //   },
    //   { expand: true, include: ['images'] }
    // );
  }
  async onGetAndSetTransactions(listing, listingBookings) {
    const url = `${apiBaseUrl()}/api/listing-transactions`;
    const data = { listingId: listing.id.uuid };
    this.setState({ isTransactionsLoading: true, activeListing: listing, listingBookings });
    return axios
      .post(url, data)
      .then(resp => {
        this.setState({
          isTransactionsLoading: false,
          transactions: resp.data.data.data,
          isAllTransactionOpen: true,
        });
      })
      .catch(err => {
        this.setState({ isTransactionsLoading: false });
        console.log(err);
      });
  }
  render() {
    const {
      closingListing,
      closingListingError,
      listings,
      onCloseListing,
      onOpenListing,
      openingListing,
      openingListingError,
      pagination,
      queryInProgress,
      queryListingsError,
      queryParams,
      scrollingDisabled,
      intl,
      currentUser,
      params,
      history,
      onLoadData,
      onManageDisableScrolling,
    } = this.props;
    const { tab } = params;
    const isStarter =
      currentUser?.attributes?.profile?.privateData?.subscription?.activePlan === 'starter';
    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const listingsAreLoaded = !queryInProgress && hasPaginationInfo;
    const loadingResults = (
      <h2>
        <FormattedMessage id="ManageListingsPage.loadingOwnListings" />
      </h2>
    );

    const queryError = (
      <h2 className={css.error}>
        <FormattedMessage id="ManageListingsPage.queryError" />
      </h2>
    );

    const noResults =
      listingsAreLoaded && pagination.totalItems === 0 ? (
        <h1 className={css.title}>
          <FormattedMessage id="ManageListingsPage.noResults" />
        </h1>
      ) : null;

    const heading =
      listingsAreLoaded && pagination.totalItems > 0 ? (
        <h1 className={css.title}>
          <FormattedMessage
            id="ManageListingsPage.youHaveListings"
            values={{ count: pagination.totalItems }}
          />
        </h1>
      ) : (
        noResults
      );

    const page = queryParams ? queryParams.page : 1;
    const paginationLinks =
      listingsAreLoaded && pagination && pagination.totalPages > 1 ? (
        <PaginationLinks
          className={css.pagination}
          pageName="ManageListingsPage"
          pageSearchParams={{ page }}
          pagePathParams={{ tab }}
          pagination={pagination}
        />
      ) : null;

    const listingMenuOpen = this.state.listingMenuOpen;
    const closingErrorListingId = !!closingListingError && closingListingError.listingId;
    const openingErrorListingId = !!openingListingError && openingListingError.listingId;

    const title = intl.formatMessage({ id: 'ManageListingsPage.title' });

    const panelWidth = 62.5;
    // Render hints for responsive image
    const renderSizes = [
      `(max-width: 767px) 100vw`,
      `(max-width: 1920px) ${panelWidth / 2}vw`,
      `${panelWidth / 3}vw`,
    ].join(', ');
    const draftListingsMaybe = (
      <div>
        {/* <div className={css.headdiv}> */}
        <h2 className={css.lhead}>
          {tab === 'allListings'
            ? 'All Listings'
            : tab === 'activeListings'
            ? 'Active Listings'
            : tab === 'draftListings'
            ? 'Unfinished Listings'
            : tab === 'snoozedListings'
            ? 'Snoozed Listings'
            : tab === 'pastListings'
            ? 'Past Listings'
            : null}
          {queryInProgress ? <IconSpinner /> : null}
        </h2>{' '}
        {queryListingsError ? (
          <span className={css.errorspn}>Unable to fetch listings, Try refreshing the page</span>
        ) : null}
        {/* <div className={css.spin}></div> */}
        {/* </div> */}
        {listingsAreLoaded && pagination.totalItems === 0 ? (
          <p className={css.noListings}>No Listings</p>
        ) : (
          <div className={css.draftListings}>
            {listings.map(l => (
              <ManageListingCard
                className={css.listingCard}
                key={l.id.uuid}
                listing={l}
                isMenuOpen={!!listingMenuOpen && listingMenuOpen.id.uuid === l.id.uuid}
                actionsInProgressListingId={openingListing || closingListing}
                onToggleMenu={this.onToggleMenu}
                onCloseListing={onCloseListing}
                onOpenListing={onOpenListing}
                hasOpeningError={openingErrorListingId.uuid === l.id.uuid}
                hasClosingError={closingErrorListingId.uuid === l.id.uuid}
                renderSizes={renderSizes}
                currentUser={currentUser}
                params={params}
                onLoadData={onLoadData}
                onManageDisableScrolling={onManageDisableScrolling}
                duplicateListing={this.duplicateListing}
                isStarter={isStarter}
                isTransactionsLoading={this.state.isTransactionsLoading}
                transactions={this.state.transactions}
                onGetAndSetTransactions={this.onGetAndSetTransactions}
              />
            ))}
          </div>
        )}
      </div>
    );
    const tabsMaybe = (
      <div className={css.tabsdiv}>
        <div
          className={classNames(css.btnwrapper, { [css.activeWrapper]: tab === 'activeListings' })}
        >
          <button
            onClick={() => {
              tab !== 'activeListings' &&
                history.push(
                  createResourceLocatorString(
                    'ManageListingsPage',
                    routeConfiguration(),
                    { tab: 'activeListings' },
                    {}
                  )
                );
            }}
          >
            Active Listings
          </button>
        </div>
        <div
          className={classNames(css.btnwrapper, { [css.activeWrapper]: tab === 'draftListings' })}
        >
          <button
            onClick={() => {
              tab !== 'draftListings' &&
                history.push(
                  createResourceLocatorString(
                    'ManageListingsPage',
                    routeConfiguration(),
                    { tab: 'draftListings' },
                    {}
                  )
                );
            }}
          >
            Unfinished & Duplicated Listings
          </button>
        </div>
        <div
          className={classNames(css.btnwrapper, { [css.activeWrapper]: tab === 'snoozedListings' })}
        >
          <button
            onClick={() => {
              tab !== 'snoozedListings' &&
                history.push(
                  createResourceLocatorString(
                    'ManageListingsPage',
                    routeConfiguration(),
                    { tab: 'snoozedListings' },
                    {}
                  )
                );
            }}
          >
            Snoozed Listings
          </button>
        </div>
        <div
          className={classNames(css.btnwrapper, { [css.activeWrapper]: tab === 'pastListings' })}
        >
          <button
            onClick={() => {
              tab !== 'pastListings' &&
                history.push(
                  createResourceLocatorString(
                    'ManageListingsPage',
                    routeConfiguration(),
                    { tab: 'pastListings' },
                    {}
                  )
                );
            }}
          >
            Past Listings
          </button>
        </div>
      </div>
    );
    const perChildTransaction = this.state.transactions?.reduce((final, t) => {
      const childArr = t.attributes.protectedData?.childDetails;
      const objArr = childArr
        ? childArr?.map(c => ({
            childId: `${c.id}+${t.id.uuid}`,
            childDetails: c,
            transaction: t,
          }))
        : [];
      return [...final, ...objArr];
    }, []);

    return (
      <Page title={title} scrollingDisabled={scrollingDisabled}>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="ManageListingsPage" />
            <UserNav selectedPageName="ManageListingsPage" />
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <h1 className={css.wlmsg}>
              Welcome to your dashboard {currentUser?.attributes.profile.firstName}!
            </h1>

            <div className={css.listingPanel}>
              <div className={css.listingCards}>
                {tabsMaybe}
                {draftListingsMaybe}
              </div>
              {/* {queryInProgress ? loadingResults : null} */}
              {queryListingsError ? queryError : null}
              {paginationLinks}
            </div>
            <Modal
              isOpen={this.state.isAllTransactionOpen}
              // isOpen={true}
              onClose={() => this.setState({ isAllTransactionOpen: false })}
              onManageDisableScrolling={onManageDisableScrolling}
              id="ManageListingsPage.transactionModal"
              usePortal
            >
              <div className={css.mmdiv}>
                <div className={css.smcrd}>
                  <ManageListingCardTop
                    currentListing={this.state.activeListing}
                    listingBookings={this.state.listingBookings}
                    onTitleClick={() => {}}
                    currentUser={currentUser}
                    intl={intl}
                  />
                </div>
                <div className={css.modaldiv}>
                  {this.state.transactions?.length === 0 ? (
                    'This Listing has not been booked yet'
                  ) : this.state.transactions?.length > 0 ? (
                    <div className={css.tsns}>
                      <p className={css.listType}>
                        {this.state.renderWaitingTable ? 'Waiting List' : 'Booking List'}
                      </p>
                      <table
                        className={classNames(css.bktbl, {
                          [css.waitingTable]: this.state.renderWaitingTable,
                        })}
                      >
                        {this.state.renderWaitingTable ? (
                          <thead>
                            <tr>
                              <th>No of Spaces</th>
                              <th>Date of Enquiry</th>
                              <th>Parent Name</th>
                              <th>Email Address</th>
                            </tr>
                          </thead>
                        ) : (
                          <thead>
                            <tr>
                              <th>Child's Name</th>
                              <th>Date of Birth</th>
                              <th>Paid</th>
                              <th>Booked</th>
                              <th>Due</th>
                              <th>Medical</th>
                              <th>Parent Name & Tel No.</th>
                              <th>Parent Email</th>
                              <th>Emergency Contact 1</th>
                              <th>Emergency Contact 2</th>
                            </tr>
                          </thead>
                        )}
                        <tbody>
                          {this.state.renderWaitingTable
                            ? this.state.activeListing.attributes.privateData?.waitingListData?.map(
                                ({ parentName, phnNo, email, seats, date }, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        <span>{seats}</span>
                                      </td>
                                      <td>
                                        <span>{moment(date).format('DD-MM-YYYY')}</span>
                                      </td>
                                      <td>
                                        <span className={css.emgspn}>
                                          <span>{parentName}</span>
                                          <span>{phnNo}</span>
                                        </span>
                                      </td>
                                      <td>
                                        <span>{email}</span>
                                      </td>
                                    </tr>
                                  );
                                }
                              )
                            : perChildTransaction?.map((item, i) => {
                                const { childDetails, transaction } = item;
                                const emergencyContacts =
                                  transaction.attributes.protectedData.emgContact;
                                const isDeposit =
                                  transaction.attributes.protectedData.payingOptions ===
                                  'depositPayment';
                                const depositOptionAmount = isDeposit
                                  ? this.state.activeListing.attributes.publicData
                                      ?.depositOptionAmount
                                  : null;
                                const payIn = transaction.attributes.payinTotal.amount / 100;
                                const lineItems = transaction.attributes.lineItems;
                                const childPayment = lineItems.find(
                                  l =>
                                    l.code.indexOf(childDetails.firstName) !== -1 &&
                                    l.code.indexOf(childDetails.lastName)
                                );
                                const booked = moment(transaction.attributes.createdAt).format(
                                  'DD[-]MM[-]YYYY'
                                );
                                const customerDetails =
                                  transaction.attributes.protectedData.customerDetails;
                                return (
                                  <tr key={i}>
                                    <td>
                                      <span>
                                        {childDetails.firstName} {childDetails.lastName}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{moment(childDetails.dob).format('DD-MM-YYYY')}</span>
                                    </td>
                                    <td>
                                      <span className={css.emgspn}>
                                        <span>
                                          {isDeposit
                                            ? `€${depositOptionAmount.amount}`
                                            : `€${childPayment.lineTotal.amount / 100}`}
                                        </span>
                                        {isDeposit && !childDetails?.isTrial ? (
                                          <span style={{ color: '#959595' }}>(deposit)</span>
                                        ) : childDetails?.isTrial ? (
                                          <span style={{ color: '#959595' }}>(trial)</span>
                                        ) : null}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{booked}</span>
                                    </td>
                                    <td>
                                      <span>
                                        {isDeposit && !childDetails?.isTrial
                                          ? `€${childPayment.lineTotal.amount / 100 -
                                              depositOptionAmount.amount}`
                                          : '€0'}
                                      </span>
                                    </td>
                                    <td>
                                      <span>{childDetails.childMedicalNotes}</span>
                                    </td>
                                    <td>
                                      <span className={css.emgspn}>
                                        <span>{customerDetails?.customerName}</span>
                                        <span>
                                          <u>{customerDetails?.customerPhnNo}</u>
                                        </span>
                                      </span>
                                    </td>
                                    <td>
                                      <span
                                        onClick={() => {
                                          if (typeof window !== 'undefined') {
                                            window.location.href = `mailto:${customerDetails?.customerEmail}`;
                                          }
                                        }}
                                      >
                                        {customerDetails?.customerEmail}
                                      </span>
                                    </td>
                                    <td>
                                      <span className={css.emgspn}>
                                        {emergencyContacts && emergencyContacts?.[0] ? (
                                          <>
                                            <span>{`${emergencyContacts[0].firstName} ${emergencyContacts[0].lastName}`}</span>
                                            <span>{`${emergencyContacts[0].phnNo}`}</span>
                                          </>
                                        ) : (
                                          '---'
                                        )}
                                      </span>{' '}
                                    </td>
                                    <td>
                                      <span className={css.emgspn}>
                                        {emergencyContacts && emergencyContacts?.[1] ? (
                                          <>
                                            <span>{`${emergencyContacts[1].firstName} ${emergencyContacts[1].lastName}`}</span>
                                            <span>{`${emergencyContacts[1].phnNo}`}</span>
                                          </>
                                        ) : (
                                          '---'
                                        )}
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}

                          <tr className={css.cstmrow}>
                            <div className={css.btndv}>
                              {/* <button type="button">Print Booking List</button> */}
                              {/* <CustomCsvDownloader
                                txData={perChildTransaction}
                                // prepareData={prepareDataForCsv}
                                depositOptionAmount={
                                  currentListing.attributes.publicData?.depositOptionAmount || null
                                }
                                fileName="listing.csv"
                              /> */}
                              <DownloadListComponent
                                list={perChildTransaction}
                                title={'Attendee List'}
                                isPrint={false}
                                containerClassName={css.downloadCont}
                                btnClassName={css.downloadBtn}
                                currentListing={this.state.activeListing}
                              />
                              {currentUser?.attributes?.emailVerified ? (
                                <button
                                  type="button"
                                  onClick={() => {
                                    const ids = this.state.transactions.map(
                                      t => t.attributes.protectedData.customerDetails.customerEmail
                                    );
                                    const uIds = [...new Set(ids)];
                                    const mailtoString =
                                      uIds?.length > 0
                                        ? uIds.reduce(
                                            (str, curr, idx) =>
                                              str + `${idx === 0 ? curr : `;${curr}`}`,
                                            ''
                                          )
                                        : '';
                                    if (typeof window !== 'undefined' && mailtoString) {
                                      window.location = `mailto:${currentUser?.attributes?.email}?bcc=${mailtoString}`;
                                    }
                                  }}
                                >
                                  Email All Parents
                                </button>
                              ) : (
                                <button type="button">Email not verified</button>
                              )}
                              {this.state.activeListing.attributes.privateData?.waitingListData
                                ?.length > 0 ? (
                                <button
                                  type="button"
                                  onClick={e =>
                                    this.setState(state => ({
                                      ...state,
                                      renderWaitingTable: !state.renderWaitingTable,
                                    }))
                                  }
                                >
                                  {this.state.renderWaitingTable ? 'Booking List' : 'Waiting List'}
                                </button>
                              ) : null}
                            </div>
                          </tr>
                        </tbody>
                      </table>
                      {/* <div className={css.btndv}>
                  <button type="button">Print Booking List</button>
                  <button type="button">Email All Parents</button>
                </div> */}
                    </div>
                  ) : null}
                </div>
              </div>
            </Modal>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

ManageListingsPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  queryListingsError: null,
  queryParams: null,
  closingListing: null,
  closingListingError: null,
  openingListing: null,
  openingListingError: null,
};

const { arrayOf, bool, func, object, shape, string } = PropTypes;

ManageListingsPageComponent.propTypes = {
  closingListing: shape({ uuid: string.isRequired }),
  closingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  listings: arrayOf(propTypes.ownListing),
  onCloseListing: func.isRequired,
  onOpenListing: func.isRequired,
  openingListing: shape({ uuid: string.isRequired }),
  openingListingError: shape({
    listingId: propTypes.uuid.isRequired,
    error: propTypes.error.isRequired,
  }),
  pagination: propTypes.pagination,
  queryInProgress: bool.isRequired,
  queryListingsError: propTypes.error,
  queryParams: object,
  scrollingDisabled: bool.isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const {
    currentPageResultIds,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
  } = state.ManageListingsPage;
  const { currentUser } = state.user;
  const listings = getOwnListingsById(
    state,
    currentPageResultIds.map(lid => new UUID(lid.uuid))
  );
  return {
    currentPageResultIds,
    listings,
    pagination,
    queryInProgress,
    queryListingsError,
    queryParams,
    scrollingDisabled: isScrollingDisabled(state),
    openingListing,
    openingListingError,
    closingListing,
    closingListingError,
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  onCloseListing: listingId => dispatch(closeListing(listingId)),
  onOpenListing: listingId => dispatch(openListing(listingId)),
  onLoadData: (params, search) => dispatch(loadData(params, search)),
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

const ManageListingsPage = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl,
  withRouter
)(ManageListingsPageComponent);

export default ManageListingsPage;
