import { types as sdkTypes } from './util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.
const defaultLocations = [
  {
    id: 'default-bray',
    place_id: 'ChIJr33MW2aoZ0gRZ3fTTM48j6c',
    predictionPlace: {
      address: 'Bray, County Wicklow, Ireland',
      bounds: new LatLngBounds(
        new LatLng(53.21507003291262, -6.088299927348739),
        new LatLng(53.17896996584117, -6.138330038569715)
      ),
    },
  },
  {
    id: 'default-greystones',
    place_id: 'ChIJKcXgkDGvZ0gRIBcyl6nHAAo',
    predictionPlace: {
      address: 'Greystones, County Wicklow, Ireland',
      bounds: new LatLngBounds(
        new LatLng(53.16057996517873, -6.056159971403718),
        new LatLng(53.12935004345947, -6.104829934170933)
      ),
    },
  },
  {
    id: 'default-wicklow',
    place_id: 'ChIJWShQ2bWwZ0gR56NB9ft5Tig',

    predictionPlace: {
      address: 'Wicklow, County Wicklow, Ireland',
      bounds: new LatLngBounds(
        new LatLng(52.99892001918486, -6.015459919682286),
        new LatLng(52.96610005978194, -6.070040046690126)
      ),
    },
  },
  {
    id: 'default-arklow',
    place_id: 'ChIJjcXDVOTDZ0gRwNAxl6nHAAo',
    predictionPlace: {
      address: 'Arklow, County Wicklow, Ireland',
      bounds: new LatLngBounds(
        new LatLng(52.81055490013971, -6.13340534317216),
        new LatLng(52.77958993547418, -6.181259997919196)
      ),
    },
  },
  {
    id: 'default-Blessington',
    place_id: 'ChIJyeeQbiCdZ0gRQNUxl6nHAAo',
    predictionPlace: {
      address: 'Blessington, County Wicklow, Ireland',
      bounds: new LatLngBounds(
        new LatLng(53.1763460082005, -6.518061276228583),
        new LatLng(53.1629687492057, -6.550076123771419)
      ),
    },
  },
  // {
  //   id: 'default-donegal',
  //   predictionPlace: {
  //     address: 'Donegal, Ireland',
  //     bounds: new LatLngBounds(
  //       new LatLng(54.66157157137809, -8.087926105923588),
  //       new LatLng(54.64266870346417, -8.124109332587416)
  //     ),
  //   },
  // },
];
export default defaultLocations;
