import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { parse } from '../../util/urlHelpers';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import config from '../../config';
import { denormalisedResponseEntities } from '../../util/data';
const { UUID } = sdkTypes;

// ============Action Type =================
export const FETCH_REVIEWS_REQUEST = 'app/FavouritesPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/FavouritesPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ReviewsPage/FETCH_REVIEWS_ERROR';

//=============Reducer=================
const initialState = {
  pagination: null,
  queryInProgress: null,
  queryReviewsError: null,
  reviews: [],
};
const resultIds = data => data.data.map(l => l.id);

const reviewsPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_REVIEWS_REQUEST:
      return { ...state, queryReviewsError: null, queryInProgress: true };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload, queryInProgress: false };
    case FETCH_REVIEWS_ERROR:
      return { ...state, reviews: [], queryReviewsError: payload, queryInProgress: false };
    default:
      return { ...state };
  }
};
export default reviewsPageReducer;
//=======ActionCreators==============

export const queryReviewsRequest = () => ({
  type: FETCH_REVIEWS_REQUEST,
});

export const queryReviewsSuccess = reviews => ({
  type: FETCH_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryReviewsError = e => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: e,
});

//==========fetch from database ===========
export const getReviews = page => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  dispatch(queryReviewsRequest(currentUser));
  const resp = await sdk.reviews.query({
    subjectId: currentUser.id.uuid,
    per_page: 10,
    page,
    include: ['author', 'author.profileImage', 'listing'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
  });
  const reviews = denormalisedResponseEntities(resp);
  dispatch(queryReviewsSuccess(reviews));
  return reviews;
};

export const loadData = (param, search) => async (dispatch, getState) => {
  const queryParams = parse(search);
  const page = queryParams.page;
  const queryPage = !!page ? page : 1;
  await dispatch(fetchCurrentUser());
  return dispatch(getReviews(queryPage));
};
