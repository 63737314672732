import React, { useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { GrCheckboxSelected, GrCheckbox } from 'react-icons/gr';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import css from './EditListingAvailabilityForm.module.css';
import { Button, FieldTextInput, Form } from '../../components';
import moment from 'moment';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { AVAILABILITY, WHATTOBRING } from '../../components/EditListingWizard/EditListingWizardTab';
import { ReactComponent as Arrowleft } from '../../assets/chevron_left.svg';
import { ReactComponent as ArrowRight } from '../../assets/chevron_right.svg';
import { MdOutlineNavigateNext, MdOutlineNavigateBefore } from 'react-icons/md';
import { FieldArray } from 'react-final-form-arrays';
const PrevIcon = props => <Arrowleft {...props} className={css.arrowIcon} />;
const NextIcon = props => <ArrowRight {...props} className={css.arrowIcon} />;
const DESC_MAX_LENGTH = 1000;
const minDate = moment().add(2, 'months');
const EditListingAvailabilityForm = props => {
  const {
    publicData,
    tabProgress,
    initialValues,
    backButton,
    loadingForSubmit,
    onSubmit,
    backbtnLoader,
    sveXit,
  } = props;
  const { categories } = publicData;
  const tabPercent = tabProgress(AVAILABILITY);
  const daysOfWeek = [
    { value: 'sunday', label: 'Sun' },
    { value: 'monday', label: 'Mon' },
    { value: 'tuesday', label: 'Tue' },
    { value: 'wednesday', label: 'Wed' },
    { value: 'thursday', label: 'Thu' },
    { value: 'friday', label: 'Fri' },
    { value: 'saturday', label: 'Sat' },
  ];
  const [descriptionFocussed, setDescriptionFocussed] = useState(false);
  const [description, setDescription] = useState(
    initialValues.description ? initialValues.description : null
  );
  // !!initialValues.description ? initialValues.description : mainMsg
  const [dayOfWeek, setDayOfWeek] = useState(
    initialValues.dayOfWeek ? initialValues.dayOfWeek : daysOfWeek[1]
  );
  const [startDate, setStartDate] = useState(
    !!initialValues.startDate ? moment(initialValues.startDate) : null
  );
  const [endDate, setEndDate] = useState(
    !!initialValues.endDate ? moment(initialValues.endDate) : null
  );
  const [noOfDays, setnoOfDays] = useState(initialValues.noOfDays ? initialValues.noOfDays : 0);
  const [noOfClasses, setnoOfClasses] = useState(
    initialValues.noOfClasses ? initialValues.noOfClasses : 0
  );
  const [earlyDropOffTime, setEarlyDropOffTime] = useState({
    hour: initialValues.earlyDropOffTime.hour,
    minute: initialValues.earlyDropOffTime.minute,
    focusOnHour: false,
    focusOnMinute: false,
  });
  const [extendedChildCare, setExtendedChildCare] = useState({
    earlyDropOff: initialValues.extendedChildCare.earlyDropOff,
    latePickup: initialValues.extendedChildCare.latePickup,
  });
  const [latePickupTime, setLatePickupTime] = useState({
    hour: initialValues.latePickupTime.hour,
    minute: initialValues.latePickupTime.minute,
    focusOnHour: false,
    focusOnMinute: false,
  });
  const [endTime, setEndTime] = useState({
    hour: initialValues.endTime.hour,
    minute: initialValues.endTime.minute,
    focusOnHour: false,
    focusOnMinute: false,
  });
  const [startTime, setStartTime] = useState({
    hour: initialValues.startTime.hour,
    minute: initialValues.startTime.minute,
    focusOnHour: false,
    focusOnMinute: false,
  });
  const lastTab = WHATTOBRING;
  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={fieldRenderProps => {
        const { handleSubmit, values, initialValues, form, listing } = fieldRenderProps;
        const extendedChildCareMaybe = (
          <div className={css.extended}>
            <label>Do you wish to offer extended childcare hours for a fee?</label>
            <div>
              <input
                name="extendedChildCare"
                type="checkbox"
                id="earlyDropOff"
                onChange={e => {
                  setExtendedChildCare({ ...extendedChildCare, earlyDropOff: e.target.checked });
                  form.change('extendedChildCare', {
                    ...extendedChildCare,
                    earlyDropOff: e.target.checked,
                  });
                }}
              />
              <label htmlFor="earlyDropOff" className={css.checkboxLabel}>
                {extendedChildCare.earlyDropOff ? (
                  <span className={css.checkIcon}>
                    <GrCheckboxSelected />
                  </span>
                ) : (
                  <span className={css.checkIcon}>
                    <GrCheckbox />
                  </span>
                )}
                <span>Early Drop-Off</span>
              </label>
            </div>
            <div>
              <input
                name="extendedChildCare"
                type="checkbox"
                id="latePickup"
                onChange={e => {
                  setExtendedChildCare({ ...extendedChildCare, latePickup: e.target.checked });
                  form.change('extendedChildCare', {
                    ...extendedChildCare,
                    latePickup: e.target.checked,
                  });
                }}
              />
              <label htmlFor="latePickup" className={css.checkboxLabel}>
                {extendedChildCare.latePickup ? (
                  <span className={css.checkIcon}>
                    <GrCheckboxSelected />
                  </span>
                ) : (
                  <span className={css.checkIcon}>
                    <GrCheckbox />
                  </span>
                )}
                <span>Late Pick Up</span>
              </label>
            </div>
          </div>
        );
        const noOfDaysMaybe = (
          <div className={css.flexprice}>
            <label>No. of days:</label>
            <div className={`${css.innerDive} ${css.idfst}`}>
              <button
                className={css.ageButton}
                disabled={noOfDays === 0}
                type="button"
                onClick={() => {
                  setnoOfDays(prevState => prevState - 1);
                  form.change('noOfDays', Math.max(0, noOfDays - 1));
                }}
              >
                -
              </button>
              <input
                type="text"
                value={noOfDays}
                onChange={e => {
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) && value <= 30 && value !== '') {
                    setnoOfDays(parseInt(value));
                    form.change('noOfDays', parseInt(value));
                  } else if (value === null || value === '') {
                    setnoOfDays(0);
                    form.change('noOfDays', 0);
                  }
                }}
              />
              <button
                className={css.ageButton}
                disabled={noOfDays === 30}
                type="button"
                onClick={() => {
                  setnoOfDays(prevState => prevState + 1);
                  form.change('noOfDays', noOfDays + 1);
                }}
              >
                +
              </button>
            </div>
          </div>
        );
        const noOfClassesMaybe = (
          <div className={css.flexprice}>
            <label>No. of classes in term:</label>
            <div className={`${css.innerDive} ${css.idfst}`}>
              <button
                className={css.ageButton}
                disabled={noOfClasses === 0}
                type="button"
                onClick={() => {
                  setnoOfClasses(prevState => prevState - 1);
                  form.change('noOfClasses', Math.max(0, noOfClasses - 1));
                }}
              >
                -
              </button>
              <input
                type="text"
                value={noOfClasses}
                onChange={e => {
                  const value = e.target.value;
                  if (/^[0-9]*$/.test(value) && value !== '') {
                    setnoOfClasses(parseInt(value));
                    form.change('noOfClasses', parseInt(value));
                  } else if (value === null || value === '') {
                    setnoOfClasses(0);
                    form.change('noOfClasses', 0);
                  }
                }}
              />
              <button
                className={css.ageButton}
                // disabled={noOfDays === 30}
                type="button"
                onClick={() => {
                  setnoOfClasses(prevState => prevState + 1);
                  form.change('noOfClasses', noOfClasses + 1);
                }}
              >
                +
              </button>
            </div>
          </div>
        );
        const openTillMaybe = (
          <div className={css.mpDiv}>
            <span className={css.mpSpan}>Early Drop-Off Option:</span>
            <div className={css.flexprice}>
              <label>
                {publicData.categories?.includes('afterSchoolClub') ||
                publicData.categories?.includes('birthdayParty')
                  ? "What time do you stay open 'til"
                  : publicData.categories?.includes('breakfastClub')
                  ? 'What time do you open on school mornings?'
                  : publicData.categories?.includes('schoolTours')
                  ? 'What length of time is this school tour package?'
                  : publicData.categories?.includes('inSchoolProgrammes')
                  ? 'What length of time is this in-school programme package?'
                  : publicData.categories?.includes('camps') ||
                    publicData.categories?.includes('classes')
                  ? 'Early Drop-Off'
                  : null}
                {publicData.categories?.includes('camps') ||
                publicData.categories?.includes('classes') ? (
                  <span className={css.from}>
                    From: <span className={css.tfspn}>24-hour clock</span>
                  </span>
                ) : null}
              </label>
              <div className={`${css.innerDive} ${css.modind}`}>
                <button
                  className={css.ageButton}
                  type="button"
                  onClick={() => {
                    if (earlyDropOffTime.focusOnHour && earlyDropOffTime.hour > 0) {
                      setEarlyDropOffTime(state => ({ ...state, hour: state.hour - 1 }));
                      form.change('earlyDropOffTime', {
                        ...values.earlyDropOffTime,
                        hour: Number.parseInt(earlyDropOffTime.hour) - 1,
                      });
                    }
                    if (earlyDropOffTime.focusOnMinute && earlyDropOffTime.minute > 0) {
                      setEarlyDropOffTime(state => ({ ...state, minute: state.minute - 1 }));
                      form.change('earlyDropOffTime', {
                        ...values.earlyDropOffTime,
                        minute: Number.parseInt(earlyDropOffTime.minute) - 1,
                      });
                    }
                  }}
                >
                  -
                </button>
                <span>
                  <input
                    autoFocus
                    id="hour"
                    type="text"
                    className={css.mpi}
                    value={
                      earlyDropOffTime.hour < 10
                        ? `0${earlyDropOffTime.hour}`
                        : earlyDropOffTime.hour
                    }
                    onChange={e => {
                      if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 25) {
                        setEarlyDropOffTime({
                          ...earlyDropOffTime,
                          hour: Number.parseInt(e.target.value),
                        });
                        form.change('earlyDropOffTime', {
                          ...values.earlyDropOffTime,
                          hour: Number.parseInt(e.target.value),
                        });
                      }
                      if (e.target.value === '') {
                        form.change('earlyDropOffTime', { ...values.earlyDropOffTime, hour: 0 });
                        setEarlyDropOffTime({ ...earlyDropOffTime, hour: 0 });
                      }
                    }}
                    onBlur={e => {
                      // setEarlyDropOffTime({ ...earlyDropOffTime, focusOnHour: false });
                      e.target.value === ''
                        ? setEarlyDropOffTime({ ...earlyDropOffTime, hour: 0 })
                        : null;
                    }}
                    onFocus={e => {
                      setEarlyDropOffTime({
                        ...earlyDropOffTime,
                        focusOnHour: true,
                        focusOnMinute: false,
                      });
                    }}
                  />
                  <p className={css.pdot}>.</p>
                  <input
                    type="text"
                    id="minute"
                    value={
                      earlyDropOffTime.minute < 10
                        ? `0${earlyDropOffTime.minute}`
                        : earlyDropOffTime.minute
                    }
                    className={css.mpnu}
                    onChange={e => {
                      if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 60) {
                        form.change('earlyDropOffTime', {
                          ...values.earlyDropOffTime,
                          minute: Number.parseInt(e.target.value),
                        });
                        setEarlyDropOffTime({
                          ...earlyDropOffTime,
                          minute: Number.parseInt(e.target.value),
                        });
                      }
                      if (e.target.value === '') {
                        form.change('earlyDropOffTime', { ...values.earlyDropOffTime, minute: 0 });
                        setEarlyDropOffTime({ ...earlyDropOffTime, minute: 0 });
                      }
                    }}
                    onBlur={e => {
                      // setEarlyDropOffTime({ ...earlyDropOffTime, focusOnMinute: false });
                      e.target.value === ''
                        ? setEarlyDropOffTime({ ...earlyDropOffTime, minute: 0 })
                        : null;
                    }}
                    onFocus={e => {
                      setEarlyDropOffTime({
                        ...earlyDropOffTime,
                        focusOnMinute: true,
                        focusOnHour: false,
                      });
                    }}
                  />
                </span>
                <button
                  className={css.ageButton}
                  type="button"
                  // disabled={() => {
                  //   if (earlyDropOffTime.focusOnHour && earlyDropOffTime.hour === 24) {
                  //     return true;
                  //   } else if (earlyDropOffTime.focusOnMinute && earlyDropOffTime.minute === 60) {
                  //     return true;
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                  onClick={() => {
                    if (earlyDropOffTime.focusOnHour && earlyDropOffTime.hour < 24) {
                      setEarlyDropOffTime(state => ({ ...state, hour: state.hour + 1 }));
                      form.change('earlyDropOffTime', {
                        ...values.earlyDropOffTime,
                        hour: Number.parseInt(earlyDropOffTime.hour) + 1,
                      });
                    }
                    if (earlyDropOffTime.focusOnMinute && earlyDropOffTime.minute < 60) {
                      setEarlyDropOffTime(state => ({ ...state, minute: state.minute + 1 }));
                      form.change('earlyDropOffTime', {
                        ...values.earlyDropOffTime,
                        minute: Number.parseInt(earlyDropOffTime.minute) + 1,
                      });
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        );
        const eveningCareMaybe = (
          <div className={css.mpDiv}>
            <span>Late Pick Up Option:</span>
            <div className={css.flexprice}>
              <label>
                {publicData.categories?.includes('afterSchoolClub') ||
                publicData.categories?.includes('birthdayParty')
                  ? "What time do you stay open 'til"
                  : publicData.categories?.includes('breakfastClub')
                  ? 'What time do you open on school mornings?'
                  : publicData.categories?.includes('schoolTours')
                  ? 'What length of time is this school tour package?'
                  : publicData.categories?.includes('inSchoolProgrammes')
                  ? 'What length of time is this in-school programme package?'
                  : publicData.categories?.includes('camps') ||
                    publicData.categories?.includes('classes')
                  ? 'Late Pick-Up'
                  : null}
                {publicData.categories?.includes('camps') ||
                publicData.categories?.includes('classes') ? (
                  <span>
                    Until: <span className={css.tfspn}>24-hour clock</span>
                  </span>
                ) : null}
              </label>
              <div className={`${css.innerDive} ${css.modind}`}>
                <button
                  className={css.ageButton}
                  type="button"
                  onClick={() => {
                    if (latePickupTime.focusOnHour && latePickupTime.hour > 0) {
                      setLatePickupTime(state => ({ ...state, hour: state.hour - 1 }));
                      form.change('latePickupTime', {
                        ...values.latePickupTime,
                        hour: Number.parseInt(latePickupTime.hour) - 1,
                      });
                    }
                    if (latePickupTime.focusOnMinute && latePickupTime.minute > 0) {
                      setLatePickupTime(state => ({ ...state, minute: state.minute - 1 }));
                      form.change('latePickupTime', {
                        ...values.latePickupTime,
                        minute: Number.parseInt(latePickupTime.minute) - 1,
                      });
                    }
                  }}
                >
                  -
                </button>
                <span>
                  <input
                    autoFocus
                    id="hour"
                    type="text"
                    className={css.mpi}
                    value={
                      latePickupTime.hour < 10 ? `0${latePickupTime.hour}` : latePickupTime.hour
                    }
                    onChange={e => {
                      if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 25) {
                        setLatePickupTime({
                          ...latePickupTime,
                          hour: Number.parseInt(e.target.value),
                        });
                        form.change('latePickupTime', {
                          ...values.latePickupTime,
                          hour: Number.parseInt(e.target.value),
                        });
                      }
                      if (e.target.value === '') {
                        form.change('latePickupTime', { ...values.latePickupTime, hour: 0 });
                        setLatePickupTime({ ...latePickupTime, hour: 0 });
                      }
                    }}
                    onBlur={e => {
                      // setLatePickupTime({ ...latePickupTime, focusOnHour: false });
                      e.target.value === ''
                        ? setLatePickupTime({ ...latePickupTime, hour: 0 })
                        : null;
                    }}
                    onFocus={e => {
                      setLatePickupTime({
                        ...latePickupTime,
                        focusOnHour: true,
                        focusOnMinute: false,
                      });
                    }}
                  />
                  <p className={css.pdot}>.</p>
                  <input
                    type="text"
                    id="minute"
                    value={
                      latePickupTime.minute < 10
                        ? `0${latePickupTime.minute}`
                        : latePickupTime.minute
                    }
                    className={css.mpnu}
                    onChange={e => {
                      if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 60) {
                        form.change('latePickupTime', {
                          ...values.latePickupTime,
                          minute: Number.parseInt(e.target.value),
                        });
                        setLatePickupTime({
                          ...latePickupTime,
                          minute: Number.parseInt(e.target.value),
                        });
                      }
                      if (e.target.value === '') {
                        form.change('latePickupTime', { ...values.latePickupTime, minute: 0 });
                        setLatePickupTime({ ...latePickupTime, minute: 0 });
                      }
                    }}
                    onBlur={e => {
                      // setLatePickupTime({ ...latePickupTime, focusOnMinute: false });
                      e.target.value === ''
                        ? setLatePickupTime({ ...latePickupTime, minute: 0 })
                        : null;
                    }}
                    onFocus={e => {
                      setLatePickupTime({
                        ...latePickupTime,
                        focusOnMinute: true,
                        focusOnHour: false,
                      });
                    }}
                  />
                </span>
                <button
                  className={css.ageButton}
                  type="button"
                  // disabled={() => {
                  //   if (latePickupTime.focusOnHour && latePickupTime.hour === 24) {
                  //     return true;
                  //   } else if (latePickupTime.focusOnMinute && latePickupTime.minute === 60) {
                  //     return true;
                  //   } else {
                  //     return false;
                  //   }
                  // }}
                  onClick={() => {
                    if (latePickupTime.focusOnHour && latePickupTime.hour < 24) {
                      setLatePickupTime(state => ({ ...state, hour: state.hour + 1 }));
                      form.change('latePickupTime', {
                        ...values.latePickupTime,
                        hour: Number.parseInt(latePickupTime.hour) + 1,
                      });
                    }
                    if (latePickupTime.focusOnMinute && latePickupTime.minute < 60) {
                      setLatePickupTime(state => ({ ...state, minute: state.minute + 1 }));
                      form.change('latePickupTime', {
                        ...values.latePickupTime,
                        minute: Number.parseInt(latePickupTime.minute) + 1,
                      });
                    }
                  }}
                >
                  +
                </button>
              </div>
            </div>
          </div>
        );
        const endTimeMaybe = (
          <div className={`${css.rowlbl} ${css.flexprice}`}>
            <label>
              {categories[0] === 'camps' ||
              categories[0] === 'workshops' ||
              categories[0] === 'classes'
                ? 'Finish Time:'
                : 'End Time:'}{' '}
              <span className={css.tfspn}>24-hour clock</span>
            </label>
            <div className={`${css.innerDive} ${css.modind}`}>
              <button
                className={css.ageButton}
                type="button"
                onClick={() => {
                  if (endTime.focusOnHour && endTime.hour > 0) {
                    setEndTime(state => ({ ...state, hour: state.hour - 1 }));
                    form.change('endTime', {
                      ...values.endTime,
                      hour: Number.parseInt(endTime.hour) - 1,
                    });
                  }
                  if (endTime.focusOnMinute && endTime.minute > 0) {
                    setEndTime(state => ({ ...state, minute: state.minute - 1 }));
                    form.change('endTime', {
                      ...values.endTime,
                      minute: Number.parseInt(endTime.minute) - 1,
                    });
                  }
                }}
              >
                -
              </button>
              <span>
                <input
                  autoFocus
                  id="hour"
                  type="text"
                  className={css.mpi}
                  value={endTime.hour < 10 ? `0${endTime.hour}` : endTime.hour}
                  onChange={e => {
                    if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 25) {
                      setEndTime({ ...endTime, hour: Number.parseInt(e.target.value) });
                      form.change('endTime', {
                        ...values.endTime,
                        hour: Number.parseInt(e.target.value),
                      });
                    }
                    if (e.target.value === '') {
                      form.change('endTime', { ...values.endTime, hour: 0 });
                      setEndTime({ ...endTime, hour: 0 });
                    }
                  }}
                  onBlur={e => {
                    // setEndTime({ ...endTime, focusOnHour: false });
                    e.target.value === '' ? setEndTime({ ...endTime, hour: 0 }) : null;
                  }}
                  onFocus={e => {
                    setEndTime({ ...endTime, focusOnHour: true, focusOnMinute: false });
                  }}
                />
                <p className={css.pdot}>.</p>
                <input
                  type="text"
                  id="minute"
                  value={endTime.minute < 10 ? `0${endTime.minute}` : endTime.minute}
                  className={css.mpnu}
                  onChange={e => {
                    if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 60) {
                      form.change('endTime', {
                        ...values.endTime,
                        minute: Number.parseInt(e.target.value),
                      });
                      setEndTime({
                        ...endTime,
                        minute: Number.parseInt(e.target.value),
                      });
                    }
                    if (e.target.value === '') {
                      form.change('endTime', { ...values.endTime, minute: 0 });
                      setEndTime({ ...endTime, minute: 0 });
                    }
                  }}
                  onBlur={e => {
                    // setEndTime({ ...endTime, focusOnMinute: false });
                    e.target.value === '' ? setEndTime({ ...endTime, minute: 0 }) : null;
                  }}
                  onFocus={e => {
                    setEndTime({ ...endTime, focusOnMinute: true, focusOnHour: false });
                  }}
                />
              </span>
              <button
                className={css.ageButton}
                type="button"
                // disabled={() => {
                //   if (endTime.focusOnHour && endTime.hour === 24) {
                //     return true;
                //   } else if (endTime.focusOnMinute && endTime.minute === 60) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                // }}
                onClick={() => {
                  if (endTime.focusOnHour && endTime.hour < 24) {
                    setEndTime(state => ({ ...state, hour: state.hour + 1 }));
                    form.change('endTime', {
                      ...values.endTime,
                      hour: Number.parseInt(endTime.hour) + 1,
                    });
                  }
                  if (endTime.focusOnMinute && endTime.minute < 60) {
                    setEndTime(state => ({ ...state, minute: state.minute + 1 }));
                    form.change('endTime', {
                      ...values.endTime,
                      minute: Number.parseInt(endTime.minute) + 1,
                    });
                  }
                }}
              >
                +
              </button>
            </div>
          </div>
        );

        const startTimeMaybe = (
          <div className={`${css.rowlbl} ${css.flexprice}`}>
            <label>
              Start Time:<span className={css.tfspn}> 24-hour clock</span>
            </label>
            <div className={`${css.innerDive} ${css.modind}`}>
              <button
                className={css.ageButton}
                type="button"
                onClick={() => {
                  if (startTime?.focusOnHour && startTime?.hour > 0) {
                    setStartTime(state => ({ ...state, hour: state.hour - 1 }));
                    form.change('startTime', {
                      ...values.startTime,
                      hour: Number.parseInt(startTime.hour) - 1,
                    });
                  }
                  if (startTime?.focusOnMinute && startTime?.minute > 0) {
                    setStartTime(state => ({ ...state, minute: state.minute - 1 }));
                    form.change('startTime', {
                      ...values.startTime,
                      minute: Number.parseInt(startTime.minute) - 1,
                    });
                  }
                }}
              >
                -
              </button>
              <span>
                <input
                  autoFocus
                  id="hour"
                  type="text"
                  className={css.mpi}
                  value={startTime.hour < 10 ? `0${startTime.hour}` : startTime.hour}
                  onChange={e => {
                    if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 25) {
                      setStartTime({ ...startTime, hour: Number.parseInt(e.target.value) });
                      form.change('startTime', {
                        ...values.startTime,
                        hour: Number.parseInt(e.target.value),
                      });
                    }
                    if (e.target.value === '') {
                      form.change('startTime', { ...values.startTime, hour: 0 });
                      setStartTime({ ...startTime, hour: 0 });
                    }
                  }}
                  onBlur={e => {
                    // setEndTime({ ...startTime, focusOnHour: false });
                    e.target.value === '' ? setStartTime({ ...startTime, hour: 0 }) : null;
                  }}
                  onFocus={e => {
                    setStartTime({ ...startTime, focusOnHour: true, focusOnMinute: false });
                  }}
                />
                <p className={css.pdot}>.</p>
                <input
                  type="text"
                  id="minute"
                  value={startTime.minute < 10 ? `0${startTime.minute}` : startTime.minute}
                  className={css.mpnu}
                  onChange={e => {
                    if (/^[0-9]*$/.test(e.target.value) && Number.parseInt(e.target.value) < 60) {
                      form.change('startTime', {
                        ...values.startTime,
                        minute: Number.parseInt(e.target.value),
                      });
                      setStartTime({
                        ...startTime,
                        minute: Number.parseInt(e.target.value),
                      });
                    }
                    if (e.target.value === '') {
                      form.change('startTime', { ...values.startTime, minute: 0 });
                      setStartTime({ ...startTime, minute: 0 });
                    }
                  }}
                  onBlur={e => {
                    // setEndTime({ ...startTime, focusOnMinute: false });
                    e.target.value === '' ? setStartTime({ ...startTime, minute: 0 }) : null;
                  }}
                  onFocus={e => {
                    setStartTime({ ...startTime, focusOnMinute: true, focusOnHour: false });
                  }}
                />
              </span>
              <button
                className={css.ageButton}
                type="button"
                // disabled={() => {
                //   if (endTime.focusOnHour && endTime.hour === 24) {
                //     return true;
                //   } else if (endTime.focusOnMinute && endTime.minute === 60) {
                //     return true;
                //   } else {
                //     return false;
                //   }
                // }}
                onClick={() => {
                  if (startTime.focusOnHour && startTime.hour < 24) {
                    setStartTime(state => ({ ...state, hour: state.hour + 1 }));
                    form.change('startTime', {
                      ...values.startTime,
                      hour: Number.parseInt(startTime.hour) + 1,
                    });
                  }
                  if (startTime.focusOnMinute && startTime.minute < 60) {
                    setStartTime(state => ({ ...state, minute: state.minute + 1 }));
                    form.change('startTime', {
                      ...values.startTime,
                      minute: Number.parseInt(startTime.minute) + 1,
                    });
                  }
                }}
              >
                +
              </button>
            </div>
          </div>
        );

        const startDateMaybe = (
          <div className={`${css.calendarWrapper} calendarWrapper`}>
            <label>{categories[0] === 'workshops' ? 'Date:' : 'Start Date:'}</label>
            <SingleDatePicker
              id="startDate"
              focused={true}
              date={startDate}
              weekDayFormat="ddd"
              firstDayOfWeek={1}
              onDateChange={date => {
                setStartDate(date);
                form.change('startDate', date);
              }}
              onFocusChange={() => {}}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              navPrev={<MdOutlineNavigateBefore />}
              navNext={<MdOutlineNavigateNext />}
              initialVisibleMonth={() => (startDate ? startDate : minDate)}
              isDayBlocked={date => moment(date).isBefore(moment())}
              isDayHighlighted={date =>
                startDate && endDate && categories[0] !== 'workshops'
                  ? moment(date).isBetween(moment(startDate), moment(endDate))
                  : false
              }
            />
          </div>
        );
        const endDateMaybe = (
          <div className={`${css.calendarWrapper} calendarWrapper`}>
            <label>End Date:</label>
            <SingleDatePicker
              id="endDate"
              weekDayFormat="ddd"
              focused={true}
              firstDayOfWeek={1}
              date={endDate}
              onDateChange={date => {
                setEndDate(date);
                form.change('endDate', date);
              }}
              onFocusChange={() => {}}
              numberOfMonths={1}
              hideKeyboardShortcutsPanel
              navPrev={<MdOutlineNavigateBefore />}
              navNext={<MdOutlineNavigateNext />}
              initialVisibleMonth={() => (endDate ? endDate : minDate)}
              isDayBlocked={
                date => moment(date).isBefore(startDate)
                // ||
                // moment(date).isAfter(
                //   moment()
                //     .add(90, 'days')
                //     .startOf('day')
                // )
              }
              isDayHighlighted={date =>
                startDate && endDate
                  ? moment(date).isBetween(moment(startDate), moment(endDate))
                  : false
              }
            />
          </div>
        );
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              sveXit(values);
            }}
          >
            Save & Exit
          </Button>
        );
        const daysOfWeekMaybe = (
          <div className={css.flexprice}>
            <label>
              What day the {categories[0] === 'workshops' ? 'activity' : 'class'} is on:
            </label>
            <div className={`${css.innerDive} ${css.idfst}`}>
              <button
                className={css.ageButton}
                type="button"
                onClick={() => {
                  if (daysOfWeek.findIndex(d => d.value === dayOfWeek.value) === 0) {
                    form.change('dayOfWeek', daysOfWeek[daysOfWeek.length - 1]);
                    setDayOfWeek(daysOfWeek[daysOfWeek.length - 1]);
                  } else {
                    form.change(
                      'dayOfWeek',
                      daysOfWeek[daysOfWeek.findIndex(d => d.value === dayOfWeek.value) - 1]
                    );
                    setDayOfWeek(
                      prevState =>
                        daysOfWeek[daysOfWeek.findIndex(d => d.value === prevState.value) - 1]
                    );
                  }
                }}
              >
                -
              </button>
              <input type="text" value={dayOfWeek.label} disabled={true} />
              <button
                className={css.ageButton}
                type="button"
                onClick={() => {
                  if (
                    daysOfWeek.findIndex(d => d.value === dayOfWeek.value) <
                    daysOfWeek.length - 1
                  ) {
                    form.change(
                      'dayOfWeek',
                      daysOfWeek[daysOfWeek.findIndex(d => d.value === dayOfWeek.value) + 1]
                    );
                    setDayOfWeek(
                      prevState =>
                        daysOfWeek[daysOfWeek.findIndex(d => d.value === prevState.value) + 1]
                    );
                  } else if (
                    daysOfWeek.findIndex(d => d.value === dayOfWeek.value) ===
                    daysOfWeek.length - 1
                  ) {
                    form.change('dayOfWeek', daysOfWeek[0]);
                    setDayOfWeek(daysOfWeek[0]);
                  }
                }}
              >
                +
              </button>
            </div>
          </div>
        );
        const eventDaysTime =
          categories[0] === 'events' ? (
            <div className={css.evntcon}>
              <FieldArray name="day">
                {({ fields }) => {
                  return (
                    <div>
                      <div className={css.flexprice}>
                        <label>No. of days:</label>

                        <div className={`${css.innerDive} ${css.idfst}`}>
                          <button
                            className={css.ageButton}
                            disabled={noOfDays === 0}
                            type="button"
                            onClick={() => {
                              setnoOfDays(prevState => prevState - 1);
                              form.change('noOfDays', Math.max(0, noOfDays - 1));
                              fields.pop();
                            }}
                          >
                            -
                          </button>
                          <input
                            type="text"
                            value={noOfDays}
                            onChange={e => {
                              const value = e.target.value;
                              if (/^[0-9]*$/.test(value) && value <= 30 && value !== '') {
                                setnoOfDays(parseInt(value));
                                form.change('noOfDays', parseInt(value));
                              } else if (value === null || value === '') {
                                setnoOfDays(0);
                                form.change('noOfDays', 0);
                              }
                            }}
                          />
                          <button
                            className={css.ageButton}
                            disabled={noOfDays === 30}
                            type="button"
                            onClick={() => {
                              setnoOfDays(prevState => prevState + 1);
                              form.change('noOfDays', noOfDays + 1);
                              fields.push();
                            }}
                          >
                            +
                          </button>
                        </div>
                      </div>
                      {[...Array(noOfDays).keys()].map((c, i) => {
                        const name = `day[${i}]`;
                        if (!values.day[i]?.startTime?.minute) {
                          form.change(`${name}.startTime.minute`, 0);
                        }
                        if (!values.day[i]?.startTime?.hour) {
                          form.change(`${name}.startTime.hour`, 0);
                        }
                        if (!values.day[i]?.endTime?.minute) {
                          form.change(`${name}.endTime.minute`, 0);
                        }
                        if (!values.day[i]?.endTime?.hour) {
                          form.change(`${name}.endTime.hour`, 0);
                        }

                        return (
                          <div key={name + i} className={css.daytmecon}>
                            <label>Day {i + 1}</label>
                            <div className={css.echdaytme}>
                              <div className={css.flexprice}>
                                <label>Start Time:</label>
                                <div className={`${css.innerDive} ${css.modind}`}>
                                  <button
                                    className={css.ageButton}
                                    type="button"
                                    onClick={() => {
                                      if (
                                        values?.day[i]?.startTime?.focusOnHour &&
                                        values?.day[i]?.startTime?.hour > 0
                                      ) {
                                        form.change(`${name}.startTime`, {
                                          ...values?.day[i]?.startTime,
                                          hour: values?.day[i]?.startTime?.hour - 1,
                                        });
                                      }
                                      if (
                                        values?.day[i]?.startTime?.focusOnMinute &&
                                        values?.day[i]?.startTime?.minute > 0
                                      ) {
                                        form.change(`${name}.startTime`, {
                                          ...values?.day[i]?.startTime,
                                          minute: values?.day[i]?.startTime?.minute - 1,
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </button>
                                  <span>
                                    <input
                                      type="text"
                                      className={css.mpi}
                                      id={`${name}.startTime.hour`}
                                      value={
                                        values.day[i]?.startTime?.hour < 10
                                          ? `0${values.day[i]?.startTime?.hour}`
                                          : values.day[i]?.startTime?.hour
                                      }
                                      onChange={e => {
                                        const value = e.target.value;

                                        if (/^[0-9]*$/.test(value) && Number.parseInt(value) < 25) {
                                          form.change(
                                            `${name}.startTime.hour`,
                                            Number.parseInt(value)
                                          );
                                        }
                                      }}
                                      onFocus={() => {
                                        form.change(`${name}.startTime.focusOnHour`, true);
                                        form.change(`${name}.startTime.focusOnMinute`, false);
                                      }}
                                      autoFocus
                                    />
                                    <p className={css.pdot}>.</p>
                                    <input
                                      type="text"
                                      className={css.mpnu}
                                      id={`${name}.startTime.minute`}
                                      value={
                                        values.day[i]?.startTime?.minute < 10
                                          ? `0${values.day[i]?.startTime?.minute}`
                                          : values.day[i]?.startTime?.minute
                                      }
                                      onFocus={() => {
                                        form.change(`${name}.startTime.focusOnMinute`, true);
                                        form.change(`${name}.startTime.focusOnHour`, false);
                                      }}
                                      onChange={e => {
                                        const value = e.target.value;
                                        if (/^[0-9]*$/.test(value) && Number.parseInt(value) < 60) {
                                          form.change(
                                            `${name}.startTime.minute`,
                                            Number.parseInt(value)
                                          );
                                        }
                                      }}
                                    />
                                  </span>
                                  <button
                                    className={css.ageButton}
                                    type="button"
                                    onClick={() => {
                                      if (
                                        values?.day[i]?.startTime?.focusOnHour &&
                                        values?.day[i]?.startTime?.hour < 24
                                      ) {
                                        form.change(`${name}.startTime`, {
                                          ...values?.day[i]?.startTime,
                                          hour: values?.day[i]?.startTime?.hour + 1,
                                        });
                                      }
                                      if (
                                        values?.day[i]?.startTime?.focusOnMinute &&
                                        values?.day[i]?.startTime?.minute < 60
                                      ) {
                                        form.change(`${name}.startTime`, {
                                          ...values?.day[i]?.startTime,
                                          minute: values?.day[i]?.startTime?.minute + 1,
                                        });
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                              <div className={css.flexprice}>
                                <label>Finish Time:</label>
                                <div className={`${css.innerDive} ${css.modind}`}>
                                  <button
                                    className={css.ageButton}
                                    type="button"
                                    onClick={() => {
                                      if (
                                        values?.day[i]?.endTime?.focusOnHour &&
                                        values?.day[i]?.endTime?.hour > 0
                                      ) {
                                        form.change(`${name}.endTime`, {
                                          ...values?.day[i]?.endTime,
                                          hour: values?.day[i]?.endTime?.hour - 1,
                                        });
                                      }
                                      if (
                                        values?.day[i]?.endTime?.focusOnMinute &&
                                        values?.day[i]?.endTime?.minute > 0
                                      ) {
                                        form.change(`${name}.endTime`, {
                                          ...values?.day[i]?.endTime,
                                          minute: values?.day[i]?.endTime?.minute - 1,
                                        });
                                      }
                                    }}
                                  >
                                    -
                                  </button>

                                  <span>
                                    <input
                                      type="text"
                                      autoFocus
                                      className={css.mpi}
                                      id={`${name}.endTime.hour`}
                                      value={
                                        values.day[i]?.endTime?.hour < 10
                                          ? `0${values.day[i]?.endTime?.hour}`
                                          : values.day[i]?.endTime?.hour
                                      }
                                      onChange={e => {
                                        const value = e.target.value;

                                        if (/^[0-9]*$/.test(value) && Number.parseInt(value) < 25) {
                                          form.change(
                                            `${name}.endTime.hour`,
                                            Number.parseInt(value)
                                          );
                                        }
                                      }}
                                      onFocus={() => {
                                        form.change(`${name}.endTime.focusOnHour`, true);
                                        form.change(`${name}.endTime.focusOnMinute`, false);
                                      }}
                                    />
                                    <p className={css.pdot}>.</p>
                                    <input
                                      type="text"
                                      className={css.mpnu}
                                      id={`${name}.endTime.minute`}
                                      value={
                                        values.day[i]?.endTime?.minute < 10
                                          ? `0${values.day[i]?.endTime?.minute}`
                                          : values.day[i]?.endTime?.minute
                                      }
                                      onFocus={() => {
                                        form.change(`${name}.endTime.focusOnMinute`, true);
                                        form.change(`${name}.endTime.focusOnHour`, false);
                                      }}
                                      onChange={e => {
                                        const value = e.target.value;
                                        if (/^[0-9]*$/.test(value) && Number.parseInt(value) < 60) {
                                          form.change(
                                            `${name}.endTime.minute`,
                                            Number.parseInt(value)
                                          );
                                        }
                                      }}
                                    />
                                  </span>
                                  <button
                                    className={css.ageButton}
                                    type="button"
                                    onClick={() => {
                                      if (
                                        values?.day[i]?.endTime?.focusOnHour &&
                                        values?.day[i]?.endTime?.hour < 24
                                      ) {
                                        form.change(`${name}.endTime`, {
                                          ...values?.day[i]?.endTime,
                                          hour: values?.day[i]?.endTime?.hour + 1,
                                        });
                                      }
                                      if (
                                        values?.day[i]?.endTime?.focusOnMinute &&
                                        values?.day[i]?.endTime?.minute < 60
                                      ) {
                                        form.change(`${name}.endTime`, {
                                          ...values?.day[i]?.endTime,
                                          minute: values?.day[i]?.endTime?.minute + 1,
                                        });
                                      }
                                    }}
                                  >
                                    +
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                }}
              </FieldArray>
            </div>
          ) : null;
        const descriptionMaybe = (
          <>
            {/* <p>{mainMsg}</p> */}
            <textarea
              id="description"
              lang="en-IE"
              spellcheck="true"
              name="description"
              className={css.description}
              value={description}
              onChange={e => {
                const value = e.target.value;
                if (value.length <= DESC_MAX_LENGTH) {
                  setDescription(value);
                  form.change('description', value);
                }
              }}
              autoFocus={true}
              onFocus={e => {
                const value = e.target.value;
                // e.target.selectionStart = value.length;
                setDescriptionFocussed(true);
              }}
              onBlur={() => {
                setDescriptionFocussed(false);
              }}
              // placeholder={placeholderMessage}
            ></textarea>
          </>
        );
        const customPh = (
          <div
            className={css.textdiv}
            onClick={() => {
              setDescriptionFocussed(true);
            }}
          >
            <p className={css.hdngp}>OPTION 1: Seasonal Hours</p>
            <p className={css.optnp1}>
              <span>OPEN April - October</span>
              <span>Tues - Sun 9.00- 18.00</span>
            </p>
            <p className={css.optnp1}>
              <span>OPEN May - September</span>
              <span>Tues - Sun 9.00- 18.00</span>
            </p>
            <p className={css.hdngp}>OPTION 2: Year-Round Opening Hours</p>
            <p>
              <span className={css.cphdiv}>
                <span>Mon: 9.00 - 18.00</span>
              </span>{' '}
              <span className={css.cphdiv}>
                <span>Wed: 9.00 - 18.00</span>
              </span>{' '}
              <span className={css.cphdiv}>
                <span>Sat: 9.00 - 12.00</span>
              </span>{' '}
              <span className={css.cphdiv}>
                <span>Sun: 10.00 - 15.00</span>
              </span>{' '}
            </p>
          </div>
        );
        const placeVisitAvailability = (
          <div className={css.pldiv} onClick={() => setDescriptionFocussed(true)}>
            <label>
              Try to display your <span>hours</span> concisely
            </label>
            <div className={css.fxdiv} onClick={() => setDescriptionFocussed(true)}>
              {descriptionFocussed || description ? descriptionMaybe : customPh}
            </div>
          </div>
        );
        return (
          <Form
            onSubmit={e => {
              e.preventDefault();
              values.startDate = startDate;
              values.endDate = endDate;
              values.noOfDays = noOfDays;
              values.noOfClasses = noOfClasses;
              onSubmit(values);
            }}
            className={css.availabilityForm}
          >
            <div className={css.svexbtnsec}>{saveExit}</div>
            <div className={css.formseclist}>
              {categories[0] === 'camps' ? (
                <>
                  {startDateMaybe}
                  {noOfDaysMaybe}
                  {startTimeMaybe}
                  {endTimeMaybe}
                  {extendedChildCareMaybe}
                  {extendedChildCare.earlyDropOff ? openTillMaybe : null}
                  {extendedChildCare.latePickup ? eveningCareMaybe : null}
                </>
              ) : categories[0] === 'classes' ? (
                <>
                  {startDateMaybe}
                  {endDateMaybe}
                  {daysOfWeekMaybe}
                  {noOfClassesMaybe}
                  {startTimeMaybe}
                  {endTimeMaybe}
                </>
              ) : categories[0] === 'workshops' ? (
                <>
                  {startDateMaybe}
                  {daysOfWeekMaybe}
                  {startTimeMaybe}
                  {endTimeMaybe}
                </>
              ) : categories[0] === 'events' ? (
                <>
                  {startDateMaybe}
                  {eventDaysTime}
                </>
              ) : categories[0] === 'placesToVisit' ? (
                placeVisitAvailability
              ) : null}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar progress={tabPercent} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  type="submit"
                  inProgress={loadingForSubmit}
                  className={css.submitButton}
                  disabled={
                    publicData.categories[0] === 'camps'
                      ? !startDate?._isAMomentObject ||
                        !noOfDays ||
                        (extendedChildCare.earlyDropOff &&
                          startTime.hour < earlyDropOffTime.hour) ||
                        (extendedChildCare.latePickup && endTime.hour > latePickupTime.hour) ||
                        startTime.hour >= endTime.hour
                      : publicData.categories[0] === 'classes'
                      ? !startDate?._isAMomentObject || !endDate?._isAMomentObject || !noOfClasses
                      : publicData.categories[0] === 'workshops'
                      ? !startDate?._isAMomentObject || startTime.hour >= endTime.hour
                      : publicData.categories[0] === 'events'
                      ? !startDate?._isAMomentObject || !noOfDays
                      : publicData.categories[0] === 'placesToVisit'
                      ? !description
                      : false
                  }
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

export default EditListingAvailabilityForm;
