import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  NamedLink,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingWhatToBringForm.module.css';
import Select from 'react-select';
import { DESCRIPTION, WHATTOBRING } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
// import { AiOutlineDoubleLeft } from 'react-icons/ai';

const TITLE_MAX_LENGTH = 60;
const WTB_MAX_LENGTH = 500;

const EditListingWhatToBringFormComponent = props => {
  const { filterConfig, initialValues, publicData, tabProgress } = props;
  const tabPercent = tabProgress(WHATTOBRING);
  const mainMsg = publicData.categories?.includes('afterSchoolClub')
    ? 'Our After-School club includes'
    : publicData.categories?.includes('birthdayParty')
    ? 'For this party we include'
    : publicData.categories?.includes('breakfastClub')
    ? 'Our breakfast club includes'
    : publicData.categories?.includes('schoolTours')
    ? 'For this school tour we include'
    : publicData.categories?.includes('inSchoolProgrammes')
    ? 'For this in-school programme we include'
    : publicData.categories?.includes('workshops')
    ? 'For this workshop it would be good to bring'
    : publicData.categories?.includes('club')
    ? 'For this club activity it would be good to'
    : publicData.categories?.includes('placesToVisit') || publicData.categories?.includes('events')
    ? null
    : publicData.categories?.includes('camps')
    ? 'For this camp it would be good to bring'
    : publicData.categories?.includes('classes')
    ? 'For this class it would be good to bring'
    : null;
  const placeholderMessage = publicData.categories?.includes('afterSchoolClub')
    ? `Snacks and drinks, garda vetted staff, school pick-ups, etc. You know what to include!`
    : publicData.categories?.includes('birthdayParty')
    ? 'Equipment, supplies, food, a cake, decorations, games, staff etc. You know what to include!'
    : publicData.categories?.includes('breakfastClub')
    ? 'Continental breakfasts, garda vetted staff, school drop offs, etc. You know what to include!'
    : publicData.categories?.includes('classes')
    ? 'special clothing like swim suits or ballet shoes, old clothes, a snack and a drink etc.'
    : publicData.categories?.includes('schoolTours')
    ? 'E.G. free coffee for adults, outdoor picnic area, jugs of juice etc. You know what to include!'
    : publicData.categories?.includes('inSchoolProgrammes')
    ? 'Equipment, supplies, garda vetted staff and insurance etc. You know what to include!'
    : publicData.categories?.includes('workshops')
    ? 'special clothing like swim suits or ballet shoes, old clothes, a snack and a drink etc'
    : publicData.categories?.includes('club')
    ? 'have special clothing, appropriate footwear, attend regularly, a water bottle, label clothing, on-site facilities, etc. You know what to write here!'
    : publicData.categories?.includes('placesToVisit')
    ? 'Food options, what’s nearby, You know what to include! '
    : publicData.categories?.includes('camps')
    ? 'special clothing, change of clothes, appropriate footwear, sun cream, hats, packed lunch and extra water etc. '
    : publicData.categories?.includes('events')
    ? 'Free entertainment, food stalls, coffee stations, picnic areas, smoothie stands or face-painting etc. You know what to include! '
    : null;

  const additionalInfoPlaceholder =
    'Please advise us of any medical or additional needs, in advance of this activity, so we can ensure your child(ren), along with staff and other participants can enjoy the best experience possible.';
  //  publicData.categories?.includes('birthdayParty')
  //   ? 'Do you sell any extras? Here is a good spot to add them. Tell parents about your party bags, signature birthday cakes and balloons etc.'
  //   : publicData.categories?.includes('schoolTours')
  //   ? 'Change of clothes, transport & parking options, extra water, packed lunches, pizza delivery etc.'
  //   : publicData.categories?.includes('inSchoolProgrammes')
  //   ? 'Anything extra? Add a testimonial here if you wish.'
  //   : publicData.categories?.includes('club')
  //   ? 'Extra details like perhaps transport links, parking – anything that needs adding.'
  //   : publicData.categories?.includes('placesToVisit')
  //   ? 'Transport & parking options, toilet and baby changing facilities, first aid etc.'
  //   : publicData.categories?.includes('events')
  //   ? 'Transport & parking options, toilet and bay changing facilities, first aid etc. '
  //   : publicData.categories?.includes('classes')
  //   ? 'Please advise us of any medical or additional needs, in advance of this activity, so we can ensure your child(ren), along with staff and other participants can enjoy the best experience possible.'
  //   : null;
  const [whatToBringFocussed, setWhatToBringFocussed] = useState(false);
  const [additionalInfoFocussed, setAdditionalInfoFocussed] = useState(false);
  const [whatToBring, setWhatToBring] = useState(
    !!initialValues.whatToBring ? initialValues.whatToBring : mainMsg
  );
  return (
    <FinalForm
      {...props}
      initialValues={{
        ...initialValues,
        additionalInfo: !!initialValues.additionalInfo
          ? initialValues.additionalInfo
          : additionalInfoPlaceholder,
      }}
      render={formRenderProps => {
        const {
          certificateOptions,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          saveActionMsg,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          publicData,
          values,
          savenExit,
          backbtnLoader,
          initialValues,
          backButton,
        } = formRenderProps;

        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );

        const whatToBringPlaceholderMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.whatToBringPlaceholder',
        });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.descriptionRequired',
        });

        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const lastTab = DESCRIPTION;

        const whatToBringMaybe = (
          <div>
            <textarea
              id="whatToBring"
              name="whatToBring"
              lang="en-IE"
              spellcheck="true"
              className={css.description}
              value={whatToBring}
              onBlur={() => setWhatToBringFocussed(false)}
              autoFocus={true}
              onFocus={e => {
                const value = e.target.value;
                e.target.selectionStart = value.length;
                setWhatToBringFocussed(true);
              }}
              onChange={e => {
                const value = e.target.value;
                if (value?.length < WTB_MAX_LENGTH) {
                  setWhatToBring(value);
                  form.change('whatToBring', value);
                }
              }}
              placeholder={placeholderMessage}
            ></textarea>
          </div>
        );
        const additionalInfoMaybe = (
          <div className={css.wtkbm}>
            <label>Additional Info</label>
            <div className={css.descriptionContainer}>
              {!additionalInfoFocussed && !values.additionalInfo ? (
                <div className={css.textdiv} onClick={() => setAdditionalInfoFocussed(true)}>
                  {!!additionalInfoPlaceholder ? (
                    <h2> {`${additionalInfoPlaceholder}...`} </h2>
                  ) : null}
                  {/* <p
                        className={
                          publicData.categories?.includes('placesToVisit') ||
                          publicData.categories?.includes('events')
                            ? css.nomar
                            : css.mar
                        }
                      >
                        {placeholderMessage}
                      </p> */}
                </div>
              ) : (
                <textarea
                  id="additionalInfo"
                  name="additionalInfo"
                  lang="en-IE"
                  spellcheck="true"
                  autoFocus={true}
                  className={css.description}
                  value={values.additionalInfo}
                  onBlur={() => setAdditionalInfoFocussed(false)}
                  onFocus={e => {
                    const value = e.target.value;
                    e.target.selectionStart = value.length;
                    setAdditionalInfoFocussed(true);
                  }}
                  onChange={e => {
                    const value = e.target.value;
                    if (value?.length < WTB_MAX_LENGTH) {
                      form.change('additionalInfo', value);
                    }
                  }}
                  placeholder={additionalInfoPlaceholder}
                ></textarea>
              )}
            </div>
          </div>
        );
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const { whatToBring, additionalInfo } = values;
              const updateValues = {
                publicData: {
                  whatToBring,
                  additionalInfo,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div
              className={classNames(css.formseclist, {
                [css.campfrm]: publicData.categories?.includes('camps'),
              })}
            >
              {publicData.categories?.includes('afterSchoolClub') ||
              publicData.categories?.includes('birthdayParty') ||
              publicData.categories?.includes('breakfastClub') ||
              publicData.categories?.includes('schoolTours') ||
              publicData.categories?.includes('inSchoolProgrammes') ||
              publicData.categories?.includes('events') ||
              publicData.categories?.includes('classes') ||
              publicData.categories?.includes('workshops') ? (
                <div className={css.descriptionContainer}>
                  <label>
                    {publicData.categories?.includes('classes') ||
                    publicData.categories?.includes('workshops')
                      ? 'What to bring'
                      : 'What’s Included'}
                  </label>

                  {!whatToBringFocussed && !values.whatToBring ? (
                    <div className={css.textdiv} onClick={() => setWhatToBringFocussed(true)}>
                      {!!mainMsg ? <h2> {`${mainMsg}...`} </h2> : null}
                      <p
                        className={
                          publicData.categories?.includes('placesToVisit') ||
                          publicData.categories?.includes('events')
                            ? css.nomar
                            : css.mar
                        }
                      >
                        {placeholderMessage}
                      </p>
                    </div>
                  ) : (
                    whatToBringMaybe
                  )}
                  <span>
                    {!!whatToBring ? whatToBring.length : 0}/{WTB_MAX_LENGTH}
                  </span>
                  {additionalInfoMaybe}
                  <span>
                    {values?.additionalInfo?.length ? values?.additionalInfo?.length : 0}/
                    {WTB_MAX_LENGTH}
                  </span>
                </div>
              ) : publicData.categories?.includes('camps') ? (
                <div className={css.descriptionContainer}>
                  <label>What to bring</label>
                  <div>
                    {!whatToBringFocussed && !values.whatToBring ? (
                      <div className={css.textdiv} onClick={() => setWhatToBringFocussed(true)}>
                        <h2>{!!mainMsg ? `${mainMsg}...` : mainMsg}</h2>
                        <p>{placeholderMessage}</p>
                      </div>
                    ) : (
                      whatToBringMaybe
                    )}
                  </div>
                  <span>
                    {!!whatToBring ? whatToBring.length : 0}/{WTB_MAX_LENGTH}
                  </span>
                </div>
              ) : publicData.categories?.includes('club') ||
                publicData.categories?.includes('placesToVisit') ? (
                <div className={css.descriptionContainer}>
                  <label>What to know</label>
                  <div>
                    {!whatToBringFocussed && !values.whatToBring ? (
                      <div
                        className={`${css.ptvwtb} ${css.textdiv}`}
                        onClick={() => setWhatToBringFocussed(true)}
                      >
                        <p>{placeholderMessage}</p>
                      </div>
                    ) : (
                      whatToBringMaybe
                    )}
                    <span>
                      {!!whatToBring ? whatToBring.length : 0}/{WTB_MAX_LENGTH}
                    </span>
                  </div>

                  {additionalInfoMaybe}
                  <span>
                    {values?.additionalInfo?.length ? values?.additionalInfo?.length : 0}/
                    {WTB_MAX_LENGTH}
                  </span>
                </div>
              ) : null}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!values.whatToBring}
                  ready={submitReady}
                >
                  {/* {saveActionMsg} */}
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingWhatToBringFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingWhatToBringFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingWhatToBringFormComponent);
