import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingLocationForm } from '../../forms';
import config from '../../config';
import { types as sdkTypes } from '../../util/sdkLoader';

import css from './EditListingLocationPanel.module.css';
import EditListingImage from '../EditListingImage/EditListingImage';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-06-icon.png';

const { LatLng } = sdkTypes;
const irelandCords = { lat: 53.1423672, lng: -7.692053599999999 };
class EditListingLocationPanel extends Component {
  constructor(props) {
    super(props);

    this.getInitialValues = this.getInitialValues.bind(this);

    this.state = {
      initialValues: this.getInitialValues(),
    };
  }

  getInitialValues() {
    const { listing } = this.props;
    const currentListing = ensureOwnListing(listing);
    const { geolocation, publicData } = currentListing.attributes;

    // Only render current search if full place object is available in the URL params
    // TODO bounds are missing - those need to be queried directly from Google Places
    const locationFieldsPresent =
      publicData && publicData.location && publicData.location.address && geolocation;
    const location = publicData && publicData.location ? publicData.location : {};
    const { address, building } = location;

    return {
      building,
      location: locationFieldsPresent
        ? {
            search: address,
            selectedPlace: { address, origin: geolocation },
          }
        : null,
    };
  }

  render() {
    const {
      className,
      rootClassName,
      listing,
      disabled,
      ready,
      onSubmit,
      onChange,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      errors,
      history,
      onManageDisableScrolling,
      backButton,
      tabProgress,
      currentTab,
      savenExit,
      backbtnLoader,
    } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    const currentListing = ensureOwnListing(listing);
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingLocationPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingLocationPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingLocationPanel.createListingTitle" />
    );
    const publicData = currentListing.attributes.publicData;
    const {
      address,
      address2,
      eircode,
      county,
      city,
      country,
      lat,
      lng,
      bpType,
      allCounties,
    } = publicData;
    const submitValues = values => {
      const { address2, eircode, lat, lng, address = '', county, city, country, bpType } = values;
      const formattedCounty =
        Array.isArray(county) && county?.includes('chooseall')
          ? county?.filter(c => c !== 'chooseall')
          : county;
      const countyInLC = Array.isArray(formattedCounty)
        ? formattedCounty[0].toLowerCase()
        : formattedCounty;
      const updateValues = {
        geolocation:
          !!lat && !!lng
            ? new LatLng(parseFloat(lat), parseFloat(lng))
            : new LatLng(irelandCords.lat, irelandCords.lng),
        publicData: {
          eircode,
          lat,
          lng,
          address,
          county:
            countyInLC?.includes(' ') && countyInLC?.indexOf('county') === 0
              ? countyInLC.split(' ')?.[1]
              : countyInLC,
          allCounties: Array.isArray(county) ? county : null,
          city,
          country,
          address2,
          bpType,
        },
      };
      return updateValues;
    };
    const saveAndExit = values => {
      const updateValues = submitValues(values);
      savenExit(updateValues);
    };
    return (
      <div className={classes}>
        <div className={css.sectionMiddle}>
          <EditListingImage
            className={css.imgSection}
            imageText="Where's your activity located?"
            currentTab={currentTab}
            logoImg={listinglogo}
            desktopImg={clisting5}
            mobileImg={clistingmb5}
            bgIcon={screenicon}
          ></EditListingImage>
          <div className={css.rightSection}>
            <EditListingLocationForm
              className={css.form}
              backButton={backButton}
              tabProgress={tabProgress}
              backbtnLoader={backbtnLoader}
              saveAndExit={saveAndExit}
              initialValues={{
                eircode,
                address2,
                city,
                country,
                lat,
                lng,
                address,
                county,
                bpType,
                allCounties,
              }}
              onSubmit={values => {
                const updateValues = submitValues(values);
                onSubmit(updateValues);
              }}
              history={history}
              onChange={onChange}
              listing={currentListing}
              saveActionMsg={submitButtonText}
              disabled={disabled}
              ready={ready}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              fetchErrors={errors}
              onManageDisableScrolling={onManageDisableScrolling}
              locationOptions={config.custom.locationOptions}
              categories={publicData.categories}
              publicData={publicData}
              activityType={publicData?.activityType}
            />
          </div>
        </div>
      </div>
    );
  }
}

const { func, object, string, bool } = PropTypes;

EditListingLocationPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingLocationPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingLocationPanel;
