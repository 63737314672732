/**
 * Booking breakdown estimation
 *
 * Transactions have payment information that can be shown with the
 * BookingBreakdown component. However, when selecting booking
 * details, there is no transaction object present and we have to
 * estimate the breakdown of the transaction without data from the
 * API.
 *
 * If the payment process of a customized marketplace is something
 * else than simply daily or nightly bookings, the estimation will
 * most likely need some changes.
 *
 * To customize the estimation, first change the BookingDatesForm to
 * collect all booking information from the user (in addition to the
 * default date pickers), and provide that data to the
 * EstimatedBreakdownMaybe components. You can then make customization
 * within this file to create a fake transaction object that
 * calculates the breakdown information correctly according to the
 * process.
 *
 * In the future, the optimal scenario would be to use the same
 * transactions.initiateSpeculative API endpoint as the CheckoutPage
 * is using to get the breakdown information from the API, but
 * currently the API doesn't support that for logged out users, and we
 * are forced to estimate the information here.
 */
import React from 'react';
import Decimal from 'decimal.js';
import { types as sdkTypes } from '../../util/sdkLoader';
import { TRANSITION_REQUEST_PAYMENT, TX_TRANSITION_ACTOR_CUSTOMER } from '../../util/transaction';
import {
  unitDivisor,
  convertMoneyToNumber,
  convertUnitToSubUnit,
  formatMoney,
} from '../../util/currency';
import config from '../../config';
import { BookingBreakdown } from '../../components';
import { BiInfoCircle } from 'react-icons/bi';

import css from './BookingTimeForm.module.css';
import moment from 'moment';
import { humanizeLineItemCode } from '../../util/data';

const { Money, UUID } = sdkTypes;
export const getChildNameFromLineitemCode = (input, pattern) => {
  const regex = new RegExp(`${pattern}-\\((.*?)\\)`);
  const match = input.match(regex);
  return match ? match[1] : null;
};
const estimatedTotalPrice = lineItems => {
  const numericTotalPrice = lineItems.reduce((sum, lineItem) => {
    const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
    return new Decimal(numericPrice).add(sum);
  }, 0);

  // All the lineItems should have same currency so we can use the first one to check that
  // In case there are no lineItems we use currency from config.js as default
  const currency =
    lineItems[0] && lineItems[0].unitPrice ? lineItems[0].unitPrice.currency : config.currency;

  return new Money(
    convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
    currency
  );
};

// When we cannot speculatively initiate a transaction (i.e. logged
// out), we must estimate the transaction for booking breakdown. This function creates
// an estimated transaction object for that use case.
//
// We need to use FTW backend to calculate the correct line items through thransactionLineItems
// endpoint so that they can be passed to this estimated transaction.
const estimatedTransaction = (bookingStart, bookingEnd, lineItems, userRole) => {
  const now = new Date();

  const isCustomer = userRole === 'customer';

  const customerLineItems = lineItems.filter(item => item.includeFor.includes('customer'));
  const providerLineItems = lineItems.filter(item => item.includeFor.includes('provider'));

  const payinTotal = estimatedTotalPrice(customerLineItems);
  const payoutTotal = estimatedTotalPrice(providerLineItems);

  return {
    id: new UUID('estimated-transaction'),
    type: 'transaction',
    attributes: {
      createdAt: now,
      lastTransitionedAt: now,
      lastTransition: TRANSITION_REQUEST_PAYMENT,
      payinTotal,
      payoutTotal,
      lineItems: isCustomer ? customerLineItems : providerLineItems,
      transitions: [
        {
          createdAt: now,
          by: TX_TRANSITION_ACTOR_CUSTOMER,
          transition: TRANSITION_REQUEST_PAYMENT,
        },
      ],
    },
    booking: {
      id: new UUID('estimated-booking'),
      type: 'booking',
      attributes: {
        start: bookingStart,
        end: bookingEnd,
      },
    },
  };
};

const EstimatedBreakdownMaybe = props => {
  const { intl, transaction, isDeposit, depositOptionAmount } = props;
  const { unitType, startDate, endDate, timeZone } = props.bookingData || {};
  const lineItems = transaction ? transaction?.attributes?.lineItems : props.lineItems;
  const depositPrice = isDeposit
    ? new Money(depositOptionAmount?.amount * 100, depositOptionAmount?.currency)
    : null;
  const formattedDeposit = depositPrice ? formatMoney(intl, depositPrice) : null;
  // Currently the estimated breakdown is used only on ListingPage where we want to
  // show the breakdown for customer so we can use hard-coded value here
  const userRole = 'customer';
  const tx = transaction
    ? transaction
    : startDate && endDate && lineItems
    ? estimatedTransaction(startDate, endDate, lineItems, userRole)
    : null;

  return tx ? (
    <div>
      <div className={css.dvth}>
        {lineItems
          .filter(item => item.code.includes('/child'))
          .map(item => {
            const code = item.code;
            const isTrial = item.code.includes('trial');
            const childName = isTrial
              ? code.replace('line-item/trial/child/', '').replace('-', ' ')
              : code.replace('line-item/child/', '').replace('-', ' ');
            const label = isTrial ? (
              <>
                {childName} <span className={css.greyspan}>(trial)</span>
              </>
            ) : (
              <>
                {childName} {isDeposit ? <span className={css.greyspan}>(deposit)</span> : null}
              </>
            );
            const formattedTotal = formatMoney(intl, item.lineTotal);
            return (
              <div key={code}>
                <span>{label}</span>
                <span>{isDeposit && !isTrial ? formattedDeposit : formattedTotal}</span>
              </div>
            );
          })}

        {lineItems
          .filter(
            item =>
              item.code.includes('line-item/early-drop-off-') ||
              item.code.includes('line-item/late-pick-up-')
          )
          .map(item => {
            const code = item.code;
            const type = code.includes('line-item/late-pick-up-')
              ? 'late-pick-up'
              : 'early-drop-off';
            const childName = getChildNameFromLineitemCode(code, type);
            const label = code.includes('line-item/late-pick-up-') ? (
              <>
                Late Pick Up <span className={css.greyspan}>({childName})</span>
              </>
            ) : (
              <>
                Early Drop Off <span className={css.greyspan}>({childName})</span>
              </>
            );
            // quantity && quantity > 1
            // ? `${humanizeLineItemCode(item.code)} x ${quantity}`
            // :
            const formattedTotal = formatMoney(intl, item.lineTotal);
            return (
              <div key={code}>
                <span>{label}</span>
                <span>{formattedTotal}</span>
              </div>
            );
          })}
        <div>
          <span>
            {lineItems.find(l => l.code.includes('service-fee')) && 'Ticketing Fee'}
            <span className={css.tooltipContainer}>
              <span className={css.tooltip}>
                <BiInfoCircle />
              </span>
              <span className={css.tooltipInfo}>
                This helps us run our platform plus includes credit card booking fees.
              </span>
            </span>
          </span>
          <span>
            {lineItems.find(l => l.code.includes('service-fee')) &&
              formatMoney(intl, lineItems.find(l => l.code.includes('service-fee'))?.lineTotal)}
          </span>
        </div>
        <div>
          <span>Balance due later</span>{' '}
          <span>
            -{' '}
            {lineItems &&
            lineItems?.find(l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1)
              ?.lineTotal &&
            lineItems?.find(l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1)
              ?.lineTotal.amount
              ? formatMoney(
                  intl,
                  new Money(
                    lineItems?.find(l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1)
                      ?.lineTotal.amount * -1,
                    config.currency
                  )
                )
              : '€0.00'}
          </span>
        </div>
        {/* <div>
          {' '}
          <span>Total Price Due:</span>
          <span>
            {tx &&
            lineItems?.find(l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1)
              ?.lineTotal &&
            lineItems?.find(l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1)
              ?.lineTotal.amount
              ? formatMoney(
                  intl,
                  new Money(
                    tx?.attributes?.payinTotal?.amount +
                      lineItems?.find(
                        l => l.code.indexOf('line-item/pay-at-venue-deduction') !== -1
                      )?.lineTotal.amount *
                        -1,
                    config.currency
                  )
                )
              : formatMoney(intl, tx?.attributes?.payinTotal)}
          </span>
        </div> */}
      </div>
      <div className={css.dvfr}>
        <div>
          {' '}
          <span>Total Price Today:</span>
          <span>{formatMoney(intl, tx?.attributes?.payinTotal)}</span>
        </div>
      </div>
    </div>
  ) : // <BookingBreakdown
  //   className={css.receipt}
  //   userRole={userRole}
  //   unitType={unitType}
  //   transaction={tx}
  //   booking={tx.booking}
  //   timeZone={timeZone}
  // />
  null;
};

export default EstimatedBreakdownMaybe;
