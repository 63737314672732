import React, { Component } from 'react';
import { array, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, Field } from 'react-final-form';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import isEqual from 'lodash/isEqual';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { nonEmptyArray, composeValidators } from '../../util/validators';
import { isUploadImageOverLimitError, storableError } from '../../util/errors';
import { AddImages, Button, Form, IconSpinner, Modal, ValidationError } from '../../components';
import { BiImage } from 'react-icons/bi';
import css from './EditListingPhotosForm.module.css';
import { AGE, NUMBEROFKIDS, PHOTOS } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { urlToFile } from '../../containers/ManageListingsPage/ManageListingsPage';
import imgss from '../../assets/background-14401.jpg';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import ImageGalleryModal from '../../components/ImageGalleryModal/ImageGalleryModal';
const ACCEPT_IMAGES = 'image/*';
function toDataURL(url, callback) {
  var xhr = new XMLHttpRequest();
  xhr.onload = function() {
    var reader = new FileReader();
    reader.onloadend = function() {
      callback(reader.result);
    };
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.responseType = 'blob';
  xhr.send();
}
// const urls = process.env.REACT_APP_IMAGE_GALLERY_URLS;
// const demoImages = urls.split(',').slice(0, 10);
const demoImages = [
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Athletics%20%26%20Running%20-%20children%20at%20the%20starting%20line%20outdoors.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=9ed69f91fc9940e31200d6535898b0dd52c8ae80148506626691cafee9f54568&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Badminton%20-%20a%20group%20of%20children%20in%20a%20club%20getting%20a%20lesson.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=b3d5f6560371505fd786a9154f7f5ac702fe572f0324f8c9a579fa2ac2b09d4f&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Art%20-%20children%20painting%20at%20easels.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=0250c854b742dca154f980de32c2b0fab390e9d218c03ce5700324736e2d4416&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Art%20-%20children%20getting%20art%20class%20with%20easels%20and%20paints.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=51d6269c84e122f28ca7a0392190604a1cda2a07c405a8b926792a3e29974718&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Art%20-%20children%20doing%20arts%20and%20crafts%20at%20a%20table%20together.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=be019231cd4012c2f0e3ad1804acec16c7d99fb486cc7556bff39b664b179f57&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Art%20-%20children%20doing%20a%20pottery%20class.jpeg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=208f2986b3a9657822250ed19bf5c9b277891298ed68dbb8b1b4cb3cf7fd07f2&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Art%20-%20child%20painting%20and%20decorating%20a%20carnival%20mask.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=50d0f8fe3df6e8122e2871c079c1694f1e07f975fbe8929dbaa2444c8d59f3ef&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Archery%20-%20happy%20girl%20with%20her%20score.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=f196d68bc131551c9be8414eecdec4166685a0f5b72fac33b60c5ef0d0b2aae0&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Archery%20-%20boy%20having%20an%20archery%20lesson.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=12d59678e19e74f5e47ae7e6014bbdb1e19b970e076f7f871228605ab7b7fffa&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Aerial%20and%20Zipline%20adventures%20-%20two%20kids%20high%20in%20trees.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=fdef948279c6a8e2afb2f0d3148bc2c87aa8679f677e373fe903dfda1af561f4&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/Adventure%20Sport%20-%20Child%20Rock%20Climbing.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=ccbfa3cc592ce9f9b8353a444323e66bc738b2fc79e7986409bc0eaabf8ccc7b&X-Amz-SignedHeaders=host',
  'https://provider-profile.s3.ap-south-1.amazonaws.com/upload-image-gallery/?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAVRCTWCEY5AACSHCY%2F20231108%2Fap-south-1%2Fs3%2Faws4_request&X-Amz-Date=20231108T101827Z&X-Amz-Expires=3600&X-Amz-Signature=5dcd19af6e44b039c69ba3eb5b9d8bae618b38e85d200ab6b18b08a1e9cebee6&X-Amz-SignedHeaders=host',
];
export class EditListingPhotosFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageUploadRequested: false,
      maxImageReached: props.initialValues.images?.length >= 5 ? true : false,
      imagesUploaded: [],
      openGallery: false,
      editModal: false,
      currEditImg: null,
      uploadGalleryImageError: null,
      photoConsent: props.initialValues?.photoConsent,
      uploadImageUrls: [],
      urlsLoading: false,
      galleryImgLoading: false,
    };
    this.onImageUploadHandler = this.onImageUploadHandler.bind(this);
    this.submittedImages = [];
  }

  async onImageUploadHandler(file, values) {
    if (file) {
      this.setState({ imageUploadRequested: true });
      this.props
        .onImageUpload({ id: `${file.name}_${Date.now()}`, file })
        .then(() => {
          this.setState({
            imageUploadRequested: false,
            maxImageReached: values.images?.length >= 4 ? true : false,
          });
        })
        .catch(() => {
          this.setState({ imageUploadRequested: false });
        });
    }
  }

  render() {
    return (
      <FinalForm
        {...this.props}
        onImageUploadHandler={this.onImageUploadHandler}
        imageUploadRequested={this.state.imageUploadRequested}
        initialValues={{ images: this.props.images }}
        render={formRenderProps => {
          const {
            form,
            className,
            fetchErrors,
            handleSubmit,
            images,
            imageUploadRequested,
            intl,
            invalid,
            onImageUploadHandler,
            onRemoveImage,
            disabled,
            ready,
            backButton,
            updated,
            updateInProgress,
            tabProgress,
            categories,
            values,
            saveAndExit,
            backbtnLoader,
            onManageDisableScrolling,
            listingImages,
            viewport,
          } = formRenderProps;
          const onImageClick = async i => {
            if (this.state.galleryImgLoading) {
              return;
            }
            this.setState({ galleryImgLoading: i });
            try {
              if (listingImages.length >= 5) {
                return this.setState({
                  uploadGalleryImageError: {
                    message: 'Please remove an image and save the page and then retry uploading',
                  },
                });
              }
              const url = i;
              const imageResp = await axios.post(
                `${apiBaseUrl()}/fileshare/upload-image-from-s3-to-sharetribe`,
                { url },
                {
                  method: 'POST',
                  responseType: 'arraybuffer',
                  headers: { 'Content-Type': 'application/json' },
                }
              );

              const fileResp = imageResp.data;
              const blob = new Blob([fileResp], { type: 'image/jpeg' });
              const file = new File([blob], 'temp.jpeg', { type: 'image/jpeg' });
              this.setState({ galleryImgLoading: false });

              if (listingImages.length <= 4) {
                form.change(`addImage`, file);
                form.blur(`addImage`);
                onImageUploadHandler(file, values);
                this.setState({
                  openGallery: false,
                  uploadGalleryImageError: null,
                });
              }
            } catch (err) {
              console.log(err);
              return this.setState({
                uploadGalleryImageError: storableError(err),
                galleryImgLoading: false,
              });
            }
          };
          const { width } = viewport;
          const tabPercent = tabProgress(PHOTOS);
          const lastTab =
            categories[0] === 'camps' ||
            categories[0] === 'classes' ||
            categories[0] === 'workshops' ||
            categories[0] === 'afterSchoolClub' ||
            categories[0] === 'breakfastClub' ||
            categories[0] === 'placesToVisit' ||
            categories[0] === 'club'
              ? AGE
              : NUMBEROFKIDS;
          const chooseImageText = (
            <span
              className={classNames(css.chooseImageText, {
                [css.atlstonesvg]: values.images?.length > 0,
              })}
            >
              <div
                className={classNames(css.uploadbtn, css.addMar, {
                  [css.atlstone]: values.images?.length > 0,
                })}
              >
                From your device
              </div>
              <BiImage />
              <button
                type="button"
                className={classNames(css.uploadbtn, { [css.atlstone]: values.images?.length > 0 })}
                onClick={e => {
                  e.stopPropagation();
                  this.state.uploadImageUrls.length === 0;
                  this.setState({ openGallery: true });
                }}
              >
                From our library
              </button>
            </span>
          );

          const imageRequiredMessage = intl.formatMessage({
            id: 'EditListingPhotosForm.imageRequired',
          });

          const { publishListingError, showListingsError, updateListingError, uploadImageError } =
            fetchErrors || {};
          const uploadOverLimit = isUploadImageOverLimitError(uploadImageError);

          let uploadImageFailed = null;

          if (uploadOverLimit) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadOverLimit" />
              </p>
            );
          } else if (uploadImageError) {
            uploadImageFailed = (
              <p className={css.error}>
                <FormattedMessage id="EditListingPhotosForm.imageUploadFailed.uploadFailed" />
              </p>
            );
          }

          // NOTE: These error messages are here since Photos panel is the last visible panel
          // before creating a new listing. If that order is changed, these should be changed too.
          // Create and show listing errors are shown above submit button
          const publishListingFailed = publishListingError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.publishListingFailed" />
            </p>
          ) : null;
          const showListingFailed = showListingsError ? (
            <p className={css.error}>
              <FormattedMessage id="EditListingPhotosForm.showListingFailed" />
            </p>
          ) : null;

          const submittedOnce = this.submittedImages.length > 0;
          // imgs can contain added images (with temp ids) and submitted images with uniq ids.
          const arrayOfImgIds = imgs =>
            imgs.map(i => (typeof i.id === 'string' ? i.imageId : i.id));
          const imageIdsFromProps = arrayOfImgIds(images);
          const imageIdsFromPreviousSubmit = arrayOfImgIds(this.submittedImages);
          const imageArrayHasSameImages = isEqual(imageIdsFromProps, imageIdsFromPreviousSubmit);
          const pristineSinceLastSubmit = submittedOnce && imageArrayHasSameImages;

          const submitReady = (updated && pristineSinceLastSubmit) || ready;
          const submitInProgress = updateInProgress;
          const submitDisabled =
            invalid ||
            disabled ||
            submitInProgress ||
            imageUploadRequested ||
            // ready ||
            values.images?.length < 3 ||
            !this.state.photoConsent;
          const classes = classNames(css.root, className);
          const saveExit = (
            <Button
              type="button"
              className={css.exitButton}
              inProgress={backbtnLoader}
              onClick={() => {
                saveAndExit(values);
              }}
            >
              Save & Exit
            </Button>
          );
          const onEditImage = async i => {
            this.setState({ editModal: true, currEditImg: await urlToFile(i) });
          };
          const addImagesMaybe = renderWithin => (
            <AddImages
              className={css.imagesField}
              renderWithin={renderWithin}
              images={images}
              thumbnailClassName={css.thumbnail}
              savedImageAltText={intl.formatMessage({
                id: 'EditListingPhotosForm.savedImageAltText',
              })}
              onRemoveImage={onRemoveImage}
              width={width}
              // onRemoveImage={onEditImage}
            >
              <Field
                id="addImage"
                name="addImage"
                accept={ACCEPT_IMAGES}
                form={null}
                label={chooseImageText}
                type="file"
                disabled={imageUploadRequested || values.images?.length >= 5}
              >
                {fieldprops => {
                  const { accept, input, label, disabled: fieldDisabled } = fieldprops;
                  const { name, type } = input;
                  const onChange = e => {
                    const file = e.target.files[0];
                    form.change(`addImage`, file);
                    form.blur(`addImage`);
                    onImageUploadHandler(file, values);
                  };
                  const inputProps = { accept, id: name, name, onChange, type };
                  return (
                    <div className={`${css.addImageWrapper} ${css.addImageWrapperfst}`}>
                      <div className={css.aspectRatioWrapper}>
                        {fieldDisabled ? null : (
                          <input {...inputProps} className={css.addImageInput} />
                        )}
                        <label htmlFor={name} className={css.addImage}>
                          {label}
                        </label>
                      </div>
                    </div>
                  );
                }}
              </Field>

              <Field
                component={props => {
                  const { input, meta } = props;
                  return (
                    <div className={css.imageRequiredWrapper}>
                      <input {...input} />
                      <ValidationError fieldMeta={meta} />
                    </div>
                  );
                }}
                name="images"
                type="hidden"
                validate={composeValidators(nonEmptyArray(imageRequiredMessage))}
              />
            </AddImages>
          );
          const consentMaybe = (
            <div className={css.consentDecoy}>
              {' '}
              <div className={css.consentDiv}>
                <label htmlFor="photoConsent">
                  <p>
                    I declare that I have the appropriate permission to use these photos from the
                    photographer and person(s) in the photos.{' '}
                  </p>
                  <span className={css.consentSpan}>
                    {this.state.photoConsent ? (
                      <span>
                        <span className={css.inspan}></span>
                      </span>
                    ) : (
                      <span></span>
                    )}
                  </span>
                </label>
                <input
                  type="checkbox"
                  id="photoConsent"
                  value={this.state.photoConsent}
                  onChange={e => {
                    this.setState(
                      state => ({ photoConsent: !state.photoConsent }),
                      () => form.change('photoConsent', this.state.photoConsent)
                    );
                  }}
                />
              </div>
            </div>
          );
          return (
            <Form
              className={classes}
              onSubmit={e => {
                this.submittedImages = images;
                handleSubmit(e);
              }}
            >
              <div className={css.svexbtnsec}>{saveExit}</div>

              {/* <label className={css.lblp}>
                
              </label> */}

              <div
                className={classNames(css.formseclist, { [css.noimg]: values?.images?.length < 1 })}
              >
                <h3 className={css.title}>
                  Add 3 - 5 images. The first image added is your feature image.
                </h3>
                {consentMaybe}
                {addImagesMaybe(true)}
                {/* {addImagesMaybe(true)} */}

                {uploadImageFailed}
              </div>
              <div className={css.formlistbtmsec}>
                <ProgressBar bgcolor="red" progress={tabPercent} height={30} />
                <span className={css.progressSpan}>
                  {values?.images?.length > 0 ? `${values?.images?.length}/5` : '0/5'}
                </span>
                <div className={css.buttonDiv}>
                  <Button
                    className={css.backButton}
                    type="button"
                    onClick={() => backButton(lastTab)}
                  >
                    Back
                  </Button>
                  <Button
                    className={css.submitButton}
                    type="submit"
                    inProgress={submitInProgress}
                    disabled={submitDisabled}
                    ready={submitReady}
                  >
                    Super, let's go!
                  </Button>
                </div>
              </div>
              <ImageGalleryModal
                onImageClick={onImageClick}
                isOpen={this.state.openGallery}
                onModalClose={() =>
                  this.setState({ openGallery: false, uploadGalleryImageError: null })
                }
                onManageDisableScrolling={onManageDisableScrolling}
                galleryImgLoading={this.state.galleryImgLoading}
                error={this.state.uploadGalleryImageError}
              />

              <Modal
                id="EditModal"
                // containerClassName={css.modalContainer}
                // contentClassName={css.modalContent}
                isOpen={this.state.editModal}
                onClose={() => this.setState({ editModal: false })}
                onManageDisableScrolling={onManageDisableScrolling}
              >
                {/* <div className={css.modaldv}>
                  <ImageEditor
                    includeUI={{
                      loadImage: {
                        path: imgss,
                        name: 'SampleImage',
                      },
                      // theme: myTheme,
                      menu: ['shape', 'filter'],
                      initMenu: 'filter',
                      uiSize: {
                        width: '1000px',
                        height: '700px',
                      },
                      menuBarPosition: 'bottom',
                    }}
                    cssMaxHeight={500}
                    cssMaxWidth={700}
                    selectionStyle={{
                      cornerSize: 20,
                      rotatingPointOffset: 70,
                    }}
                    usageStatistics={true}
                  />
                </div> */}
              </Modal>
            </Form>
          );
        }}
      />
    );
  }
}

EditListingPhotosFormComponent.defaultProps = { fetchErrors: null, images: [] };

EditListingPhotosFormComponent.propTypes = {
  fetchErrors: shape({
    publishListingError: propTypes.error,
    showListingsError: propTypes.error,
    uploadImageError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  images: array,
  intl: intlShape.isRequired,
  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default compose(injectIntl)(EditListingPhotosFormComponent);
