import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import classNames from 'classnames';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import { getDefaultTimeZoneOnBrowser, timestampToDate } from '../../util/dates';
import { propTypes } from '../../util/types';
import config from '../../config';
import { MdCheck, MdClose } from 'react-icons/md';
import arrayMutators from 'final-form-arrays';
import { BiInfoCircle } from 'react-icons/bi';
import * as validators from '../../util/validators';
import {
  FieldTextInput,
  Form,
  IconSpinner,
  PrimaryButton,
  ExternalLink,
  Modal,
  FieldCheckbox,
  Button,
  AvatarLarge,
  IconClose,
} from '../../components';
import Tooltip from 'rc-tooltip';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import listinglogo from '../../assets/listinglogowhite.png';
import {
  convertMoneyToNumber,
  convertUnitToSubUnit,
  formatMoney,
  unitDivisor,
} from '../../util/currency';
import { MdBlock } from 'react-icons/md';
import { types as sdkTypes } from '../../util/sdkLoader';
import EstimatedBreakdownMaybe from './EstimatedBreakdownMaybe';
import FieldDateAndTimeInput from './FieldDateAndTimeInput';
import Select from 'react-select';
import css from './BookingTimeForm.module.css';
import { components } from 'react-select';
import { transactionLineItems } from '../../util/api';
import moment from 'moment';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import Decimal from 'decimal.js';
import { Option } from '../../components/ReactSelectCustomComponent/ReactSelectCustomComponent';
import { ReactComponent as CancelIcon } from '../../assets/cancel.svg';

import avimgnew from '../../assets/footerlogo.png';
import { updateProfile } from '../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { FieldArray } from 'react-final-form-arrays';
import { BsFillShieldLockFill } from 'react-icons/bs';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import CustomDateInput from '../../components/DateInput/CustomDateInput';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import { ReactComponent as PlusIconNew } from '../../assets/plus_new.svg';
import PayingOptionsComponent from './PayingOptionsComponent';

const { Money } = sdkTypes;
export class BookingTimeFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isAddChildModalOpen: false,
      startDate: '',
      childFirstName: '',
      childLastName: '',
      childSchoolName: '',
      childStandard: '',
      childStandardError: false,
      child: [],
      childForSelect: [],
      payingOptions: 'fullPayment',
      noOfChild: 0,
      isEmgContOpen: false,
      accordianOpen: [],
      updateProfileLoader: false,
      emgContact: [
        { firstName: '', lastName: '', phnNo: '' },
        { firstName: '', lastName: '', phnNo: '' },
      ],
      selemgconts: [],
      emergencyContacts: [],
      emgContactsFromParents: null,
      addEmgContClicked: false,
      earlyDropOff: [],
      latePickup: [],
      tncModalOpen: false,
      addChildError: false,
      emergencyContactsOptions: [],
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    // this.estimatedTotalPrice = this.estimatedTotalPrice(this);
  }
  componentDidUpdate() {
    if (this.props.currentUser && this.state.emgContactsFromParents === null) {
      this.setState(state => {
        const emg = this.props.currentUser?.attributes?.profile?.protectedData?.emgContacts;
        const isEmgPresent = emg?.length > 0;
        return {
          ...state,
          emgContactsFromParents: isEmgPresent
            ? this.props.currentUser?.attributes?.profile?.protectedData?.emgContacts
            : [],
          emergencyContactsOptions: isEmgPresent
            ? emg.map(e => ({
                key: e.firstName + e.lastName + e.phnNo,
                value: e,
                label: e.firstName,
              }))
            : [],
        };
      });
    }
  }
  estimatedTotalPrice = lineItems => {
    const numericTotalPrice = lineItems?.reduce((sum, lineItem) => {
      const numericPrice = convertMoneyToNumber(lineItem.lineTotal);
      return new Decimal(numericPrice).add(sum);
    }, 0);

    // All the lineItems should have same currency so we can use the first one to check that
    // In case there are no lineItems we use currency from config.js as default
    const currency =
      lineItems[0] && lineItems[0].unitPrice ? lineItems[0].unitPrice.currency : config.currency;

    return new Money(
      convertUnitToSubUnit(numericTotalPrice.toNumber(), unitDivisor(currency)),
      currency
    );
  };
  handleFormSubmit(e) {
    this.props.onSubmit(e);
  }
  // componentDidMount() {
  //   const { startDate, endDate } = this.props.listing.attributes.publicData;
  //   const startDate = timestampToDate(startDate);
  //   const endDate = timestampToDate(endDate);
  //   this.props.onFetchTransactionLineItems({
  //     bookingData: { startDate, endDate },
  //     listingId: this.props.listing.id.uuid,
  //     isOwnListing: this.props.isOwnListing,
  //   });
  // }
  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const startDatePD = this.props.listing.attributes.publicData.startDate;
    const endDatePD = this.props.listing.attributes.publicData.endDate;
    const { child, payingOptions, emgContact } = formValues.values;
    const startDate = startDatePD ? moment(startDatePD).toDate() : null;
    const endDate = endDatePD ? moment(endDatePD).toDate() : null;

    const listingId = this.props.listingId;
    const isOwnListing = this.props.isOwnListing;
    const isSameTime = moment(startDatePD).valueOf() === moment(endDatePD).valueOf();
    const earlyDropOff =
      formValues.values.earlyDropOff?.length > 0 ? formValues.values.earlyDropOff : [];
    const latePickup = formValues.values.latePickup?.length > 0 ? formValues.values.latePickup : [];
    // We expect values bookingStartTime and bookingEndTime to be strings
    // which is the default case when the value has been selected through the form
    if (
      startDate &&
      endDate &&
      child &&
      child?.length > 0 &&
      !isSameTime &&
      !this.props.fetchLineItemsInProgress
    ) {
      this.props.onFetchTransactionLineItems({
        bookingData: {
          startDate,
          endDate,
          quantity: child.length,
          latePickup,
          earlyDropOff,
          child,
          payingOptions,
          emgContact,
        },
        listingId,
        isOwnListing,
      });
    }
  }

  render() {
    const { rootClassName, className, price: unitPrice, initialValues, ...rest } = this.props;
    const classes = classNames(rootClassName || css.root, className);
    if (!unitPrice) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingPriceMissing" />
          </p>
        </div>
      );
    }
    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="BookingTimeForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        initialValuesEqual={() => true}
        mutators={{ ...arrayMutators }}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        initialValues={{
          ...initialValues,
          child: this.state.child,
          earlyDropOff: this.state.earlyDropOff,
          latePickup: this.state.latePickup,
          payingOptions: this.state.payingOptions,
          emgContact: this.state.emergencyContacts,
        }}
        render={fieldRenderProps => {
          const {
            endDatePlaceholder,
            startDatePlaceholder,
            form,
            pristine,
            handleSubmit,
            intl,
            isOwnListing,
            listingId,
            submitButtonWrapperClassName,
            unitType,
            values,
            monthlyTimeSlots,
            timeZone,
            checkoutLoading,
            lineItems,
            listing,
            currentUser,
            history,
            fetchLineItemsInProgress,
            fetchLineItemsError,
            addChild,
            childOptions,
            childListLoading,
            earlyDropOffTime,
            latePickupTime,
            earlyDropOff,
            latePickup,
            categories,
            setHideCross,
            width,
            initialValues,
            pathname,
            isProvider,
            setModalOpen,
            onManageDisableScrolling,
            errors,
          } = fieldRenderProps;
          const currentAuthor = listing.author;
          const {
            startDate,
            endDate,
            age,
            noOfClasses,
            trialFee,
            depositOption,
            depositOptionAmount,
            bookingLimits,
            bookingLimitCount,
            trialClasses,
            extendedChildCare,
            noOfDays,
            classType,
            trialSeats,
            trialOption,
          } = listing.attributes.publicData;
          const remainingFullSeats = listing?.currentStock?.attributes?.quantity || 0;
          const remainingTrialSeats =
            trialSeats - (values?.child || []).filter(f => f.isTrial).length;
          const tncs = listing?.author.attributes.profile.publicData?.tncs;
          const refundPolicy = listing?.author.attributes.profile.publicData?.refundPolicy;
          const ownPolicy = listing?.author.attributes.profile.publicData?.ownPolicy;
          const customerLineItems =
            lineItems && lineItems.filter(item => item.includeFor.includes('customer'));
          const providerLineItems =
            lineItems && lineItems.filter(item => item.includeFor.includes('provider'));
          const payinTotal = !!customerLineItems && this.estimatedTotalPrice(customerLineItems);
          const payoutTotal = !!providerLineItems && this.estimatedTotalPrice(providerLineItems);
          const activityStartDate = moment(startDate).format();
          const activityEndDate = moment(endDate).format();
          const formattedEarlyDropOff = earlyDropOff
            ? formatMoney(intl, new Money(earlyDropOff.amount * 100, earlyDropOff.currency))
            : null;
          const formattedLatePickUp = latePickup
            ? formatMoney(intl, new Money(latePickup.amount * 100, latePickup.currency))
            : null;

          const bookingStartLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingStartTitle',
          });
          const bookingEndLabel = intl.formatMessage({
            id: 'BookingTimeForm.bookingEndTitle',
          });
          const firstNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.firstNameRequired',
          });
          const firstNameRequired = validators.required(firstNameRequiredMessage);
          const lastNameRequiredMessage = intl.formatMessage({
            id: 'SignupForm.lastNameRequired',
          });
          const lastNameRequired = validators.required(lastNameRequiredMessage);
          // const diff = startDate
          //     ? moment(dob).diff(moment(startDate), 'months')
          //     : moment(dob).diff(moment(), 'months');
          //   const absDiff = Math.abs(diff);
          // const diffInYears = absDiff < 11 ? absDiff : Math.floor(absDiff / 12);

          // const startDate = timestampToDate(startDate);
          // const endDate = timestampToDate(endDate);

          // This is the place to collect breakdown estimation data. See the
          // EstimatedBreakdownMaybe component to change the calculations
          // for customized payment processes.
          const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

          const bookingData =
            activityStartDate && activityEndDate && values.child.length !== 0
              ? {
                  unitType,
                  startDate: startDate,
                  endDate: endDate,
                  timeZone: tz,
                }
              : null;
          const onEarlyDropOffChange = e => {
            const childIdAsString = e.target.value;
            const childId = parseInt(childIdAsString);
            const newEarlyDropOffObject = e.target.checked
              ? [...this.state.earlyDropOff, childId]
              : this.state.earlyDropOff.filter(id => id !== childId);
            this.setState({ earlyDropOff: newEarlyDropOffObject });
            form.change('earlyDropOff', newEarlyDropOffObject);
            this.handleOnChange({
              values: { ...values, earlyDropOff: newEarlyDropOffObject },
            });
          };
          const onLatePickupChange = e => {
            const childIdAsString = e.target.value;
            const childId = parseInt(childIdAsString);
            const newEarlyDropOffObject = e.target.checked
              ? [...this.state.latePickup, childId]
              : this.state.latePickup.filter(id => id !== childId);
            this.setState({ latePickup: newEarlyDropOffObject });
            form.change('latePickup', newEarlyDropOffObject);
            this.handleOnChange({
              values: { ...values, latePickup: newEarlyDropOffObject },
            });
          };

          const showEstimatedBreakdown =
            bookingData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError;
          const earlyDropOffMaybe = extendedChildCare.earlyDropOff
            ? childObject => {
                const { id: childId } = childObject;
                const isCurrentlySelected =
                  values.earlyDropOff?.includes(childId) ||
                  this.state.earlyDropOff?.includes(childId);
                return (
                  <div
                    className={classNames(css.additionalFee, {
                      [css.erlydrpslct]: isCurrentlySelected,
                    })}
                  >
                    {/* <FieldCheckbox id="earlyDropOff" name="earlyDropOff" value="earlyDropOff" /> */}
                    <div className={css.earlyDropOff}>
                      <label className={css.erlydrplbl} htmlFor="earlyDropOff">
                        <span>
                          Early Drop-off at{' '}
                          {earlyDropOffTime
                            ? `${
                                earlyDropOffTime.hour < 10
                                  ? `0${earlyDropOffTime.hour}`
                                  : earlyDropOffTime.hour
                              }:${
                                earlyDropOffTime.minute < 10
                                  ? `0${earlyDropOffTime.minute}`
                                  : earlyDropOffTime.minute
                              }`
                            : null}{' '}
                          <span className={css.moneyMult}>
                            {formattedEarlyDropOff} <span>x {noOfDays} days</span>
                          </span>
                        </span>
                        <span className={classNames(css.radiomainspn, css.EarlydropCheckbox)}>
                          {isCurrentlySelected ? (
                            <span>
                              <span className={css.inspan}></span>
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        id={'earlyDropOff' + childId}
                        value={childId}
                        checked={isCurrentlySelected}
                        onChange={onEarlyDropOffChange}
                      />
                    </div>
                  </div>
                );
              }
            : () => null;
          const latePickupMaybe = extendedChildCare?.latePickup
            ? childObject => {
                const { id: childId } = childObject;
                const isCurrentlySelected =
                  values.latePickup?.includes(childId) || this.state.latePickup?.includes(childId);
                return (
                  <div
                    className={classNames(css.additionalFee, {
                      [css.latepckslct]: isCurrentlySelected,
                    })}
                  >
                    {/* <FieldCheckbox id="latePickup" name="latePickup" value="latePickup" /> */}
                    <div className={css.latepickup}>
                      <label htmlFor="latePickup">
                        <span>
                          Late Pick-up at{' '}
                          {latePickupTime
                            ? `${
                                latePickupTime.hour < 10
                                  ? `0${latePickupTime.hour}`
                                  : latePickupTime.hour
                              }:${
                                latePickupTime.minute < 10
                                  ? `0${latePickupTime.minute}`
                                  : latePickupTime.minute
                              }`
                            : null}
                          <span className={css.moneyMult}>
                            {formattedLatePickUp} <span>x {noOfDays} days</span>
                          </span>
                        </span>
                        <span className={classNames(css.radiomainspn, css.EarlydropCheckbox)}>
                          {isCurrentlySelected ? (
                            <span>
                              <span className={css.inspan}></span>
                            </span>
                          ) : (
                            <span></span>
                          )}
                        </span>
                      </label>
                      <input
                        type="checkbox"
                        id={'latePickup' + childId}
                        value={childId}
                        checked={isCurrentlySelected}
                        onChange={onLatePickupChange}
                      />
                    </div>
                  </div>
                );
              }
            : () => null;
          const bookingInfoMaybe =
            showEstimatedBreakdown && values?.child?.length !== 0 ? (
              <div className={css.priceBreakdownContainer}>
                {/* <p>{listing?.attributes?.title}</p> */}
                <EstimatedBreakdownMaybe
                  bookingData={bookingData}
                  lineItems={lineItems}
                  intl={intl}
                  isDeposit={values.payingOptions === 'depositPayment'}
                  depositOptionAmount={depositOptionAmount}
                />
              </div>
            ) : null;
          const loadingSpinnerMaybe = fetchLineItemsInProgress ? (
            <IconSpinner className={css.spinner} />
          ) : null;

          const bookingInfoErrorMaybe = fetchLineItemsError ? (
            <span className={css.sideBarError}>
              <FormattedMessage id="BookingDatesForm.fetchLineItemsError" />
            </span>
          ) : null;

          const submitButtonClasses = classNames(
            submitButtonWrapperClassName || css.submitButtonWrapper
          );

          const startDateInputProps = {
            label: bookingStartLabel,
            placeholderText: startDatePlaceholder,
          };
          const endDateInputProps = {
            label: bookingEndLabel,
            placeholderText: endDatePlaceholder,
          };

          const dateInputProps = {
            startDateInputProps,
            endDateInputProps,
          };
          const customControl = props => (
            <components.Control {...props} className={css.customControl}>
              {props.children}
            </components.Control>
          );
          const customEmgContControl = props => (
            <components.Control
              {...props}
              className={classNames(css.custEmgCnt, {
                [css.contEleg]:
                  this.state.noOfChild === values?.child?.length || values?.emgContact?.length > 0,
              })}
            >
              {props.children}
            </components.Control>
          );
          const customMenu = props => (
            <components.Menu {...props} className={css.customMenu}>
              {props.children}
            </components.Menu>
          );
          const requiredAsterix = <span className={css.redAstx}>*</span>;
          const containsObject = (obj, list) => {
            var x;
            for (x in list) {
              if (list.hasOwnProperty(x) && list[x] === obj) {
                return true;
              }
            }

            return false;
          };
          const SelectMenuButton = props => {
            return (
              <components.MenuList {...props}>
                <div className={css.addchildbtn}>
                  <button
                    type="button"
                    className={css.addchld}
                    onClick={() => {
                      if (isProvider) {
                        return setModalOpen(true);
                      }
                      setHideCross(true);
                      this.setState({ isAddChildModalOpen: true }, () => {
                        if (
                          values?.addChild?.length >= 1 &&
                          (!values?.addChild?.[values.addChild?.length - 1]?.firstName ||
                            !values?.addChild?.[values.addChild?.length - 1]?.lastName ||
                            !values?.addChild?.[values.addChild?.length - 1]?.dob)
                        ) {
                          const elem = document.getElementById(
                            `addChild.${values.addChild?.length - 1}`
                          );
                          if (elem) {
                            elem.scrollIntoView({ behavior: 'smooth' });
                          }
                        } else {
                          form.change('addChild', [
                            ...(values.addChild || []),
                            {
                              id: new Date().getTime(),
                              firstName: '',
                              lastName: '',
                              dob: '',
                              childMedicalNotes: '',
                            },
                          ]);
                          setTimeout(() => {
                            const elem = document.getElementById(
                              `addChild.${values.addChild?.length}`
                            );
                            if (elem) {
                              elem.scrollIntoView({ behavior: 'smooth' });
                            }
                          }, 200);
                        }
                      });
                    }}
                  >
                    {' '}
                    {/* <span><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z"/></svg>
                  </span> */}
                    <PlusIconNew />
                    Add A New Child
                  </button>
                </div>

                <div className={css.customOptions}>{props.children}</div>
              </components.MenuList>
            );
          };
          const EmgContSelectMenuButton = props => {
            return (
              <components.MenuList {...props}>
                <div className={css.addchildbtn}>
                  <button
                    type="button"
                    className={css.addchld}
                    onClick={() => {
                      if (isProvider) {
                        return setModalOpen(true);
                      }
                      setHideCross(true);
                      this.setState({ addEmgContClicked: true, isEmgContOpen: true });
                    }}
                  >
                    <PlusIconNew />
                    Add A New Contact
                  </button>
                </div>

                <div className={css.customOptions}>{props.children}</div>
              </components.MenuList>
            );
          };
          const EmgContSingleValueCont = props => {
            return (
              <components.SingleValue {...props}>
                {this.state.emergencyContacts.length > 1
                  ? `${this.state.emergencyContacts.length} Contacts Selected`
                  : this.state.emergencyContacts.length === 1
                  ? '1 Contact Selected'
                  : 'Select Contact'}
              </components.SingleValue>
            );
          };
          const customOption = props => {
            const diff = startDate
              ? moment(props.value.dob).diff(moment(startDate), 'months')
              : moment(props.value.dob).diff(moment(), 'months');
            const absDiff = Math.abs(diff);
            const diffInYears =
              absDiff < 11 ? `${absDiff} months` : `${Math.floor(absDiff / 12)} years`;
            return (
              <components.Option
                {...props}
                className={classNames(css.customOption, { [css.disabledOption]: props.isDisabled })}
              >
                <div className={css.chldo}>
                  <span>{props.label}</span>
                  <span>
                    {moment(props.value.dob).format('DD-MM-YYYY')}{' '}
                    <span className={css.years}>({diffInYears})</span>
                  </span>
                </div>
              </components.Option>
            );
          };
          const emgContsCustomOption = props => {
            const {
              value: { firstName, lastName, phnNo },
            } = props;
            const currObj = this.state.emergencyContacts.find(
              con =>
                con?.firstName === firstName && con?.lastName === lastName && con?.phnNo === phnNo
            );
            const isSelected = !!currObj?.firstName;
            return (
              <components.Option
                {...props}
                className={classNames(css.customOption, css.customEmgOption, {
                  [css.disabledOption]: props.isDisabled,
                })}
              >
                <div className={css.emgo}>
                  <div>
                    <p>
                      {firstName} {lastName}
                    </p>
                    <p>{phnNo}</p>
                  </div>
                  <span
                    className={classNames(css.emgContCheck, { [css.emgContSelected]: isSelected })}
                  >
                    {isSelected ? <MdCheck className={css.tickSN} /> : null}
                  </span>
                </div>
              </components.Option>
            );
          };

          const isChildDisabled = e => {
            const value = e.value;
            const dob = moment(value.dob);
            const minAge = age[0];
            const maxAge = age[age.length - 1];
            const diff = startDate
              ? moment(dob).diff(moment(startDate), 'months')
              : moment(dob).diff(moment(), 'months');
            const absDiff = Math.abs(diff);
            const diffInYears = absDiff < 11 ? absDiff : Math.floor(absDiff / 12);
            return diffInYears >= minAge && diffInYears <= maxAge ? false : true;
          };

          const addChildMaybe = (
            <>
              <div className={css.flexprice}>
                <button
                  type="button"
                  onClick={() => {
                    if (currentUser?.id) {
                      if (this.state.noOfChild >= 1) {
                        this.setState(
                          state => {
                            const updatedChildren = state.child.slice(0, -1); // Remove the last child
                            const updatedChildForSelect = state.childForSelect.slice(0, -1); // Remove last childForSelect
                            return {
                              noOfChild: state.noOfChild - 1,
                              child: updatedChildren,
                              childForSelect: updatedChildForSelect,
                            };
                          },
                          () => {
                            // Update form and invoke handleOnChange after state is updated
                            form.change('child', this.state.child);
                            this.handleOnChange({ values: { ...values, child: this.state.child } });
                          }
                        );
                      }
                    } else {
                      history.push(
                        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                        { from: pathname }
                      );
                    }
                  }}
                  disabled={this.state.noOfChild === 0}
                  className={css.ageButton}
                >
                  <MinusIcon />
                </button>
                <span className={css.mainPrice}>
                  <input
                    type="text"
                    className={css.mainPriceInput}
                    value={this.state.noOfChild}
                    id="noOfChild"
                    disabled
                    onChange={e => {}}
                    // onBlur={e => {
                    //   if (e.target.value === '') {
                    //     setPrice(0);
                    //   }
                    // }}
                  />
                </span>
                <button
                  type="button"
                  onClick={() => {
                    if (currentUser?.id && this.state.noOfChild < remainingFullSeats) {
                      this.setState(state => {
                        const updatedAccordianOpen = [
                          ...(state.accordianOpen?.filter(a => a !== 'raa-0') || []),
                          'raa-0',
                        ];
                        return {
                          noOfChild: state.noOfChild + 1,
                          accordianOpen: updatedAccordianOpen,
                        };
                      });
                    } else {
                      history.push(
                        createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                        { from: pathname }
                      );
                    }
                  }}
                  className={css.ageButton}
                  disabled={
                    (!!bookingLimits && this.state.noOfChild === bookingLimitCount) ||
                    this.state.noOfChild >= remainingFullSeats
                  }
                >
                  <PlusIcon />
                </button>
              </div>
            </>
          );

          const oneChildCont = (
            <div className={css.childrenbk}>
              {[...Array(this.state.noOfChild).keys()].map((c, i) => {
                // const currChildElement = this.state.childForSelect[i];
                // const currChild = currChildElement?.value;
                const currChild = this.state.child[i];
                const diff = startDate
                  ? moment(currChild?.dob).diff(moment(startDate), 'months')
                  : moment(currChild?.dob).diff(moment(), 'months');
                const absDiff = Math.abs(diff);
                const diffInYears =
                  absDiff < 11 ? `${absDiff} months` : `${Math.floor(absDiff / 12)} years`;

                return (
                  <div key={c} className={css.chldsctn}>
                    <span className={css.nocrscont}>
                      <span>
                        Child {c + 1}
                        <span>*</span>
                      </span>
                    </span>
                    <div>
                      {currChild ? (
                        <div className={css.chlddet}>
                          <div className={css.btautname}>
                            <div className={css.chldNameAge}>
                              <p>
                                {currChild.firstName} {currChild.lastName}
                              </p>
                              <p>
                                {moment(currChild.dob)?.format('Do MMM YYYY')}
                                {/* needs to be done */}
                                <span className={css.months}>({diffInYears})</span>
                                {/* <span className={css.months}>(3 years 2 months)</span> */}
                              </p>
                            </div>

                            <span
                              className={classNames(css.emgctspn, css.CrossIcon)}
                              onClick={() => {
                                this.setState(
                                  state => ({
                                    child: state.child?.filter((ch, idx) => idx !== i),
                                    childForSelect: state.childForSelect?.filter(
                                      (ch, idx) => idx !== i
                                    ),
                                    noOfChild: state.noOfChild - 1,
                                    earlyDropOff: state.earlyDropOff.filter(
                                      e => e !== currChild.id
                                    ),
                                    latePickup: state.latePickup.filter(e => e !== currChild.id),
                                  }),
                                  () => {
                                    form.batch(() => {
                                      form.change('child', this.state.child);
                                      form.change('earlyDropOff', this.state.earlyDropOff);
                                      form.change('latePickup', this.state.latePickup);
                                    });
                                    this.handleOnChange({
                                      values: {
                                        ...values,
                                        child: this.state.child,
                                        earlyDropOff: this.state.earlyDropOff,
                                        latePickup: this.state.latePickup,
                                      },
                                    });
                                  }
                                );
                              }}
                            >
                              <CancelIcon />
                            </span>
                            {(extendedChildCare.latePickup || extendedChildCare.earlyDropOff) && (
                              <div className={css.extendchldCareDiv}>
                                <div className={css.chcdv}>
                                  {/* <p>Additions:</p> */}
                                  <div className={css.chch}>{earlyDropOffMaybe(currChild)}</div>
                                  <div className={css.chch}>{latePickupMaybe(currChild)}</div>
                                </div>
                              </div>
                            )}
                          </div>

                          {categories[0] === 'classes' && trialOption ? (
                            <div className={css.bookngCtgry}>
                              <div
                                className={classNames(css.bookOtps, {
                                  [css.actbookopt]: values.child?.find(
                                    e => e.id === values.child[c]?.id
                                  )?.isTrial,
                                })}
                              >
                                <label
                                  htmlFor={`${i}trial`}
                                  className={classNames(css.trllbl, {
                                    [css.trllbldisbl]:
                                      remainingTrialSeats === 0 &&
                                      !values.child?.find(e => e.id === values.child[c]?.id)
                                        ?.isTrial,
                                  })}
                                >
                                  <span className={css.headspan}>
                                    <span>Trial {classType ? classType?.label : 'classes'}</span>
                                    <span>
                                      {classType ? classType?.label : 'Classes'}: x {trialClasses}{' '}
                                      {trialSeats === 0 ? '(Sold Out)' : null}
                                    </span>
                                    <span>
                                      Cost:{' '}
                                      {trialFee?.amount === 0 ? 'FREE' : `€${trialFee?.amount}`}
                                    </span>
                                  </span>
                                  {remainingTrialSeats === 0 &&
                                  !values.child?.find(e => e.id === values.child[c]?.id)
                                    ?.isTrial ? (
                                    <MdBlock className={css.ampradblck} />
                                  ) : values.child?.find(e => e.id === values.child[c]?.id)
                                      ?.isTrial === true ? (
                                    <span
                                      className={`${css.ampradio} ${css.ampradioselect}`}
                                    ></span>
                                  ) : (
                                    <span className={css.ampradio}></span>
                                  )}
                                </label>
                                <input
                                  type="radio"
                                  name={`${i}bookingOption`}
                                  id={`${i}trial`}
                                  className={css.bkngoptin}
                                  disabled={
                                    remainingTrialSeats === 0 &&
                                    !values.child?.find(e => e.id === values.child[c]?.id)?.isTrial
                                  }
                                  // value={this.state.bookingOption}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      const currIdx = values.child?.findIndex(
                                        e => e.id === values.child[c].id
                                      );
                                      if (this.state.child?.length === 1) {
                                        this.setState({ payingOptions: 'fullPayment' });
                                        form.change('payingOptions', 'fullPayment');
                                      }
                                      if (currIdx < 0) return;
                                      const newObj = {
                                        ...values.child[currIdx],
                                        isTrial: true,
                                      };
                                      const newChild = [...values.child];
                                      newChild[currIdx] = newObj;
                                      this.setState(
                                        state => (state.child = newChild),
                                        () =>
                                          this.handleOnChange({
                                            values: {
                                              ...values,
                                              child: this.state.child,
                                              payingOptions: 'fullPayment',
                                            },
                                          })
                                      );
                                      form.change(`child`, newChild);
                                    }
                                  }}
                                />
                              </div>
                              <div
                                className={classNames(css.bookOtps, {
                                  [css.actbookopt]: !values.child?.find(
                                    e => e.id === values.child[c]?.id
                                  )?.isTrial,
                                })}
                              >
                                <label htmlFor={`${i}full`} className={css.trllbl}>
                                  <span className={css.headspan}>
                                    <span>
                                      Full-term {classType ? classType?.label : 'classes'}
                                    </span>
                                    <span>
                                      {classType ? classType?.label : 'Classes'}: x {noOfClasses}
                                    </span>
                                    <span>Cost: €{listing.attributes.price?.amount / 100}</span>
                                  </span>
                                  {!values.child?.find(e => e.id === values.child[c]?.id)
                                    ?.isTrial ? (
                                    // <GrCheckboxSelected />
                                    <span
                                      className={`${css.ampradio} ${css.ampradioselect}`}
                                    ></span>
                                  ) : (
                                    // <GrCheckbox />
                                    <span className={css.ampradio}></span>
                                  )}
                                </label>
                                <input
                                  type="radio"
                                  defaultChecked={true}
                                  name={`${i}bookingOption`}
                                  id={`${i}full`}
                                  className={css.bkngoptin}
                                  // value={this.state.bookingOption}
                                  onChange={e => {
                                    if (e.target.checked) {
                                      const currIdx = values.child?.findIndex(
                                        e => e.id === values.child[c].id
                                      );
                                      if (currIdx < 0) return;
                                      const newObj = {
                                        ...values.child[currIdx],
                                        isTrial: false,
                                      };
                                      const newChild = [...values.child];
                                      newChild[currIdx] = newObj;
                                      this.setState(
                                        state => (state.child = newChild),
                                        () =>
                                          this.handleOnChange({
                                            values: { ...values, child: this.state.child },
                                          })
                                      );
                                      form.change(`child`, newChild);
                                    }
                                  }}
                                />
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : (
                        <Select
                          placeholderText={'Add a child'}
                          options={childOptions?.filter(
                            c => !this.state.child.find(d => d.id === c.key)
                          )}
                          isSearchable={false}
                          components={{
                            Control: customControl,
                            Menu: customMenu,
                            Option: customOption,
                            MenuList: SelectMenuButton,
                            IndicatorSeparator: () => null,
                          }}
                          className={css.select}
                          isClearable={true}
                          id={`aChild.${c}`}
                          key={`aChild.${c}`}
                          isOptionDisabled={e => isChildDisabled(e)}
                          onChange={e => {
                            if (e !== null) {
                              this.setState(state => ({
                                child: [...state.child, e.value],
                                childForSelect: [...state.childForSelect, e],
                              }));
                              form.change('child', [...values.child, e.value]);
                              this.handleOnChange({
                                values: { ...values, child: [...values.child, e.value] },
                              });
                            }
                          }}
                          value={this.state.childForSelect?.[c] || null}
                        />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          );
          const payingOptions =
            showEstimatedBreakdown && values?.child?.length !== 0 && depositOption ? (
              <PayingOptionsComponent
                values={values}
                intl={intl}
                listing={listing}
                bookingData={{
                  ...bookingData,
                  quantity: this.state.child.length,
                  latePickup: this.state.latePickup,
                  earlyDropOff: this.state.earlyDropOff,
                  child: this.state.child,
                  payingOptions: this.state.payingOptions,
                  emgContact: this.state.emergencyContacts,
                }}
                estimatedTotalPrice={this.estimatedTotalPrice}
                listingId={listingId}
                onOptionChange={(e, payingOptionType) => {
                  if (e.target.checked) {
                    const isDepositPayment = payingOptionType === 'depositPayment';
                    this.setState(state => ({ ...state, payingOptions: payingOptionType }));
                    form.change('payingOptions', payingOptionType);
                    this.handleOnChange({
                      values: {
                        ...values,
                        payingOptions: payingOptionType,
                        ...(isDepositPayment ? { depositAmount: depositOptionAmount.amount } : {}),
                      },
                    });
                  }
                }}
              />
            ) : null;
          const bookingLimitInfo = bookingLimits
            ? `There is a maximum booking limit of ${bookingLimitCount} spaces for this activity.`
            : null;

          const addEmgCont = (
            // <button
            //   type="button"
            //   className={css.emgbtn}
            //   onClick={() => {
            //     if (isProvider) {
            //       return setModalOpen(true);
            //     }
            //     if (currentUser?.id) {
            //       setHideCross(true);
            //       this.setState({ isEmgContOpen: true });
            //     } else {
            //       history.push(
            //         createResourceLocatorString('LoginPage', routeConfiguration(), {}, {}),
            //         { from: pathname }
            //       );
            //     }
            //   }}
            // >
            //   Add Emergency Contact
            // </button>
            <Select
              placeholder={'Select Contact'}
              options={this.state.emergencyContactsOptions}
              // closeMenuOnSelect={this.state.emergencyContacts.length >=  ? true : false}
              components={{
                Control: customEmgContControl,
                Menu: customMenu,
                Option: emgContsCustomOption,
                MenuList: EmgContSelectMenuButton,
                IndicatorSeparator: () => null,
                SingleValue: EmgContSingleValueCont,
              }}
              inputValue={''}
              className={css.select}
              isSearchable={false}
              id={'select-emg-contact'}
              onInputChange={() => {
                return '';
              }}
              onChange={e => {
                if (e !== null) {
                  const isChecked = this.state.emergencyContacts.find(
                    con =>
                      con?.firstName === e.value.firstName &&
                      con?.lastName === e.value.lastName &&
                      con?.phnNo === e.value.phnNo
                  )?.firstName;
                  this.setState(
                    state => ({
                      emergencyContacts: isChecked
                        ? this.state.emergencyContacts.filter(
                            con =>
                              con?.firstName !== e.value.firstName &&
                              con?.lastName !== e.value.lastName &&
                              con?.phnNo !== e.value.phnNo
                          )
                        : state.emergencyContacts.length === 2
                        ? [...state.emergencyContacts.slice(0, -1), e.value]
                        : [...state.emergencyContacts, e.value],
                    }),
                    () => {
                      form.change('emgContact', this.state.emergencyContacts);
                    }
                  );
                }
              }}
            />
          );
          const emgContactsMaybe =
            this.state.emergencyContacts?.length > 0 ? (
              <div className={css.emgdet}>
                {values.emgContact.map((itm, idx) => (
                  <div className={css.emgdecoy} key={idx}>
                    <div className={`${css.dspecnt} ${css.btautname}`}>
                      <div>
                        <p>
                          {itm.firstName} {itm.lastName}
                        </p>
                        <p>{itm.phnNo}</p>
                      </div>
                      {/* <CancelIcon/> */}
                      <MdClose
                        className={css.emgctspn}
                        onClick={() => {
                          this.setState(state => ({
                            emergencyContacts: state.emergencyContacts.filter(
                              v =>
                                !(
                                  v.firstName === itm.firstName &&
                                  v.lastName === itm.lastName &&
                                  v.phnNo === itm.phnNo
                                )
                            ),
                          }));
                          form.change(
                            'emgContact',
                            values.emgContact.filter(
                              v =>
                                !(
                                  v.firstName === itm.firstName &&
                                  v.lastName === itm.lastName &&
                                  v.phnNo === itm.phnNo
                                )
                            )
                          );

                          this.setState({ updateProfileLoader: true });
                          setTimeout(() => this.setState({ updateProfileLoader: false }), 300);
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            ) : null;
          const childArray = (
            <div className={css.fmlydiv}>
              <span>Family Members</span>
              <p>
                For <span>insurance purposes and T&Cs</span> you are advised to only make bookings
                for your own child(ren). Exceptions are made for school activities.
              </p>
              <div className={css.fld} id="child-container">
                <FieldArray name="addChild">
                  {({ fields }) => {
                    const currIdx = fields.length;
                    const showAddNewBtn =
                      values?.addChild?.[currIdx - 1]?.firstName &&
                      values?.addChild?.[currIdx - 1]?.lastName &&
                      values?.addChild?.[currIdx - 1]?.dob;
                    return (
                      <div>
                        {values?.addChild?.length >= 1 && (
                          <button
                            className={classNames(css.AddNewChild, css.adChildTop)}
                            type="button"
                            // disabled={
                            //   !values?.addChild?.[currIdx - 1]?.firstName ||
                            //   !values?.addChild?.[currIdx - 1]?.lastName ||
                            //   !values?.addChild?.[currIdx - 1]?.dob
                            // }
                            onClick={() => {
                              if (
                                !values?.addChild?.[currIdx - 1]?.firstName ||
                                !values?.addChild?.[currIdx - 1]?.lastName ||
                                !values?.addChild?.[currIdx - 1]?.dob
                              ) {
                                const elem = document.getElementById(`addChild.${currIdx - 1}`);
                                return elem.scrollIntoView({ behavior: 'smooth' });
                              }
                              fields.push({
                                id: new Date().getTime(),
                                firstName: '',
                                lastName: '',
                                dob: '',
                                childMedicalNotes: '',
                              });
                              setTimeout(() => {
                                const elem = document.getElementById(`addChild.${currIdx}`);
                                if (elem) {
                                  elem.scrollIntoView({ behavior: 'smooth' });
                                }
                              }, 200);
                            }}
                          >
                            {' '}
                            <PlusIconNew />
                            <span>Add New Child</span>
                          </button>
                        )}
                        {fields.map((f, idx) => (
                          <div id={`addChild.${idx}`} key={idx}>
                            <div>
                              <label>
                                <span className={css.cspn}>Child {idx + 1}</span>
                                <span className={css.xpsn} onClick={() => fields.remove(idx)}>
                                  x
                                </span>
                              </label>
                              <div className={css.flnmdiv}>
                                <div className={css.FirstNameDiv}>
                                  <label className={css.firstName}>
                                    First Name {requiredAsterix}
                                  </label>

                                  <FieldTextInput
                                    id={`${f}.firstName`}
                                    name={`${f}.firstName`}
                                    className={css.policyName}
                                    type="text"
                                    // value={values.child[idx]?.firstName}
                                    placeholder={'First name'}
                                    onChange={e => {
                                      const value = e.target.value;
                                      if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                        form.change(`${f}.firstName`, value);
                                      }
                                    }}
                                    validate={firstNameRequired}
                                  />
                                </div>

                                <div className={css.LastnameDiv}>
                                  <label className={css.lastName}>
                                    Last Name {requiredAsterix}
                                  </label>
                                  <FieldTextInput
                                    id={`${f}.lastName`}
                                    name={`${f}.lastName`}
                                    className={css.policyName}
                                    type="text"
                                    placeholder={'Last name'}
                                    onChange={e => {
                                      const value = e.target.value;
                                      if (/^[a-zA-Z ]+$/.test(value) || value === '') {
                                        form.change(`${f}.lastName`, value);
                                      }
                                    }}
                                    validate={lastNameRequired}
                                  />
                                </div>
                              </div>
                              <div className={css.DateField}>
                                <label className={css.DateLabel}>
                                  Date of Birth {requiredAsterix}{' '}
                                  <Tooltip
                                    placement={width > 767 ? 'left' : 'top'}
                                    // trigger={['click']}
                                    overlayClassName={css.rctooltip}
                                    overlayInnerStyle={{
                                      background: '#002435',
                                      backgroundColor: '#002435',
                                      border: '0',
                                      color: '#fff',
                                    }}
                                    // arrowContent={<div className={css.tooltipArrow}></div>}
                                    overlay={
                                      <p>
                                        Your family's sensitive data is securely stored and shared
                                        only with providers when you make a booking and not with any
                                        other third parties. It helps providers assign appropriate
                                        staff based on the age and needs of attendees. And, your
                                        future bookings will be 85% faster.
                                      </p>
                                    }
                                  >
                                    <span className={css.rctooltipicn}>
                                      <BiInfoCircle />
                                    </span>
                                  </Tooltip>
                                </label>
                                <div className={css.bnsdate}>
                                  <CustomDateInput
                                    initialDate={
                                      values?.addChild ? values.addChild[idx]?.dob : null
                                    }
                                    id={`${f}.dob`}
                                    name={`${f}.dob`}
                                    className={`${css.datepicker}`}
                                    intl={intl}
                                    placeholderText={'DD/MM/YYYY'}
                                    value={values?.child ? values.child[idx]?.dob : null}
                                    block={false}
                                    readOnly={false}
                                    showErrorMessage={true}
                                    validate={validators.customDateRequired('Date is required')}
                                  />
                                </div>
                              </div>

                              <div className={css.mdnts}>
                                {/* <span>Medical Notes</span> */}

                                <label>Medical, Dietary, Additional Needs</label>
                                <FieldTextInput
                                  placeholder={'Shared with providers upon booking.'}
                                  id={`${f}.childMedicalNotes`}
                                  name={`${f}.childMedicalNotes`}
                                  type="textarea"
                                />
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className={css.addChlbtndv}>
                          {showAddNewBtn && (
                            <button
                              className={css.AddNewChild}
                              type="button"
                              disabled={
                                !values?.addChild?.[currIdx - 1]?.firstName ||
                                !values?.addChild?.[currIdx - 1]?.lastName ||
                                !values?.addChild?.[currIdx - 1]?.dob
                              }
                              onClick={() => {
                                fields.push({
                                  id: new Date().getTime(),
                                  firstName: '',
                                  lastName: '',
                                  dob: '',
                                  childMedicalNotes: '',
                                });
                                setTimeout(() => {
                                  const elem = document.getElementById(`addChild.${currIdx}`);
                                  if (elem) {
                                    elem.scrollIntoView({ behavior: 'smooth' });
                                  }
                                }, 200);
                              }}
                            >
                              <PlusIconNew />
                              <span>Add New Child</span>
                            </button>
                          )}
                          <Button
                            inProgress={childListLoading}
                            className={css.csbtn}
                            disabled={errors?.addChild}
                            type="button"
                            onClick={async () => {
                              const oldChildren = new Set(
                                (initialValues?.addChild || [])
                                  .filter(
                                    item => item.id && item.firstName && item.lastName && item.dob
                                  )
                                  .map(item => item.id)
                              );
                              const newChildren = (values?.addChild || []).filter(
                                item => !oldChildren.has(item.id)
                              );
                              const addableChildren = newChildren.filter(
                                c => !isChildDisabled({ value: c, dob: c.dob })
                              );

                              const hasUnaddableNewChildren = newChildren.filter(c =>
                                isChildDisabled({ value: c, dob: c.dob })
                              );
                              const newNoOfChild =
                                (addableChildren.length || 0) +
                                (values.child?.length || 0) +
                                (hasUnaddableNewChildren.length > 0
                                  ? hasUnaddableNewChildren.length
                                  : 0);
                              try {
                                await addChild(values.addChild); // Wait for addChild to complete

                                const updatedChildren = [
                                  ...(values?.child || []),
                                  ...addableChildren,
                                ];

                                form.change('child', updatedChildren);

                                this.setState(
                                  state => ({
                                    child: [...(state.child || []), ...addableChildren],
                                    noOfChild: newNoOfChild,
                                    childForSelect: [
                                      ...(state.childForSelect || []),
                                      ...addableChildren.map(c => ({
                                        key: c.id,
                                        label: `${c.firstName} ${c.lastName}`,
                                        value: c,
                                        dob: c.dob,
                                      })),
                                    ],
                                  }),
                                  () => {
                                    // State update callback
                                    this.handleOnChange({
                                      values: { ...values, child: this.state.child },
                                    });
                                    this.setState({ isAddChildModalOpen: false });
                                  }
                                );
                              } catch (e) {
                                // Handle errors
                                this.setState({ addChildError: true }, () => {
                                  setTimeout(() => this.setState({ addChildError: false }), 2000);
                                });
                              }
                            }}
                          >
                            Save
                          </Button>
                        </div>
                      </div>
                    );
                  }}
                </FieldArray>
              </div>

              <p>{this.state.addChildError ? 'Failed to add child, Try again later' : null}</p>
            </div>
          );

          const trialClassesFullInfo = categories[0] === 'classes' &&
            trialOption &&
            remainingTrialSeats === 0 &&
            values?.child?.length > 0 && (
              <span className={css.trialfull}>*Trial space are now fully booked *</span>
            );
          return (
            <>
              <>
                <Form
                  onSubmit={handleSubmit}
                  className={classes}
                  enforcePagePreloadFor="CheckoutPage"
                >
                  <span
                    className={classNames(css.depositOptionInfo, {
                      [css.limitReached]: this.state.child.length === bookingLimitCount,
                    })}
                  >
                    {bookingLimitInfo}
                  </span>
                  {addChildMaybe}
                  {trialClassesFullInfo}
                  {this.state.noOfChild === 0 ? null : oneChildCont}
                  {this.state.noOfChild > 0 && categories[0] === 'classes' && trialOption ? (
                    <span className={css.sdat}>
                      <span>*</span>Sibling Discount applies to full bookings not trial classes
                      <span>*</span>
                    </span>
                  ) : null}
                  {this.state.noOfChild > 0 ? (
                    <>
                      <span id="Add-Emergency-Contact-Details-heading" className={css.emgcnhead}>
                        Add Emergency Contact Details<span>*</span>
                      </span>
                      <div
                        className={classNames(css.emgcntmndv, {
                          [css.emgsPresent]: this.state.emergencyContacts?.length > 0,
                        })}
                      >
                        {addEmgCont}
                        {emgContactsMaybe}
                      </div>
                    </>
                  ) : null}

                  {payingOptions}

                  {loadingSpinnerMaybe}
                  {values.child?.length > 0 && !loadingSpinnerMaybe && (
                    <h3 className={css.priceBreakdownTitle}>Booking Summary</h3>
                  )}
                  {bookingInfoMaybe}
                  <div className={css.terms}>
                    <span>
                      <span onClick={() => this.setState({ tncModalOpen: true })}>
                        Terms and Conditions Policy
                      </span>
                      <span>*</span>
                    </span>
                    <div>
                      <label htmlFor="termscond" className={css.termslabel}>
                        <span>
                          I have read, understand and agree to the terms and conditions policy.
                        </span>
                        <span className={classNames(css.radiomainspn)}>
                          {values.agreeTerms ? (
                            <span>
                              <span className={css.inspan}></span>
                            </span>
                          ) : (
                            <span></span>
                          )}
                          <input
                            type="checkbox"
                            id="termscond"
                            checked={values.agreeTerms}
                            onChange={e =>
                              currentUser?.id
                                ? form.change('agreeTerms', e.target.checked)
                                : history.push(
                                    createResourceLocatorString(
                                      'SignupPage',
                                      routeConfiguration(),
                                      {},
                                      {}
                                    ),
                                    { from: pathname }
                                  )
                            }
                          />
                        </span>
                      </label>
                    </div>
                  </div>

                  {bookingInfoErrorMaybe}
                  <div className={submitButtonClasses}>
                    <PrimaryButton
                      inProgress={checkoutLoading}
                      type="submit"
                      disabled={
                        !values.child ||
                        values?.child?.length === 0 ||
                        !values.emgContact ||
                        values?.emgContact?.length === 0 ||
                        !values.agreeTerms
                      }
                    >
                      Checkout
                    </PrimaryButton>
                  </div>
                </Form>
              </>

              <Modal
                id="ProfilePage.childModal"
                isOpen={this.state.isAddChildModalOpen}
                onClose={() => {
                  setHideCross(false);
                  this.setState({ isAddChildModalOpen: false });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
                additionalContainerClassname={css.modalCtn}
              >
                {/* {width < 768 ? (
                  <button
                    className={css.modalcros}
                    onClick={() => this.setState({ isAddChildModalOpen: false })}
                  >
                    <IconClose />
                  </button>
                ) : null} */}
                <div className={`${css.contactProviderModal} ${css.modalWidth}`}>
                  <div className={css.emgmain}>{childArray}</div>
                </div>
              </Modal>
              <Modal
                id="ProfilePage.emergencyContact"
                isOpen={this.state.isEmgContOpen}
                // isOpen={true}
                onClose={() => {
                  setHideCross(false);
                  this.setState({ isEmgContOpen: false, addEmgContClicked: false });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                {/* {width < 768 ? (
                  <button
                    className={css.modalcros}
                    onClick={() =>
                      this.setState({ isEmgContOpen: false, addEmgContClicked: false })
                    }
                  >
                    <IconClose />
                  </button>
                ) : null} */}
                <div className={`${css.contactProviderModal} ${css.modalWidth}`}>
                  <div className={css.emgmain}>
                    <div className={css.emgdv}>
                      <span>Emergency Contact Details</span>
                      <h3>
                        Please share an additional contact person we can reach if you're
                        unavailable:
                      </h3>
                      {// this.state.emgContactsFromParents?.length > 0 &&
                      // !this.state.addEmgContClicked

                      false ? (
                        <>
                          <div className={css.emggntsdv}>
                            <label>Select at least 1 Emergency Contact</label>
                            <div className={css.contdv}>
                              {this.state.emgContactsFromParents.map((emg, idx) => (
                                <div key={idx} className={css.echemg}>
                                  <input
                                    key={`${emg.firstName}_${idx}_input`}
                                    type="checkbox"
                                    name="emgContacts"
                                    id={`emgContacts${idx}`}
                                    checked={containsObject(emg, this.state.selemgconts)}
                                    onChange={e => {
                                      if (e.target.checked) {
                                        if (this.state.selemgconts?.length < 2) {
                                          this.setState(prevState => ({
                                            ...prevState,
                                            selemgconts: [...prevState.selemgconts, emg],
                                          }));
                                        } else if (this.state.selemgconts?.length === 2) {
                                          this.setState(
                                            prevState => ({
                                              ...prevState,
                                              selemgconts: [
                                                ...prevState.selemgconts.filter(
                                                  (v, i) => i !== prevState.selemgconts.length - 1
                                                ),
                                              ],
                                            }),
                                            () =>
                                              this.setState(prevState => ({
                                                selemgconts: [...prevState.selemgconts, emg],
                                              }))
                                          );
                                        }
                                      } else {
                                        this.setState(prevState => ({
                                          ...prevState,
                                          selemgconts: prevState.selemgconts.filter(
                                            v =>
                                              !(
                                                v.firstName === emg.firstName &&
                                                v.lastName === emg.lastName &&
                                                v.phnNo === emg.phnNo
                                              )
                                          ),
                                        }));
                                      }
                                    }}
                                  />
                                  <label
                                    key={`${emg.firstName}_${idx}`}
                                    htmlFor={`emgContacts${idx}`}
                                    className={classNames(css.emgcntlbl, {
                                      [css.selemgcnt]: containsObject(emg, this.state.selemgconts),
                                    })}
                                  >
                                    <span>
                                      {emg.firstName} {emg.lastName}
                                    </span>
                                    <span>{emg.phnNo}</span>
                                  </label>
                                </div>
                              ))}
                            </div>
                            <div className={css.addemgbtndv}>
                              {' '}
                              <button
                                type="button"
                                onClick={() => this.setState({ addEmgContClicked: true })}
                                className={css.ademgcntbtn}
                              >
                                Add New Emergency Contact
                              </button>
                            </div>
                          </div>
                          <div className={`${css.subemgcnt} ${submitButtonClasses}`}>
                            <PrimaryButton
                              className={css.cntbtn}
                              type="button"
                              // disabled={this.state.emgContact[0].firstName ? !(this.state.emgContact[0].lastName) }
                              onClick={() => {
                                this.setState({ emergencyContacts: this.state.selemgconts });
                                form.change('emgContact', this.state.selemgconts);
                                this.setState({ isEmgContOpen: false, selemgconts: [] });
                                setHideCross(false);
                              }}
                              disabled={this.state.selemgconts.length === 0}
                            >
                              Continue
                            </PrimaryButton>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className={css.ondiv}>
                            <div>
                              <div className={css.namediv}>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  onChange={e => {
                                    const value = e.target.value;
                                    this.setState(
                                      state => ({
                                        emgContact: [
                                          { ...state.emgContact[0], firstName: value },
                                          state.emgContact[1],
                                        ],
                                      })
                                      // () => form.change('emgContact', this.state.emgContact)
                                    );
                                  }}
                                />
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  onChange={e => {
                                    const value = e.target.value;
                                    this.setState(
                                      state => ({
                                        emgContact: [
                                          { ...state.emgContact[0], lastName: value },
                                          state.emgContact[1],
                                        ],
                                      })
                                      // () => form.change('emgContact', this.state.emgContact)
                                    );
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                placeholder="Phone Number"
                                className={css.phndiv}
                                onChange={e => {
                                  const value = e.target.value;
                                  this.setState(
                                    state => ({
                                      emgContact: [
                                        { ...state.emgContact[0], phnNo: value },
                                        state.emgContact[1],
                                      ],
                                    })
                                    // () => form.change('emgContact', this.state.emgContact)
                                  );
                                }}
                              />
                            </div>
                          </div>
                          {/* <div className={css.ondiv}>
                            <h3>
                              Emergency Contact 2<span>(optional)</span>
                            </h3>
                            <div>
                              <div className={css.namediv}>
                                <input
                                  type="text"
                                  placeholder="First Name"
                                  onChange={e => {
                                    const value = e.target.value;
                                    this.setState(
                                      state => ({
                                        emgContact: [
                                          state.emgContact[0],
                                          { ...state.emgContact[1], firstName: value },
                                        ],
                                      })
                                      // () => form.change('emgContact', this.state.emgContact)
                                    );
                                  }}
                                />
                                <input
                                  type="text"
                                  placeholder="Last Name"
                                  onChange={e => {
                                    const value = e.target.value;
                                    this.setState(
                                      state => ({
                                        emgContact: [
                                          state.emgContact[0],
                                          { ...state.emgContact[1], lastName: value },
                                        ],
                                      })
                                      // () => form.change('emgContact', this.state.emgContact)
                                    );
                                  }}
                                />
                              </div>
                              <input
                                type="text"
                                placeholder="Phone Number"
                                className={css.phndiv}
                                onChange={e => {
                                  const value = e.target.value;
                                  this.setState(
                                    state => ({
                                      emgContact: [
                                        state.emgContact[0],
                                        { ...state.emgContact[1], phnNo: value },
                                      ],
                                    })
                                    // () => form.change('emgContact', this.state.emgContact)
                                  );
                                }}
                              />
                            </div>
                          </div> */}
                          <div className={submitButtonClasses}>
                            <PrimaryButton
                              className={css.cntbtn}
                              type="button"
                              inProgress={this.state.updateProfileLoader}
                              onClick={() => {
                                this.setState({ updateProfileLoader: true });
                                let emgContArr = [];
                                if (
                                  this.state.emgContact[0].firstName !== '' &&
                                  this.state.emgContact[0].lastName !== '' &&
                                  this.state.emgContact[0].phnNo !== ''
                                ) {
                                  emgContArr[0] = this.state.emgContact[0];
                                }
                                if (
                                  this.state.emgContact[1].firstName !== '' &&
                                  this.state.emgContact[1].lastName !== '' &&
                                  this.state.emgContact[1].phnNo !== ''
                                ) {
                                  emgContArr.length === 0
                                    ? (emgContArr[0] = this.state.emgContact[1])
                                    : (emgContArr[1] = this.state.emgContact[1]);
                                }

                                const profile = {
                                  protectedData: {
                                    emgContacts: [
                                      ...this.state.emgContactsFromParents,
                                      ...emgContArr,
                                    ],
                                  },
                                };
                                this.props.sdkInstance.currentUser
                                  .updateProfile(profile)
                                  .then(res => {
                                    return this.props.sdkInstance.currentUser.show();
                                  })
                                  .then(res => {
                                    this.setState(prevState => ({
                                      ...prevState,
                                      updateProfileLoader: false,
                                      isEmgContOpen: false,
                                      addEmgContClicked: false,
                                      emergencyContactsOptions: [
                                        ...prevState.emergencyContactsOptions,
                                        ...emgContArr.map(e => ({
                                          key: e.firstName + e.lastName + e.phnNo,
                                          value: e,
                                          label: e.firstName,
                                        })),
                                      ],
                                      emgContactsFromParents: [
                                        ...res.data.data.attributes.profile.protectedData
                                          .emgContacts,
                                      ],
                                    }));
                                  });
                              }}
                              disabled={
                                !this.state.emgContact[0].firstName ||
                                !this.state.emgContact[0].lastName ||
                                !this.state.emgContact[0].phnNo ||
                                this.state.emgContact[0].phnNo?.length < 9 ||
                                this.state.emgContact[0].phnNo?.length > 11
                              }
                            >
                              Continue
                            </PrimaryButton>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                id="ProfilePage.tncModal"
                isOpen={this.state.tncModalOpen}
                onClose={() => {
                  setHideCross(false);
                  this.setState({ tncModalOpen: false });
                }}
                onManageDisableScrolling={onManageDisableScrolling}
                usePortal
              >
                {width < 768 ? (
                  <button
                    className={css.modalcros}
                    onClick={() => this.setState({ tncModalOpen: false })}
                  >
                    <IconClose />
                  </button>
                ) : null}
                <div className={css.tncdv}>
                  <p>
                    Terms And Conditions from {currentAuthor?.attributes.profile.publicData?.bname}
                  </p>
                  <div>
                    {tncs &&
                      tncs.map(
                        t =>
                          t.firstHeading &&
                          t.value !== '' && (
                            <div className={`${css.revtc} ${css.eachtc}`}>
                              <label>{t.firstHeading}</label>
                              <p>{t.value}</p>
                            </div>
                          )
                      )}
                  </div>
                  <div>
                    {refundPolicy && (
                      <div className={`${css.revtc} ${css.eachtc}`}>
                        <label>REFUND POLICY</label>
                        <p>{refundPolicy?.info}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    {ownPolicy && (
                      <div className={`${css.revtc} ${css.eachtc}`}>
                        <label>YOUR POLICY</label>
                        <p>{ownPolicy}</p>
                      </div>
                    )}
                  </div>
                  <div>
                    <div className={css.service}>
                      {/* <label>YOUR POLICY</label> */}
                      <p>
                        Service fees applied by Activity Time for processing your order are not
                        refundable.
                      </p>
                    </div>
                  </div>
                </div>
              </Modal>
            </>
          );
        }}
      />
    );
  }
}

BookingTimeFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: false,
  listingId: null,
  startDatePlaceholder: null,
  endDatePlaceholder: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

BookingTimeFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,
  listingId: propTypes.uuid,
  monthlyTimeSlots: object,

  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from injectIntl
  intl: intlShape.isRequired,

  // for tests
  startDatePlaceholder: string,
  endDatePlaceholder: string,
};

const BookingTimeForm = compose(injectIntl)(BookingTimeFormComponent);
BookingTimeForm.displayName = 'BookingTimeForm';

export default BookingTimeForm;
