import React, { useEffect, useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';
import * as validators from '../../util/validators';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  Button,
  Form,
  FieldCurrencyInput,
  FieldCheckbox,
  ExternalLink,
  FieldTextInput,
} from '../../components';
import css from './EditListingBookingTypeForm.module.css';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import {
  BOOKINGTYPE,
  GETTINGNEWENQUIRY,
} from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
const { Money } = sdkTypes;

export const EditListingBookingTypeFormComponent = props => (
  <FinalForm
    {...props}
    render={formRenderProps => {
      const {
        className,
        disabled,
        ready,
        handleSubmit,
        intl,
        values,
        initialValues,
        invalid,
        pristine,
        backButton,
        updated,
        updateInProgress,
        tabProgress,
        form,
        publicData,
        history,
        loadingState,
        seatsForUpdate,
      } = formRenderProps;
      const tabPercent = tabProgress(BOOKINGTYPE);
      const classes = classNames(css.root, className);
      const submitReady = (updated && pristine) || ready;
      const [seats, setSeats] = useState(values?.trialSeats || seatsForUpdate);
      const [trialSeats, setTrialSeats] = useState(values.trialSeats);
      const [bookingLimitCount, setBookingLimitCount] = useState(
        !!values.bookingLimitCount ? values.bookingLimitCount : 0
      );
      const [notificationLimitCount, setNotificationLimitCount] = useState(
        !!values.notificationLimitCount ? values.notificationLimitCount : 0
      );
      useEffect(() => {
        setSeats(seatsForUpdate);
      }, [seatsForUpdate]);
      const submitInProgress = updateInProgress;
      const submitDisabled = invalid || disabled || submitInProgress;
      const lastTab = GETTINGNEWENQUIRY;
      const trialSeatsMaybe = (
        <div className={css.flexprice}>
          <button
            type="button"
            onClick={() => {
              if (trialSeats >= 1) setTrialSeats(state => state - 1);
              form.change('trialSeats', Math.max(0, trialSeats - 1));
            }}
            disabled={trialSeats === 0}
            className={css.ageButton}
          >
            -
          </button>
          <span className={css.mainPrice}>
            {
              <input
                type="text"
                className={css.mainPriceInput}
                value={trialSeats}
                id="trialSeats"
                onChange={e => {
                  if (/^[0-9]/.test(e.target.value)) {
                    setTrialSeats(Number.parseInt(e.target.value));
                    form.change('trialSeats', Number.parseInt(e.target.value));
                  }

                  if (e.target.value === '') {
                    form.change('trialSeats', 0);
                    setTrialSeats(0);
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setTrialSeats(0);
                  }
                }}
              />
            }
          </span>
          <button
            type="button"
            onClick={() => {
              setTrialSeats(state => state + 1);
              form.change('trialSeats', trialSeats + 1);
            }}
            className={css.ageButton}
            // disabled={maxAge === 18}
          >
            +
          </button>
        </div>
      );
      const seatsMaybe = (
        <div className={css.flexprice}>
          <button
            type="button"
            onClick={() => {
              if (seats >= 1) setSeats(state => state - 1);
              form.change('seats', Math.max(0, seats - 1));
            }}
            disabled={seats === 0}
            className={css.ageButton}
          >
            -
          </button>
          <span className={css.mainPrice}>
            {
              <input
                type="text"
                className={css.mainPriceInput}
                value={seats}
                id="price"
                onChange={e => {
                  if (/^[0-9]/.test(e.target.value)) {
                    setSeats(Number.parseInt(e.target.value));
                    form.change('seats', Number.parseInt(e.target.value));
                  }

                  if (e.target.value === '') {
                    form.change('seats', 0);
                    setSeats(0);
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setSeats(0);
                  }
                }}
              />
            }
          </span>
          <button
            type="button"
            onClick={() => {
              setSeats(state => state + 1);
              form.change('seats', seats + 1);
            }}
            className={css.ageButton}
            // disabled={maxAge === 18}
          >
            +
          </button>
        </div>
      );
      const bookingLimitCountMaybe = (
        <div className={css.flexprice}>
          <button
            type="button"
            onClick={() => {
              if (bookingLimitCount >= 1) setBookingLimitCount(state => state - 1);
              form.change('bookingLimitCount', Math.max(0, bookingLimitCount - 1));
            }}
            disabled={bookingLimitCount === 0}
            className={css.ageButton}
          >
            -
          </button>
          <span className={css.mainPrice}>
            {
              <input
                type="text"
                className={css.mainPriceInput}
                value={bookingLimitCount}
                id="price"
                onChange={e => {
                  if (/^[0-9]/.test(e.target.value) && e.target.value < seats) {
                    setBookingLimitCount(Number.parseInt(e.target.value));
                    form.change('bookingLimitCount', Number.parseInt(e.target.value));
                  }

                  if (e.target.value === '') {
                    form.change('bookingLimitCount', 0);
                    setBookingLimitCount(null);
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setBookingLimitCount(0);
                  }
                }}
              />
            }
          </span>
          <button
            type="button"
            onClick={() => {
              if (bookingLimitCount < seats) {
                setBookingLimitCount(state => state + 1);
                form.change('bookingLimitCount', bookingLimitCount + 1);
              }
            }}
            className={css.ageButton}
            // disabled={maxAge === 18}
          >
            +
          </button>
        </div>
      );
      const notificationCountMaybe = (
        <div className={css.flexprice}>
          <button
            type="button"
            onClick={() => {
              if (notificationLimitCount >= 1) setNotificationLimitCount(state => state - 1);
              form.change('notificationLimitCount', Math.max(0, notificationLimitCount - 1));
            }}
            disabled={notificationLimitCount === 0}
            className={css.ageButton}
          >
            -
          </button>
          <span className={css.mainPrice}>
            {
              <input
                type="text"
                className={css.mainPriceInput}
                value={notificationLimitCount}
                id="price"
                onChange={e => {
                  if (/^[0-9]/.test(e.target.value) && e.target.value < seats) {
                    setNotificationLimitCount(Number.parseInt(e.target.value));
                    form.change('notificationLimitCount', Number.parseInt(e.target.value));
                  }

                  if (e.target.value === '') {
                    form.change('notificationLimitCount', 0);
                    setNotificationLimitCount(null);
                  }
                }}
                onBlur={e => {
                  if (e.target.value === '') {
                    setNotificationLimitCount(0);
                  }
                }}
              />
            }
          </span>
          <button
            type="button"
            onClick={() => {
              if (notificationLimitCount < seats) {
                setNotificationLimitCount(state => state + 1);
                form.change('notificationLimitCount', notificationLimitCount + 1);
              }
            }}
            className={css.ageButton}
            // disabled={maxAge === 18}
          >
            +
          </button>
        </div>
      );
      const trialOptionMaybe = (
        <div className={css.trial}>
          {' '}
          <label className={css.trialLabel}>
            Trial spaces:<span className={css.trialspn}> available for activity?</span>
          </label>
          <div className={css.trialOptions}>
            <div className={css.optionDiv}>
              <label htmlFor="offerInstantBooking">
                <span>Offer Express Booking</span>
                <span>
                  {values.trialMethod === 'offerInstantBooking' ? (
                    <GrCheckboxSelected className={css.checkboxIconTrial} />
                  ) : (
                    <GrCheckbox className={css.checkboxIconTrial} />
                  )}
                </span>
              </label>

              <input
                type="radio"
                id="offerInstantBooking"
                value="offerInstantBooking"
                name="trialOption"
                onChange={e => form.change('trialMethod', e.target.value)}
                className={css.otherCheckboxesInput}
              />
            </div>
            <div className={css.optionDiv}>
              <label htmlFor="uponRequest">
                <span>Only upon request</span>
                <span>
                  {values.trialMethod === 'uponRequest' ? (
                    <GrCheckboxSelected className={css.checkboxIconTrial} />
                  ) : (
                    <GrCheckbox className={css.checkboxIconTrial} />
                  )}
                </span>
              </label>

              <input
                type="radio"
                id="uponRequest"
                value="uponRequest"
                name="trialOption"
                onChange={e => form.change('trialMethod', e.target.value)}
                className={css.otherCheckboxesInput}
              />
            </div>
          </div>
        </div>
      );
      const waitingListMaybe = (
        <div className={css.bborow}>
          <input
            type="checkbox"
            id="waitingList"
            defaultChecked={values.waitingList}
            onChange={e => form.change('waitingList', e.target.checked)}
            className={css.otherCheckboxesInput}
          />
          <label htmlFor="waitingList" className={css.otherCheckboxesLabel}>
            <span className={css.checkicon}>
              {values.waitingList ? (
                <GrCheckboxSelected className={css.checkboxIcon} />
              ) : (
                <GrCheckbox className={css.checkboxIcon} />
              )}
            </span>
            <div className={css.labelDiv}>
              <span className={css.redLabel}>Join Waiting List</span> – turn on this feature for
              parents
            </div>{' '}
          </label>
        </div>
      );
      const notificationMaybe = (
        <>
          <div className={css.bborow}>
            <input
              type="checkbox"
              id="notification"
              defaultChecked={values.notification}
              onChange={e => form.change('notification', e.target.checked)}
              className={css.otherCheckboxesInput}
            />
            <label htmlFor="notification" className={css.otherCheckboxesLabel}>
              <span className={css.checkicon}>
                {values.notification ? (
                  <GrCheckboxSelected className={css.checkboxIcon} />
                ) : (
                  <GrCheckbox className={css.checkboxIcon} />
                )}
              </span>
              <div className={css.labelDiv}>
                <span className={css.redLabel}>Notification</span> – get email when spaces get low
              </div>{' '}
            </label>
          </div>
          {values.notification ? (
            <div className={css.extraInfo}>
              <label>
                I would like to get notified when the spaces get <span>down to...</span>
              </label>
              {notificationCountMaybe}
            </div>
          ) : null}
        </>
      );
      const bookingLimitsMaybe = (
        <>
          <div className={css.bborow}>
            <input
              type="checkbox"
              id="bookingLimits"
              defaultChecked={values.bookingLimits}
              onChange={e => form.change('bookingLimits', e.target.checked)}
              className={css.otherCheckboxesInput}
            />
            <label htmlFor="bookingLimits" className={css.otherCheckboxesLabel}>
              <span className={css.checkicon}>
                {values.bookingLimits ? (
                  <GrCheckboxSelected className={css.checkboxIcon} />
                ) : (
                  <GrCheckbox className={css.checkboxIcon} />
                )}
              </span>
              <div className={css.labelDiv}>
                <span className={css.redLabel}>Booking Limits</span> - control number of bookings by
                one person
              </div>{' '}
            </label>
          </div>
          {values.bookingLimits ? (
            <div className={css.extraInfo}>
              <label>
                The maximum <span>spaces</span> one person can book is:
              </label>
              {bookingLimitCountMaybe}
            </div>
          ) : null}
        </>
      );
      const saveExit = (
        <button
          className={css.exitButton}
          type="button"
          onClick={() => {
            props.onSubmit(values);
            history.push(createResourceLocatorString('LandingPage', routeConfiguration(), {}, {}));
          }}
          disabled={!values.categories || values.categories?.length === 0}
        >
          Save & Exit
        </button>
      );
      const totalSeatsMaybe = publicData?.trialOption && (
        <h2 className={css.totalbkabl}>
          Total number of bookable spaces: {Number(seats) + Number(trialSeats)}
        </h2>
      );
      return (
        <Form onSubmit={handleSubmit} className={classes}>
          <div className={css.svexbtnsec}>{saveExit}</div>
          <div className={`${css.formseclist} ${css.bookingTypeDiv}`}>
            {publicData.trialOption ? (
              <h2>
                Number of <span>full-term spaces</span> available?
              </h2>
            ) : (
              <h2>
                <span>Total spaces</span> available for activity?
              </h2>
            )}

            <div className={css.seats}>{seatsMaybe}</div>
            {publicData?.trialOption && (
              <h2 className={css.martopzero}>
                Number of <span>bookable trial spaces</span> available?
              </h2>
            )}
            {publicData.trialOption ? trialSeatsMaybe : null}
            {publicData.trialOption ? totalSeatsMaybe : null}
            <div className={css.otherCheckboxes}></div>
            {waitingListMaybe}
            {notificationMaybe}
            {bookingLimitsMaybe}
          </div>
          <div className={css.formlistbtmsec}>
            <ProgressBar progress={tabPercent} />
            <div className={css.buttonDiv}>
              <Button type="button" className={css.backButton} onClick={() => backButton(lastTab)}>
                Back
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={loadingState || submitInProgress}
                disabled={
                  submitDisabled ||
                  (publicData.trialMethod ? !values.trialMethod : false) ||
                  (!values.isSeatsSaved && !values.seats) ||
                  (values.notification && !values.isSeatsSaved && notificationLimitCount === 0) ||
                  (values.bookingLimits && !values.isSeatsSaved && bookingLimitCount === 0)
                }
                ready={submitReady}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingBookingTypeFormComponent.defaultProps = { fetchErrors: null };

EditListingBookingTypeFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingBookingTypeFormComponent);
