import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';

import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import css from './EditListingWhatToBringPanel.module.css';

import EditListingImage from '../EditListingImage/EditListingImage';

import EditListingWhatToBringForm from '../../forms/EditListingWhatToBring/EditListingWhatToBringForm';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-11-icon.png';

const EditListingWhatToBringPanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    currentTab,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = currentListing.attributes;
  const { whatToBring, additionalInfo, categories } = publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText={`Let’s tell them ${
            publicData.categories?.includes('afterSchoolClub') ||
            publicData.categories?.includes('birthdayParty') ||
            publicData.categories?.includes('breakfastClub') ||
            publicData.categories?.includes('schoolTours') ||
            publicData.categories?.includes('inSchoolProgrammes') ||
            publicData.categories?.includes('events')
              ? "what's included"
              : categories[0] === 'club' || categories[0] === 'placesToVisit'
              ? 'what to know'
              : 'what to bring'
          } `}
          currentTab={currentTab}
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
          bgIcon={screenicon}
        />
        <div className={css.rightSection}>
          <EditListingWhatToBringForm
            isPublished={isPublished}
            className={css.form}
            backbtnLoader={backbtnLoader}
            backButton={backButton}
            tabProgress={tabProgress}
            initialValues={{
              whatToBring,
              additionalInfo,
            }}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { whatToBring, additionalInfo } = values;
              const updateValues = {
                publicData: {
                  whatToBring,
                  additionalInfo,
                },
              };
              onSubmit(updateValues);
            }}
            history={history}
            savenExit={savenExit}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
            publicData={listing.attributes.publicData}
          />
        </div>
      </div>
    </div>
  );
};

EditListingWhatToBringPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingWhatToBringPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingWhatToBringPanelComponent;
