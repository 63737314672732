import React, { useState } from 'react';
import { bool, func, shape, string } from 'prop-types';
import classNames from 'classnames';
import { Form as FinalForm } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { findOptionsForSelectFilter } from '../../util/search';
import { propTypes } from '../../util/types';
import config from '../../config';
import { Button, FieldRadioButton, Form, FieldTextInput, FieldCheckbox } from '../../components';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

import css from './EditListingNumberOfKidsForm.module.css';
import { AGE, NUMBEROFKIDS } from '../../components/EditListingWizard/EditListingWizardTab';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';

const EditListingNumberOfKidsFormComponent = props => (
  <FinalForm
    {...props}
    mutators={{ ...arrayMutators }}
    render={formRenderProps => {
      const {
        disabled,
        ready,
        rootClassName,
        className,
        handleSubmit,
        pristine,
        updated,
        values,
        updateInProgress,
        fetchErrors,
        form,
        backButton,
        initialValues,
        publicData,
        tabProgress,
        savenExit,
      } = formRenderProps;
      const tabPercent = tabProgress(NUMBEROFKIDS);
      const classes = classNames(rootClassName || css.root, className);
      const submitReady = (updated && pristine) || ready;
      const submitInProgress = updateInProgress;
      const submitDisabled = disabled || submitInProgress;
      const [minNo, setMinNo] = useState(!initialValues.minNo ? 0 : initialValues.minNo);
      const [maxNo, setMaxNo] = useState(!initialValues.maxNo ? 0 : initialValues.maxNo);

      const { updateListingError, showListingsError } = fetchErrors || {};
      const errorMessage = updateListingError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAgeForm.updateFailed" />
        </p>
      ) : null;
      const errorMessageShowListing = showListingsError ? (
        <p className={css.error}>
          <FormattedMessage id="EditListingAgeForm.showListingFailed" />
        </p>
      ) : null;
      const lastTab = AGE;
      const maxAgeMaybe = (
        <div className={css.maxAgeContainer}>
          <label>Max No.</label>
          <div className={css.butcon}>
            <button
              type="button"
              onClick={() => {
                maxNo > 0 ? setMaxNo(state => state - 1) : null;
                form.change('maxNo', maxNo);
              }}
              disabled={maxNo === 0}
              className={css.ageButton}
            >
              -
            </button>
            <input
              className={css.agefigure}
              type="number"
              value={maxNo}
              onChange={e => {
                const value = e.target.value;
                if (value === '') {
                  setMaxNo(0);
                  form.change('maxNo', value);
                }
                if (!isNaN(value)) {
                  setMaxNo(value);
                  form.change('maxNo', value);
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                setMaxNo(state => state + 1);
                form.change('maxNo', maxNo);
              }}
              className={css.ageButton}
            >
              +
            </button>
          </div>
        </div>
      );
      const minAgeMaybe = (
        <div className={css.minAgeContainer}>
          <label>Min No.</label>
          <div className={css.butcon}>
            <button
              type="button"
              onClick={() => {
                minNo > 0 ? setMinNo(state => state - 1) : null;
                form.change('minNo', minNo);
              }}
              disabled={minNo === 0}
              className={css.ageButton}
            >
              -
            </button>
            <input
              className={css.agefigure}
              type="number"
              value={minNo}
              onChange={e => {
                const value = e.target.value;
                if (value === '') {
                  setMinNo(0);
                  form.change('minNo', value);
                }
                if (!isNaN(value)) {
                  setMinNo(value);
                  form.change('minNo', value);
                }
              }}
            />
            <button
              type="button"
              onClick={() => {
                setMinNo(state => state + 1);
                form.change('minNo', minNo);
              }}
              className={css.ageButton}
            >
              +
            </button>
          </div>
        </div>
        // </div>
      );
      const minMaxAge = (
        <>
          <div className={css.agecon}>
            {minAgeMaybe}
            {maxAgeMaybe}

            {/* {minNo > maxNo ? (
              <span className={css.error}>Minimum kids should be lesser than maximum kids</span>
            ) : null}
            {maxNo > 122 ? <span className={css.error}>Enter valid number</span> : null}
            {minNo === maxNo && minNo !== 0 && maxNo !== 0 ? (
              <span className={css.error}>
                Minimum number of kids and maximum number of kids is same
              </span>
            ) : null} */}
          </div>
        </>
      );
      const unltdNumberMaybe = (
        <div className={css.siblingDiscountCheck}>
          <input
            type="checkbox"
            id="unltdNumber"
            value="unltdNumber"
            onChange={e => form.change('unltdNumber', e.target.checked)}
            defaultChecked={initialValues?.unltdNumber}
          />{' '}
          <label htmlFor="unltdNumber" className={css.otherCheckboxesLabel}>
            <span className={css.checkicon}>
              {values.unltdNumber ? (
                <GrCheckboxSelected className={css.checkboxIcon} />
              ) : (
                <GrCheckbox className={css.checkboxIcon} />
              )}
            </span>
            <div className={css.labelDiv}>
              <span>Unlimited No.</span> –
            </div>{' '}
          </label>
        </div>
      );
      const saveExit = (
        <Button
          className={css.exitButton}
          type="button"
          onClick={() => {
            const { minNo, maxNo, unltdNumber } = values;
            let updatedValues;
            if (
              categories === 'birthdayParty' ||
              categories === 'afterSchoolClub' ||
              categories === 'schoolTours' ||
              categories === 'inSchoolProgrammes'
            ) {
              updatedValues = {
                publicData: {
                  noOfKids: { maxNo, minNo },
                },
              };
              savenExit(updatedValues);
            }
            if (categories === 'events') {
              updatedValues = {
                publicData: {
                  noOfKids: { maxNo, minNo: 0 },
                  unltdNumber,
                },
              };
              savenExit(updatedValues);
            }
          }}
        >
          Save & Exit
        </Button>
      );
      return (
        <Form
          className={classes}
          onSubmit={e => {
            e.preventDefault();
            props.onSubmit({ maxNo, minNo, unltdNumber: values?.unltdNumber });
          }}
        >
          <div className={css.svexbtnsec}>{saveExit}</div>
          <div className={css.formseclist}>
            {publicData.categories?.includes('afterSchoolClub') ||
            publicData.categories?.includes('birthdayParty') ||
            publicData.categories?.includes('schoolTours') ||
            publicData.categories?.includes('inSchoolProgrammes') ? (
              minMaxAge
            ) : publicData.categories?.includes('events') ? (
              <div className={css.agecon}>
                {' '}
                {maxAgeMaybe}
                {unltdNumberMaybe}
              </div>
            ) : null}
          </div>

          <div className={css.formlistbtmsec}>
            <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
            <div className={css.buttonDiv}>
              <Button type="button" className={css.backButton} onClick={() => backButton(lastTab)}>
                Back
              </Button>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={
                  publicData.categories?.includes('events')
                    ? submitDisabled || (maxNo === 0 && !values.unltdNumber)
                    : submitDisabled ||
                      maxNo === 0 ||
                      minNo === 0 ||
                      minNo > maxNo ||
                      minNo === maxNo
                }
                ready={submitReady}
              >
                Next
              </Button>
            </div>
          </div>
        </Form>
      );
    }}
  />
);

EditListingNumberOfKidsFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingNumberOfKidsFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  name: string.isRequired,
  onSubmit: func.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  filterConfig: propTypes.filterConfig,
};

const EditListingNumberOfKidsForm = EditListingNumberOfKidsFormComponent;

export default EditListingNumberOfKidsForm;
