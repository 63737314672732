import React, { useState } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import classNames from 'classnames';
import css from './DownloadListComponent.module.css';
import moment from 'moment';
const DownloadListComponent = props => {
  const { list = [], containerClassName, btnClassName, currentListing } = props;
  const [downloadLoading, setDownloadLoading] = useState(false);
  const divClassname = classNames(css.divClass, containerClassName);
  const buttonClass = classNames(css.btnClass, btnClassName);
  const currentTitle = currentListing?.attributes?.title;
  const currentLoc =
    currentListing?.attributes?.publicData?.activityMode === 'online'
      ? 'Online'
      : currentListing?.attributes?.publicData?.city ||
        currentListing?.attributes?.publicData?.county;
  const currentDate =
    currentListing?.attributes?.publicData?.categories?.[0] === 'events' ||
    currentListing?.attributes?.publicData?.categories?.[0] === 'workshops'
      ? moment(currentListing?.attributes?.publicData?.startDate).format('MMM Do, YYYY')
      : `${moment(currentListing?.attributes?.publicData?.startDate).format('MMM Do')} – ${moment(
          currentListing?.attributes?.publicData?.endDate
        ).format('MMM Do, YYYY')}`;

  const currentPrice =
    currentListing?.attributes?.price?.amount === 0
      ? 'Free'
      : `€${currentListing?.attributes?.price?.amount / 100}`;
  const currentAge =
    (currentListing?.attributes?.publicData?.categories?.[0] === 'events' ||
      currentListing?.attributes?.publicData?.categories?.[0] === 'placesToVisit') &&
    currentListing?.attributes?.publicData?.allAges
      ? 'All Ages'
      : `${currentListing?.attributes?.publicData?.age[0]} - ${
          currentListing?.attributes?.publicData?.age[
            currentListing?.attributes?.publicData?.age?.length - 1
          ]
        }`;
  const currentTime = `${
    currentListing?.attributes?.publicData?.startTime?.hour >= 10
      ? currentListing?.attributes?.publicData?.startTime?.hour
      : `0${currentListing?.attributes?.publicData?.startTime?.hour}`
  }.${
    currentListing?.attributes?.publicData?.startTime?.minute >= 10
      ? currentListing?.attributes?.publicData?.startTime?.minute
      : `0${currentListing?.attributes?.publicData?.startTime?.minute}`
  }–${
    currentListing?.attributes?.publicData?.endTime?.hour >= 10
      ? currentListing?.attributes?.publicData?.endTime?.hour
      : `0${currentListing?.attributes?.publicData?.endTime?.hour}`
  }.${
    currentListing?.attributes?.publicData?.endTime?.minute >= 10
      ? currentListing?.attributes?.publicData?.endTime?.minute
      : `0${currentListing?.attributes?.publicData?.endTime?.minute}`
  }`;
  const docTitle =
    `ATTENDEE LIST: ` +
    `${currentTitle} • ${currentLoc} • ${currentDate} • ${currentTime} • ${currentAge} • ${currentPrice}`;
  const getPrintList = () => {
    const formattedList = list.map(item => {
      const { childDetails, transaction } = item;
      const isDeposit = transaction.attributes.protectedData.payingOptions === 'depositPayment';
      const depositOptionAmount = isDeposit
        ? currentListing.attributes.publicData?.depositOptionAmount
        : null;
      const emergencyContacts = transaction.attributes.protectedData.emgContact;
      const customerDetails = transaction.attributes.protectedData.customerDetails;
      const lineItems = transaction.attributes.lineItems;
      const childPayment = lineItems.find(
        l => l.code.indexOf(childDetails.firstName) !== -1 && l.code.indexOf(childDetails.lastName)
      );
      const Booked = moment(transaction.attributes.createdAt).format('DD[-]MM[-]YYYY');
      const Due =
        isDeposit && !childDetails?.isTrial
          ? `€${childPayment.lineTotal.amount / 100 - depositOptionAmount.amount}`
          : '€0';
      const Paid = isDeposit
        ? `€${depositOptionAmount.amount}`
        : `€${childPayment.lineTotal.amount / 100}` +
          ' ' +
          `${
            isDeposit && !childDetails?.isTrial
              ? '(deposit)'
              : childDetails?.isTrial
              ? '(trial)'
              : ''
          }`;
      const firstEmg =
        `${emergencyContacts[0].firstName} ${emergencyContacts[0].lastName}` +
        ' ' +
        `${emergencyContacts[0].phnNo}`;
      const secondEmg =
        emergencyContacts && emergencyContacts?.[1]
          ? `${emergencyContacts[1].firstName} ${emergencyContacts[1].lastName}` +
            ' ' +
            `${emergencyContacts[1].phnNo}`
          : '---';
      return {
        Child: `${childDetails.firstName} ${childDetails.lastName}`,
        DOB: moment(childDetails.dob).format('DD-MM-YYYY'),
        Paid,
        Booked,
        Due,
        Medical: childDetails.childMedicalNotes,
        Parent: customerDetails?.customerName,
        Phone: customerDetails?.customerPhnNo,
        Email: customerDetails?.customerEmail,
        Emergency1: firstEmg,
        Emergency2: secondEmg,
      };
    });
    return formattedList;
  };

  const handleDownloadPdf = async () => {
    try {
      setDownloadLoading(true);
      const attendeeList = getPrintList();

      const pdf = new jsPDF({ orientation: 'landscape' });
      pdf.text(docTitle || `Attendee list`, 10, 10);
      // const headers = createHeadersForPdf([
      //   'Child',
      //   'DOB',
      //   'Paid',
      //   'Booked',
      //   'Due',
      //   'Medical',
      //   'Parent',
      //   'Email',
      //   'Emergency1',
      //   'Emergency2',
      // ]);
      const headers = [
        { title: 'Child', dataKey: 'Child' },
        { title: 'DOB', dataKey: 'DOB' },
        { title: 'Paid', dataKey: 'Paid' },
        { title: 'Booked', dataKey: 'Booked' },
        { title: 'Due', dataKey: 'Due' },
        { title: 'Medical', dataKey: 'Medical' },
        { title: 'Parent', dataKey: 'Parent' },
        { title: 'Phone', dataKey: 'Phone' },
        { title: 'Email', dataKey: 'Email' },
        { title: 'Emergency1', dataKey: 'Emergency1' },
        { title: 'Emergency2', dataKey: 'Emergency2' },
      ];
      // pdf.table(10, 20, attendeeList, headers, { autoSize: true });
      pdf.autoTable({
        head: [headers.map(header => header.title)],
        body: attendeeList.map(item => Object.values(item)),
        startY: 20,
        styles: { fontSize: 10 }, // Reduce font size if needed
        theme: 'grid',
        margin: { top: 10 },
        headStyles: { fillColor: [0, 36, 53] },
      });
      const currentDate = moment().format('YYYY-MM-DD-HH:mm');
      const fileName = `Attendee-list-${currentDate}`;
      setDownloadLoading(false);
      pdf.save(`${fileName}.pdf`);
    } catch (error) {
      setDownloadLoading(false);
    }
  };

  return (
    <div className={divClassname}>
      {/* {isPrint ? (
        <button className={printClass} type="button" onClick={handlePrint}>
          Print
        </button>
      ) : null} */}
      <button className={buttonClass} type="button" onClick={handleDownloadPdf}>
        {downloadLoading ? 'Starting download...' : 'Download to Print'}
      </button>
    </div>
  );
};

export default DownloadListComponent;
