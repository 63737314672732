import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';
import { EditListingPoliciesForm } from '../../forms';
import EditListingImage from '../EditListingImage/EditListingImage';
import css from './EditListingPoliciesPanel.module.css';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-15-icon.png';

const EditListingPoliciesPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    tabProgress,
    history,
    backButton,
    currentTab,
    savenExit,
    backbtnLoader,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, price } = currentListing.attributes;
  const {
    gardaVetting,
    insuranceCover,
    wheelChair,
    specialNeeds,
    permission,
    depositBalanceDue,
    bookingType,
    freeTickets,
  } = publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingPoliciesPanel.title"
      values={{ listingTitle: <ListingLink listing={listing} /> }}
    />
  ) : (
    <FormattedMessage id="EditListingPoliciesPanel.createListingTitle" />
  );

  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Just a last few questions... "
          currentTab={currentTab}
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
          bgIcon={screenicon}
        />

        <div className={css.rightSection}>
          <EditListingPoliciesForm
            className={css.form}
            publicData={publicData}
            initialValues={{
              gardaVetting,
              insuranceCover,
              wheelChair,
              specialNeeds,
              permission,
              depositBalanceDue,
              freeTickets,
            }}
            onSubmit={values => {
              const {
                gardaVetting,
                insuranceCover,
                wheelChair,
                specialNeeds,
                permission,
                depositBalanceDue,
                freeTickets,
              } = values;
              const updateValues = {
                publicData: {
                  depositBalanceDue,
                  gardaVetting,
                  insuranceCover,
                  wheelChair: !!wheelChair,
                  specialNeeds: !!specialNeeds,
                  permission: !!permission,
                  freeTickets,
                },
              };
              onSubmit(updateValues);
            }}
            tabProgress={tabProgress}
            backbtnLoader={backbtnLoader}
            history={history}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            saveActionMsg={submitButtonText}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            backButton={backButton}
            savenExit={savenExit}
            bookingType={bookingType}
            price={price}
          />
        </div>
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingPoliciesPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingPoliciesPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingPoliciesPanel;
