import React, { useState, useEffect } from 'react';
import { TopbarContainer } from '../../containers';
import { ADD_EXCEPTION_SUCCESS } from '../../containers/EditListingPage/EditListingPage.duck';
import Footer from '../Footer/Footer';
import LayoutSingleColumn from '../LayoutSingleColumn/LayoutSingleColumn';
import LayoutWrapperFooter from '../LayoutWrapperFooter/LayoutWrapperFooter';
import LayoutWrapperMain from '../LayoutWrapperMain/LayoutWrapperMain';
import LayoutWrapperTopbar from '../LayoutWrapperTopbar/LayoutWrapperTopbar';
import NamedLink from '../NamedLink/NamedLink';
import Page from '../Page/Page';
import Topbar from '../Topbar/Topbar';
import css from './SubscriptionProducts.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import ExternalLink from '../ExternalLink/ExternalLink';

const Message = ({ message }) => (
  <section>
    <p>{message}</p>
  </section>
);

const SuccessDisplay = ({ sessionId }) => {
  return (
    <section>
      <div className="product Box-root">
        <div className="description Box-root">
          <h3>Subscription to starter plan successful!</h3>
        </div>
      </div>
      <form action="/create-portal-session" method="POST">
        <input type="hidden" id="session-id" name="session_id" value={sessionId} />
        <button id="checkout-and-portal-button" type="submit">
          Manage your billing information
        </button>
      </form>
    </section>
  );
};

const ProductDisplay = () => (
  <div className={css.cardsContainer}>
    <section className={css.card}>
      <div className="product">
        <div className="description">
          <h3>Starter plan</h3>
          <h5>$20.00 / month</h5>
        </div>
      </div>
      <input
        type="hidden"
        name="lookup_key"
        value={{ PRICE_LOOKUP_KEY: 'price_1KuttgAms4kkns1bdlMBuyQS' }}
      />
      <button id="checkout-and-portal-button" type="submit">
        Checkout
      </button>
    </section>
    <section className={css.card}>
      <div className="product">
        <div className="description">
          <h3>Pro plan</h3>
          <h5>$99.00 / year</h5>
        </div>
      </div>
      <input
        type="hidden"
        name="lookup_key"
        value={{ PRICE_LOOKUP_KEY: 'price_1KvfE2Ai9tBge0oP1IE0QrTC' }}
      />
      <button
        id="checkout-and-portal-button"
        type="submit"
        onClick={() => {
          axios
            .post(`${apiBaseUrl()}/api/createCheckoutSession`, {
              PRICE_LOOKUP_KEY: 'price_1KvfE2Ai9tBge0oP1IE0QrTC',
            })
            .then(res => {
              typeof window !== 'undefined' ? (window.location.href = res.data.url) : null;
            })
            .catch(err => console.log(err));
        }}
      >
        Checkout
      </button>
    </section>
    <section className={css.card}>
      <div className="product">
        <div className="description">
          <h3>Growth plan</h3>
          <h5>$12.00 / month</h5>
        </div>
      </div>
      <input
        type="hidden"
        name="lookup_key"
        value={{ PRICE_LOOKUP_KEY: 'price_1Kva99Ai9tBge0oPQ90jSSXY' }}
      />
      <button
        id="checkout-and-portal-button"
        type="submit"
        onClick={() => {
          axios
            .post(`${apiBaseUrl()}/api/createCheckoutSession`, {
              PRICE_LOOKUP_KEY: 'price_1Kva99Ai9tBge0oPQ90jSSXY',
            })
            .then(res =>
              typeof window !== 'undefined' ? (window.location.href = res.data.url) : null
            )
            .catch(err => console.log(err));
        }}
      >
        Checkout
      </button>
    </section>
  </div>
);
function SubscriptionProducts() {
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [sessionId, setSessionId] = useState('');

  useEffect(() => {
    // Check to see if this is a redirect back from Checkout
    // const query = new URLSearchParams(window.location.search);
    const query = new URLSearchParams(window.location.search);

    if (query.get('success')) {
      setSuccess(true);
      setSessionId(query.get('session_id'));
    }

    if (query.get('canceled')) {
      setSuccess(false);
      setMessage("Order canceled -- continue to shop around and checkout when you're ready.");
    }
  }, [sessionId]);

  if (!success && message === '') {
    return (
      <Page /*className={css.root} title={title} scrollingDisabled={scrollingDisabled}*/>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="SubscriptionProducts" />
            {/* <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>Subscription Plans</h1>
              </div>
              <ProductDisplay />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else if (success && sessionId !== '') {
    return (
      <Page /*className={css.root} title={title} scrollingDisabled={scrollingDisabled}*/>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="SubscriptionProducts" />
            {/* <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>Subscription Plans</h1>
              </div>
              <SuccessDisplay />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  } else {
    return (
      <Page /*className={css.root} title={title} scrollingDisabled={scrollingDisabled}*/>
        <LayoutSingleColumn>
          <LayoutWrapperTopbar>
            <TopbarContainer currentPage="SubscriptionProducts" />
            {/* <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} /> */}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            <div className={css.content}>
              <div className={css.headingContainer}>
                <h1 className={css.heading}>Subscription Plans</h1>
              </div>
              <Message />
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter>
            <Footer />
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
      </Page>
    );
  }
}

//   return (
//     <Page /*className={css.root} title={title} scrollingDisabled={scrollingDisabled}*/>
//       <LayoutSingleColumn>
//         <LayoutWrapperTopbar>
//           <TopbarContainer currentPage="SubscriptionProducts" />
//           {/* <UserNav selectedPageName="ProfileSettingsPage" listing={currentUserListing} /> */}
//         </LayoutWrapperTopbar>
//         <LayoutWrapperMain>
//           <div className={css.content}>
//             <div className={css.headingContainer}>
//               <h1 className={css.heading}>Subscription Plans</h1>
//             </div>
//             {productDisplay}

//           </div>
//         </LayoutWrapperMain>
//         <LayoutWrapperFooter>
//           <Footer />
//         </LayoutWrapperFooter>
//       </LayoutSingleColumn>
//     </Page>
//   );

export default SubscriptionProducts;
