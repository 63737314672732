import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingPricingForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { types as sdkTypes } from '../../util/sdkLoader';
import config from '../../config';
import EditListingImage from '../EditListingImage/EditListingImage';

import css from './EditListingBookingTypePanel.module.css';
import EditListingBookingTypeFormComponent from '../../forms/EditListingBookingTypeForm/EditListingBookingTypeForm';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';

import clisting10 from '../../assets/screen-14-ripped-img.png';
import clistingmb10 from '../../assets/screen-14-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogo.png';
const { Money } = sdkTypes;

const EditListingBookingTypePanel = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    currentUser,
    history,
    backButton,
    tabProgress,
    fetchTimeslotsInProgress,
    timeslots,
    availabilityExceptions,
  } = props;
  const [loadingState, setloadingState] = useState(false);
  const currentStock = listing?.currentStock?.attributes?.quantity || 0;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { price, publicData } = currentListing.attributes;
  const {
    waitingList,
    notification,
    bookingLimits,
    trialMethod,
    bookingLimitCount,
    notificationLimitCount,
    trialOption,
    trialSeats = 0,
    isSeatsSaved = false,
  } = currentListing && currentListing.attributes.publicData;

  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const saveSeats = async values => {
    setloadingState(true);
    const {
      trialMethod,
      waitingList,
      notification,
      bookingLimits,
      seats,
      bookingLimitCount,
      notificationLimitCount,
      trialSeats,
    } = values;
    const finalSeats = trialOption ? Number(seats) + Number(trialSeats) : seats;
    const stocksMaybe = {
      stockUpdate: {
        oldTotal: listing?.currentStock?.attributes?.quantity || null,
        newTotal: finalSeats,
      },
    };
    const updateValues = {
      ...stocksMaybe,
      publicData: {
        waitingList,
        notification,
        bookingLimits,
        trialMethod,
        bookingLimitCount,
        notificationLimitCount,
        trialSeats,
        isSeatsSaved: true,
      },
    };

    onSubmit(updateValues);
    setloadingState(false);
  };

  const priceCurrencyValid = price instanceof Money ? price.currency === config.currency : true;
  const form = priceCurrencyValid ? (
    <EditListingBookingTypeFormComponent
      className={css.form}
      initialValues={{
        waitingList,
        notification,
        bookingLimits,
        trialMethod,
        seats: trialOption ? currentStock - trialSeats : currentStock,
        bookingLimitCount,
        notificationLimitCount,
        trialSeats,
        isSeatsSaved,
      }}
      // onSubmit={onSubmit}
      onSubmit={values => {
        saveSeats(values);
      }}
      history={history}
      tabProgress={tabProgress}
      onChange={onChange}
      saveActionMsg={submitButtonText}
      disabled={disabled}
      seatsForUpdate={currentStock - trialSeats}
      ready={ready}
      updated={panelUpdated}
      updateInProgress={updateInProgress}
      fetchErrors={errors}
      currentUser={currentUser}
      publicData={publicData}
      backButton={backButton}
      loadingState={loadingState}
    />
  ) : (
    <div className={css.priceCurrencyInvalid}>
      <FormattedMessage id="EditListingPricingPanel.listingPriceCurrencyInvalid" />
    </div>
  );

  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Getting bookings!"
          logoImg={listinglogo}
          desktopImg={clisting10}
          mobileImg={clistingmb10}
        />

        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          {form}
        </div>
      </div>
    </div>
  );
};

const { func, object, string, bool } = PropTypes;

EditListingBookingTypePanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
};

EditListingBookingTypePanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingBookingTypePanel;
