import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  EditListingImage,
  NamedLink,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingSubCategoriesForm.module.css';
import Select from 'react-select';
import {
  ACTIVITYTYPE,
  CATEGORIES,
  SUBCATEGORIES,
} from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
// import { AiOutlineDoubleLeft } from 'react-icons/ai';

const TITLE_MAX_LENGTH = 60;
const EditListingSubCategoriesFormComponent = props => {
  const { filterConfig, initialValues, categories, tabProgress } = props;
  const tabPercent = tabProgress(SUBCATEGORIES);
  const allSubCategories = findOptionsForSelectFilter('subCategories', filterConfig);

  const campSubs =
    categories[0] === 'camps' &&
    allSubCategories?.filter(
      s =>
        s.value === 'creative' ||
        s.value === 'dance' ||
        s.value === 'food' ||
        s.value === 'educational' ||
        s.value === 'inclusionAndSpecialNeeds' ||
        s.value === 'languages' ||
        s.value === 'multiActivity' ||
        s.value === 'music' ||
        s.value === 'outdoor' ||
        s.value === 'performingArts' ||
        s.value === 'residential' ||
        s.value === 'sports' ||
        s.value === 'STEM' ||
        s.value === 'wellbeing' ||
        s.value === 'other'
    );
  const classesSubs =
    categories[0] === 'classes' &&
    allSubCategories?.filter(
      s =>
        s.value === 'creative' ||
        s.value === 'dance' ||
        s.value === 'food' ||
        s.value === 'educational' ||
        s.value === 'inclusionAndSpecialNeeds' ||
        s.value === 'languages' ||
        s.value === 'multiActivity' ||
        s.value === 'music' ||
        s.value === 'outdoor' ||
        s.value === 'performingArts' ||
        s.value === 'sports' ||
        s.value === 'STEM' ||
        s.value === 'wellbeing' ||
        s.value === 'other'
    );
  const clubSubs =
    categories[0] === 'club' &&
    allSubCategories?.filter(
      s =>
        s.value === 'business' ||
        s.value === 'creative' ||
        s.value === 'cultural' ||
        s.value === 'food' ||
        s.value === 'educational' ||
        s.value === 'languages' ||
        s.value === 'literature' ||
        s.value === 'music' ||
        s.value === 'outdoor' ||
        s.value === 'performingArts' ||
        s.value === 'sports' ||
        s.value === 'STEM' ||
        s.value === 'wellbeing' ||
        s.value === 'writing' ||
        s.value === 'other'
    );
  const eventsSubs =
    categories[0] === 'events' &&
    allSubCategories?.filter(
      s =>
        s.value === 'creative' ||
        s.value === 'cultural' ||
        s.value === 'dance' ||
        s.value === 'family' ||
        s.value === 'festival' ||
        s.value === 'music' ||
        s.value === 'nature' ||
        s.value === 'performingArts' ||
        s.value === 'sports' ||
        s.value === 'other'
    );
  const inSchlProgSubs =
    categories[0] === 'inSchoolProgrammes' &&
    allSubCategories?.filter(
      s =>
        s.value === 'business' ||
        s.value === 'creative' ||
        s.value === 'cultural' ||
        s.value === 'dance' ||
        s.value === 'educational' ||
        s.value === 'languages' ||
        s.value === 'math' ||
        s.value === 'music' ||
        s.value === 'performingArts' ||
        s.value === 'sports' ||
        s.value === 'STEM' ||
        s.value === 'wellbeing' ||
        s.value === 'other'
    );
  const workshopSubs =
    categories[0] === 'workshops' &&
    allSubCategories?.filter(
      s =>
        s.value === 'creative' ||
        s.value === 'dance' ||
        s.value === 'food' ||
        s.value === 'educational' ||
        s.value === 'inclusionAndSpecialNeeds' ||
        s.value === 'languages' ||
        s.value === 'multiActivity' ||
        s.value === 'music' ||
        s.value === 'outdoor' ||
        s.value === 'performingArts' ||
        s.value === 'sports' ||
        s.value === 'STEM' ||
        s.value === 'wellbeing' ||
        s.value === 'other'
    );
  const subCategoryOptions =
    categories[0] === 'camps'
      ? campSubs
      : categories[0] === 'classes'
      ? classesSubs
      : categories[0] === 'club'
      ? clubSubs
      : categories[0] === 'events'
      ? eventsSubs
      : categories[0] === 'inSchoolProgrammes'
      ? inSchlProgSubs
      : categories[0] === 'workshops'
      ? workshopSubs
      : [];
  const [subCategories, setSubCategories] = useState(initialValues.subCategories);

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          history,
          tabProgress,
          savenExit,
          backbtnLoader,
        } = formRenderProps;
        if (subCategories.length !== 0 && subCategories.length !== values.subCategories.length) {
          form.change('subCategories', subCategories);
        }
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const lastTab = categories[0] === 'camps' ? ACTIVITYTYPE : CATEGORIES;
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const subCategoryTitleRequiredMessage = intl.formatMessage({
          id: 'EditListingDescriptionForm.specificCategoryTitleRequired',
        });
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const { subCategories, otherSubCategory } = values;
              const updateValues = {
                publicData: {
                  subCategories,
                  otherSubCategory,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>
            <div className={css.formseclist}>
              {subCategoryOptions?.map(c => (
                <div
                  key={`${c.key}_${values.subCategories?.includes(c.value)}_div`}
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.subCategories?.includes(c.value),
                  })}
                >
                  <label
                    htmlFor={`${c.key}_${values.subCategories?.includes(c.value)}`}
                    className={css.checkboxLabel}
                  >
                    <span className={css.keylbl}>{c.label}</span>
                  </label>
                  <input
                    className={css.checkbox}
                    type="checkbox"
                    id={`${c.key}_${values.subCategories?.includes(c.value)}`}
                    name={'subCategories'}
                    checked={subCategories?.includes(c.value)}
                    onChange={e => {
                      var isSelected = e.currentTarget.checked;
                      if (isSelected) {
                        if (subCategories.length < 3) {
                          setSubCategories([...subCategories, c.key]);
                          form.change('subCategories', [...subCategories, c.key]);
                        } else if (subCategories.length === 3) {
                          setSubCategories([
                            ...subCategories.filter(
                              s => subCategories.indexOf(s) !== subCategories.length - 1
                            ),
                            c.key,
                          ]);
                          form.change('subCategories', [
                            ...subCategories.filter(
                              s => subCategories.indexOf(s) !== subCategories.length - 1
                            ),
                            c.key,
                          ]);
                        }
                      } else {
                        setSubCategories(subCategories?.filter(item => c.key !== item));
                        form.change(
                          'subCategories',
                          subCategories?.filter(item => c.key !== item)
                        );
                      }
                    }}
                  />
                </div>
              ))}
              {subCategories.includes('other') ? (
                <div className={css.othertxt}>
                  <input
                    type="text"
                    value={values.otherSubCategory}
                    placeholder="Please type your category"
                    onChange={e => {
                      const value = e.target.value;
                      form.change('otherSubCategory', value);
                    }}
                  />
                </div>
              ) : null}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar progress={tabPercent} height={30} className={css.stepprgs} />
              <span className={css.progressSpan}>
                {subCategories?.length > 0 ? `${subCategories?.length}/3` : '0/3'}
              </span>
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={subCategories && subCategories?.length === 0}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingSubCategoriesFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingSubCategoriesFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingSubCategoriesFormComponent);
