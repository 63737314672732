import React, { useState, Suspense, lazy } from 'react';
import { bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { Form, Button, FieldTextInput, InlineTextButton, Modal } from '../../components';
import { required, composeValidators } from '../../util/validators';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import css from './EditListingPoliciesForm.module.css';
import { GrCheckbox, GrCheckboxSelected } from 'react-icons/gr';
import { setInitialValues } from '../../ducks/paymentMethods.duck';
import {
  BOOKINGTYPE,
  GETTINGNEWENQUIRY,
  POLICY,
  PRICING,
} from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';

const Editor = lazy(() => import('./Editor'));
export const EditListingPoliciesFormComponent = props => {
  const [policy, setPolicy] = useState('');
  const [policyError, setPolicyError] = useState(false);
  const [isTrustModalOpen, setIsTrustModalOpen] = useState(false);

  return (
    <FinalForm
      {...props}
      mutators={{ ...arrayMutators }}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          form,
          invalid,
          pristine,
          tabProgress,
          updated,
          updateInProgress,
          fetchErrors,
          values,
          publicData,
          initialValues,
          backButton,
          savenExit,
          backbtnLoader,
          bookingType,
          price,
        } = formRenderProps;

        const tabPercent = tabProgress(POLICY);
        const policyNamePlaceholderMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.policyNamePlaceholder',
        });
        const policyNameRequiredMessage = intl.formatMessage({
          id: 'EditListingPoliciesForm.policyNameRequired',
        });

        const { updateListingError, showListingsError } = fetchErrors || {};
        const errorMessage = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPoliciesForm.updateFailed" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingPoliciesForm.showListingFailed" />
          </p>
        ) : null;

        values?.policy?.length === 0 ? setPolicyError(true) : setPolicyError(false);
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const lastTab =
          publicData.categories[0] === 'afterSchoolClub' ||
          publicData.categories[0] === 'breakfastClub' ||
          publicData.categories[0] === 'schoolTours' ||
          publicData?.categories?.includes('inSchoolProgrammes')
            ? PRICING
            : publicData.categories[0] === 'camps' ||
              publicData.categories[0] === 'classes' ||
              publicData.categories[0] === 'workshops'
            ? publicData.bookingType === 'instantBooking'
              ? BOOKINGTYPE
              : GETTINGNEWENQUIRY
            : GETTINGNEWENQUIRY;
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const {
                gardaVetting,
                insuranceCover,
                wheelChair,
                specialNeeds,
                permission,
                depositBalanceDue,
                freeTickets,
              } = values;
              const updateValues = {
                publicData: {
                  depositBalanceDue,
                  gardaVetting,
                  insuranceCover,
                  wheelChair: !!wheelChair,
                  specialNeeds: !!specialNeeds,
                  permission: !!permission,
                  freeTickets,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        const showFreeTickets =
          price?.amount === 0 || (publicData?.trialOption && publicData?.trialFee?.amount === 0);
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              <span className={css.someImp}>
                <p>Some important things to know:</p>
                <ul>
                  <li>* Ensure your T&C’s are relevant for this activity after publishing.</li>
                  <li>
                    * Read our{' '}
                    <span
                      className={`${css.fcstxt} ${css.lnk}`}
                      onClick={() => {
                        setIsTrustModalOpen(true);
                      }}
                    >
                      Trust & Safety
                    </span>{' '}
                    policy here.{' '}
                  </li>
                </ul>
              </span>
              <div>
                <div className={css.bborow}>
                  <input
                    className={css.inputCheckbox}
                    type="checkbox"
                    name="declare"
                    id="gardaVetting"
                    value="gardaVetting"
                    defaultChecked={initialValues.gardaVetting}
                    onChange={e => {
                      if (e.target.checked) {
                        form.change('gardaVetting', true);
                      } else {
                        form.change('gardaVetting', false);
                      }
                    }}
                  />{' '}
                  <label htmlFor="gardaVetting" className={css.gardaVettingDiv}>
                    <span className={css.checkicon}>
                      {values.gardaVetting ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </span>
                    <span className={css.gardaVetting}>
                      <span className={css.gvhed}>*Garda Vetting:</span>
                      <span>
                        {' '}
                        I declare that I have up-to-date garda vetting for all relevant members of
                        staff for this listed activity.
                      </span>
                    </span>
                  </label>{' '}
                </div>
                <div className={css.bborow}>
                  <input
                    className={css.inputCheckbox}
                    type="checkbox"
                    name="declare"
                    id="insuranceCover"
                    value="insuranceCover"
                    defaultChecked={initialValues.insuranceCover}
                    onChange={e => {
                      if (e.target.checked) {
                        form.change('insuranceCover', true);
                      } else {
                        form.change('insuranceCover', false);
                      }
                    }}
                  />{' '}
                  <label htmlFor="insuranceCover" className={css.gardaVettingDiv}>
                    <span className={css.checkicon}>
                      {values.insuranceCover ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </span>
                    <span className={css.gardaVetting}>
                      <span className={css.gvhed}>*Insurance Cover:</span>
                      <span>
                        {' '}
                        I declare that I have insurance cover in place that is adequate and
                        appropriate for this listed activity.
                      </span>
                    </span>
                  </label>
                </div>
                {publicData?.bookingType === 'instantBooking' && publicData?.depositOption && (
                  <div className={css.bborow}>
                    <input
                      className={css.inputCheckbox}
                      type="checkbox"
                      name="declare"
                      id="depositBalanceDue"
                      value="depositBalanceDue"
                      defaultChecked={initialValues.depositBalanceDue}
                      onChange={e => {
                        if (e.target.checked) {
                          form.change('depositBalanceDue', true);
                        } else {
                          form.change('depositBalanceDue', false);
                        }
                      }}
                    />{' '}
                    <label htmlFor="depositBalanceDue" className={css.gardaVettingDiv}>
                      <span className={css.checkicon}>
                        {values.depositBalanceDue ? <GrCheckboxSelected /> : <GrCheckbox />}
                      </span>
                      <span className={css.gardaVetting}>
                        <span className={css.gvhed}>*Deposit Balance Due:</span>
                        <span>
                          {' '}
                          I understand when a deposit amount is paid here, the remaining amount due
                          cannot be collected by Activity Time, at this time. It is directly between
                          me and my customer.
                        </span>
                      </span>
                    </label>
                  </div>
                )}
                {showFreeTickets && (
                  <div className={css.bborow}>
                    <input
                      className={css.inputCheckbox}
                      type="checkbox"
                      name="declare"
                      id="freeTickets"
                      value="freeTickets"
                      defaultChecked={initialValues.freeTickets}
                      onChange={e => {
                        if (e.target.checked) {
                          form.change('freeTickets', true);
                        } else {
                          form.change('freeTickets', false);
                        }
                      }}
                    />{' '}
                    <label htmlFor="freeTickets" className={css.gardaVettingDiv}>
                      <span className={css.checkicon}>
                        {values.freeTickets ? <GrCheckboxSelected /> : <GrCheckbox />}
                      </span>
                      <span className={css.gardaVetting}>
                        <span className={css.gvhed}>*Free Tickets: </span>
                        <span>
                          I understand when free spaces and free trials are created, they are
                          offered at no cost to the end customer and my business will be responsible
                          for covering the ticketing fees, which include a charge of €0.99 plus VAT
                          and Stripe fees per space booked.
                        </span>
                      </span>
                    </label>
                  </div>
                )}
              </div>
              <div>
                <div className={css.inlinechck}>
                  <label htmlFor="wheelChair">
                    <div className={css.pctxt}>
                      Is this activity <span className={css.red}>wheelchair</span>-friendly?{' '}
                    </div>
                    <span className={css.checkicon}>
                      {values.wheelChair ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </span>
                  </label>
                  <input
                    className={css.inputCheckbox}
                    id="wheelChair"
                    type="checkbox"
                    value="wheelChair"
                    defaultChecked={initialValues.wheelChair}
                    onChange={e => {
                      if (e.target.checked) {
                        form.change('wheelChair', true);
                      } else {
                        form.change('wheelChair', false);
                      }
                    }}
                  />
                </div>
                <div className={css.inlinechck}>
                  <label htmlFor="specialNeeds">
                    <div className={css.pctxt}>
                      Is this activity <span className={css.red}>additional needs</span>-friendly?
                    </div>
                    <span className={css.checkicon}>
                      {values.specialNeeds ? <GrCheckboxSelected /> : <GrCheckbox />}
                    </span>
                  </label>
                  <input
                    className={css.inputCheckbox}
                    id="specialNeeds"
                    type="checkbox"
                    value="specialNeeds"
                    defaultChecked={initialValues.specialNeeds}
                    onChange={e => {
                      if (e.target.checked) {
                        form.change('specialNeeds', true);
                      } else {
                        form.change('specialNeeds', false);
                      }
                    }}
                  />
                </div>
                {/* {publicData.categories[0] === 'birthdayParty' ||
                publicData.categories[0] === 'breakfastClub' ||
                publicData.categories[0] === 'camps' ||
                publicData.categories[0] === 'classes' ||
                publicData.categories[0] === 'club' ||
                publicData.categories[0] === 'workshops' ||
                publicData.categories[0] === 'schoolTours' ? (
                  <div className={css.inlinechck}>
                    <label htmlFor="permission">
                      <div className={css.pctxt}>
                        Ask guardians permission to take <span className={css.red}>photos</span> of
                        their children for promotion of this activity?
                      </div>
                      <span className={css.checkicon}>
                        {values.permission ? <GrCheckboxSelected /> : <GrCheckbox />}
                      </span>
                    </label>
                    <input
                      className={css.inputCheckbox}
                      id="permission"
                      type="checkbox"
                      value="permission"
                      defaultChecked={initialValues.permission}
                      onChange={e => {
                        if (e.target.checked) {
                          form.change('permission', true);
                        } else {
                          form.change('permission', false);
                        }
                      }}
                    />
                  </div>
                ) : null} */}
              </div>
            </div>
            {/* <FieldArray name="policy">
              {({ fields }) => {
                return (
                  <div className={css.sectionContainer}>
                    {fields.map((name, i) => {
                      return (
                        <div key={name + i}>
                          <div className={css.policyContainer}>
                            <label>Enter policy name</label>
                            <FieldTextInput
                              id={name}
                              name={`${name}.policyName`}
                              className={css.policyName}
                              type="text"
                              placeholder={policyNamePlaceholderMessage}
                              validate={composeValidators(required(policyNameRequiredMessage))}
                            />
                            <label>Enter policy details</label>
                            <Suspense fallback={<div>Loading...</div>}>
                              <Editor
                                values={values}
                                form={form}
                                name={name}
                                i={i}
                                setPolicy={setPolicy}
                              />
                            </Suspense>
                          </div>
                        </div>
                      );
                    })}
                    <div className={css.inlinefrom}>
                      <InlineTextButton
                        className={css.addMore}
                        type="button"
                        onClick={() => {
                          fields.push();
                          setPolicy('');
                        }}
                        disabled={
                          values.policy?.length > 0 &&
                          values.policy
                            ?.map(v => {
                              return v?.policyName && v?.policyDetails ? true : false;
                            })
                            .filter(m => m === false).length > 0
                        }
                      >
                        <FormattedMessage id="EditListingPoliciesForm.addMorePolicy" />
                      </InlineTextButton>

                      {values.policy?.length > 0 ? (
                        <InlineTextButton
                          className={css.remove}
                          type="button"
                          onClick={() => {
                            fields.pop();
                          }}
                          disabled={values.policy?.length < 2}
                        >
                          <FormattedMessage id="EditListingPoliciesForm.remove" />
                        </InlineTextButton>
                      ) : null}
                    </div>
                  </div>
                );
              }}
            </FieldArray> */}
            <div className={css.formlistbtmsec}>
              <ProgressBar progress={tabPercent} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={
                    submitDisabled ||
                    !values.gardaVetting ||
                    !values.insuranceCover ||
                    (publicData.bookingType === 'instantBooking' &&
                      publicData?.depositOption &&
                      !values.depositBalanceDue)
                  }
                  ready={submitReady}
                >
                  {/* {saveActionMsg} */}
                  Next
                </Button>
              </div>
            </div>

            <Modal
              id="trustSafety"
              isOpen={isTrustModalOpen}
              onClose={() => setIsTrustModalOpen(false)}
              usePortal
              onManageDisableScrolling={() => {}}
            >
              <div className={css.termsWrapper}>
                <h2 className={css.termsHeading}>* Trust & Safety Policy:</h2>
                <p>
                  Activity Time is excited to promote and share activity providers of all types, the
                  more diverse the activities the better!
                </p>{' '}
                <p>
                  However, we do wish to make sure each business is reputable and considerate of
                  being around children.
                </p>{' '}
                <p>
                  Our due diligence requires us to ask you to answer our Trust & Safety questions
                  accurately and also produce, upon request, to Activity Time, parents and teachers
                  the following:
                </p>{' '}
                <p>* Garda vetting certificate(s)</p> <p>* Insurance cover documentation</p>
              </div>
            </Modal>
          </Form>
        );
      }}
    />
  );
};

EditListingPoliciesFormComponent.defaultProps = {
  selectedPlace: null,
  updateError: null,
};

EditListingPoliciesFormComponent.propTypes = {
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  selectedPlace: propTypes.place,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(EditListingPoliciesFormComponent);
