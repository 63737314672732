import React, { Suspense, useEffect, useRef, useState } from 'react';
import { Form as FinalForm } from 'react-final-form';
import classNames from 'classnames';
import { Form, Button, Map } from '../../components';
import css from './EditListingActivityModeForm.module.css';
import { ACTIVITYMODE, TITLE } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { isEqual } from 'lodash';
const irelandCords = { lat: 53.1423672, lng: -7.692053599999999 };
// const MapboxMap = React.lazy(() => import('../../components/Map/StaticMapboxMap'));
function EditListingActivityModeForm(props) {
  const mapRef = useRef(null);
  function initialize() {
    var myOptions = {
      zoom: 7,
      mapTypeControl: false,
      draggable: false,
      scaleControl: false,
      scrollwheel: false,
      navigationControl: false,
      streetViewControl: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      disableDefaultUI: true,
    };
    const element = document.getElementById('googlemaps');
    // mapContainer.current = element;

    if (element) {
      var map = new google.maps.Map(element, myOptions);
      mapRef.current = map;
      var latLng = new google.maps.LatLng(irelandCords.lat, irelandCords.lng);
      map.setCenter(latLng);
    }
  }

  useEffect(() => {
    if (mapRef.current === null) {
      if (typeof window !== 'undefined') {
        initialize();
      }
    }
  });
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          location,
          initialValues,
          tabProgress,
          savenExit,
          backbtnLoader,
        } = formRenderProps;
        const tabPercent = tabProgress(ACTIVITYMODE);
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const saveExitButtonDisabled = isEqual(initialValues, values) && !values.activityMode;
        const saveExit = (
          <Button
            className={css.exitButton}
            type="button"
            inProgress={backbtnLoader}
            onClick={() => {
              const updateValues = {
                publicData: {
                  activityMode: values.activityMode,
                },
              };
              savenExit(updateValues);
            }}
          >
            Save & Exit
          </Button>
        );
        const lastTab = TITLE;
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              {/* <Map {...mapProps} className={css.map} /> */}
              {/* <CustomSearchMap
                key="EditlistingPage.map"
                reusableContainerClassName={css.map}
                activeListingId={null}
                bounds={bounds}
                center={origin}
                isSearchMapOpenOnMobile={true}
                location={location}
                listings={[]}
                onMapMoveEnd={() => {}}
                onCloseAsModal={() => {
                  //   onManageDisableScrolling('EditlistingPage.map', false);
                }}
                messages={intl.messages}
                // setPickupAddress={getAddressFromLatLng}
                initialMapUpdate={initialMapUpdate}
                showCustomMap={true}
                boundsDistance={100000}
                interactive={false}
                isAddressAbsent={true}
              /> */}
              <div className={css.othcatnew}>
                <div id="googlemaps" className={css.bggmaps}></div>
                <div
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.activityMode === 'offline',
                  })}
                >
                  <label htmlFor="offline" className={css.checkboxLabel}>
                    <div className={css.labelDiv}>
                      <span
                        className={classNames(css.keylbl, {
                          [css.notsel]: values.activityMode === 'online',
                        })}
                      >
                        In-Person Activity
                      </span>
                    </div>
                  </label>
                  <input
                    type="radio"
                    name="activityMode"
                    id="offline"
                    value={'offline'}
                    className={css.radioButton}
                    defaultChecked={initialValues.activityMode === 'offline'}
                    onChange={e => {
                      if (!initialValues.activityMode) form.change('activityMode', e.target.value);
                    }}
                  />
                </div>
                <div
                  className={classNames(css.categoriesCheckbox, {
                    [css.selectedCategory]: values.activityMode === 'online',
                  })}
                >
                  <label htmlFor="online" className={css.checkboxLabel}>
                    <div className={css.labelDiv}>
                      <span
                        className={classNames(css.keylbl, {
                          [css.notsel]: values.activityMode === 'offline',
                        })}
                      >
                        Online Activity
                      </span>
                    </div>
                  </label>
                  <input
                    type="radio"
                    name="activityMode"
                    id="online"
                    value={'online'}
                    className={css.radioButton}
                    onChange={e => {
                      if (!initialValues.activityMode) form.change('activityMode', e.target.value);
                    }}
                    defaultChecked={initialValues.activityMode === 'online'}
                  />
                </div>
              </div>
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar className={css.stepprgs} progress={tabPercent} height={30} />
              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!values.activityMode}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
}

export default EditListingActivityModeForm;
