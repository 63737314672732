import React, { useState } from 'react';
import { string } from 'prop-types';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import { twitterPageURL } from '../../util/urlHelpers';
import { withRouter } from 'react-router-dom';
import config from '../../config';
import {
  IconSocialMediaFacebook,
  IconSocialMediaInstagram,
  IconSocialMediaTwitter,
  Logo,
  ExternalLink,
  NamedLink,
  Modal,
  IconSpinner,
} from '../../components';
import FooterLogo from '../../assets/footerlogo.png';

import css from './Footer.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../ducks/Auth.duck';
import { compose } from 'redux';
import { pathByRouteName } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { manageDisableScrolling } from '../../ducks/UI.duck';

const renderSocialMediaLinks = intl => {
  const { siteFacebookPage, siteInstagramPage, siteTwitterHandle } = config;
  const siteTwitterPage = twitterPageURL(siteTwitterHandle);

  const goToFb = intl.formatMessage({ id: 'Footer.goToFacebook' });
  const goToInsta = intl.formatMessage({ id: 'Footer.goToInstagram' });
  const goToTwitter = intl.formatMessage({ id: 'Footer.goToTwitter' });

  const fbLink = siteFacebookPage ? (
    <ExternalLink key="linkToFacebook" href={siteFacebookPage} className={css.icon} title={goToFb}>
      <IconSocialMediaFacebook />
    </ExternalLink>
  ) : null;

  // const twitterLink = siteTwitterPage ? (
  //   <ExternalLink
  //     key="linkToTwitter"
  //     href={siteTwitterPage}
  //     className={css.icon}
  //     title={goToTwitter}
  //   >
  //     <IconSocialMediaTwitter />
  //   </ExternalLink>
  // ) : null;

  const instragramLink = siteInstagramPage ? (
    <ExternalLink
      key="linkToInstagram"
      href={siteInstagramPage}
      className={css.icon}
      title={goToInsta}
    >
      <IconSocialMediaInstagram />
    </ExternalLink>
  ) : null;
  return [fbLink, /*twitterLink,*/ instragramLink].filter(v => v != null);
};

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const [logoutInProgress, setLogoutInProgress] = useState(false);
  const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);
  const currentUser = useSelector(state => state.user.currentUser);
  const isProvider = !!currentUser?.attributes?.profile?.publicData?.isProvider;
  const dispatch = useDispatch();

  const handleLogout = toLogin => {
    const { history } = props;
    setLogoutInProgress(true);
    dispatch(logout()).then(() => {
      const path = toLogin
        ? pathByRouteName('LoginPage', routeConfiguration())
        : isProvider
        ? pathByRouteName('SignupProviderPage', routeConfiguration())
        : pathByRouteName('SignupPage', routeConfiguration());

      // In production we ensure that data is really lost,
      // but in development mode we use stored values for debugging
      if (config.dev) {
        history.push(path);
      } else if (typeof window !== 'undefined') {
        window.location = path;
      }
      setLogoutInProgress(false);
      console.log('logged out'); // eslint-disable-line
    });
  };
  const socialMediaLinks = renderSocialMediaLinks(intl);
  const classes = classNames(rootClassName || css.root, className);
  const onManageDisableScrolling = (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling));
  const dummyLogoutBtn = isLogin => (
    <button
      type="button"
      className={`${css.link} ${css.dummyLogoutBtn}`}
      onClick={() => setModalOpen(true)}
    >
      {isLogin ? 'Login' : 'Sign Up'}
    </button>
  );
  const dummyModalOpener = name => {
    return (
      <span className={css.link} onClick={() => setModalOpen(true)}>
        {name}
      </span>
    );
  };
  return (
    <div className={classes}>
      <div className={css.topBorderWrapper}>
        <div className={css.content}>
          <div className={css.someLiksMobile}>{socialMediaLinks}</div>
          <div className={css.links}>
            <div className={css.infoLinks}>
              <label className={css.linkslabel}>Parents:</label>
              <ul className={css.list}>
                <li className={css.listItem}>
                  {isProvider ? (
                    dummyModalOpener('Newsletter')
                  ) : (
                    <NamedLink name={'LandingPage'} className={css.link}>
                      Newsletter
                    </NamedLink>
                  )}
                </li>
                <li className={css.listItem}>
                  {isProvider ? (
                    dummyModalOpener('Trust and Safety')
                  ) : (
                    <NamedLink name={'TrustAndSafetyPage'} className={css.link}>
                      <FormattedMessage id="Footer.trustAndSafety" />
                    </NamedLink>
                  )}
                </li>

                <li className={css.listItem}>
                  {isProvider ? (
                    dummyModalOpener('Favourites')
                  ) : (
                    <NamedLink name={'FavouritesPage'} className={css.link}>
                      Favourites
                    </NamedLink>
                  )}
                </li>
                {/* <li className={css.listItem}>
                  {isProvider ? (
                    dummyModalOpener('Blog for Parents')
                  ) : (
                    <NamedLink name={'LandingPage'} className={css.link}>
                      <FormattedMessage id="Footer.parentsBlogPage" />
                    </NamedLink>
                  )}
                </li> */}
              </ul>
            </div>
            <div className={css.searches}>
              <label className={css.linkslabel}>Providers:</label>
              <ul className={css.list}>
                <li className={css.listItem}>
                  {!isProvider ? (
                    dummyModalOpener('Add New Activity')
                  ) : (
                    <NamedLink name="CreateListingPage" className={css.link}>
                      Add New Activity
                    </NamedLink>
                  )}
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ListMyBusinessPage" className={css.link}>
                    New Providers
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ListMyBusinessPage" className={css.link}>
                    Pricing
                  </NamedLink>
                </li>
                {/* <li className={css.listItem}>
                  {!isProvider ? (
                    dummyModalOpener('Blog for Providers')
                  ) : (
                    <NamedLink name="LoginPage" className={css.link}>
                      <FormattedMessage id="Footer.providerBlogPage" />
                    </NamedLink>
                  )}
                </li> */}

                {/* <li className={css.listItem}>
                  <NamedLink name="LoginPage" className={css.link}>
                    <FormattedMessage id="Footer.newsletter" />
                  </NamedLink>
                </li> */}
              </ul>
            </div>
            <div className={css.searches}>
              <label className={css.linkslabel}>Useful Links:</label>
              <ul className={css.list}>
                <li className={css.listItem}>
                  <NamedLink name="AboutPage" className={css.link}>
                    <FormattedMessage id="Footer.toAboutPage" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ContactUsPage" className={css.link}>
                    <FormattedMessage id="Footer.toContactUs" />
                  </NamedLink>
                </li>
                <li className={css.listItem}>
                  <NamedLink name="ActivityTimeTerms" className={css.link}>
                    <FormattedMessage id="Footer.termsOfUse" />
                  </NamedLink>
                </li>

                <li className={css.listItem}>
                  {!isAuthenticated ? (
                    <NamedLink name="LoginPage" className={css.link}>
                      <FormattedMessage id="Footer.login" />
                    </NamedLink>
                  ) : (
                    dummyLogoutBtn(true)
                  )}
                </li>
              </ul>
            </div>
          </div>
          <div className={css.bottomDiv}>
            <div className={css.organization} id="organization">
              <NamedLink name="LandingPage" className={css.logoLink}>
                <span className={css.logo}>
                  {/* <Logo format="desktop" /> */}

                  <img src={FooterLogo} />
                </span>
              </NamedLink>
              <div className={css.organizationInfo}>
                <p className={css.organizationDescription}>
                  <FormattedMessage id="Footer.organizationDescriptionLine1" />
                  <FormattedMessage id="Footer.organizationDescriptionLine2" />
                </p>
                {/* <p className={css.organizationCopyright}>
                  <NamedLink name="LandingPage" className={css.copyrightLink}>
                    <FormattedMessage id="Footer.copyright" />
                  </NamedLink>
                </p> */}
              </div>
            </div>
            <div className={css.copyrightAndTermsMobile}>
              <NamedLink name="LandingPage" className={css.organizationCopyrightMobile}>
                <FormattedMessage id="Footer.copyright" />
              </NamedLink>
              <div className={css.tosAndPrivacyMobile}>
                <NamedLink name="PrivacyPolicy" className={css.privacy}>
                  <FormattedMessage id="Footer.privacy" />
                </NamedLink>
                <NamedLink name="TermsOfServicePage" className={css.terms}>
                  <FormattedMessage id="Footer.terms" />
                </NamedLink>
              </div>
            </div>
            <div className={css.extraLinks}>
              <div>
                <p className={css.socialDesc}>
                  By following us on social media you will be able to keep up to date with with our
                  latest news and announcements
                </p>
                <h1>We're so social!</h1>
              </div>
              <div className={css.someLinks}>{socialMediaLinks}</div>
              {/* <div className={css.legalMatters}>
                <ul className={css.tosAndPrivacy}>
                  <li>
                    <NamedLink name="TermsOfServicePage" className={css.legalLink}>
                      <FormattedMessage id="Footer.termsOfUse" />
                    </NamedLink>
                  </li>
                  
                </ul>
              </div> */}
            </div>
          </div>
        </div>
        {/* <br className={css.footerDivider}></br> */}
        <div className={css.footerBottom}>
          <FormattedMessage id="Footer.bottomText" />
          <ExternalLink href="/">Sitemap</ExternalLink> |
          <NamedLink name="ActivityTimeTerms">T&C's</NamedLink>|
          <ExternalLink href="/">Cookie Policy</ExternalLink> |
          <NamedLink name="PrivacyPolicy">Privacy Policy</NamedLink>
        </div>
      </div>
      <Modal
        id="logoutModal"
        isOpen={modalOpen}
        // isOpen={true}
        onClose={() => setModalOpen(false)}
        onManageDisableScrolling={onManageDisableScrolling}
        intl={intl}
      >
        <div>
          <p className={css.headp}>Switching Accounts?</p>
          <p className={css.secp}>
            Great! To use the {isProvider ? 'parent' : 'activity provider'} features here's what you
            need to do:
          </p>
          <div className={css.stepdv}>
            <span>Step 1:</span>
            <p>Logout of the {isProvider ? 'activity provider' : 'parent'} account.</p>
          </div>
          <div className={css.stepdv}>
            <span>Step 2:</span>
            <p>Sign-up / log-in as a {isProvider ? 'parent' : 'activity provider'}.</p>
          </div>
          <span className={css.notespn}>
            *Please note you need a separate email address for each account.
          </span>
          <button type="button" className={css.logoutbtn} onClick={handleLogout}>
            {logoutInProgress ? <IconSpinner /> : 'LOGOUT'}
          </button>
        </div>
      </Modal>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default compose(withRouter)(injectIntl(Footer));
