import React, { Component, useEffect } from 'react';
import { array, bool, func, number, object, oneOf, shape, string } from 'prop-types';
import { compose } from 'redux';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import config from '../../config';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import { withViewport } from '../../util/contextHelpers';
import { propTypes } from '../../util/types';
import {
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_NEW,
  LISTING_PAGE_PARAM_TYPES,
} from '../../util/urlHelpers';
import { ensureCurrentUser, ensureListing } from '../../util/data';

import { Modal, NamedRedirect, Tabs, StripeConnectAccountStatusBox } from '../../components';
import { StripeConnectAccountForm } from '../../forms';

import EditListingWizardTab, {
  CATEGORIES,
  ACTIVITYTYPE,
  SUBCATEGORIES,
  NUMBEROFKIDS,
  TITLE,
  AVAILABILITY,
  DESCRIPTION,
  WHATTOBRING,
  FEATURES,
  POLICY,
  LOCATION,
  PRICING,
  AGE,
  PHOTOS,
  BOOKINGTYPE,
  PUBLISH,
  SEATS,
  GETTINGNEWENQUIRY,
  OPENFOR,
  ACTIVITYMODE,
} from './EditListingWizardTab';
import css from './EditListingWizard.module.css';
import { createInstance } from '../../util/sdkLoader';
// Show availability calendar only if environment variable availabilityEnabled is true
// You can reorder these panels.
// Note 1: You need to change save button translations for new listing flow
// Note 2: Ensure that draft listing is created after the first panel
// and listing publishing happens after last panel.
// Note 3: in FTW-hourly template we don't use the POLICY tab so it's commented out.
// If you want to add a free text field to your listings you can enable the POLICY tab

// Tabs are horizontal in small screens
const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

const STRIPE_ONBOARDING_RETURN_URL_SUCCESS = 'success';
const STRIPE_ONBOARDING_RETURN_URL_FAILURE = 'failure';

const tabLabel = (intl, tab) => {
  let key = null;
  if (tab === DESCRIPTION) {
    key = 'EditListingWizard.tabLabelDescription';
  } else if (tab === FEATURES) {
    key = 'EditListingWizard.tabLabelFeatures';
  } else if (tab === POLICY) {
    key = 'EditListingWizard.tabLabelPolicy';
  } else if (tab === LOCATION) {
    key = 'EditListingWizard.tabLabelLocation';
  } else if (tab === PRICING) {
    key = 'EditListingWizard.tabLabelPricing';
  } else if (tab === AVAILABILITY) {
    key = 'EditListingWizard.tabLabelAvailability';
  } else if (tab === PHOTOS) {
    key = 'EditListingWizard.tabLabelPhotos';
  } else if (tab === AGE) {
    key = 'EditListingWizard.tabLabelAge';
  } else if (tab === CATEGORIES) {
    key = 'EditListingWizard.tabLabelCategories';
  } else if (tab === ACTIVITYTYPE) {
    key = 'EditListingWizard.tabLabelActivityType';
  } else if (tab === SUBCATEGORIES) {
    key = 'EditListingWizard.tabLabelSubCategoriesType';
  } else if (tab === TITLE) {
    key = 'EditListingWizard.tabLabelTitle';
  } else if (tab === WHATTOBRING) {
    key = 'EditListingWizard.tabLabelWhatToBring';
  } else if (tab === BOOKINGTYPE) {
    key = 'EditListingWizard.tabLabelBookingTypeBring';
  } else if (tab === PUBLISH) {
    key = 'EditListingWizard.tabLabelPublish';
  } else if (tab === SEATS) {
    key = 'EditListingWizard.tabLabelPublish';
  } else if (tab === OPENFOR) {
    key = 'EditListingWizard.tabLabelPublish';
  } else if (tab === ACTIVITYMODE) {
    key = 'EditListingWizard.tabLabelPublish';
  } else if (tab === GETTINGNEWENQUIRY) {
    key = 'EditListingWizard.tabLabelPublish';
  } else if (tab === NUMBEROFKIDS) {
    key = 'EditListingWizard.tabLabelPublish';
  }
  return intl.formatMessage({ id: key });
};

/**
 * Check if a wizard tab is completed.
 *
 * @param tab wizard's tab
 * @param listing is contains some specific data if tab is completed
 *
 * @return true if tab / step is completed.
 */

const tabCompleted = (tab, listing) => {
  const {
    availabilityPlan,
    description,
    geolocation,
    price,
    title,
    publicData,
  } = listing.attributes;
  const images = listing.images;
  switch (tab) {
    case CATEGORIES:
      return !!publicData && publicData.categories && publicData.categories.length > 0;
    case ACTIVITYTYPE:
      return !!publicData && publicData.activityType && publicData.activityType.length > 0;
    case SUBCATEGORIES:
      return !!publicData && publicData.subCategories && publicData.subCategories.length > 0;
    case TITLE:
      return !!title;
    case LOCATION:
      return publicData.categories[0] === 'inSchoolProgrammes' ||
        publicData.categories[0] === 'birthdayParty'
        ? publicData.county
        : !!publicData.lat && !!publicData.lng && !!publicData.county;
    case DESCRIPTION:
      return !!(description && title);
    case FEATURES:
      return !!(publicData && publicData.features);
    case POLICY:
      return !!publicData.gardaVetting && !!publicData.insuranceCover;
    case PRICING:
      return !!price;
    case AVAILABILITY:
      return true;
    case PHOTOS:
      return images && images.length > 0;
    case AGE:
      return !!publicData && publicData.age;
    case WHATTOBRING:
      return !!publicData && publicData.whatToBring;
    case BOOKINGTYPE:
      return true;
    case PUBLISH:
      return !!publicData && typeof publicData.policy !== 'undefined';
    case NUMBEROFKIDS:
      return !!publicData && publicData.noOfKids;
    case OPENFOR:
      return !!publicData && publicData.openFor;
    case ACTIVITYMODE:
      return !!publicData && publicData.activityMode;
    case GETTINGNEWENQUIRY:
      return !!publicData.categories && publicData.categories[0] === 'club'
        ? !!publicData.bookingType
        : !!publicData;
    default:
      return false;
  }
};

/**
 * Check which wizard tabs are active and which are not yet available. Tab is active if previous
 * tab is completed. In edit mode all tabs are active.
 *
 * @param isNew flag if a new listing is being created or an old one being edited
 * @param listing data to be checked
 *
 * @return object containing activity / editability of different tabs of this wizard
 */
// const tabsActive = (isNew, listing) => {
//   return TABS.reduce((acc, tab) => {
//     const previousTabIndex = TABS.findIndex(t => t === tab) - 1;
//     const isActive =
//       previousTabIndex >= 0 ? !isNew || tabCompleted(TABS[previousTabIndex], listing) : true;
//     return { ...acc, [tab]: isActive };
//   }, {});
// };

const scrollToTab = (tabPrefix, tabId) => {
  const el = document.querySelector(`#${tabPrefix}_${tabId}`);
  if (el) {
    el.scrollIntoView({
      block: 'start',
      behavior: 'smooth',
    });
  }
};

// Create return URL for the Stripe onboarding form
const createReturnURL = (returnURLType, rootURL, routes, pathParams) => {
  const path = createResourceLocatorString(
    'EditListingStripeOnboardingPage',
    routes,
    { ...pathParams, returnURLType },
    {}
  );
  const root = rootURL.replace(/\/$/, '');
  return `${root}${path}`;
};

// Get attribute: stripeAccountData
const getStripeAccountData = stripeAccount => stripeAccount.attributes.stripeAccountData || null;

// Get last 4 digits of bank account returned in Stripe account
const getBankAccountLast4Digits = stripeAccountData =>
  stripeAccountData && stripeAccountData.external_accounts.data.length > 0
    ? stripeAccountData.external_accounts.data[0].last4
    : null;

// Check if there's requirements on selected type: 'past_due', 'currently_due' etc.
const hasRequirements = (stripeAccountData, requirementType) =>
  stripeAccountData != null &&
  stripeAccountData.requirements &&
  Array.isArray(stripeAccountData.requirements[requirementType]) &&
  stripeAccountData.requirements[requirementType].length > 0;

// Redirect user to Stripe's hosted Connect account onboarding form
const handleGetStripeConnectAccountLinkFn = (getLinkFn, commonParams) => type => () => {
  getLinkFn({ type, ...commonParams })
    .then(url => {
      window.location.href = url;
    })
    .catch(err => console.error(err));
};

const RedirectToStripe = ({ redirectFn }) => {
  useEffect(redirectFn('custom_account_verification'), []);
  return <FormattedMessage id="EditListingWizard.redirectingToStripe" />;
};

// Create a new or edit listing through EditListingWizard
class EditListingWizard extends Component {
  constructor(props) {
    super(props);

    // Having this info in state would trigger unnecessary rerendering
    this.hasScrolledToTab = false;

    this.state = {
      draftId: null,
      showPayoutDetails: false,
      portalRoot: null,
      currentUserHasPublishedListing: null,
      sdkInstance: null,
      snoozeLoading: false,
    };
    this.sdkLoaded = React.createRef();
    this.hasListingFetched = React.createRef();

    this.handleCreateFlowTabScrolling = this.handleCreateFlowTabScrolling.bind(this);
    this.handlePublishListing = this.handlePublishListing.bind(this);
    this.handlePayoutModalClose = this.handlePayoutModalClose.bind(this);
    this.handlePayoutSubmit = this.handlePayoutSubmit.bind(this);
  }

  componentDidMount() {
    this.sdkLoaded.current = false;
    this.hasListingFetched.current = false;
    const { stripeOnboardingReturnURL, currentUser } = this.props;

    if (stripeOnboardingReturnURL != null && !this.showPayoutDetails) {
      this.setState({ showPayoutDetails: true });
    }
  }
  componentDidUpdate() {
    const { currentUser } = this.props;

    if (this.state.sdkInstance === null && this.sdkLoaded.current === false) {
      this.sdkLoaded.current = true;
      this.setState(() => ({ sdkInstance: createInstance({ clientId: config.sdk.clientId }) }));
    }
    if (
      this.state.sdkInstance &&
      this.state.currentUserHasPublishedListing === null &&
      currentUser &&
      this.hasListingFetched.current === false
    ) {
      this.hasListingFetched.current === true;
      this.state.sdkInstance.listings.query({ authorId: currentUser.id.uuid }).then(res => {
        const sdkResp = res?.data?.data;
        if (sdkResp?.length === 0) {
          this.setState({ currentUserHasPublishedListing: false });
        } else if (res?.data?.data?.length > 0) {
          this.setState({ currentUserHasPublishedListing: true });
        }
      });
    }
  }
  handleCreateFlowTabScrolling(shouldScroll) {
    this.hasScrolledToTab = shouldScroll;
  }

  handlePublishListing(id, snoozed = false) {
    const {
      onPublishListingDraft,
      currentUser,
      stripeAccount,
      listing,
      onCloseListing,
      history,
      params,
    } = this.props;

    const stripeConnected = currentUser?.stripeAccount?.id;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;
    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const categories = listing?.attributes?.publicData?.categories || [];
    const bookingType = listing?.attributes?.publicData?.bookingType || '';
    const canPublishWithoutStripe = bookingType !== 'instantBooking';

    const publishAndNavigate = isFirstListing => {
      const pageName = isFirstListing ? 'PublishSuccessPage' : 'FirstListingPublishSuccessPage';
      const route = createResourceLocatorString(pageName, routeConfiguration(), params, {});
      history.push(route);
    };
    const closeAndNavigate = () => {
      return onCloseListing(id).then(() => {
        this.setState({ snoozeLoading: false });
        // history.push(
        //   createResourceLocatorString('ManageListingsPage', routeConfiguration(), {
        //     tab: 'snoozedListings',
        //   })
        // );
        if (typeof window !== 'undefined') {
          window.location.pathname = createResourceLocatorString(
            'ManageListingsPage',
            routeConfiguration(),
            {
              tab: 'snoozedListings',
            }
          );
        }
      });
    };

    const handleStripeCheck = (snoozed = false) => {
      if (stripeConnected && !requirementsMissing) {
        onPublishListingDraft(id).then(() =>
          snoozed
            ? closeAndNavigate()
            : publishAndNavigate(this.state.currentUserHasPublishedListing)
        );
      } else {
        this.setState({
          draftId: id,
          showPayoutDetails: true,
          ...(snoozed ? { snoozeLoading: false } : {}),
        });
      }
    };
    if (snoozed) {
      this.setState({ snoozeLoading: true });
      canPublishWithoutStripe
        ? onPublishListingDraft(id)
            .then(() => closeAndNavigate())
            .catch(error => {
              console.log(error);
              this.setState({ snoozeLoading: false });
            })
        : handleStripeCheck(snoozed);
    } else {
      canPublishWithoutStripe
        ? onPublishListingDraft(id).then(() =>
            publishAndNavigate(this.state.currentUserHasPublishedListing)
          )
        : handleStripeCheck();
    }
  }

  handlePayoutModalClose() {
    this.setState({ showPayoutDetails: false });
  }

  handlePayoutSubmit(values) {
    this.props
      .onPayoutDetailsSubmit(values)
      .then(response => {
        this.props.onManageDisableScrolling('EditListingWizard.payoutModal', false);
      })
      .catch(() => {
        // do nothing
      });
  }

  render() {
    const {
      id,
      className,
      rootClassName,
      params,
      listing,
      viewport,
      intl,
      errors,
      fetchInProgress,
      payoutDetailsSaveInProgress,
      payoutDetailsSaved,
      onManageDisableScrolling,
      onPayoutDetailsFormChange,
      onGetStripeConnectAccountLink,
      getAccountLinkInProgress,
      createStripeAccountError,
      updateStripeAccountError,
      fetchStripeAccountError,
      stripeAccountFetched,
      stripeAccount,
      stripeAccountError,
      stripeAccountLinkError,
      currentUser,
      history,
      ...rest
    } = this.props;

    let TABS = [
      CATEGORIES,
      // ACTIVITYTYPE,
      // SUBCATEGORIES,
      TITLE,
      // LOCATION,
      // AGE,
      // PHOTOS,
      // DESCRIPTION,
      // WHATTOBRING,
      // ...availabilityMaybe,
      PRICING,
      // BOOKINGTYPE,
      // POLICY,
      // PUBLISH,
      // FEATURES,
    ];
    if (listing.attributes.publicData.categories?.[0] === 'camps') {
      const seatsMaybe =
        listing.attributes.publicData.bookingType === 'offPlatform' ? [] : [BOOKINGTYPE];
      const locationMaybe =
        listing.attributes.publicData.activityMode === 'online' ? [] : [LOCATION];
      TABS = [
        CATEGORIES,
        ACTIVITYTYPE,
        SUBCATEGORIES,
        TITLE,
        ACTIVITYMODE,
        ...locationMaybe,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        AVAILABILITY,
        PRICING,
        GETTINGNEWENQUIRY,
        ...seatsMaybe,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'classes') {
      const seatsMaybe =
        listing.attributes.publicData.bookingType === 'offPlatform' ? [] : [BOOKINGTYPE];
      const locationMaybe =
        listing.attributes.publicData.activityMode === 'online' ? [] : [LOCATION];
      TABS = [
        CATEGORIES,
        SUBCATEGORIES,
        TITLE,
        ACTIVITYMODE,
        ...locationMaybe,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        AVAILABILITY,
        PRICING,
        GETTINGNEWENQUIRY,
        ...seatsMaybe,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'workshops') {
      const seatsMaybe =
        listing.attributes.publicData.bookingType === 'offPlatform' ? [] : [BOOKINGTYPE];
      const locationMaybe =
        listing.attributes.publicData.activityMode === 'online' ? [] : [LOCATION];
      TABS = [
        CATEGORIES,
        SUBCATEGORIES,
        TITLE,
        ACTIVITYMODE,
        ...locationMaybe,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        AVAILABILITY,
        PRICING,
        GETTINGNEWENQUIRY,
        ...seatsMaybe,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'birthdayParty') {
      TABS = [
        //no availabilty and booking option here
        CATEGORIES,
        ACTIVITYTYPE,
        TITLE,
        LOCATION,
        AGE,
        NUMBEROFKIDS,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        GETTINGNEWENQUIRY,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'schoolTours') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      TABS = [
        CATEGORIES,
        ACTIVITYTYPE,
        TITLE,
        LOCATION,
        AGE,
        NUMBEROFKIDS,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'events') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      const locationMaybe =
        listing.attributes.publicData.activityMode === 'online' ? [] : [LOCATION];
      TABS = [
        CATEGORIES,
        SUBCATEGORIES,
        TITLE,
        ACTIVITYMODE,
        ...locationMaybe,
        AGE,
        NUMBEROFKIDS,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        AVAILABILITY,
        PRICING,
        GETTINGNEWENQUIRY,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'afterSchoolClub') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      //does not have availability n booking type
      TABS = [
        CATEGORIES,
        // OPENFOR,
        TITLE,
        LOCATION,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'breakfastClub') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      //does not have availability n booking type
      TABS = [
        CATEGORIES,
        // OPENFOR,
        TITLE,
        LOCATION,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'inSchoolProgrammes') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      //does not have availability n booking type
      TABS = [
        CATEGORIES,
        SUBCATEGORIES,
        TITLE,
        LOCATION,
        AGE,
        NUMBEROFKIDS,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'placesToVisit') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      //does not have availability n booking type
      TABS = [
        CATEGORIES,
        ACTIVITYTYPE,
        TITLE,
        LOCATION,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        AVAILABILITY,
        PRICING,
        GETTINGNEWENQUIRY,
        POLICY,
        PUBLISH,
      ];
    }
    if (listing.attributes.publicData.categories?.[0] === 'club') {
      const seatsMaybe = listing.attributes.publicData.bookingType === 'referal' ? [] : [SEATS];
      //does not have availability n booking type
      TABS = [
        CATEGORIES,
        SUBCATEGORIES,
        TITLE,
        LOCATION,
        AGE,
        PHOTOS,
        DESCRIPTION,
        WHATTOBRING,
        PRICING,
        GETTINGNEWENQUIRY,
        POLICY,
        PUBLISH,
      ];
    }
    const backButton = tab => {
      if (Object.keys(params).length > 0) {
        const newParams = { ...params, tab: tab };
        history.replace(
          createResourceLocatorString('EditListingPage', routeConfiguration(), newParams)
        );
      }
    };
    const tabsActive = (isNew, listing) => {
      return TABS.reduce((acc, tab) => {
        const previousTabIndex = TABS.findIndex(t => t === tab) - 1;
        const isActive =
          previousTabIndex >= 0 ? !isNew || tabCompleted(TABS[previousTabIndex], listing) : true;
        return { ...acc, [tab]: isActive };
      }, {});
    };
    const selectedTab = params.tab;
    const isNewListingFlow = [LISTING_PAGE_PARAM_TYPE_NEW, LISTING_PAGE_PARAM_TYPE_DRAFT].includes(
      params.type
    );
    const rootClasses = rootClassName || css.root;
    const classes = classNames(rootClasses, className);
    const currentListing = ensureListing(listing);
    const tabsStatus = tabsActive(isNewListingFlow, currentListing);

    // If selectedTab is not active, redirect to the beginning of wizard
    if (!tabsStatus[selectedTab]) {
      const currentTabIndex = TABS.indexOf(selectedTab);
      const nearestActiveTab = TABS.slice(0, currentTabIndex)
        .reverse()
        .find(t => tabsStatus[t]);

      return <NamedRedirect name="EditListingPage" params={{ ...params, tab: nearestActiveTab }} />;
    }

    const { width } = viewport;
    const hasViewport = width > 0;
    const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
    const hasFontsLoaded =
      hasViewport && document.documentElement.classList.contains('fontsLoaded');

    // Check if scrollToTab call is needed (tab is not visible on mobile)
    if (hasVerticalTabLayout) {
      this.hasScrolledToTab = true;
    } else if (hasHorizontalTabLayout && !this.hasScrolledToTab && hasFontsLoaded) {
      const tabPrefix = id;
      scrollToTab(tabPrefix, selectedTab);
      this.hasScrolledToTab = true;
    }

    const tabLink = tab => {
      return { name: 'EditListingPage', params: { ...params, tab } };
    };

    const setPortalRootAfterInitialRender = () => {
      if (!this.state.portalRoot) {
        this.setState({ portalRoot: document.getElementById('portal-root') });
      }
    };
    const formDisabled = getAccountLinkInProgress;
    const ensuredCurrentUser = ensureCurrentUser(currentUser);
    const currentUserLoaded = !!ensuredCurrentUser.id;
    const stripeConnected = currentUserLoaded && !!stripeAccount && !!stripeAccount.id;

    const rootURL = config.canonicalRootURL;
    const routes = routeConfiguration();
    const { returnURLType, ...pathParams } = params;
    const successURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_SUCCESS,
      rootURL,
      routes,
      pathParams
    );
    const failureURL = createReturnURL(
      STRIPE_ONBOARDING_RETURN_URL_FAILURE,
      rootURL,
      routes,
      pathParams
    );

    const accountId = stripeConnected ? stripeAccount.id : null;
    const stripeAccountData = stripeConnected ? getStripeAccountData(stripeAccount) : null;

    const requirementsMissing =
      stripeAccount &&
      (hasRequirements(stripeAccountData, 'past_due') ||
        hasRequirements(stripeAccountData, 'currently_due'));

    const savedCountry = stripeAccountData ? stripeAccountData.country : null;

    const handleGetStripeConnectAccountLink = handleGetStripeConnectAccountLinkFn(
      onGetStripeConnectAccountLink,
      {
        accountId,
        successURL,
        failureURL,
      }
    );

    const returnedNormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_SUCCESS;
    const returnedAbnormallyFromStripe = returnURLType === STRIPE_ONBOARDING_RETURN_URL_FAILURE;
    const showVerificationNeeded = stripeConnected && requirementsMissing;

    // Redirect from success URL to basic path for StripePayoutPage
    if (returnedNormallyFromStripe && stripeConnected && !requirementsMissing) {
      return <NamedRedirect name="EditListingPage" params={pathParams} />;
    }
    return (
      <div className={classes} ref={setPortalRootAfterInitialRender}>
        <Tabs
          rootClassName={css.tabsContainer}
          navRootClassName={css.nav}
          tabRootClassName={css.tab}
          isTabNavReq={false}
        >
          {TABS.map(tab => {
            return (
              <EditListingWizardTab
                {...rest}
                currentUser={currentUser}
                key={tab}
                tabId={`${id}_${tab}`}
                tabLabel={tabLabel(intl, tab)}
                tabLinkProps={tabLink(tab)}
                selected={selectedTab === tab}
                disabled={isNewListingFlow && !tabsStatus[tab]}
                tab={tab}
                intl={intl}
                params={params}
                listing={listing}
                marketplaceTabs={TABS}
                errors={errors}
                handleCreateFlowTabScrolling={this.handleCreateFlowTabScrolling}
                handlePublishListing={this.handlePublishListing}
                fetchInProgress={fetchInProgress}
                onManageDisableScrolling={onManageDisableScrolling}
                backButton={backButton}
                snoozeLoading={this.state.snoozeLoading}
              />
            );
          })}
        </Tabs>
        <Modal
          id="EditListingWizard.payoutModal"
          isOpen={this.state.showPayoutDetails}
          onClose={this.handlePayoutModalClose}
          onManageDisableScrolling={onManageDisableScrolling}
          usePortal
        >
          <div className={css.modalPayoutDetailsWrapper}>
            <h1 className={css.modalTitle}>
              <FormattedMessage id="EditListingWizard.payoutModalTitleOneMoreThing" />
            </h1>
            {/* <h1 className={css.modalTitle}>
              {width > 767 ? (
                <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferences" />
              ) : (
                <FormattedMessage id="EditListingWizard.payoutModalTitlePayoutPreferencesMobile" />
              )}
            </h1> */}
            {!currentUserLoaded ? (
              <FormattedMessage id="StripePayoutPage.loadingData" />
            ) : returnedAbnormallyFromStripe && !stripeAccountLinkError ? (
              <p className={css.modalMessage}>
                <RedirectToStripe redirectFn={handleGetStripeConnectAccountLink} />
              </p>
            ) : (
              <>
                <p className={css.modalMessage}>
                  <FormattedMessage id="EditListingWizard.payoutModalInfo" />
                </p>
                <StripeConnectAccountForm
                  disabled={formDisabled}
                  inProgress={payoutDetailsSaveInProgress}
                  ready={payoutDetailsSaved}
                  currentUser={ensuredCurrentUser}
                  stripeBankAccountLastDigits={getBankAccountLast4Digits(stripeAccountData)}
                  savedCountry={savedCountry}
                  submitButtonText={intl.formatMessage({
                    id: 'StripePayoutPage.submitButtonText',
                  })}
                  stripeAccountError={stripeAccountError}
                  stripeAccountFetched={stripeAccountFetched}
                  stripeAccountLinkError={stripeAccountLinkError}
                  onChange={onPayoutDetailsFormChange}
                  onSubmit={rest.onPayoutDetailsSubmit}
                  onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink}
                  stripeConnected={stripeConnected}
                >
                  {stripeConnected && !returnedAbnormallyFromStripe && showVerificationNeeded ? (
                    <StripeConnectAccountStatusBox
                      type="verificationNeeded"
                      inProgress={getAccountLinkInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_verification'
                      )}
                    />
                  ) : stripeConnected && savedCountry && !returnedAbnormallyFromStripe ? (
                    <StripeConnectAccountStatusBox
                      type="verificationSuccess"
                      inProgress={getAccountLinkInProgress}
                      disabled={payoutDetailsSaveInProgress}
                      onGetStripeConnectAccountLink={handleGetStripeConnectAccountLink(
                        'custom_account_update'
                      )}
                    />
                  ) : null}
                </StripeConnectAccountForm>
              </>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}

EditListingWizard.defaultProps = {
  className: null,
  currentUser: null,
  rootClassName: null,
  listing: null,
  stripeAccount: null,
  stripeAccountFetched: null,
  updateInProgress: false,
  createStripeAccountError: null,
  updateStripeAccountError: null,
  fetchStripeAccountError: null,
  stripeAccountError: null,
  stripeAccountLinkError: null,
};

EditListingWizard.propTypes = {
  id: string.isRequired,
  className: string,
  currentUser: propTypes.currentUser,
  rootClassName: string,
  params: shape({
    id: string.isRequired,
    slug: string.isRequired,
    type: oneOf(LISTING_PAGE_PARAM_TYPES).isRequired,
    // tab: oneOf(TABS).isRequired,
  }).isRequired,
  stripeAccount: object,
  stripeAccountFetched: bool,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: shape({
    attributes: shape({
      publicData: object,
      description: string,
      geolocation: object,
      pricing: object,
      title: string,
    }),
    images: array,
  }),

  errors: shape({
    createListingDraftError: object,
    updateListingError: object,
    publishListingError: object,
    showListingsError: object,
    uploadImageError: object,
  }).isRequired,
  createStripeAccountError: propTypes.error,
  updateStripeAccountError: propTypes.error,
  fetchStripeAccountError: propTypes.error,
  stripeAccountError: propTypes.error,
  stripeAccountLinkError: propTypes.error,

  fetchInProgress: bool.isRequired,
  getAccountLinkInProgress: bool.isRequired,
  payoutDetailsSaveInProgress: bool.isRequired,
  payoutDetailsSaved: bool.isRequired,
  onPayoutDetailsFormChange: func.isRequired,
  onGetStripeConnectAccountLink: func.isRequired,
  onManageDisableScrolling: func.isRequired,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withViewport, injectIntl)(EditListingWizard);
