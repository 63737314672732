import React, { useEffect } from 'react';
import css from './BookingTimeForm.module.css';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { fetchTransactionLineItemsForPaymentOptions } from '../../containers/ListingPage/ListingPage.duck';
import moment from 'moment';
import { formatMoney } from '../../util/currency';
import { types as sdkTypes } from '../../util/sdkLoader';

const { Money } = sdkTypes;

const PayingOptionsComponent = props => {
  const dispatch = useDispatch();
  const LISTING_PAGE_REDUX = useSelector(state => state.ListingPage);

  const {
    fetchPayingOptionLineItemsInProgress,
    fetchPayingOptionLineItemsError,
    payingOptionData,
  } = LISTING_PAGE_REDUX;
  const {
    onOptionChange,
    values,
    bookingData,
    listingId,
    listing,
    estimatedTotalPrice,
    intl,
  } = props;
  const { fullPaymentLineItems = [], depositPaymentLineItems = [] } = payingOptionData;
  const depositLineItem = depositPaymentLineItems.find(
    l => l.code === 'line-item/pay-at-venue-deduction'
  );
  const depositAmount = depositLineItem?.code
    ? formatMoney(
        intl,
        new Money(
          Math.abs(depositLineItem?.lineTotal?.amount || 0),
          depositLineItem.lineTotal.currency
        )
      )
    : 0;
  const fullPayinTotalasMoney =
    fullPaymentLineItems?.length > 0 ? estimatedTotalPrice(fullPaymentLineItems) : null;
  const depositPayinTotalasMoney =
    depositPaymentLineItems?.length > null ? estimatedTotalPrice(depositPaymentLineItems) : 0;

  const formattedFullPay = fullPayinTotalasMoney ? formatMoney(intl, fullPayinTotalasMoney) : '';
  const formattedDepositPay = depositPayinTotalasMoney
    ? formatMoney(intl, depositPayinTotalasMoney)
    : '';
  const onInputChange = e => {
    const event = e;
    const payingOptionType = event.target.id;
    return onOptionChange(e, payingOptionType);
  };
  useEffect(() => {
    const startDatePD = listing.attributes.publicData.startDate;
    const endDatePD = listing.attributes.publicData.endDate;
    const startDate = startDatePD ? moment(startDatePD).toDate() : null;
    const endDate = endDatePD ? moment(endDatePD).toDate() : null;
    dispatch(
      fetchTransactionLineItemsForPaymentOptions({
        bookingData: { ...bookingData, startDate, endDate },
        listingId,
        isOwnListing: false,
        payingOptionsTypes: ['fullPayment', 'depositPayment'],
      })
    );
  }, []);
  return (
    <div className={css.payoptmaindiv}>
      <p>
        Choose Amount to Pay<span>*</span>
      </p>
      <div
        className={classNames(css.Fullpayment, {
          [css.Fullpaymntslct]: values.payingOptions === 'fullPayment',
        })}
      >
        <label
          className={classNames(css.payoptlabel, {
            [css.selbl]: values.payingOptions === 'fullPayment',
          })}
          htmlFor="fullPayment"
        >
          <span>
            <span>Pay in full:</span>
            <span>Pay the total {formattedFullPay} and you're all set.</span>
          </span>

          <span>
            {values.payingOptions === 'fullPayment' ? (
              // GrCheckboxSelected
              <span className={`${css.ampradio} ${css.ampradioselect}`}></span>
            ) : (
              // <GrCheckbox />
              <span className={css.ampradio}></span>
            )}
          </span>
        </label>
        <input
          type="radio"
          // defaultChecked={true}
          name="payingOptions"
          id="fullPayment"
          onChange={onInputChange}
        />
      </div>
      {bookingData.quantity === 1 && bookingData.child?.[0]?.isTrial ? null : (
        <div
          className={classNames(css.PartPayment, {
            [css.PartPaymntSlct]: values.payingOptions === 'depositPayment',
          })}
        >
          <label
            className={classNames(css.payoptlabel, {
              [css.selbl]: values.payingOptions === 'depositPayment',
            })}
            htmlFor="depositPayment"
          >
            <span>
              <span>Pay part now, part later:</span>
              <span>
                Pay {formattedDepositPay} today and {depositAmount} at a later date directly to
                provider
              </span>
            </span>

            <span>
              {values.payingOptions === 'depositPayment' ? (
                // <GrCheckboxSelected />
                <span className={`${css.ampradio} ${css.ampradioselect}`}></span>
              ) : (
                // <GrCheckbox />
                <span className={css.ampradio}></span>
              )}
            </span>
          </label>
          <input
            type="radio"
            name="payingOptions"
            id="depositPayment"
            disabled={depositAmount === 0}
            onChange={onInputChange}
          />
        </div>
      )}
    </div>
  );
};

export default PayingOptionsComponent;
