import axios from 'axios';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { apiBaseUrl } from '../../util/api';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';
import { types as sdkTypes } from '../../util/sdkLoader';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
const { UUID } = sdkTypes;

// ============Action Type =================
export const FETCH_FAVOURITE_LISTINGS_REQUEST =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_REQUEST';
export const FETCH_FAVOURITE_LISTINGS_SUCCESS =
  'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_SUCCESS';
export const FETCH_FAVOURITE_LISTINGS_ERROR = 'app/FavouritesPage/FETCH_FAVOURITE_LISTINGS_ERROR';
export const UPDATE_PAGE_LISTINGS = 'app/FavouritesPage/UPDATE_PAGE_LISTINGS';

//=============Reducer=================
const initialState = {
  pagination: null,
  queryParams: null,
  queryInProgress: null,
  queryListingsError: null,
  currentPageResultIds: [],
  pageListings: [],
};
const resultIds = data => data.data.map(l => new UUID(l));

const favouritesPageReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case UPDATE_PAGE_LISTINGS:
      return {
        ...state,
        pageListings: payload,
      };
    case FETCH_FAVOURITE_LISTINGS_REQUEST:
      return {
        ...state,
        queryParams: payload.queryParams,
        queryInProgress: true,
        queryListingsError: null,
        currentPageResultIds: [],
      };
    case FETCH_FAVOURITE_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: payload.data.sortedIds,
        pagination: {
          pages: payload.data.pages,
          count: payload.data.count,
          currentPage: payload.data.currentPage,
        },
        queryInProgress: false,
      };
    case FETCH_FAVOURITE_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      return { ...state, queryInProgress: false, queryListingsError: payload };
    default:
      return { ...state };
  }
};
export default favouritesPageReducer;
//=======ActionCreators==============
export const queryListingsRequest = currentUser => ({
  type: FETCH_FAVOURITE_LISTINGS_REQUEST,
  payload: { currentUser },
});

export const queryListingsSuccess = response => {
  return {
    type: FETCH_FAVOURITE_LISTINGS_SUCCESS,
    payload: { data: response },
  };
};

export const queryListingsError = e => ({
  type: FETCH_FAVOURITE_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const setPageListings = listingIds => {
  return {
    type: UPDATE_PAGE_LISTINGS,
    payload: listingIds,
  };
};
//==========fetch from database ===========
export const getFavouriteListings = page => async (dispatch, getState, sdk) => {
  const { currentUser } = getState().user;
  dispatch(queryListingsRequest(currentUser));
  const url = `${apiBaseUrl()}/api/currentuser-favourite-listings`;
  const userId = currentUser?.id.uuid;
  const response = await axios.post(url, { userId, page, perPage: 10 });
  dispatch(setPageListings(response.data.listingIds));
  const listingIds = response.data.listingIds.map(d => new UUID(d));
  const newL = await sdk.listings.query({
    ids: listingIds,
    sort: '',
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      // Listing page
      'variants.landscape-crop',
      'variants.landscape-crop2x',
    ],
  });

  const final = {
    status: 200,
    statusText: '',
    data: { data: newL.data.data, included: newL.data.included },
    count: response.data.count,
    pages: response.data.pages,
    currentPage: page,
    sortedIds: listingIds,
  };
  dispatch(addMarketplaceEntities(final));
  dispatch(queryListingsSuccess(final));
  return final;
};

export const loadData = (param, search) => async (dispatch, getState) => {
  dispatch(queryListingsRequest(true));
  const queryParams = parse(search);
  const page = queryParams.page || 1;
  await dispatch(fetchCurrentUser());
  return dispatch(getFavouriteListings(page));
};
