import React, { useState } from 'react';
import { arrayOf, bool, func, shape, string } from 'prop-types';
import { compose } from 'redux';
import { Form as FinalForm } from 'react-final-form';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { propTypes } from '../../util/types';
import { maxLength, required, composeValidators } from '../../util/validators';
import {
  Form,
  Button,
  FieldTextInput,
  FieldCheckbox,
  FieldCheckboxGroup,
  NamedLink,
} from '../../components';
import config from '../../config';
import { findOptionsForSelectFilter } from '../../util/search';
import css from './EditListingDescriptionForm.module.css';
import Select from 'react-select';
import { DESCRIPTION, PHOTOS } from '../../components/EditListingWizard/EditListingWizardTab';
import ProgressBar from '../../components/ProgressBar/ProgressBar';
// import { AiOutlineDoubleLeft } from 'react-icons/ai';

const TITLE_MAX_LENGTH = 60;
const DESC_MAX_LENGTH = 1000;
const EditListingDescriptionFormComponent = props => {
  const { filterConfig, initialValues, publicData } = props;
  const mainMsg = publicData.categories?.includes('afterSchoolClub')
    ? 'Our After-School club offers'
    : publicData.categories?.includes('birthdayParty')
    ? 'With this amazing party package you can expect'
    : publicData.categories?.includes('breakfastClub')
    ? 'Our breakfast club offers'
    : publicData.categories?.includes('classes')
    ? 'Our weekly classes encourage your child to'
    : publicData.categories?.includes('schoolTours')
    ? 'With this school tour your class can expect'
    : publicData.categories?.includes('inSchoolProgrammes')
    ? 'Let us bring your classroom to life with'
    : publicData.categories?.includes('workshops')
    ? 'This workshop offers your child an insight into'
    : publicData.categories?.includes('club')
    ? ''
    : publicData.categories?.includes('placesToVisit')
    ? 'Visit us to experience'
    : publicData.categories?.includes('camps')
    ? 'Let us tell you about our great camp'
    : publicData.categories?.includes('events')
    ? 'This child-friendly event is'
    : null;
  const [descriptionFocussed, setDescriptionFocussed] = useState(false);
  const [description, setDescription] = useState(
    !!initialValues.description ? initialValues.description : mainMsg
  );
  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          savenExit,
          className,
          disabled,
          ready,
          handleSubmit,
          intl,
          invalid,
          pristine,
          backButton,
          updated,
          updateInProgress,
          fetchErrors,
          form,
          values,
          backbtnLoader,
          publicData,
          initialValues,
          tabProgress,
        } = formRenderProps;
        const tabPercent = tabProgress(DESCRIPTION);
        const { updateListingError, createListingDraftError, showListingsError } =
          fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        // This error happens only on first tab (of EditListingWizard)
        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;
        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;
        const classes = classNames(css.root, className);
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress;
        const placeholderMessage = publicData.categories?.includes('afterSchoolClub')
          ? `It’s your time to shine! What makes your after-school club unique? Location, homework help, activities, play space and skilled staff, etc. Tell parents what to expect and why they should book this. `
          : publicData.categories?.includes('birthdayParty')
          ? 'It’s your time to shine! What makes your party option unique? Location, original games,  skilled staff, activities etc. Tell parents what to expect and why they should book this.'
          : publicData.categories?.includes('breakfastClub')
          ? 'It’s your time to shine! What makes your breakfast club unique? Location, breakfast options, activities, play space and skilled staff, etc. Tell parents what to expect and why they should book this.'
          : publicData.categories?.includes('classes')
          ? 'It’s your time to shine! What makes your classes unique? Location, original games,  skilled staff, seasonal activities etc. Tell parents what to expect and why they should book this.'
          : publicData.categories?.includes('schoolTours')
          ? 'It’s your time to shine! What makes your school tour option unique? Location, original activities, skilled staff, etc. Tell teachers what to expect and why they should book this.'
          : publicData.categories?.includes('inSchoolProgrammes')
          ? 'It’s your time to shine! What makes your in-school programme unique?  Give 2-3 sample ideas of programmes with a brief description. Tell teachers what to expect and why they should book this.'
          : publicData.categories?.includes('workshops')
          ? 'It’s your time to shine! What makes your workshop unique? Tell everyone what to expect and why they should book this.'
          : publicData.categories?.includes('placesToVisit')
          ? 'It’s your time to shine! What makes your venue unique and family-friendly? Location, original activities,  skilled staff, etc.  Tell visitors what to expect and why they should come.'
          : publicData.categories?.includes('camps')
          ? 'It’s your time to shine! What makes your camp unique? Location, original games,  skilled staff, seasonal activities etc. Tell parents what to expect and why they should book this.'
          : publicData.categories?.includes('events')
          ? 'It’s your time to shine! What makes your event unique? Location, original activities,  skilled staff, etc. Tell parents what to expect and why they should attend. '
          : null;

        const clubCustomPlaceHolder = (
          <div className={css.textdiv} onClick={() => setDescriptionFocussed(true)}>
            <p className={css.ccphp}>
              What makes this club activity worth joining? Location, training, awards, skilled
              staff, events and activities etc.
            </p>
            <p>
              When do you meet up?
              <div>
                <span>Mon:</span>
                <span>7.00- 8.15pm</span>
              </div>{' '}
              <div>
                <span>Wed:</span>
                <span>7.00 – 8.15pm</span>
              </div>{' '}
              <div>
                <span>Sat:</span>
                <span>10.00 – 11.30am</span>
              </div>{' '}
              <div>
                <span>Sun:</span>
                <span>matches</span>
              </div>{' '}
            </p>
          </div>
        );
        const lastTab = PHOTOS;
        const saveExit = (
          <Button
            type="button"
            className={css.exitButton}
            inProgress={backbtnLoader}
            onClick={() => {
              const { description } = values;
              const updatedValues = {
                description,
              };
              savenExit(updatedValues);
            }}
          >
            Save & Exit
          </Button>
        );
        const descriptionMaybe = (
          <>
            {/* <p>{mainMsg}</p> */}
            <textarea
              id="description"
              name="description"
              lang="en-IE"
              spellcheck="true"
              className={css.description}
              value={description}
              onChange={e => {
                const value = e.target.value;
                if (value.length <= DESC_MAX_LENGTH) {
                  setDescription(value);
                  form.change('description', value);
                }
              }}
              autoFocus={true}
              onFocus={e => {
                const value = e.target.value;
                e.target.selectionStart = value.length;
                setDescriptionFocussed(true);
              }}
              onBlur={() => {
                setDescriptionFocussed(false);
              }}
              placeholder={placeholderMessage}
            ></textarea>
          </>
        );
        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.svexbtnsec}>{saveExit}</div>

            <div className={css.formseclist}>
              {publicData.categories?.includes('afterSchoolClub') ||
              publicData.categories?.includes('birthdayParty') ||
              publicData.categories?.includes('breakfastClub') ||
              publicData.categories?.includes('schoolTours') ||
              publicData.categories?.includes('classes') ||
              publicData.categories?.includes('inSchoolProgrammes') ||
              publicData.categories?.includes('workshops') ||
              publicData.categories?.includes('club') ||
              publicData.categories?.includes('placesToVisit') ||
              publicData.categories?.includes('camps') ||
              publicData.categories?.includes('events') ? (
                <>
                  <div className={css.descriptionContainer}>
                    <label>Create your description</label>

                    {publicData.categories?.includes('club') &&
                    !descriptionFocussed &&
                    !values.description ? (
                      clubCustomPlaceHolder
                    ) : !descriptionFocussed && !values.description ? (
                      <div>
                        <div className={css.textdiv} onClick={() => setDescriptionFocussed(true)}>
                          <h2>{!!mainMsg ? `${mainMsg}...` : mainMsg}</h2>
                          <p>{placeholderMessage}</p>
                        </div>
                      </div>
                    ) : (
                      descriptionMaybe
                    )}
                  </div>
                  <span className={css.cntspn}>
                    {!!description ? description.length : 0}/{DESC_MAX_LENGTH}
                  </span>
                </>
              ) : null}
            </div>
            <div className={css.formlistbtmsec}>
              <ProgressBar bgcolor="red" progress={tabPercent} height={30} />

              <div className={css.buttonDiv}>
                <Button
                  type="button"
                  className={css.backButton}
                  onClick={() => backButton(lastTab)}
                >
                  Back
                </Button>
                <Button
                  className={css.submitButton}
                  type="submit"
                  inProgress={submitInProgress}
                  disabled={!values.description}
                  ready={submitReady}
                >
                  Next
                </Button>
              </div>
            </div>
          </Form>
        );
      }}
    />
  );
};

EditListingDescriptionFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

EditListingDescriptionFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  filterConfig: propTypes.filterConfig,
  fetchErrors: shape({
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
  certificateOptions: arrayOf(
    shape({
      key: string.isRequired,
      label: string.isRequired,
    })
  ),
};

export default compose(injectIntl)(EditListingDescriptionFormComponent);
