import React, { useState } from 'react';
import { bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { findOptionsForSelectFilter } from '../../util/search';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { ListingLink } from '../../components';
import { EditListingCategoriesForm } from '../../forms';
import config from '../../config';
import topRead1 from '../../assets/topRead1.jpg';

import css from './EditListingCategoriesPanel.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Modal from '../Modal/Modal';
import { types as sdkTypes } from '../../util/sdkLoader';
import { defaultTitle, starterOrBookableCategories } from '../../marketplace-custom-config';
import EditListingImage from '../EditListingImage/EditListingImage';
import clisting2 from '../../assets/clisting-2.png';
import clistingmb2 from '../../assets/screen-02-full-img.png';
import listinglogo from '../../assets/listinglogowhite.png';
const { LatLng } = sdkTypes;
const irelandCords = { lat: 53.1423672, lng: -7.692053599999999 };
const EditListingCategoriesPanelComponent = props => {
  const {
    className,
    rootClassName,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    tabProgress,
    savenExit,
    backbtnLoader,
    isNewURI,
    currentUser,
    isStarter,
  } = props;
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData, title } = currentListing.attributes;
  const categories = (publicData && publicData.categories) ?? [];
  const { isTitleSet } = publicData;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;

  const certificateOptions = findOptionsForSelectFilter('certificate', config.custom.filters);
  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        {/* <img src={topRead1} alt="1" className={css.imgSection} /> */}
        <EditListingImage
          className={css.leftSection}
          logoImg={listinglogo}
          desktopImg={clisting2}
          mobileImg={clistingmb2}
          imageText="What kind of activity will you list?"
        ></EditListingImage>
        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingCategoriesForm
            isPublished={isPublished}
            className={css.form}
            currentUser={currentUser}
            backbtnLoader={backbtnLoader}
            isStarter={isStarter}
            initialValues={{
              categories,
            }}
            savenExit={savenExit}
            demoGeolocation={new LatLng(irelandCords.lat, irelandCords.lng)}
            saveActionMsg={submitButtonText}
            onSubmit={values => {
              const { categories } = values;
              const shouldStopExecution =
                isStarter && !starterOrBookableCategories.includes(categories[0]);
              if (shouldStopExecution) return;
              const formattedCategories = isStarter
                ? categories.filter(c => c === 'camps' || c === 'classes' || c === 'workshops')
                : categories;
              const updateValues = {
                title: !!title ? title : defaultTitle,
                publicData: {
                  isTitleSet: !!isTitleSet,
                  categories: formattedCategories?.length >= 1 ? formattedCategories : null,
                },
              };
              if (isNewURI) {
                updateValues.geolocation = new LatLng(irelandCords.lat, irelandCords.lng);
              }
              onSubmit(updateValues);
            }}
            isTitleSet={isTitleSet}
            history={history}
            tabProgress={tabProgress}
            onChange={onChange}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
            certificateOptions={certificateOptions}
            title={title}
            defaultTitle={defaultTitle}
          />
        </div>
      </div>
    </div>
  );
};

EditListingCategoriesPanelComponent.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  listing: null,
};

EditListingCategoriesPanelComponent.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};
// const EditListingDescriptionPanel = compose(withRouter)(EditListingDescriptionPanelComponent);
// export default EditListingDescriptionPanel;
export default EditListingCategoriesPanelComponent;
