import React, { Component } from 'react';
import { array, bool, func, object, string } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { LISTING_STATE_DRAFT } from '../../util/types';
import { EditListingPhotosForm } from '../../forms';
import { ensureOwnListing } from '../../util/data';
import { ListingLink } from '../../components';

import css from './EditListingPhotosPanel.module.css';
import EditListingImage from '../EditListingImage/EditListingImage';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-09-icon.png';

class EditListingPhotosPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }
  handleScroll = () => {
    if (typeof window === 'undefined') return;
    if (window.scrollY >= 600) {
      this.setState({ isSticky: true });
    } else {
      this.setState({ isSticky: false });
    }
  };
  componentDidMount() {
    typeof window === 'object' && window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    typeof window === 'object' && window.removeEventListener('scroll', this.handleScroll);
  }
  render() {
    const {
      className,
      rootClassName,
      errors,
      disabled,
      ready,
      images,
      listing,
      onImageUpload,
      onUpdateImageOrder,
      submitButtonText,
      panelUpdated,
      updateInProgress,
      onChange,
      onSubmit,
      onRemoveImage,
      history,
      backButton,
      tabProgress,
      currentTab,
      savenExit,
      backbtnLoader,
      onManageDisableScrolling,
      viewport,
    } = this.props;
    const rootClass = rootClassName || css.root;
    const classes = classNames(rootClass, className);
    const currentListing = ensureOwnListing(listing);
    const listingImages = currentListing?.images ? currentListing?.images : [];
    const { categories } = currentListing.attributes.publicData;
    const photoConsent = currentListing.attributes.publicData?.photoConsent;
    const isPublished =
      currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
    const panelTitle = isPublished ? (
      <FormattedMessage
        id="EditListingPhotosPanel.title"
        values={{
          listingTitle: (
            <ListingLink listing={listing}>
              <FormattedMessage id="EditListingPhotosPanel.listingTitle" />
            </ListingLink>
          ),
        }}
      />
    ) : (
      <FormattedMessage id="EditListingPhotosPanel.createListingTitle" />
    );
    const saveAndExit = values => {
      const { photoConsent, images } = values;
      const submitValues = { images: images, publicData: { photoConsent } };
      savenExit(submitValues);
    };
    return (
      <div className={classes}>
        <div className={css.sectionMiddle}>
          <EditListingImage
            className={css.imgSection}
            imageText="Next, let’s add some photos of your activity. "
            currentTab={currentTab}
            logoImg={listinglogo}
            desktopImg={clisting5}
            mobileImg={clistingmb5}
            bgIcon={screenicon}
          />
          <div className={css.rightSection}>
            <EditListingPhotosForm
              viewport={viewport}
              saveAndExit={saveAndExit}
              backbtnLoader={backbtnLoader}
              className={classNames(css.form, { [css.stick]: this.state.isSticky })}
              disabled={disabled}
              backButton={backButton}
              ready={ready}
              fetchErrors={errors}
              initialValues={{ images, photoConsent: !!photoConsent }}
              images={images}
              onImageUpload={onImageUpload}
              onManageDisableScrolling={onManageDisableScrolling}
              onSubmit={values => {
                const { photoConsent, images } = values;
                const submitValues = { images: images, publicData: { photoConsent } };
                values.images?.length >= 3 && onSubmit(submitValues);
              }}
              history={history}
              onChange={onChange}
              onUpdateImageOrder={onUpdateImageOrder}
              onRemoveImage={onRemoveImage}
              saveActionMsg={submitButtonText}
              updated={panelUpdated}
              updateInProgress={updateInProgress}
              categories={categories}
              tabProgress={tabProgress}
              listingImages={listingImages}
            />
          </div>
        </div>
      </div>
    );
  }
}

EditListingPhotosPanel.defaultProps = {
  className: null,
  rootClassName: null,
  errors: null,
  images: [],
  listing: null,
};

EditListingPhotosPanel.propTypes = {
  className: string,
  rootClassName: string,
  errors: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  images: array,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  onImageUpload: func.isRequired,
  onUpdateImageOrder: func.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  onRemoveImage: func.isRequired,
};

export default EditListingPhotosPanel;
