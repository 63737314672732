import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, bool, func, object, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { ensureOwnListing } from '../../util/data';
import { getDefaultTimeZoneOnBrowser, resetToStartOfDay, timestampToDate } from '../../util/dates';
import { LISTING_STATE_DRAFT, DATE_TYPE_DATETIME, propTypes } from '../../util/types';
import {
  Button,
  IconClose,
  IconEdit,
  IconSpinner,
  InlineTextButton,
  ListingLink,
  Modal,
  TimeRange,
} from '../../components';
import { EditListingAvailabilityPlanForm, EditListingAvailabilityExceptionForm } from '../../forms';
import config from '../../config';
import { createInstance } from '../../util/sdkLoader';
import css from './EditListingAvailabilityPanel.module.css';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import moment from 'moment';
import EditListingImage from '../EditListingImage/EditListingImage';

import clisting5 from '../../assets/screen-05-ripped-img.png';
import clistingmb5 from '../../assets/screen-05-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogowhite.png';
import screenicon from '../../assets/screen-12-icon.png';
import EditListingAvailabilityForm from '../../forms/EditListingAvailabilityForm/EditListingAvailabilityForm';

const WEEKDAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

// We want to sort exceptions on the client-side, maximum pagination page size is 100,
// so we need to restrict the amount of exceptions to that.
const MAX_EXCEPTIONS_COUNT = 100;

const defaultTimeZone = () =>
  typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';

/////////////
// Weekday //
/////////////
const findEntry = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.find(d => d.dayOfWeek === dayOfWeek);

const getEntries = (availabilityPlan, dayOfWeek) =>
  availabilityPlan.entries.filter(d => d.dayOfWeek === dayOfWeek);

const Weekday = props => {
  const { availabilityPlan, dayOfWeek, openEditModal } = props;
  const hasEntry = findEntry(availabilityPlan, dayOfWeek);

  return (
    <div
      className={classNames(css.weekDay, { [css.blockedWeekDay]: !hasEntry })}
      onClick={() => openEditModal(true)}
      role="button"
    >
      <div className={css.dayOfWeek}>
        <FormattedMessage id={`EditListingAvailabilityPanel.dayOfWeek.${dayOfWeek}`} />
      </div>
      <div className={css.entries}>
        {availabilityPlan && hasEntry
          ? getEntries(availabilityPlan, dayOfWeek).map(e => {
              return (
                <span className={css.entry} key={`${e.dayOfWeek}${e.startTime}`}>
                  {`${e.startTime} - ${e.endTime === '00:00' ? '24:00' : e.endTime}`}
                  {'  - '}
                  {e.seats} seats
                </span>
              );
            })
          : null}
      </div>
    </div>
  );
};

///////////////////////////////////////////////////
// EditListingAvailabilityExceptionPanel - utils //
///////////////////////////////////////////////////

// Create initial entry mapping for form's initial values
const createEntryDayGroups = (entries = {}) =>
  entries.reduce((groupedEntries, entry) => {
    const { startTime, endTime: endHour, dayOfWeek, seats } = entry;
    const dayGroup = groupedEntries[dayOfWeek] || [];
    return {
      ...groupedEntries,
      [dayOfWeek]: [
        ...dayGroup,
        {
          startTime,
          endTime: endHour === '00:00' ? '24:00' : endHour,
          seats,
        },
      ],
    };
  }, {});

// Create initial values
const createInitialValues = availabilityPlan => {
  const { timezone, entries } = availabilityPlan || {};
  const tz = timezone || defaultTimeZone();
  return {
    timezone: tz,
    ...createEntryDayGroups(entries),
  };
};

// Create entries from submit values
const createEntriesFromSubmitValues = values =>
  WEEKDAYS.reduce((allEntries, dayOfWeek) => {
    const dayValues = values[dayOfWeek] || [];
    const dayEntries = dayValues.map(dayValue => {
      const { startTime, endTime, seats } = dayValue;
      // Note: This template doesn't support seats yet.
      return startTime && endTime
        ? {
            dayOfWeek,
            seats,
            startTime,
            endTime: endTime === '24:00' ? '00:00' : endTime,
          }
        : null;
    });

    return allEntries.concat(dayEntries.filter(e => !!e));
  }, []);

// Create availabilityPlan from submit values
const createAvailabilityPlan = values => ({
  availabilityPlan: {
    type: 'availability-plan/time',
    timezone: values.timezone,
    entries: createEntriesFromSubmitValues(values),
  },
});

// Ensure that the AvailabilityExceptions are in sensible order.
//
// Note: if you allow fetching more than 100 exception,
// pagination kicks in and that makes client-side sorting impossible.
const sortExceptionsByStartTime = (a, b) => {
  return a.attributes.start.getTime() - b.attributes.start.getTime();
};
const tz = typeof window !== 'undefined' ? getDefaultTimeZoneOnBrowser() : 'Etc/UTC';
const today = new Date();
const start = resetToStartOfDay(today, tz, 0);
// Query range: today + 364 days
const exceptionRange = 364;
const end = resetToStartOfDay(today, tz, exceptionRange);
//////////////////////////////////
// EditListingAvailabilityPanel //
//////////////////////////////////
const EditListingAvailabilityPanel = props => {
  const {
    className,
    rootClassName,
    listing,
    availabilityExceptions,
    fetchExceptionsInProgress,
    timeslots,
    disabled,
    ready,
    onSubmit,
    onManageDisableScrolling,
    onNextTab,
    tabProgress,
    backButton,
    updateInProgress,
    errors,
    history,
    currentTab,
    savenExit,
    backbtnLoader,
  } = props;
  // Hooks
  // const [sdkInstance, setSdkInstance] = useState(null);
  // const [activityAvailability, setActivityAvailability] = useState(null);
  // const [activityAvailabilityChanged, setActivityAvailabilityChanged] = useState(null);
  const [valuesFromLastSubmit, setValuesFromLastSubmit] = useState(null);
  const [loadingForSubmit, setLoadingForSubmit] = useState(false);
  //didmount
  // useEffect(() => {
  //   setSdkInstance(createInstance({ clientId: config.sdk.clientId }));
  //   setActivityAvailabilityChanged('initialState');
  //   sdkInstance &&
  //     sdkInstance?.availabilityExceptions
  //       ?.query(
  //         {
  //           listingId: listing.id.uuid,
  //           start,
  //           end,
  //         },
  //         { expand: true }
  //       )
  //       .then(res => {
  //         setActivityAvailability(res.data.data);
  //       })
  //       .catch(e => console.log(e));
  // }, []);

  // useEffect(() => {
  //   sdkInstance &&
  //     sdkInstance?.availabilityExceptions
  //       ?.query(
  //         {
  //           listingId: listing.id.uuid,
  //           start,
  //           end,
  //         },
  //         { expand: true }
  //       )
  //       .then(res => {
  //         setActivityAvailability(res.data.data);
  //       })
  //       .catch(e => console.log(e));
  // }, [activityAvailabilityChanged]);
  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureOwnListing(listing);
  const { publicData } = listing.attributes;
  const { categories } = publicData;
  // const isNextButtonDisabled = activityAvailability?.length === 0;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const defaultAvailabilityPlan = {
    type: 'availability-plan/time',
    timezone: defaultTimeZone(),
    entries: [
      // { dayOfWeek: 'mon', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'tue', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'wed', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'thu', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'fri', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sat', startTime: '09:00', endTime: '17:00', seats: 1 },
      // { dayOfWeek: 'sun', startTime: '09:00', endTime: '17:00', seats: 1 },
    ],
  };
  const availabilityPlan = currentListing.attributes.availabilityPlan || defaultAvailabilityPlan;
  const initialValues = valuesFromLastSubmit
    ? valuesFromLastSubmit
    : createInitialValues(availabilityPlan);

  const handleSubmit = values => {
    setValuesFromLastSubmit(values);

    // Final Form can wait for Promises to return.
    return onSubmit(createAvailabilityPlan(values))
      .then(() => {
        setIsEditPlanModalOpen(false);
      })
      .catch(e => {
        // Don't close modal if there was an error
      });
  };

  const exceptionCount = availabilityExceptions ? availabilityExceptions.length : 0;
  const sortedAvailabilityExceptions = availabilityExceptions.sort(sortExceptionsByStartTime);
  // Save exception click handler
  const constructUpdatedValues = values => {
    const {
      extendedChildCare,
      latePickupTime,
      earlyDropOffTime,
      endTime,
      startTime,
      startDate,
      endDate,
      noOfDays,
      dayOfWeek,
      noOfClasses,
      day,
      description,
    } = values;
    // const startDateForApi = moment()
    //   .startOf('day')
    //   .toDate();
    // const endDateForApi = moment()
    //   .startOf('day')
    //   .add(22, 'hours')
    //   .toDate();

    const startDateForApi = moment(startDate)
      .startOf('day')
      .add(startTime.hour, 'hours')
      .add(startTime.minute, 'minutes')
      .toDate();

    const endDateForApi =
      categories[0] === 'camps'
        ? moment(startDate)
            .startOf('day')
            .add(noOfDays - 1, 'days')
            .add(endTime.hour, 'hours')
            .add(endTime.minute, 'minutes')
            .toDate()
        : categories[0] === 'classes'
        ? moment(endDate)
            .startOf('day')
            .add(endTime.hour, 'hours')
            .add(endTime.minutes, 'minutes')
            .toDate()
        : categories[0] === 'workshops'
        ? moment(startDate)
            .add(1, 'days')
            .add(endTime.hour, 'hours')
            .add(endTime.minute, 'minutes')
            .toDate()
        : null;
    const updateValues =
      categories[0] === 'events'
        ? {
            publicData: {
              startDate: !!startDateForApi ? startDateForApi?.toString() : null,
              noOfDays,
              day,
            },
          }
        : categories[0] === 'placesToVisit'
        ? { publicData: { description } }
        : {
            availabilityPlan,
            publicData: {
              earlyDropOffTime,
              dayOfWeek,
              endTime,
              noOfClasses,
              extendedChildCare,
              latePickupTime,
              noOfDays,
              startDate: !!startDateForApi ? startDateForApi?.toString() : null,
              endDate: !!endDateForApi ? endDateForApi?.toString() : null,
              startDateSearch: !!startDateForApi ? startDateForApi?.getTime() : null,
              searchableTill: !!startDateForApi
                ? moment(startDateForApi)
                    .endOf(day)
                    .valueOf()
                : null,
              endDateSearch: !!endDateForApi ? endDateForApi?.getTime() : null,
              reviewEndDateSearch: !!endDateForApi
                ? moment(endDateForApi)
                    .add(7, 'days')
                    .toDate()
                    ?.getTime()
                : null,
              startTime,
            },
          };
    return { updateValues, startDateForApi, endDateForApi };
  };
  const saveException = async values => {
    setLoadingForSubmit(true);
    const submitValues = constructUpdatedValues(values);
    const { updateValues, startDateForApi, endDateForApi } = submitValues;

    if (categories[0] === 'placesToVisit' || categories[0] === 'events') {
      return onSubmit(updateValues).then(() => setLoadingForSubmit(false));
    }
    return onSubmit(updateValues).then(() => setLoadingForSubmit(false));
  };
  const sveXit = values => {
    const submitValues = constructUpdatedValues(values);
    const { updateValues } = submitValues;
    savenExit(updateValues);
  };
  const {
    extendedChildCare,
    latePickupTime,
    earlyDropOffTime,
    endTime,
    startTime,
    startDate,
    endDate,
    noOfDays,
    dayOfWeek,
    noOfClasses,
    description,
    day,
  } = publicData;
  return (
    <main className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="Let’s add some dates and times "
          currentTab={currentTab}
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
          bgIcon={screenicon}
        />

        <div className={css.rightSection}>
          <EditListingAvailabilityForm
            backButton={backButton}
            loadingForSubmit={loadingForSubmit}
            publicData={publicData}
            tabProgress={tabProgress}
            listing={listing}
            sveXit={sveXit}
            backbtnLoader={backbtnLoader}
            initialValues={{
              extendedChildCare: {
                earlyDropOff: !!extendedChildCare?.earlyDropOff,
                latePickup: !!extendedChildCare?.latePickup,
              },
              noOfClasses,
              dayOfWeek: !!dayOfWeek ? dayOfWeek : { value: 'monday', label: 'Mon' },
              latePickupTime: {
                hour: !!latePickupTime?.hour ? latePickupTime?.hour : 0,
                minute: !!latePickupTime?.minute ? latePickupTime?.minute : 0,
              },
              earlyDropOffTime: {
                hour: !!earlyDropOffTime?.hour ? earlyDropOffTime?.hour : 0,
                minute: !!earlyDropOffTime?.minute ? earlyDropOffTime?.minute : 0,
              },
              endTime: {
                hour: !!endTime?.hour ? endTime?.hour : 0,
                minute: !!endTime?.minute ? endTime?.minute : 0,
              },
              startTime: {
                hour: !!startTime?.hour ? startTime?.hour : 0,
                minute: !!startTime?.minute ? startTime?.minute : 0,
              },
              startDate: !!startDate ? new Date(startDate) : null,
              endDate: !!endDate ? new Date(endDate) : null,
              noOfDays: !!noOfDays ? noOfDays : 0,
              day: day ? day : [],
              description,
            }}
            onSubmit={values => {
              saveException(values);
            }}
          />
        </div>
      </div>
    </main>
  );
};

EditListingAvailabilityPanel.defaultProps = {
  className: null,
  rootClassName: null,
  listing: null,
  availabilityExceptions: [],
};

EditListingAvailabilityPanel.propTypes = {
  className: string,
  rootClassName: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  availabilityExceptions: arrayOf(propTypes.availabilityException),
  fetchExceptionsInProgress: bool.isRequired,
  onAddAvailabilityException: func.isRequired,
  onDeleteAvailabilityException: func.isRequired,
  onSubmit: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  onNextTab: func.isRequired,
  submitButtonText: string.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingAvailabilityPanel;
