import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';

import { LISTING_STATE_DRAFT } from '../../util/types';
import { ensureListing } from '../../util/data';
import EditListingAgeForm from '../../forms/EditListingAgeForm/EditListingAgeForm';
import { ListingLink } from '..';
import EditListingImage from '../EditListingImage/EditListingImage';

import css from './EditListingNumberOfKidsPanel.module.css';
import EditListingNumberOfKidsForm from '../../forms/EditListingNumberOfKidsForm/EditListingNumberOfKidsForm';

import clisting5 from '../../assets/screen-03-ripped-img.png';
import clistingmb5 from '../../assets/screen-03-ripped-imgmb.png';
import listinglogo from '../../assets/listinglogo.png';
const AGE = 'age';

const EditListingNumberOfKidsPanel = props => {
  const {
    rootClassName,
    className,
    listing,
    disabled,
    ready,
    onSubmit,
    onChange,
    submitButtonText,
    panelUpdated,
    updateInProgress,
    errors,
    history,
    backButton,
    tabProgress,
    savenExit,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const { publicData } = currentListing.attributes;
  const isPublished = currentListing.id && currentListing.attributes.state !== LISTING_STATE_DRAFT;
  const panelTitle = isPublished ? (
    <FormattedMessage
      id="EditListingNumberOfKidsPanel.title"
      values={{
        listingTitle: (
          <ListingLink listing={listing}>
            <FormattedMessage id="EditListingNumberOfKidsPanel.listingTitle" />
          </ListingLink>
        ),
      }}
    />
  ) : (
    <FormattedMessage id="EditListingNumberOfKidsPanel.createListingTitle" />
  );
  const categories = !!publicData.categories && publicData.categories[0];
  const initialValues = {
    minNo: publicData?.noOfKids?.minNo || 0,
    maxNo: publicData?.noOfKids?.maxNo || 0,
    unltdNumber: publicData?.unltdNumber || false,
  };

  return (
    <div className={classes}>
      <div className={css.sectionMiddle}>
        <EditListingImage
          className={css.imgSection}
          imageText="What is the best number of kids for this package?"
          logoImg={listinglogo}
          desktopImg={clisting5}
          mobileImg={clistingmb5}
        />
        <div className={css.rightSection}>
          {/* <h1 className={css.title}>{panelTitle}</h1> */}
          <EditListingNumberOfKidsForm
            className={css.form}
            name={AGE}
            backButton={backButton}
            tabProgress={tabProgress}
            initialValues={initialValues}
            savenExit={savenExit}
            onSubmit={values => {
              const { minNo, maxNo, unltdNumber } = values;
              let updatedValues;
              if (
                categories === 'birthdayParty' ||
                categories === 'afterSchoolClub' ||
                categories === 'schoolTours' ||
                categories === 'inSchoolProgrammes'
              ) {
                updatedValues = {
                  publicData: {
                    noOfKids: { maxNo, minNo },
                  },
                };
                onSubmit(updatedValues);
              }
              if (categories === 'events') {
                updatedValues = {
                  publicData: {
                    noOfKids: { maxNo, minNo: 0 },
                    unltdNumber,
                  },
                };
                onSubmit(updatedValues);
              }
            }}
            history={history}
            publicData={publicData}
            onChange={onChange}
            saveActionMsg={submitButtonText}
            disabled={disabled}
            ready={ready}
            updated={panelUpdated}
            updateInProgress={updateInProgress}
            fetchErrors={errors}
          />
        </div>
      </div>
    </div>
  );
};

EditListingNumberOfKidsPanel.defaultProps = {
  rootClassName: null,
  className: null,
  listing: null,
};

const { bool, func, object, string } = PropTypes;

EditListingNumberOfKidsPanel.propTypes = {
  rootClassName: string,
  className: string,

  // We cannot use propTypes.listing since the listing might be a draft.
  listing: object,

  disabled: bool.isRequired,
  ready: bool.isRequired,
  onSubmit: func.isRequired,
  onChange: func.isRequired,
  submitButtonText: string.isRequired,
  panelUpdated: bool.isRequired,
  updateInProgress: bool.isRequired,
  errors: object.isRequired,
};

export default EditListingNumberOfKidsPanel;
