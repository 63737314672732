const workerFuncWrapper = () => {
  onmessage = e => {
    const dataObject = JSON.parse(e.data);
    const [keys, values] = [Object.keys(dataObject), Object.values(dataObject)];
    let sanitizedData = [];
    for (let d of values) {
      let updatedData = d === null || d === undefined ? '' : d.toString();
      updatedData = updatedData.replace(/(\r\n|\n|\r)/gm, '').replace(/"/gm, '""');
      updatedData = '"' + updatedData + '"';
      sanitizedData.push(updatedData);
    }
    postMessage({
      keys: keys.join(','),
      values: sanitizedData.join(','),
    });
  };
};

export default workerFuncWrapper;
