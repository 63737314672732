import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';
import { intlShape, injectIntl } from '../../util/reactIntl';
import { Form, IconClose, LocationAutocompleteInput } from '../../components';

import { findOptionsForSelectFilter } from '../../util/search';
import config from '../../config';
import { propTypes } from '../../util/types';
import { types as sdkTypes } from '../../util/sdkLoader';
import css from './TopbarSearchForm.module.css';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { BiSearch } from 'react-icons/bi';
import axios from 'axios';
import { apiBaseUrl } from '../../util/api';
import { locationBounds } from '../../util/googleMaps';
import { googleBoundsToSDKBounds } from '../../components/SearchMap/CustomSearchMapWithGoogleMaps';
import { encodeLatLngBounds } from '../../util/urlHelpers';

const identity = v => v;
// const allSubCategories = findOptionsForSelectFilter('subCategories', filterConfig);
const { LatLng } = sdkTypes;
class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    // this.state = { categories: '', subCategories: '', county: '' };
    this.state = {
      categories: [],
      subCategories: [],
      county: [],
      countyCheckbox: false,
      activityCheckbox: false,
      categoryCheckbox: false,
      lastActiveTab: '',
      listingCount: {},
    };
    this.options = null;
  }
  onChange(location) {
    if (location.selectedPlace) {
      this.props.onSubmit({ location });
      // blur search input to hide software keyboard
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }

  onCountyChange = e => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (isChecked) {
      this.setState(({ county }) => {
        return { county: [...county, value] };
      });
    } else {
      this.setState(({ county }) => {
        return { county: county.filter(county => county !== value) };
      });
    }
  };
  onActivityChange = e => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (isChecked) {
      this.setState(({ categories }) => {
        return { categories: [...categories, value] };
      });
    } else {
      this.setState(({ categories }) => {
        return { categories: categories.filter(categories => categories !== value) };
      });
    }
  };

  onFocus = event => {
    if (!!this.state.lastActiveTab) {
      this.setState(({ lastActiveTab }) => ({ [lastActiveTab]: true, lastActiveTab: '' }));
    }
  };

  onCategoryChange = e => {
    const isChecked = e.target.checked;
    const value = e.target.value;
    if (isChecked) {
      this.setState(({ subCategories }) => {
        return { subCategories: [...subCategories, value] };
      });
    } else {
      this.setState(({ subCategories }) => {
        return { subCategories: subCategories.filter(subCategories => subCategories !== value) };
      });
    }
  };
  searchListingCount = async () => {
    const url = `${apiBaseUrl()}/api/count/get`;
    const resp = await axios.get(url);
    if (resp.status === 200 && resp.data?._id) {
      this.setState({ listingCount: resp.data });
    }
  };
  handleDivBlur = e => {
    if (!e.relatedTarget) {
      this.setState({
        countyCheckbox: false,
        activityCheckbox: false,
        categoryCheckbox: false,
      });
    }
  };

  componentDidMount() {
    this.searchListingCount();
  }

  render() {
    const { history, listings } = this.props;
    const countyOption = findOptionsForSelectFilter('county', this.props.filterConfig);
    const allSubCategoryOptions = findOptionsForSelectFilter('age', this.props.filterConfig);
    const categoryOptions = findOptionsForSelectFilter('categories', this.props.filterConfig);
    const queryParam = {
      pub_categories:
        this.state.categories.length > 0 ? `has_all:${String(this.state.categories)}` : null,
      pub_age:
        this.state.subCategories.length > 0 ? `has_all:${String(this.state.subCategories)}` : null,
      pub_county: this.state.county ? `${String(this.state.county)}` : null,
    };

    return (
      <FinalForm
        {...this.props}
        render={formRenderProps => {
          const { rootClassName, className, desktopInputRoot, intl, isMobile } = formRenderProps;

          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;

          // Allow form submit only when the place has changed
          const preventFormSubmit = e => e.preventDefault();

          return (
            <Form
              className={classes}
              onSubmit={preventFormSubmit}
              enforcePagePreloadFor="SearchPage"
            >
              <Field
                name="location"
                format={identity}
                render={({ input, meta }) => {
                  const { onChange, ...restInput } = input;

                  // Merge the standard onChange function with custom behaviur. A better solution would
                  // be to use the FormSpy component from Final Form and pass this.onChange to the
                  // onChange prop but that breaks due to insufficient subscription handling.
                  // See: https://github.com/final-form/react-final-form/issues/159
                  const searchOnChange = value => {
                    onChange(value);
                    this.onChange(value);
                  };

                  const searchInput = { ...restInput, onChange: searchOnChange };
                  return (
                    <>
                      <div className={css.srch}>
                        <div className={css.browseBy}>
                          <p className={css.bestWay}>The best way to</p>{' '}
                          <p className={css.findKids}>Find kids activities in Ireland</p>
                        </div>

                        <div className={css.secondRow}>
                          <div className={css.selectSrch}>
                            <div
                              className={css.countySrch}
                              onBlur={this.handleDivBlur}
                              tabIndex="0"
                            >
                              <button
                                className={css.srchBtn}
                                onClick={() => {
                                  this.setState({
                                    countyCheckbox: this.state.countyCheckbox ? false : true,
                                    activityCheckbox: false,
                                    categoryCheckbox: false,
                                  });
                                }}
                              >
                                <BiSearch />

                                {this.state.county.length > 0
                                  ? ` Location (${this.state.county.length})`
                                  : 'Location'}
                              </button>
                              <div
                                className={classNames(css.countyOptns, {
                                  [css.countySec]: this.state.countyCheckbox,
                                })}
                              >
                                {this.state.countyCheckbox && (
                                  <div className={css.popdv}>
                                    <span>Popular Counties:</span>
                                    <span>Co. Wicklow</span>
                                  </div>
                                )}
                                {this.state.countyCheckbox && (
                                  <button className={css.cls} onClick={this.handleDivBlur}>
                                    <IconClose />
                                  </button>
                                )}
                                {this.state.countyCheckbox
                                  ? countyOption.map(m => (
                                      <div className={css.optionSec} key={m.value}>
                                        <input
                                          id={`county ${m.value}`}
                                          type="checkbox"
                                          name="county"
                                          value={m.value}
                                          defaultChecked={this.state.county.includes(m.value)}
                                          onChange={this.onCountyChange}
                                          disabled={!this.state.countyCheckbox}
                                        />
                                        <label htmlFor={`county ${m.value}`}>
                                          {m.label}
                                          <span className={css.listingCount}>
                                            ({this.state.listingCount[m.value] ?? 0})
                                          </span>
                                        </label>
                                      </div>
                                    ))
                                  : null}{' '}
                              </div>
                            </div>
                            {/* <div
                              className={css.activitySrch}
                              onBlur={this.handleDivBlur}
                              tabIndex="1"
                            >
                              <button
                                className={css.srchBtn}
                                onClick={() =>
                                  this.setState({
                                    activityCheckbox: this.state.activityCheckbox ? false : true,
                                    categoryCheckbox: false,
                                    countyCheckbox: false,
                                  })
                                }
                              >
                                <BiSearch />

                                {this.state.categories.length > 0
                                  ? ` Activity (${this.state.categories.length})`
                                  : 'Activity'}
                              </button>
                              <div
                                className={classNames(css.countyOptns, {
                                  [css.activitySec]: this.state.activityCheckbox,
                                })}
                              >
                                {this.state.activityCheckbox && (
                                  <button className={css.cls} onClick={this.handleDivBlur}>
                                    <IconClose />
                                  </button>
                                )}
                                {this.state.activityCheckbox
                                  ? categoryOptions.map(m => (
                                      <div className={css.optionSec} key={m.value}>
                                        <input
                                          id={`categories  ${m.value}`}
                                          type="checkbox"
                                          name="categories"
                                          value={m.value}
                                          defaultChecked={this.state.categories.includes(m.value)}
                                          onChange={this.onActivityChange}
                                        />

                                        <label htmlFor={`categories  ${m.value}`}>
                                          {m.value === 'classes'
                                            ? 'Classes, Sessions and Lessons'
                                            : m.label}
                                          <span className={css.listingCount}>
                                            ({this.state.listingCount[m.value] ?? 0})
                                          </span>
                                        </label>
                                      </div>
                                    ))
                                  : null}{' '}
                              </div>
                            </div> */}
                            {/* <div
                              className={css.categorySrch}
                              onBlur={this.handleDivBlur}
                              tabIndex="2"
                            >
                              <button
                                className={css.srchBtn}
                                onClick={() =>
                                  this.setState({
                                    categoryCheckbox: this.state.categoryCheckbox ? false : true,
                                    countyCheckbox: false,
                                    activityCheckbox: false,
                                  })
                                }
                              >
                                <BiSearch />

                                {this.state.subCategories.length > 0
                                  ? ` Age (${this.state.subCategories.length})`
                                  : 'Age'}
                              </button>
                              <div
                                className={classNames(`${css.countyOptns} ${css.ageoptns}`, {
                                  [`${css.categorySec} ${css.cateexp}`]: this.state
                                    .categoryCheckbox,
                                })}
                              >
                                {this.state.categoryCheckbox && (
                                  <button className={css.cls} onClick={this.handleDivBlur}>
                                    <IconClose />
                                  </button>
                                )}
                                {this.state.categoryCheckbox
                                  ? allSubCategoryOptions.map(m => (
                                      <div className={css.optionSec} key={m.value}>
                                        <input
                                          id={`subCategories  ${m.value}`}
                                          type="checkbox"
                                          name="subCategories"
                                          value={m.value}
                                          defaultChecked={this.state.subCategories.includes(
                                            m.value
                                          )}
                                          onChange={this.onCategoryChange}
                                        />
                                        <label htmlFor={`subCategories  ${m.value}`}>
                                          {m.label}
                                          <span className={css.listingCount}>
                                            ({this.state.listingCount[m.value] ?? 0})
                                          </span>
                                        </label>
                                      </div>
                                    ))
                                  : null}{' '}
                              </div>
                            </div> */}
                          </div>

                          <div className={css.buttonDiv}>
                            <button
                              className={css.srchButton}
                              onClick={() => {
                                Object.keys(queryParam).forEach(key => {
                                  if (queryParam[key] === '' || queryParam[key]?.length === 0) {
                                    delete queryParam[key];
                                    open;
                                  }
                                });
                                if (this.state.county?.length === 1) {
                                  const cords = countyOption?.find(
                                    f => f.value === this.state.county?.[0]
                                  ).latlng;
                                  const org = new LatLng(cords.lat, cords.lng);
                                  const bounds = locationBounds(org, 30000);
                                  queryParam.bounds = encodeLatLngBounds(bounds);
                                } else {
                                  queryParam.bounds =
                                    '55.02045567,-6.01731693,51.58704624,-9.82239281';
                                }
                                // queryParam.mapSearch = true;
                                history.push(
                                  createResourceLocatorString(
                                    'SearchPage',
                                    routeConfiguration(),
                                    {},
                                    queryParam
                                  )
                                );
                              }}
                            >
                              {' '}
                              SEARCH
                            </button>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                }}
                open
              />
            </Form>
          );
        }}
      />
    );
  }
}

const { func, string, bool } = PropTypes;

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  filterConfig: config.custom.filters,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  filterConfig: propTypes.filterConfig,

  // from injectIntl
  intl: intlShape.isRequired,
};

// const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);
const TopbarSearchForm = compose(
  withRouter,

  injectIntl
)(TopbarSearchFormComponent);
export default TopbarSearchForm;
