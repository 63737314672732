import React, { useState } from 'react';
import { string, object } from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { NamedLink } from '../../components';
import { BiSearch, BiArrowBack } from 'react-icons/bi';
import FilterComponent from '../../containers/SearchPage/FilterComponent';
import config from '../../config';
import { propTypes } from '../../util/types';
import {
  pickSearchParamsOnly,
  validURLParamsForExtendedData,
  validFilterParams,
  createSearchResultSchema,
} from '../../containers/SearchPage/SearchPage.helpers';
import css from './SectionHero.module.css';
import { TopbarSearchForm } from '../../forms';
import { parse, stringify } from '../../util/urlHelpers';
import { findOptionsForSelectFilter } from '../../util/search';
import Select from 'react-select';

import Wave from '../Wave/Wave';
import { dummyModalOpener } from '../Footer/Footer';
import { useSelector } from 'react-redux';
import SwitchUserModal from '../SwitchUserModal/SwitchUserModal';

const FILTER_DROPDOWN_OFFSET = -14;
const SectionHero = props => {
  const { rootClassName, className, location } = props;
  const [modalOpen, setModalOpen] = useState(false);
  const currentUser = useSelector(state => state.user?.currentUser);
  const isProvider = !!currentUser?.attributes?.profile?.publicData?.isProvider;
  const openSwitchAccount = currentUser?.id?.uuid && !isProvider;

  const classes = classNames(rootClassName || css.root, className);
  const onSearchSubmit = values => {
    const { currentSearchParams } = props;
    const { search, selectedPlace } = values.location;
    const { history } = props;
    const { origin, bounds } = selectedPlace;
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      address: search,
      bounds,
    };
    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, searchParams));
  };
  return (
    <div
      className={classNames(classes, {
        [css.rootlmb]: location.pathname === '/listmybusiness',
        [css.rootAbout]: location.pathname === '/about',
        [css.rootTrust]: location.pathname === '/trust-and-safety',
        [css.rootCont]: location.pathname === '/contact-us',
      })}
    >
      {location.pathname === '/listmybusiness' ? (
        <>
          <div className={css.lmbContent}>
            <h1 className={css.lmbMainTitle}>Turbo charge your kids’ activities.</h1>

            <h2 className={css.lmbSubTitle}>
              The only all-in-one tool connecting your activity marketing, ticketing, and admin.
              Finally freeing you to enjoy your business more and stress less.
              <br />
              <br />
              Parents and teachers are booking activities make sure they know about yours.
            </h2>
            <div className={css.getStartedContainer}>
              {openSwitchAccount ? (
                <span className={css.getStartedLink} onClick={() => setModalOpen(true)}>
                  Get listings for FREE
                </span>
              ) : (
                <NamedLink name="CreateListingPage" className={css.getStartedLink}>
                  Get listings for FREE
                </NamedLink>
              )}
            </div>
          </div>
        </>
      ) : location.pathname === '/about' ? (
        <div className={css.aboutHero}>
          <span>The Activity Time Story</span>
        </div>
      ) : location.pathname === '/trust-and-safety' ? (
        <div className={`${css.trustHero} ${css.aboutHero}`}>
          <div className={css.opap}>
            <p>TRUST & SAFETY</p>
            <p>A safe environment and plenty of fun for everyone</p>
          </div>
        </div>
      ) : location.pathname === '/contact-us' ? (
        <div className={`${css.trustHero} ${css.aboutHero}`}>
          <div className={css.cpap}>
            <p>Need to contact us?</p>
            <p>We'd love to hear from you</p>
          </div>
        </div>
      ) : (
        <>
          <div className={css.heroContent}>
            {/* <h1 className={css.heroMainTitle}>
              <FormattedMessage id="SectionHero.title" />
            </h1>
            <h2 className={css.heroSubTitle}>
              <FormattedMessage id="SectionHero.subTitle" />
            </h2> */}
            <div className={css.searchBoxContainer}>
              <TopbarSearchForm
                className={css.searchLink}
                desktopInputRoot={css.topbarSearchWithLeftPadding}
                onSubmit={onSearchSubmit}
              />
            </div>
          </div>
          {/* <Wave /> */}
        </>
      )}
      {/* <div className={css.heroContent}>
        <h1 className={css.heroMainTitle}>
          {location.pathname === '/listmybusiness' ? (
            <FormattedMessage id="SectionHero.listMyBusinessTitle" />
          ) : (
            <FormattedMessage id="SectionHero.title" />
          )}
        </h1>
        <h2 className={css.heroSubTitle}>
          {location.pathname === '/listmybusiness' ? (
            <FormattedMessage id="SectionHero.listMyBusinessSubTitle" />
          ) : (
            <FormattedMessage id="SectionHero.subTitle" />
          )}
        </h2>
        <div className={css.searchBoxContainer}>
          {location.pathname === '/listmybusiness' ? (
            <NamedLink name="LandingPage" className={css.getStartedLink}>
              <FormattedMessage id="SectionHero.getStartedLink" />
            </NamedLink>
          ) : (
            <TopbarSearchForm
              className={css.searchLink}
              desktopInputRoot={css.topbarSearchWithLeftPadding}
              onSubmit={onSearchSubmit}
            />
          )}
        </div>
      </div> */}
      {location.pathname === '/trust-and-safety' ? <Wave colName="#fff" /> : <Wave />}
      <SwitchUserModal modalOpen={modalOpen} isProvider={isProvider} setModalOpen={setModalOpen} />
    </div>
  );
};

SectionHero.defaultProps = {
  rootClassName: null,
  className: null,
};

SectionHero.propTypes = {
  rootClassName: string,
  className: string,
  filterConfig: propTypes.filterConfig,
};

export default SectionHero;
