import React from 'react';
import loadable from '@loadable/component';
import getPageDataLoadingAPI from './containers/pageDataLoadingAPI';
import { NotFoundPage } from './containers';

// routeConfiguration needs to initialize containers first
// Otherwise, components will import form container eventually and
// at that point css bundling / imports will happen in wrong order.
import { NamedRedirect } from './components';

const pageDataLoadingAPI = getPageDataLoadingAPI();

const AboutPage = loadable(() =>
  import(/* webpackChunkName: "AboutPage" */ './containers/AboutPage/AboutPage')
);
const ActivityTimeTerms = loadable(() =>
  import(/* webpackChunkName: "ActivityTimeTerms" */ './containers/ActivityTimeTerms/ActivityTimeTerms')
);
const PrivacyPolicy = loadable(() =>
  import(/* webpackChunkName: "PrivacyPolicy" */ './containers/PrivacyPolicy/PrivacyPolicy')
);
const PublishSuccessPage = loadable(() => 
  import(/* webpackChunkName: "PublishSuccessPage" */ './containers/PublishSuccessPage/PublishSuccessPage')
);
const FirstListingPublishSuccessPage = loadable(() => 
  import(/* webpackChunkName: "FirstListingPublishSuccessPage" */ './containers/FirstListingPublishSuccessPage/FirstListingPublishSuccessPage')
);
const FavouritesPage = loadable(() =>
  import(/* webpackChunkName: "FavouritesPage" */ './containers/FavouritesPage/FavouritesPage')
);
const ReviewsPage = loadable(() =>
  import(/* webpackChunkName: "ReviewsPage" */ './containers/ReviewsPage/ReviewsPage')
);
const SubscriptionSuccessPage = loadable(() =>
  import(
    /* webpackChunkName: "SubscriptionSuccessPage" */ './components/SubscriptionProducts/SubscriptionSuccess'
  )
);
const PaymentSuccess = loadable(() =>
  import(
    /* webpackChunkName: "PaymentSuccess" */ './components/PaymentSuccess/PaymentSuccess'
  )
);
const AuthenticationPage = loadable(() =>
  import(
    /* webpackChunkName: "AuthenticationPage" */ './containers/AuthenticationPage/AuthenticationPage'
  )
);
const CheckoutPage = loadable(() =>
  import(/* webpackChunkName: "CheckoutPage" */ './containers/CheckoutPage/CheckoutPage')
);
const ContactDetailsPage = loadable(() =>
  import(
    /* webpackChunkName: "ContactDetailsPage" */ './containers/ContactDetailsPage/ContactDetailsPage'
  )
);
const EditListingPage = loadable(() =>
  import(/* webpackChunkName: "EditListingPage" */ './containers/EditListingPage/EditListingPage')
);
const EmailVerificationPage = loadable(() =>
  import(
    /* webpackChunkName: "EmailVerificationPage" */ './containers/EmailVerificationPage/EmailVerificationPage'
  )
);
const InboxPage = loadable(() =>
  import(/* webpackChunkName: "InboxPage" */ './containers/InboxPage/InboxPage')
);
const LandingPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ './containers/LandingPage/LandingPage')
);
const ListMyBusinessPage = loadable(() =>
  import(/* webpackChunkName: "LandingPage" */ './containers/ListMyBusinessPage/ListMyBusinessPage')
);
const TrustAndSafetyPage = loadable(() =>
  import(/* webpackChunkName: "TrustAndSafetyPage" */ './containers/TrustAndSafetyPage/TrustAndSafetyPage')
);
const ContactUsPage = loadable(() =>
  import(/* webpackChunkName: "ContactUsPage" */ './containers/ContactUsPage/ContactUsPage')
);
const CreateListingPage = loadable(() =>
  import(
    /* webpackChunkName: "CreateListingPage" */ './containers/CreateListingPage/CreateListingPage'
  )
);
const TermsAndConditionsPage = loadable(() =>
  import(
    /* webpackChunkName: "TermsAndConditionsPage" */ './containers/TermsAndConditionsPage/TermsAndConditionsPage'
  )
);
const ProfileSettingsBasePage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsBasePage" */ './containers/ProfileSettingsBasePage/ProfileSettingsBasePage'
  )
);
const ProviderInfo = loadable(() => import(/* webpackChunkName: "ProviderInfo" */ './components/ProviderInfo/ProviderInfo'));
const ProviderLocation = loadable(() => import(/* webpackChunkName: "ProviderLocation" */ './components/ProviderLocation/ProviderLocation'));
const ProviderLogo = loadable(() => import(/* webpackChunkName: "ProviderLogo" */ './components/ProviderLogo/ProviderLogo'));
const ProviderAvailability = loadable(() => import(/* webpackChunkName: "ProviderAvailability" */ './components/ProviderAvailability/ProviderAvailability'));
const ProviderPhotos = loadable(() => import(/* webpackChunkName: "ProviderPhotos" */ './components/ProviderPhotos/ProviderPhotos'));
const ProviderCategories = loadable(() => import(/* webpackChunkName: "ProviderCategories" */ './components/ProviderCategories/ProviderCategories'));
const ProviderServices = loadable(() => import(/* webpackChunkName: "ProviderServices" */ './components/ProviderServices/ProviderServices'));
const ProviderPreview = loadable(() => import(/* webpackChunkName: "ProviderPreview" */ './components/ProviderPreview/ProviderPreview'));
const ListingPage = loadable(() =>
  import(
    /* webpackChunkName: "ListingPage" */ /* webpackPrefetch: true */ './containers/ListingPage/ListingPage'
  )
);
const PasswordChangePage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordChangePage" */ './containers/PasswordChangePage/PasswordChangePage'
  )
);
 const SubscriptionDetailsPage = loadable(() =>
import(
  /* webpackChunkName: "SubscriptionDetailsPage" */ './containers/SubscriptionDetailsPage/SubscriptionDetailsPage'
)
);
const PasswordRecoveryPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordRecoveryPage" */ './containers/PasswordRecoveryPage/PasswordRecoveryPage'
  )
);
const PasswordResetPage = loadable(() =>
  import(
    /* webpackChunkName: "PasswordResetPage" */ './containers/PasswordResetPage/PasswordResetPage'
  )
);
const PaymentMethodsPage = loadable(() =>
  import(
    /* webpackChunkName: "PaymentMethodsPage" */ './containers/PaymentMethodsPage/PaymentMethodsPage'
  )
);
const PrivacyPolicyPage = loadable(() =>
  import(
    /* webpackChunkName: "PrivacyPolicyPage" */ './containers/PrivacyPolicyPage/PrivacyPolicyPage'
  )
);
const ProfilePage = loadable(() =>
  import(/* webpackChunkName: "ProfilePage" */ './containers/ProfilePage/ProfilePage')
);
const ProfileSettingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ProfileSettingsPage" */ './containers/ProfileSettingsPage/ProfileSettingsPage'
  )
);
const SearchPage = loadable(() =>
  import(
    /* webpackChunkName: "SearchPage" */ /* webpackPrefetch: true */ './containers/SearchPage/SearchPage'
  )
);
const StripePayoutPage = loadable(() =>
  import(
    /* webpackChunkName: "StripePayoutPage" */ './containers/StripePayoutPage/StripePayoutPage'
  )
);
const TermsOfServicePage = loadable(() =>
  import(
    /* webpackChunkName: "TermsOfServicePage" */ './containers/TermsOfServicePage/TermsOfServicePage'
  )
);
const TransactionPage = loadable(() =>
  import(/* webpackChunkName: "TransactionPage" */ './containers/TransactionPage/TransactionPage')
);
const ManageListingsPage = loadable(() =>
  import(
    /* webpackChunkName: "ManageListingsPage" */ './containers/ManageListingsPage/ManageListingsPage'
  )
);

// Styleguide helps you to review current components and develop new ones
const StyleguidePage = loadable(() =>
  import(/* webpackChunkName: "StyleguidePage" */ './containers/StyleguidePage/StyleguidePage')
);
const SubscriptionProducts = loadable(() =>
  import(
    /*webpackChunkName: "SubscriptionProducts" */ './components/SubscriptionProducts/SubscriptionProducts'
  )
);

export const ACCOUNT_SETTINGS_PAGES = [
  'ContactDetailsPage',
  'PasswordChangePage',
  'StripePayoutPage',
  'PaymentMethodsPage',
  'SubscriptionDetailsPage'
];
export const PROFILE_SETTING_PAGES = [
  'ProviderInfo',
  'ProviderLocation',
  'ProviderLogo',
  'ProviderAvailability',
  'ProviderPhotos',
  'ProviderCategories',
  'ProviderServices',
  'ProviderPreview',
];

// https://en.wikipedia.org/wiki/Universally_unique_identifier#Nil_UUID
const draftId = '00000000-0000-0000-0000-000000000000';
const draftSlug = 'draft';

const RedirectToLandingPage = () => <NamedRedirect name="LandingPage" />;

// NOTE: Most server-side endpoints are prefixed with /api. Requests to those
// endpoints are indended to be handled in the server instead of the browser and
// they will not render the application. So remember to avoid routes starting
// with /api and if you encounter clashing routes see server/index.js if there's
// a conflicting route defined there.

// Our routes are exact by default.
// See behaviour from Routes.js where Route is created.
const routeConfiguration = () => {
  return [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
    },
    {
      path: '/listmybusiness',
      name: 'ListMyBusinessPage',
      component: ListMyBusinessPage,
    },
    {
      path: '/trust-and-safety',
      name: 'TrustAndSafetyPage',
      component: TrustAndSafetyPage,
    },
    {
      path: '/contact-us',
      name: 'ContactUsPage',
      component: ContactUsPage,
    },
    {
      path: '/createlistingpage',
      name: 'CreateListingPage',
      auth: true,
      authPage: 'SignupProviderPage',
      component: CreateListingPage,
    },
    {
      path: '/profileSettingsBasePage',
      name: 'ProfileSettingsBasePage',
      auth: true,
      authPage: 'SignupProviderPage',
      component: ProfileSettingsBasePage,
    },
    {
      path: '/terms-and-conditions',
      name: 'TermsAndConditionsPage',
      auth: true,
      authPage: 'SignupProviderPage',
      component: TermsAndConditionsPage,
    },
    {
      path: '/profile-settings/provider-info',
      name: 'ProviderInfo',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderInfo,
    },
    {
      path: '/profile-settings/location',
      name: 'ProviderLocation',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderLocation,
    },
    {
      path: '/profile-settings/logo',
      name: 'ProviderLogo',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderLogo,
    },
    {
      path: '/profile-settings/availability',
      name: 'ProviderAvailability',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderAvailability,
    },
    {
      path: '/profile-settings/photos',
      name: 'ProviderPhotos',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderPhotos,
    },
    {
      path: '/profile-settings/categories',
      name: 'ProviderCategories',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderCategories,
    },
    {
      path: '/profile-settings/services',
      name: 'ProviderServices',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderServices,
    },
    {
      path: '/profile-settings/preview',
      name: 'ProviderPreview',
      auth: true,
      authPage: 'LoginPage',
      component: ProviderPreview,
    },
    {
      path: '/about',
      name: 'AboutPage',
      component: AboutPage,
    },
    {
      path: '/activity-time-terms-and-conditions',
      name: 'ActivityTimeTerms',
      component: ActivityTimeTerms,
    },
    {
      path: '/privacy-policy',
      name: 'PrivacyPolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/favourite',
      name: 'FavouritesPage',
      auth: true,
      authPage: 'LoginPage',
      component: FavouritesPage,
      loadData: pageDataLoadingAPI.FavouritesPage.loadData,
    },
    {
      path: '/reviews',
      name: 'ReviewsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ReviewsPage,
      loadData: pageDataLoadingAPI.ReviewsPage.loadData,
    },
    {
      path: '/publish-success/:slug/:id',
      name: 'PublishSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: PublishSuccessPage,
    },
    {
      path: '/first-listing-publish-success/:slug/:id',
      name: 'FirstListingPublishSuccessPage',
      auth: true,
      authPage: 'LoginPage',
      component: FirstListingPublishSuccessPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/subscriptionSuccess',
      name: 'SubscriptionPage',
      component: SubscriptionSuccessPage,
      auth: true,
    },
    {
      path: '/paymentSuccess',
      name: 'PaymentSuccess',
      component: PaymentSuccess,
      auth: true,
    },
    {
      path: '/s',
      name: 'SearchPage',
      component: SearchPage,
      loadData: pageDataLoadingAPI.SearchPage.loadData,
    },
    {
      path: '/listings',
      name: 'ManageListingsBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ManageListingsPage" params={{ tab: 'activeListings' }} />,
    },
    {
      path: '/listings/:tab',
      name: 'ManageListingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ManageListingsPage,
      loadData: pageDataLoadingAPI.ManageListingsPage.loadData,
      // extraProps: { tab: 'login' },
    },
    {
      path: '/l',
      name: 'ListingBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/subscription-products',
      name: 'SubscriptionProducts',
      component: SubscriptionProducts,
    },
    {
      path: '/l/:slug/:id',
      name: 'ListingPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/checkout',
      name: 'CheckoutPage',
      auth: true,
      component: CheckoutPage,
      setInitialValues: pageDataLoadingAPI.CheckoutPage.setInitialValues,
    },
    {
      path: '/l/:slug/:id/:variant',
      name: 'ListingPageVariant',
      auth: true,
      authPage: 'LoginPage',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/l/new',
      name: 'NewListingPage',
      auth: true,
      authPage: 'SignupProviderPage',
      component: () => (
        <NamedRedirect
          name="EditListingPage"
          params={{ slug: draftSlug, id: draftId, type: 'new', tab: 'categories' }}
        />
      ),
    },
    {
      path: '/l/:slug/:id/:type/:tab',
      name: 'EditListingPage',
      auth: true,
      component: EditListingPage,
      // extraProps: { allowOnlyOneListing: true },
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },
    {
      path: '/l/:slug/:id/:type/:tab/:returnURLType',
      name: 'EditListingStripeOnboardingPage',
      auth: true,
      component: EditListingPage,
      loadData: pageDataLoadingAPI.EditListingPage.loadData,
    },

    // Canonical path should be after the `/l/new` path since they
    // conflict and `new` is not a valid listing UUID.
    {
      path: '/l/:id',
      name: 'ListingPageCanonical',
      component: ListingPage,
      loadData: pageDataLoadingAPI.ListingPage.loadData,
    },
    {
      path: '/u',
      name: 'ProfileBasePage',
      component: RedirectToLandingPage,
    },
    {
      path: '/u/:id',
      name: 'ProfilePage',
      component: ProfilePage,
      loadData: pageDataLoadingAPI.ProfilePage.loadData,
    },
    {
      path: '/profile-settings',
      name: 'ProfileSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ProfileSettingsPage,
      // component: () => <NamedRedirect name="ProviderInfo" />,
    },

    // Note: authenticating with IdP (e.g. Facebook) expects that /login path exists
    // so that in the error case users can be redirected back to the LoginPage
    // In case you change this, remember to update the route in server/api/auth/loginWithIdp.js
    {
      path: '/login',
      name: 'LoginPage',
      component: AuthenticationPage,
      extraProps: { tab: 'login' },
    },
    {
      path: '/signup',
      name: 'SignupPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup' },
    },
    {
      path: '/signup-provider',
      name: 'SignupProviderPage',
      component: AuthenticationPage,
      extraProps: { tab: 'signup-provider' },
    },
    {
      path: '/confirm',
      name: 'ConfirmPage',
      component: AuthenticationPage,
      extraProps: { tab: 'confirm' },
    },
    {
      path: '/recover-password',
      name: 'PasswordRecoveryPage',
      component: PasswordRecoveryPage,
    },
    {
      path: '/inbox',
      name: 'InboxBasePage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="InboxPage" params={{ tab: 'sales' }} />,
    },
    {
      path: '/inbox/:tab',
      name: 'InboxPage',
      auth: true,
      authPage: 'LoginPage',
      component: InboxPage,
      loadData: pageDataLoadingAPI.InboxPage.loadData,
    },
    {
      path: '/order/:id',
      name: 'OrderPage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="OrderDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/order/:id/details',
      name: 'OrderDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'customer' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'customer' }),
      setInitialValues: pageDataLoadingAPI.TransactionPage.setInitialValues,
    },
    {
      path: '/sale/:id',
      name: 'SalePage',
      auth: true,
      authPage: 'LoginPage',
      component: props => <NamedRedirect name="SaleDetailsPage" params={{ ...props.params }} />,
    },
    {
      path: '/sale/:id/details',
      name: 'SaleDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: TransactionPage,
      extraProps: { transactionRole: 'provider' },
      loadData: params =>
        pageDataLoadingAPI.TransactionPage.loadData({ ...params, transactionRole: 'provider' }),
    },
    {
      path: '/account',
      name: 'AccountSettingsPage',
      auth: true,
      authPage: 'LoginPage',
      component: () => <NamedRedirect name="ContactDetailsPage" />,
    },
    {
      path: '/account/contact-details',
      name: 'ContactDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: ContactDetailsPage,
      loadData: pageDataLoadingAPI.ContactDetailsPage.loadData,
    },
    {
      path: '/account/change-password',
      name: 'PasswordChangePage',
      auth: true,
      authPage: 'LoginPage',
      component: PasswordChangePage,
    },
    {
      path: '/account/subscription-detail/:plan',
      name: 'SubscriptionDetailsPage',
      auth: true,
      authPage: 'LoginPage',
      component: SubscriptionDetailsPage,
    },
    {
      path: '/account/payments',
      name: 'StripePayoutPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payments/:returnURLType',
      name: 'StripePayoutOnboardingPage',
      auth: true,
      authPage: 'LoginPage',
      component: StripePayoutPage,
      loadData: pageDataLoadingAPI.StripePayoutPage.loadData,
    },
    {
      path: '/account/payment-methods',
      name: 'PaymentMethodsPage',
      auth: true,
      authPage: 'LoginPage',
      component: PaymentMethodsPage,
      loadData: pageDataLoadingAPI.PaymentMethodsPage.loadData,
    },
    {
      path: '/terms-of-service',
      name: 'TermsOfServicePage',
      component: TermsOfServicePage,
    },
    // {
    //   path: '/privacy-policy',
    //   name: 'PrivacyPolicyPage',
    //   component: PrivacyPolicyPage,
    // },
    {
      path: '/styleguide',
      name: 'Styleguide',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/g/:group',
      name: 'StyleguideGroup',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component',
      name: 'StyleguideComponent',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example',
      name: 'StyleguideComponentExample',
      component: StyleguidePage,
    },
    {
      path: '/styleguide/c/:component/:example/raw',
      name: 'StyleguideComponentExampleRaw',
      component: StyleguidePage,
      extraProps: { raw: true },
    },
    {
      path: '/notfound',
      name: 'NotFoundPage',
      component: props => <NotFoundPage {...props} />,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /reset-password endpoint
    {
      path: '/reset-password',
      name: 'PasswordResetPage',
      component: PasswordResetPage,
    },

    // Do not change this path!
    //
    // The API expects that the application implements /verify-email endpoint
    {
      path: '/verify-email',
      name: 'EmailVerificationPage',
      auth: true,
      authPage: 'LoginPage',
      component: EmailVerificationPage,
      loadData: pageDataLoadingAPI.EmailVerificationPage.loadData,
    },
  ];
};

export default routeConfiguration;
