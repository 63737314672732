import React, { Component, useEffect, useRef, useState } from 'react';
import { array, string, func } from 'prop-types';
import { FormattedMessage, intlShape, injectIntl } from '../../util/reactIntl';
import classNames from 'classnames';
import { lazyLoadWithDimensions } from '../../util/contextHelpers';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT, propTypes } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { ensureListing } from '../../util/data';
import { richText } from '../../util/richText';
import { findOptionsForSelectFilter } from '../../util/search';
import { createSlug } from '../../util/urlHelpers';
import config from '../../config';
import { NamedLink, ResponsiveImage } from '../../components';
import moment from 'moment';
import { AiOutlineHeart, AiFillStar, AiFillHeart, AiOutlineStar } from 'react-icons/ai';
import { FaStar } from 'react-icons/fa';
import css from './ListingCard.module.css';
import { createInstance } from '../../util/sdkLoader';
import { set } from 'lodash';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { placesToVisitActivityTypeOptions } from '../../assets/activityTypeOptions';
import StarRating from '../StarRating/StarRating';
const MIN_LENGTH_FOR_LONG_WORDS = 10;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: intl.formatMessage(
        { id: 'ListingCard.unsupportedPrice' },
        { currency: price.currency }
      ),
      priceTitle: intl.formatMessage(
        { id: 'ListingCard.unsupportedPriceTitle' },
        { currency: price.currency }
      ),
    };
  }
  return {};
};

const getCertificateInfo = (certificateOptions, key) => {
  return certificateOptions.find(c => c.key === key);
};

class ListingImage extends Component {
  render() {
    return <ResponsiveImage {...this.props} />;
  }
}
const LazyImage = lazyLoadWithDimensions(ListingImage, { loadAfterInitialRendering: 3000 });
export const ListingCardComponent = props => {
  const {
    className,
    rootClassName,
    intl,
    listing,
    isMobileModalOpen,
    filtersConfig,
    setActiveListing,
    history,
    currentUser,
    toggleFavouritesButton,
    isFavourite,
    key,
    urlQueryParams,
    location,
  } = props;
  const [isFavouriteLoading, setIsFavouriteLoading] = useState(false);

  const classes = classNames(rootClassName || css.root, className);
  const currentListing = ensureListing(listing);
  const isProvider = currentUser?.attributes?.profile?.publicData?.isProvider;
  const id = currentListing.id.uuid;
  const { title = '', price, publicData } = currentListing.attributes;
  const seats = currentListing?.currentStock?.attributes?.quantity;
  const ProviderPublicdata = listing?.author?.attributes?.profile?.publicData;
  const slug = createSlug(title);
  const firstImage =
    currentListing.images && currentListing.images.length > 0 ? currentListing.images[0] : null;

  const certificateOptions = findOptionsForSelectFilter('certificate', filtersConfig);
  const certificate = publicData
    ? getCertificateInfo(certificateOptions, publicData.certificate)
    : null;
  const { formattedPrice, priceTitle } = priceData(price, intl);

  const unitType = config.bookingUnitType;
  const activityTypesObj = publicData?.activityType?.map(a =>
    placesToVisitActivityTypeOptions.find(o => o.value === a)
  );
  const priceTypeTexts =
    publicData?.priceType === 'perHour'
      ? 'per hour'
      : publicData?.priceType === 'perChild'
      ? 'per child'
      : publicData?.priceType === 'totalPrice'
      ? 'total price'
      : publicData?.priceType === 'perMorning'
      ? 'per morning'
      : publicData?.priceType === 'perAfternoon'
      ? 'per afternoon'
      : publicData?.priceType === 'perDay'
      ? 'per day'
      : publicData?.priceType === 'perTerm'
      ? 'per term'
      : publicData?.priceType === 'perClass'
      ? 'per class'
      : publicData?.priceType === 'perYear'
      ? 'per year'
      : publicData?.priceType === 'perMonth'
      ? 'per month'
      : publicData?.priceType === 'perAdult'
      ? 'per adult'
      : null;
  const dateMaybe = (
    <div className={css.datetimeinfocol}>
      <span>{'Dates'}:</span>{' '}
      {publicData?.categories[0] === 'events' || publicData?.categories[0] === 'workshops'
        ? moment(publicData?.startDate).format('MMM Do, YYYY')
        : `${moment(publicData?.startDate).format('MMM Do')} – ${moment(publicData?.endDate).format(
            'MMM Do, YYYY'
          )}`}{' '}
      {publicData?.categories === 'workshops' && '1 day'}
      {publicData?.categories[0] === 'events' &&
        publicData?.day &&
        `(${publicData?.day?.length} ${publicData?.day?.length > 1 ? 'days' : 'day'})`}
    </div>
  );
  const timeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Time: </span>{' '}
      {publicData?.startTime?.hour >= 10
        ? publicData?.startTime?.hour
        : `0${publicData?.startTime?.hour}`}
      .
      {publicData?.startTime?.minute >= 10
        ? publicData?.startTime?.minute
        : `0${publicData?.startTime?.minute}`}{' '}
      –{' '}
      {publicData?.endTime?.hour >= 10
        ? publicData?.endTime?.hour
        : `0${publicData?.endTime?.hour}`}
      .
      {publicData?.endTime?.minute >= 10
        ? publicData?.endTime?.minute
        : `0${publicData?.endTime?.minute}`}
    </div>
  );
  const activityTypeMaybe = publicData?.activityType && (
    <div className={`${css.atrow} ${css.datetimeinfocol}`}>
      <span>Categories: </span>{' '}
      {activityTypesObj &&
        publicData?.categories[0] === 'placesToVisit' &&
        activityTypesObj.reduce(
          (pre, curr, idx) =>
            `${pre}${
              idx === 0
                ? ` ${curr.label}`
                : idx === activityTypesObj?.length - 1
                ? ` & ${curr.label}`
                : `, ${curr.label}`
            }`,
          ''
        )}
    </div>
  );
  const ageMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Age: </span>{' '}
      {(publicData?.categories[0] === 'events' || publicData?.categories[0] === 'placesToVisit') &&
      publicData?.allAges
        ? 'All Ages'
        : `${publicData?.age[0]} - ${publicData?.age[publicData?.age?.length - 1]}`}
    </div>
  );
  const closingTimeMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Closes: </span>
      {`${
        publicData?.closingTime?.hour < 10
          ? `0${publicData?.closingTime?.hour || 0}`
          : publicData?.closingTime?.hour || '00'
      }:${
        publicData?.closingTime?.minute < 10
          ? `0${publicData?.closingTime?.minute || 0}`
          : publicData?.closingTime?.minute || '00'
      }`}
    </div>
  );
  const openTillMaybe = (
    <div className={css.datetimeinfocol}>
      <span>
        {publicData?.categories[0] === 'inSchoolProgrammes'
          ? 'Time: '
          : publicData?.categories[0] === 'birthdayParty' ||
            publicData?.categories[0] === 'schoolTours'
          ? 'Length: '
          : publicData?.categories[0] === 'breakfastClub' ||
            publicData?.categories[0] === 'afterSchoolClub'
          ? 'Opens: '
          : 'Closes: '}
      </span>
      {`${
        publicData?.openTill?.hour < 10
          ? `0${publicData?.openTill?.hour}`
          : publicData?.openTill?.hour
      }:${
        publicData?.openTill?.minute < 10
          ? `0${publicData?.openTill?.minute}`
          : publicData?.openTill?.minute
      }`}{' '}
      {publicData?.categories[0] === 'birthdayParty' ||
      publicData?.categories[0] === 'schoolTours' ||
      publicData?.categories[0] === 'inSchoolProgrammes'
        ? 'hours'
        : null}
    </div>
  );
  const noOfKidsMaybe = (
    <div className={css.datetimeinfocol}>
      <span>Min - Max: </span>
      {`${publicData?.noOfKids?.minNo} - ${publicData?.noOfKids?.maxNo}`}
    </div>
  );
  const eventTimes = (
    <div className={css.datetimeinfocol}>
      {publicData?.day?.map((d, i) => (
        <div key={i}>
          <span>Time (day {i + 1}): </span>{' '}
          {d.startTime?.hour >= 10 ? d.startTime?.hour : `0${d.startTime?.hour}`}.
          {d.startTime?.minute >= 10 ? d.startTime?.minute : `0${d.startTime?.minute}`} –{' '}
          {d.endTime?.hour >= 10 ? d.endTime?.hour : `0${d.endTime?.hour}`}.
          {d.endTime?.minute >= 10 ? d.endTime?.minute : `0${d.endTime?.minute}`}
        </div>
      ))}
    </div>
  );
  const priceDisplay = (
    <div className={css.datetimeinfocol}>
      <span>{'Cost: '}</span>
      <span className={css.pricespn}>
        {price?.amount === 0 ? 'Free' : `€${parseFloat(price?.amount / 100).toFixed(2)}`}
      </span>
      {` `}
      {publicData?.categories[0] === 'classes'
        ? `(${publicData?.noOfClasses} ${
            publicData?.classType ? publicData?.classType?.label : 'classes'
          })`
        : publicData?.categories[0] === 'camps'
        ? `(${publicData?.noOfDays} ${publicData?.noOfDays > 1 ? 'days' : 'day'})`
        : publicData?.categories[0] === 'workshops'
        ? '(1 day)'
        : null}
      {price?.amount === 0 ? '' : priceTypeTexts}
    </div>
  );
  const cityMaybe =
    publicData?.city || publicData?.county ? (
      <div className={css.datetimeinfocol}>
        <span>{'Location: '}</span>
        <span className={css.pricespn}>
          {publicData?.city ? publicData?.city.toUpperCase() : publicData?.county?.toUpperCase()}
        </span>
      </div>
    ) : null;
  return (
    <div
      key={key}
      className={classes}
      onClick={() => {
        history.push(
          createResourceLocatorString('ListingPage', routeConfiguration(), { id, slug }),
          { from: location?.search ? location.search : null }
        );
      }}
    >
      <div className={css.aspectWrapper}>
        {!isProvider ? (
          <div
            className={classNames(css.htisgn, {
              [css.dispn]: isMobileModalOpen,
            })}
          >
            {isFavouriteLoading ? (
              <AiOutlineHeart className={css.favouriteLoading} onClick={() => {}} />
            ) : isFavourite ? (
              <AiFillHeart
                className={css.favouriteTrue}
                onClick={e => {
                  e.stopPropagation();
                  if (currentUser) {
                    setIsFavouriteLoading(true);
                    !!currentUser &&
                      toggleFavouritesButton(currentUser.id.uuid, listing.id.uuid).then(() =>
                        setIsFavouriteLoading(false)
                      );
                  } else {
                    history.push(
                      createResourceLocatorString('LoginPage', routeConfiguration(), {}, {})
                    );
                  }
                }}
              />
            ) : (
              <AiOutlineHeart
                className={css.favouriteFalse}
                onClick={e => {
                  e.stopPropagation();
                  if (currentUser) {
                    setIsFavouriteLoading(true);
                    !!currentUser &&
                      toggleFavouritesButton(currentUser.id.uuid, listing.id.uuid).then(() =>
                        setIsFavouriteLoading(false)
                      );
                  } else {
                    history.push(
                      createResourceLocatorString('LoginPage', routeConfiguration(), {}, {})
                    );
                  }
                }}
              />
            )}
          </div>
        ) : null}
        <div
          className={classNames(css.spdv, {
            [css.dispn]: isMobileModalOpen,
          })}
        >
          {publicData?.categories[0] === 'afterSchoolClub' ||
          publicData?.categories[0] === 'birthdayParty' ||
          publicData?.categories[0] === 'breakfastClub' ||
          publicData?.categories[0] === 'club' ||
          publicData?.categories[0] === 'events' ||
          publicData?.categories[0] === 'inSchoolProgrammes' ||
          publicData?.categories[0] === 'placesToVisit' ||
          publicData?.categories[0] === 'schoolTours' ? (
            publicData?.bookingType === 'offPlatform' ? (
              <div className={css.spcspn}>
                <span>Off-Platform</span>
              </div>
            ) : (
              <div className={css.spcspn}>
                <span>Contact to Book</span>
              </div>
            )
          ) : null}
          {publicData?.categories[0] === 'camps' ||
          publicData?.categories[0] === 'classes' ||
          publicData?.categories[0] === 'workshops' ? (
            publicData?.bookingType === 'offPlatform' ? (
              <div className={css.spcspn}>
                <span>Off-Platform</span>
              </div>
            ) : seats ? (
              <div className={css.spcspn}>
                <span>Spaces: </span>
                {seats > 25 ? '25+' : seats}
              </div>
            ) : publicData?.waitingList ? (
              <div className={css.spcspn}>
                <span>+ Waiting List</span>
              </div>
            ) : (
              <div className={css.spcspn}>
                <span>Contact to Book</span>
              </div>
            )
          ) : null}
        </div>
        <img alt="" src={firstImage.attributes.variants['landscape-crop'].url} />
        <div className={css.ratingstar}>
          <StarRating rating={Number(ProviderPublicdata?.avgRating || 0)} />
        </div>
      </div>
      <div className={css.info}>
        {/* <span className={css.ratestar}>
          <FaStar />
          4.3
        </span> */}
        <div className={css.mainInfo}>
          <div className={css.title}>
            {richText(title, {
              longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
              longWordClass: css.longWord,
            })}
          </div>
          <div className={css.addinfo}>
            {' '}
            {ProviderPublicdata?.bname} -{' '}
            {publicData?.activityMode === 'online' ? (
              <span className={css.boldtext}>ONLINE</span>
            ) : publicData?.city ? (
              <span className={css.boldtext}>{publicData?.city?.toUpperCase()}</span>
            ) : (
              <span className={css.boldtext}>{publicData?.county?.toUpperCase()}</span>
            )}
          </div>

          <div className={css.certificateInfo}>
            {certificate && !certificate.hideFromListingInfo ? (
              <span>{certificate.label}</span>
            ) : null}
          </div>
        </div>
        <div className={css.datetimeinfo}>
          {publicData?.categories[0] === 'camps' ? (
            <>
              {dateMaybe}
              {timeMaybe}
              {ageMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'afterSchoolClub' ? (
            <>
              {ageMaybe}
              {openTillMaybe}
              {closingTimeMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'birthdayParty' ? (
            <>
              {ageMaybe}
              {openTillMaybe}
              {noOfKidsMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'breakfastClub' ? (
            <>
              {ageMaybe}
              {openTillMaybe}
              {closingTimeMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'classes' ? (
            <>
              {dateMaybe}
              {timeMaybe}
              {ageMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'events' ? (
            <>
              {ageMaybe}
              {dateMaybe}
              {eventTimes}
              {cityMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'workshops' ? (
            <>
              {dateMaybe}
              {timeMaybe}
              {ageMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'placesToVisit' ? (
            <>
              {ageMaybe}
              {activityTypeMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'club' ? (
            <>
              {ageMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'inSchoolProgrammes' ? (
            <>
              {ageMaybe}
              {openTillMaybe}
              {noOfKidsMaybe}
              {priceDisplay}
            </>
          ) : publicData?.categories[0] === 'schoolTours' ? (
            <>
              {ageMaybe}
              {openTillMaybe}
              {noOfKidsMaybe}
              {priceDisplay}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};

ListingCardComponent.defaultProps = {
  className: null,
  rootClassName: null,
  renderSizes: null,
  filtersConfig: config.custom.filters,
  setActiveListing: () => null,
};

ListingCardComponent.propTypes = {
  className: string,
  rootClassName: string,
  filtersConfig: array,
  intl: intlShape.isRequired,
  listing: propTypes.listing.isRequired,

  // Responsive image sizes hint
  renderSizes: string,

  setActiveListing: func,
};

export default injectIntl(ListingCardComponent);
